
import React from "react";

export const EVOReactTableHelper = {
  ReactTable: {
    Sum: Sum,
    Count: Count,
    RenderHeader: RenderHeader,
    RenderFooter: RenderFooter,
    RenderDefaultBodyRows: RenderDefaultBodyRows,
    RenderDefaultPagination: RenderDefaultPagination,
  }
};

export default EVOReactTableHelper;

export const FooterCellCalculationType = {
  sum: "sum",
  count: "count",
};

export const DynamicCellItemType = {
  action : "action",
  text: "text",
  number: "number",
  integer: "integer",
  checkbox: "checkbox",
  react_select: "react_select",  
  cell_template: "cell_template"
};


/**
 * Calculate basic sum on given data column name
 */
function Sum(data, columnName) {        
    const result = React.useMemo(
       () => { 
          let sum = 0;    
          data.rows.forEach(x=> sum += Number(x.values[columnName])); 
          return Number(sum.toFixed(2));
        }
      );    
    return result;
  }

/**
 * Basic Count -> must be checked if it works on pagination
 */
function Count(data, columnName) {        
    const result = React.useMemo(
        () => data.rows.length
      );    
    return result;
  }

/**
 * Basic Template for generating all headers
 */
function RenderHeader(headerGroups) {  
  const getHeaderStyleOptions = (column) => {  
    let result = {};
    if (column.maxWidth)  {
      result.maxWidth =  column.maxWidth;
    }

    if (column.minWidth)  {
      result.minWidth =  column.minWidth;
    }

    if (column.width)  {
      result.width =  column.width;
    }
    return result;
  };

  function _getPlainHeaders(column) {
    return (<th {...column.getHeaderProps(
      {style : getHeaderStyleOptions(column)}
    )}>{column.render("Header")}</th>);
  }  

  function _getSortedHeaders(column) {   
    return (
        <th {...column.getHeaderProps(column.getSortByToggleProps({style : getHeaderStyleOptions(column)}))}>
            {column.render('Header')}
            {/* Add a sort direction indicator */}
            {/* <div
                      {...column.getResizerProps()}
                      className={`resizer ${
                        column.isResizing ? 'isResizing' : ''
                      }`}
                    /> */}
            <span>                    
                {column.isSorted
                ? column.isSortedDesc
                    ? ' 🔽'
                    : ' 🔼'
                : ''}
            </span>
        </th>
    )
  }  

  return (
    <>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            _getSortedHeaders(column)       
          ))}
        </tr>
      ))}
    </>
  );
}



/**
 * Basic Template for generating footer
 */
function RenderFooter(footerGroups) {
  const getFooterStyleOptions = (column) => {
    return {}
    return {      
        maxWidth: column.maxWidth,
        minWidth: column.minWidth,
        width: column.width,      
      };
  };

  return (
    <>
      {footerGroups.map((footerGroup) => (
        <tr {...footerGroup.getFooterGroupProps()}>
          {footerGroup.headers.map((column) => (
            <th {...column.getFooterProps({style: getFooterStyleOptions(column)})}>{column.render("Footer")}</th>
          ))}
        </tr>
      ))}
    </>
  );
}

/**
 * Basic Template for generating body rows
 */
function RenderDefaultBodyRows(rows, prepareRow) {
  const getCellStyleOptions = (cell) => {        
    return { textAlign: "center" }
    return {      
        maxWidth: cell.column.maxWidth,
        minWidth: cell.column.minWidth,
        width: cell.column.width,      
      };
  };

    return (
      <>
       {rows.map((row) => {          
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {               
                return (
                  <td
                    {...cell.getCellProps({style: getCellStyleOptions(cell)})}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </>
    );
}

/**
 * Basic Template for generating pagination (needs update)
 */
function RenderDefaultPagination(
    canPreviousPage,
    canNextPage,
    pageIndex,
    pageOptions,
    pageCount,

    pageSize,

    setPageSize,
    gotoPage,
    previousPage,
    nextPage,
    ) {
    return (
      <>
       {
          <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>{' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
         <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
           <span>
            | Go to page:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
       }
      </>
    );
}