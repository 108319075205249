import React from "react";
// import { CommonLoading } from 'react-loadingg';
// import InstanceHeader from  "../../views/instances/instanceHeader"
import CustomInstanceDetails from "./custom/custom.instance.details";
import EVOBaseComponent from "../../components/common/EvoBaseComponent";
class InstanceDetails extends EVOBaseComponent {
    constructor(props) {
        super(props);
        this.state = {                                                                  
            ...this.state,       
        };        
    };    

    componentDidMount() {
        super.componentDidMount()
    }
     
    render() {
        // if (this.state.loading || !this.state.workflowInfoDetails)
        //     return ( <CommonLoading /> );
        let workflowInstanceId = this.props.match.params.id;
        return <CustomInstanceDetails workflowInstanceId={workflowInstanceId} />       
    }
}

export default InstanceDetails;