import React from "react";
import ReactTooltip from 'react-tooltip';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    
    InputGroupText,
    Input,
    InputGroup,
} from "reactstrap";

import Modal from 'react-bootstrap/Modal';
import {UserRoleHelper} from "../../../common/userRoleHelper"
import {ApiCallerHelper} from "../../../api/apiCallerHelper"

import EVOAlertComponent from "../../../components/common/EvoAlertComponent";
import AssetHelper from "../../../common/AssetHelper"
import RedirectHelper from "../../../common/redirectHelper";
import FlowsEditModal from "./FlowsEditModal";


class Flows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modelId: null,     
            editModalVisible: false,  
            userDetails: null,
            flows: [],
            showModal: false,
        };
    };

    onHideModal = (forceUpdate) => {
        setTimeout(() => {
            let callBack;
            if (forceUpdate){
              callBack = this.getAndSetUserAndFlowStepsAsync;
            }
            this.setState({      
              editModalVisible: false
            }, callBack);
        }, 200);       
      }
    
      showModalEdit = (modelId) => {    
        this.setState({
            modelId: modelId,
            editModalVisible: true
        });    
      };

    getAndSetUserAndFlowStepsAsync = async () => {
        const currentUserResult = await ApiCallerHelper.Users.getCurrentAsync()
        if (!UserRoleHelper.isMaster(currentUserResult.data)){
            this.props.history.goBack();
        }    

        const worfklowsResult = await ApiCallerHelper.Flows.getAllAsync()                 
        this.setState({
          userDetails: currentUserResult.data,
          flows: worfklowsResult.data
        });
    }  

    async componentDidMount() {
        this.getAndSetUserAndFlowStepsAsync();       
    }


    async deleteFlow(flow_id) {
        let promptResult = await EVOAlertComponent.Prompt({});
        if (promptResult.isConfirmed === true) {
            const result = await ApiCallerHelper.Flows.deleteWorkflowAsync(flow_id);           
            const worfklowsResult = await ApiCallerHelper.Flows.getAllAsync();                 
            this.setState({
                flows: worfklowsResult.data
            })
        }        
    }

    render() {
        if (this.state.userDetails === null){
            return (<></>);            
        }

        if (!UserRoleHelper.isMaster(this.state.userDetails)){
            return (<></>);            
        }

        return (                           
            <Container fluid>
                <Row className="mt-0">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Card className="shadow-none no-padding">
                            <CardHeader className="border-0 no-padding">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0 no-wrap">Διαμόρφωση Ροών Εργασίας</h3>
                                    </div>
                                    <div className="col text-right">
                                        <Button        
                                            color="primary"
                                            className="tool-button--large"                                                
                                            onClick={() => this.showModalEdit(null)}
                                            size="sm"
                                        >
                                            Προσθήκη Ροής

                                        </Button>
                                    </div>

                                </Row>
                                <Table className="align-items-center table-flush" responsive style={{ marginTop: '20px' }}>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">ΟΝΟΜΑ ΡΟΗΣ</th>
                                            <th scope="col">ΠΕΡΙΓΡΑΦΗ</th>
                                            <th scope="col">ΣΕΙΡΑ ΕΜΦΑΝΙΣΗΣ</th>
                                            <th scope="col">ΕΝΕΡΓΕΙΕΣ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.flows.map((flow) =>
                                            <tr key={flow.Name}>
                                                <td>{flow.Name}</td>
                                                <td>{flow.Description}</td>
                                                <td>{flow.Priority}</td>
                                                <td>                                                    
                                                    <Button
                                                        color="link"
                                                        onClick={() => this.showModalEdit(flow.Id)}
                                                        size="sm"
                                                    >
                                                        <img src={AssetHelper.Icons.edit}></img>
                                                        {/* <i className="fas fa-pencil-alt" /> */}
                                                    </Button>
                                                    <Button
                                                        color="link"
                                                        onClick={() => { this.deleteFlow(flow.Id) }}
                                                        size="sm"
                                                        title="Διαγραφή"
                                                    >
                                                        <img src={AssetHelper.Icons.delete}></img>
                                                        {/* <i className="fas fa-trash" /> */}
                                                    </Button>
                                                    <Button
                                                        color="warning"                                                        
                                                        size="sm"
                                                        onClick={()=>{ RedirectHelper.RedirectTo.flowEdit(flow.Id) }}
                                                    >
                                                        Επεξεργασία Βημάτων
                                                    </Button>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>

                <div className='push'></div>
                <FlowsEditModal
                    isShown={this.state.editModalVisible}
                    modelId={this.state.modelId}
                    onHideModal={this.onHideModal}
                ></FlowsEditModal>   
            </Container>                   
        );
    }

}

export default Flows;