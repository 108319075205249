import React from 'react';
import { Route, Redirect } from "react-router-dom";
import Routes from "./routes";
import {RoutesTypes} from "./common/enums/routesTypes.enum"


//const GuardedRoute = ({ component: Component, auth, ...rest }) => (
const GuardedRoute = ({ Component, auth, ...rest }) => (    
    <Route {...rest} 
        exact path={rest.path}
        render={(props) => (
        auth === true
            ? <Component {...props} />
            : <Redirect to={Routes.getURLByRouteType(RoutesTypes.LOGOUT)} />
    )} />
)

export default GuardedRoute;