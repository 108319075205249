  /**
   * Version 0.0.1
   */
import React from "react";
import { Container, Row, Col } from "reactstrap";

export const EVOLayoutHelper = {
  /**
   * Renderer for reactstrap
   */
  Renderer: {
    /**
     * wraps action in column, uses bootstrap breakpoints
     */  
    _getCol(data) {      
      let metadata = data.action?.Metadata?? null;
      if (!metadata){return ('');}                  
      return (
        <>
          <Col
            sm={metadata.bsBreakpoint.sm ?? null}
            md={metadata.bsBreakpoint.md ?? null}
            lg={metadata.bsBreakpoint.lg ?? null}
            xl={metadata.bsBreakpoint.xl ?? null}
            xxl={metadata.bsBreakpoint.xxl ?? null}
          >
          {data.action.Name}
          </Col>
        </>
      );
    },
    /**
     * Creates batches of actions grouped by row (isBsNewRow property)
     */   
    _getDataPerRow(data) {
      let batches = [];
      var indx = 0;      
      batches[indx] = []; 
      data.forEach((action, index) => {
        let isBsNewRow = action.action?.Metadata?.isBsNewRow?? true;
        if (isBsNewRow == 'true') {isBsNewRow = true;}
        if (isBsNewRow == 'false') {isBsNewRow = false;}
        if (index != 0 && isBsNewRow) {
          batches[++indx] = [];
        }
        batches[indx].push(action);
      });  
      return batches;    
    },
    /**
     * Renders the output of data based on bootstrap logic as declared in action metadata, grouped by rows, 
     * additional logic can be passed as extra arguments (example permissions or callbacks) 
     */   
    render(data, includeContainer) {
      let result = (
        <>
          {EVOLayoutHelper.Renderer._getDataPerRow(data).map(
            (data) => (
              <Row>
                {data.map((action) => (
                  <>{EVOLayoutHelper.Renderer._getCol(action)}</>
                ))}
              </Row>
            )
          )}
        </>
      );

      if (!includeContainer){
        return result;
      }

      return (
        <Container>
          {result}
        </Container>
      );
    }
  },
};
