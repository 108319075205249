/*
* Needs values refactor
* TO BE USED AS EXAMPLE
*/
import { UserRoleHelper } from "./userRoleHelper";

export const ValidationHelper = {
  Files: {
    isDeleteFileEnabledByUserRole: isDeleteFileEnabledByUserRole,
  },
  Instances: {
    isAssignedToUser: isAssignedToUser,
    isCurrentDynamicStepStatus : isCurrentDynamicStepStatus,
    isSubmitionStep : isSubmitionStep
  },
  Steps: {
    Permission: {
      hasViewPermission: hasViewPermission,
    },              
  },
};

function isDeleteFileEnabledByUserRole(
  user,
  workflowInstanceSubmittedDate,
  fileUploadDate
) {
  function _isFilePreviouslySubmitted(workflowInstanceSubmittedDate, fileUploadDate) {
    return Date.parse(fileUploadDate) < Date.parse(workflowInstanceSubmittedDate);
  }  

  if (UserRoleHelper.isEngineer(user)) {    
    return !_isFilePreviouslySubmitted(workflowInstanceSubmittedDate, fileUploadDate);
  }
  return false;
}


function isAssignedToUser(
  user,
  instance  
) {
  return instance.IsAssigned && (instance.UserAssignedId === user.Id);  
}

function hasViewPermission(
  user,
  dynamicDataStep  
) {
  return dynamicDataStep.ViewPermission.includes(user.PersonRole);  
}
 
function isCurrentDynamicStepStatus(
  instance,
  dynamicDataStep  
) {
  return dynamicDataStep.Status === instance.Status
}

function isSubmitionStep(
  instance
) {
  return instance.Status === 1
}


 