import ApiCallerHelper from "../api/apiCallerHelper";
const ExternalApiService = {
    _cacheContractorProductPrices : [],
    getContractorProductPrices: async (contractorCode) => {
        let cache = ExternalApiService._cacheContractorProductPrices;
        let contractorPrices = cache.find(x=>x.contractorCode == contractorCode);
        if (contractorPrices) {
           return contractorPrices;
        }
        let resultAsync = await ApiCallerHelper.External.getErgolavoiProductPrices(contractorCode);
        let model = {contractorCode:contractorCode, models : resultAsync};        
        cache.push(model);        
        ExternalApiService._cacheContractorProductPrices = cache;  
        return model;     
    },
    _cacheContractors : [],
    getContractors: async (workCategoryTypeValue) => {
        let cache = ExternalApiService._cacheContractors;
        let contractors = cache.find(x=>x.workCategoryTypeValue == workCategoryTypeValue);
        if (contractors) {
           return contractors;
        }        
        let resultAsync = await ApiCallerHelper.External.getErgolavoi(
            workCategoryTypeValue
        );
        let model = {workCategoryTypeValue:workCategoryTypeValue, models : resultAsync};        
        cache.push(model);   
        ExternalApiService._cacheContractors = cache;
        return model;   
    }
   
}

export default ExternalApiService;