  import {
    get, getAsync,
    post, postAsync, postFileAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    getFileAsync,    
  } from "./apiCallerHelper.core";

  import {
    validateUriToken,
    validatePhoneToken,
    phoneTokenGenerate,
    getToken
  } from "./apiCallerHelper.auth";

  import {
    getAllStepsByWorkflowIdAsync, 
    addNewAsync,
    deleteWorkflowStepAsync
  } from "./apiCallerHelper.steps";
  import {
    changeFieldAsync,
    getCurrentStepActionAsync,
    addNewStepAction,
    deleteWorkflowStepActionAsync,
    changeOrderAsync
  } from "./apiCallerHelper.stepActions";

  import {
    getAllInstancesAsync,
    getInstanceAsync,
    updateInstanceAsync,
    submitInstanceAsync,
    approveInstanceAsync,
    declineInstanceAsync,
    assignInstanceAsync,
    initialiseInstanceAsync,
    // newInstanceAsync,
    newInstance,
    getDetailsAsync,
    newInternalCommentAsync,
    fetchParentInstanceDetailsAsync,
    fetchChildInstanceDetailsAsync,
    fetchChildDetailsByAttribute,
    getInstanceMappingInfos,
    //getFaultDetails,
    getInstancesDetailsFaultResidentAsync,
    getInstancesDetailsFaultAsync,
    getInstancesDetailsVoucherAsync,
    getInstancesDetailsExtraAsync,
    createChildFlowAsync,
    getParentInstanceIdAsync,
    deleteInstanceAsync,
    getIsDeletedInstanceAsync,
    getVoucherDatatable,
    getIsIntanceSubmittedByMechanic
  } from "./apiCallerHelper.instances";

  import {
    getFileStatusByWorkflowInstanceIdAsync,
    getAllFilesByWorkflowInstanceIdAsync,
    deleteFileAsync,
    getFileURLByIdAsync,
    getFile,
    uploadFile,
  } from "./apiCallerHelper.files";

  import {
    buttonPressedAsync
  } from "./apiCallerHelper.dynamic";

  import {
    getAllUsers,
    toggleUserActive,
    createNewUser,
    editUser,
    passwordchange,    
    getUserCurrentAsync,
    getUserCurrent,
  } from "./apiCallerHelper.users";
  
  import {
    getErgolavoi,   
    getErgolavoiProductPrices,
    getHouseInfoById,
    getHouses,
  } from "./apiCallerHelper.External";


  import {
    getAllFlowsAsync,
    getAllAllowedAsync,
    getWorkflowsInfoAsync,
    newWorkflowAsync,
    editWorkflowAsync,
    deleteWorkflowAsync
  } from "./apiCallerHelper.flows"

  import {
    getAllPolygons,
    getPolygon,
    calculateInstanceLocation,
    addNewPolygon,
    editPolygon,
    deletePolygon
  } from "./apiCallerHelper.locations"

  import {
    getAllRoles,
    getAllGroups,
    getGroupsByRole,
    getRoleById,
    getGroupById,
  } from "./apiCallerHelper.roles"

  import {
    getGridViewDataById,
    getPageViewDataById,         
  } from "./apiCallerHelper.views";

  import { getNotificationsAsync, setNotificationsAsReadAsync } from "./apiCallerHelper.notifications";

  import {
    getMonthlyVouchers,
    newMonthlyVouchers,
  } from './apiCallerHelper.statistics';

  export const ApiCallerHelper = {
    hasInitialisedToken: () => {
      return hasInitialisedToken();
    },
    Common: {
      get,
      post,
      delete: del,
    },
    Auth: {
      validateUriToken: validateUriToken,
      validatePhoneToken: validatePhoneToken,
      phoneTokenGenerate: phoneTokenGenerate,
      getToken: getToken,
    },
    Users: {
      getCurrentAsync: getUserCurrentAsync,
      //getCurrent: getUserCurrent,
      getAllUsers: getAllUsers,
      toggleUserActive: toggleUserActive,
      passwordchange: passwordchange,
      createNewUser: createNewUser,
      editUser: editUser,
    },
    Workflowinstances: {
      getUncompletedResearchesAsync:
        getWorkflowinstancesUncompletedResearchesAsync,
      getUncompletedResearches: getWorkflowinstancesUncompletedResearches,
    },
    Flows: {
      getAllAsync: getAllFlowsAsync,
      getAllAllowedAsync: getAllAllowedAsync,
      getWorkflowsInfoAsync: getWorkflowsInfoAsync,
      newWorkflowAsync: newWorkflowAsync,
      editWorkflowAsync: editWorkflowAsync,
      deleteWorkflowAsync: deleteWorkflowAsync,
    },
    Instances: {
      getAllInstancesAsync: getAllInstancesAsync,
      getInstanceAsync: getInstanceAsync,
      updateInstanceAsync: updateInstanceAsync,
      submitInstanceAsync: submitInstanceAsync,
      approveInstanceAsync: approveInstanceAsync,
      declineInstanceAsync: declineInstanceAsync,
      assignInstanceAsync: assignInstanceAsync,
      initialiseInstanceAsync: initialiseInstanceAsync,
      // newInstanceAsync : newInstanceAsync,
      newInstance: newInstance,
      getDetailsAsync: getDetailsAsync,
      newInternalCommentAsync: newInternalCommentAsync,
      fetchParentInstanceDetailsAsync: fetchParentInstanceDetailsAsync,
      fetchChildInstanceDetailsAsync: fetchChildInstanceDetailsAsync,
      fetchChildDetailsByAttribute: fetchChildDetailsByAttribute,
      getInstanceMappingInfos: getInstanceMappingInfos,
      //getFaultDetails : getFaultDetails,
      getInstancesDetailsFaultResidentAsync:
        getInstancesDetailsFaultResidentAsync,
      getInstancesDetailsFaultAsync: getInstancesDetailsFaultAsync,
      getInstancesDetailsVoucherAsync: getInstancesDetailsVoucherAsync,
      getInstancesDetailsExtraAsync: getInstancesDetailsExtraAsync,
      createChildFlowAsync: createChildFlowAsync,
      getParentInstanceIdAsync: getParentInstanceIdAsync,
      deleteInstanceAsync,
      getIsDeletedInstanceAsync,
      getVoucherDatatable,
      getIsIntanceSubmittedByMechanic
    },
    Steps: {
      getAllByWorkflowIdAsync: getAllStepsByWorkflowIdAsync,
      addNewAsync: addNewAsync,
      deleteWorkflowStepAsync: deleteWorkflowStepAsync,
    },
    StepActions: {
      changefieldAsync: changeFieldAsync,
      getCurrentStepActionAsync: getCurrentStepActionAsync,
      addNewStepAction: addNewStepAction,
      deleteWorkflowStepActionAsync: deleteWorkflowStepActionAsync,
      changeOrderAsync: changeOrderAsync,
    },
    Files: {
      getFileStatusByWorkflowInstanceIdAsync:
        getFileStatusByWorkflowInstanceIdAsync,
      getAllFilesByWorkflowInstanceIdAsync:
        getAllFilesByWorkflowInstanceIdAsync,
      deleteFileAsync: deleteFileAsync,
      getFileURLByIdAsync: getFileURLByIdAsync,
      getFile: getFile,
      uploadFile: uploadFile,
    },
    Dynamic: {
      buttonPressedAsync: buttonPressedAsync,
    },
    External: {
      getErgolavoi: getErgolavoi,
      getErgolavoiProductPrices: getErgolavoiProductPrices,
      getHouseInfoById: getHouseInfoById,
      getHouses: getHouses,
    },
    Locations: {
      getAllPolygons: getAllPolygons,
      getPolygon: getPolygon,
      calculateInstanceLocation: calculateInstanceLocation,
      addNewPolygon: addNewPolygon,
      editPolygon: editPolygon,
      deletePolygon: deletePolygon,
    },
    Roles: {
      getAllRoles: getAllRoles,
      getAllGroups: getAllGroups,
      getGroupsByRole: getGroupsByRole,
      getRoleById: getRoleById,
      getGroupById: getGroupById,
    },
    Views: {
      getGridViewDataById: getGridViewDataById,
      getPageViewDataById: getPageViewDataById,
    },
    Notifications: {
      getNotificationsAsync,
      setNotificationsAsReadAsync,
    },
    Statistics: {
      getMonthlyVouchers,
      newMonthlyVouchers,
    }
  };
    
  async function getWorkflowinstancesUncompletedResearchesAsync() {
    var route = "/api/workflowinstances/uncompletedResearches/";
    return getAsync(route);
  }
  
  async function getWorkflowinstancesUncompletedResearches(successCallback) {
    let res = await getWorkflowinstancesUncompletedResearches();
    if (res) {
      successCallback(res);
    }
  }

  async function getAllRolesAsync() {
    var route = "/api/roles/all/";
    return getAsync(route);
  }

  export default ApiCallerHelper;