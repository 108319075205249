import React, { useState } from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";

//import InstanceHeader from "components/Headers/InstanceHeader.js";
import 'moment/locale/el';
import {ApiCallerHelper} from "../../api/apiCallerHelper"
import ReactTooltip from 'react-tooltip';
import InstanceBase, {ViewTypeEnum} from "./InstanceBase";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";

class Instance extends InstanceBase {

    constructor(props) {    
        super(props);   
        this.state = {                                  
            ...this.state,       
        };  
        this.bindEvents();   
      }     
    
    __ViewType = ViewTypeEnum.NEW;

    componentDidMount() {
        super.componentDidMount(     
            this.__initializeComponent   
       )
    }

    __initializeComponent = async () => {   
        let defaultNewWorkflowInstanceId = GlobalParametersHelper.Theme.SelectedTheme.Flows.DefaultNewWorkflowInstanceId;
        let initialiseInstanceResult = {
            data : {
                stepsInfused : []
            }
        };
        if (defaultNewWorkflowInstanceId){
            initialiseInstanceResult = await ApiCallerHelper.Instances.initialiseInstanceAsync(defaultNewWorkflowInstanceId);
        }        
        this.setState({
            submitButton:  0,
            dropdownActive:  0,
            createButton: 1,
            steps: initialiseInstanceResult.data.stepsInfused,            
            selectedWorkflow: this.state.selectedWorkflow
        }, async ()=>{
           await this.ExecuteOverridesOnMountAsync(this, this.state, this.state.selectedWorkflow, 1, null)}
        );    
    }

    bindEvents = () => {
        this.onClickContinueNewWorkflow = this.onClickContinueNewWorkflow.bind(this);
        this.onChangeSelectedWorkflow = this.onChangeSelectedWorkflow.bind(this);
        // this.onClickCreateInstance = this.onClickCreateInstance.bind(this);
    }   
        
    isSubmitButtonEnabled = () => {
        return this.state.submitButton === 1;
    }  

    onClickContinueNewWorkflow = async wId => {                                
        let initialiseInstanceResult = await ApiCallerHelper.Instances.initialiseInstanceAsync(this.state.selectedWorkflow);
        this.setState({
            submitButton:  0,
            dropdownActive:  0,
            createButton: 1,
            steps: initialiseInstanceResult.data.stepsInfused,
            fileUpload: null,
            selectedWorkflow: this.state.selectedWorkflow
        }, async ()=>{
            await this.ExecuteOverridesOnMountAsync(this, this.state, this.state.selectedWorkflow, 1, null)}
        );        
    }

    onChangeSelectedWorkflow(event) {        
        this.setState({ selectedWorkflow: event.target.value });

        if (event.target.value != '0') {
            this.setState({ submitButton: 1 });
        }

        if (event.target.value === '0') {
            this.state.submitButton = 0;
        }
    }   

    _RenderHelper = {
        RenderNewFlowHeader : () => {
            return (<>
                {/* <InstanceHeader /> */}
                <Container fluid> <Row className="mt-0">
                    <Col className="mb-xl-0" xl="12">
                    <Card className="shadow-none no-padding">
                        <CardHeader className="border-0 no-padding">                                        
                            <h3 className="mb-0">Υποβολή Νέας Βλάβης</h3>                                                    
                        </CardHeader>
                    </Card>
                    </Col>
                </Row></Container>               
                </>)
        },
        RenderNewFlowSelection : () => {
            if (GlobalParametersHelper.Theme.SelectedTheme.Flows.DefaultNewWorkflowInstanceId != null){
                return; 
            }
            let isSelectNewFlowEnabled = this.state.dropdownActive === 1;         
            // if (this.isSelectNewFlowEnabled() === false){
            //     return;
            // }
            
            let submitButton = (<></>);
            if (this.isSubmitButtonEnabled){
                submitButton = (                
                    <Button
                        color="success"
                        className="tool-button--primary float-right"
                        onClick={() => { this.onClickContinueNewWorkflow() }}
                        size="sm"
                        //style={{backgroundImage: "url(" + require("assets/img/btn-background.png") + ")"}}
                        >
                        Συνέχεια <i className="fas fa-chevron-right" />
                    </Button>               
                );
            }

            return (<>
            <Container fluid>                        
            <Row className="mt-5">
            <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow-none">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                            Υποβολή Νέου Αιτήματος
                        </Col>
                        <Col className="text-right" xs="4">
                            {submitButton}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Form>
                        <div className="pl-lg-4">
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="consumer-client"
                                        >
                                            Ροή Εργασίας
                                        </label>
                                        &nbsp;<i className="fa fa-info-circle" data-tip data-for={'info-katigoria-aitimatos'} aria-hidden="true"></i>
                                        <Input
                                            className="form-control-alternative"
                                            id="consumer-client"
                                            type="select"
                                            onChange={this.onChangeSelectedWorkflow}
                                        >
                                            <option value='0' key='-1'>-- Επιλέξτε ροή εργασίας --</option>
                                            { this.state.workflows.map((dynamicWorkflows, index) =>
                                                <option key={index} value={dynamicWorkflows.Id}>{dynamicWorkflows.Name} </option>
                                            )}
                                        </Input>
                                        <ReactTooltip id={'info-katigoria-aitimatos'} type='info'>
                                            <span>Επιλέξτε ροή εργασίας</span>
                                        </ReactTooltip>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </CardBody>
            </Card>
            </Col>
        </Row>
        </Container></>);
        },
        RenderStepsHeader : (dynamicData, key) => {            
            return (<Row className="align-items-center">
                <Col xs="7">
                    <h3 className="mb-0">{dynamicData.step.Name}</h3>
                </Col>
                <Col  xs="5">  
                <div className="float-right">                    
                    {this.NavigateHomeButtonComponent()}  
                    {this.CreateButtonComponent(
                        {instanceId:this.state?.instanceId??null,
                        selectedWorkflow:this.state?.selectedWorkflow??null,
                        instanceStepsData:this.state?.steps[0],
                        self: this
                        })
                    }   &nbsp;  
                </div>                                                                               
                </Col>
            </Row>);      
        },
        RenderStepsFooter : () => {
            return (
                <Row className="align-items-center">
                    <Col xs="8">
                        {/* <h3 className="mb-0">{dynamicData.step.Name}</h3> */}
                    </Col>
                    <Col className="text-right" xs="4">
                        {this.CreateButtonComponent(
                            {instanceId:this.state?.instanceId??null,
                            selectedWorkflow:this.state?.selectedWorkflow??null,
                            instanceStepsData:this.state?.steps[0],
                            self: this
                            })
                        }
                    </Col>
                </Row>
            )
        },
        RenderStepInstances : () => {
            if (!this.state.steps || this.state.steps.length === 0){
                return <></>
            }  

            return (<>                
                  { this.state.steps.map((dynamicData, key) =>                          
                      <Container className="mt-2" fluid key={key}>                                                  
                          <Row className="mt-5">
                              <Col className="order-xl-1" xl="12">
                                  <Card className="bg-secondary no-shadow">
                                      <CardHeader className="bg-white border-0">
                                          {this._RenderHelper.RenderStepsHeader(dynamicData, key)}
                                      </CardHeader>                                      
                                        <CardBody>
                                            {this.RenderHelper.RenderStepBody(dynamicData)}
                                        </CardBody>    
                                        <CardFooter className="bg-white border-0">
                                            {this._RenderHelper.RenderStepsFooter()}
                                        </CardFooter>                                  
                                  </Card>
                              </Col>
                          </Row>
                      </Container>
                  )}                  
              </>);
        }       
    }        

     render() { 
        if (!this.Base.Security.isUserLogged()) {
            return (
                <>
                    {/* <GenericHeader /> */}
                    {/* <CommonLoading /> */}
                </>
            ) 
        }    
        
        return (
        <>
            {this._RenderHelper.RenderNewFlowHeader()}
            {this._RenderHelper.RenderNewFlowSelection()}                
            {this._RenderHelper.RenderStepInstances()}                
        </>
        )
    }
}
export default Instance;