import {
    get, getAsync,
    post, postAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    ContentTypeEnum
    } from "./apiCallerHelper.core";

export async function getAllRoles() {
    var route = "/api/roles/all/roles/"
    return getAsync(route);
}

export async function getAllGroups() {
    var route = "/api/roles/all/groups/"
    return getAsync(route);
}

export async function getGroupsByRole(roleId) {
    var route = "/api/roles/roles_by_group/" + roleId + "/"
    return getAsync(route);
}

export async function getRoleById(roleId) {
    var route = "/api/roles/role/" + roleId + "/"
    return getAsync(route);
}

export async function getGroupById(groupId) {
    var route = "/api/roles/group/" + groupId + "/"
    return getAsync(route);
}