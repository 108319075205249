import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";
import axios from "axios";

export async function validateUriToken(uriToken) {
  var route = "/api/auth/uritoken/validate/";
  let data = { uri_token: uriToken };
  return postAsync(route, data, true);
}

export async function validatePhoneToken(phoneToken) {
  var route = "/api/auth/phonetoken/validate/";
  let data = { phone_token: phoneToken };
  return postAsync(route, data, true);
}

export async function phoneTokenGenerate(phoneNumber) {
  var route = "/api/auth/phonetoken/generate/";
  let data = { phone_number: phoneNumber };
  return postAsync(route, data, true);
}

export async function getToken({ username, password }) {
  try {
    const api = axios.create({
      baseURL: process.env.REACT_APP_API_LINK,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    return await api.post(
      "/api/auth/token/",
      `username=${username}&password=${password}`
    );
  } catch (error) {
    // _exception_handler(error);
  }
}
