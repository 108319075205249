import React from "react";
import {ApiCallerHelper} from "../../api/apiCallerHelper"
import {RedirectHelper} from "../../common/redirectHelper"
import GlobalParameters from "../../common/GlobalParametersHelper";

class UriTokenLogin extends React.Component {
  constructor(props) {
    super(props);    
  };

  
  async validateUriToken() {
    let uriToken = this.props.match.params.uri_token;        
    let tokenResult = await ApiCallerHelper.Auth.validateUriToken(uriToken);  
    GlobalParameters.Token.set(tokenResult.access_token);    
    RedirectHelper.RedirectTo.home();    
  }

  componentDidMount() {
    this.validateUriToken();
  };

  render() {
    return (
      <div>
        Redirecting Please Wait...
      </div>
    );
  }

}

export default UriTokenLogin;