import { React, Fragment, useState, useContext, createContext, useEffect } from "react";

import { DynamicStepActionTypes } from "../../common/enums/dynamicStepActionTypes.enum";
import 'moment/locale/el';
import ReactDatetime from "react-datetime";
import {  
  FormGroup,    
  InputGroupText,
  InputGroup,
} from "reactstrap";
import DynamicActionLabel from "./DynamicActionLabel";
import { OverrideRules } from "../../views/instances/custom/custom.InstanceBase.overrides.aspra-spitia";
import { DynamicInput } from "./DynamicInput";

export const DynamicDateTimePicker = ({
  self,
  isEnabled,
  dynamicActions,
  stepId,
  isShowlabel
}) => {    
    if (!
        (dynamicActions.action.Type === DynamicStepActionTypes.date  ||
          dynamicActions.action.Type === DynamicStepActionTypes.dateReadonly     
        )){
        return (<></>)
    }      
    let ovverideRule = OverrideRules.getOnRenderControlOverrideRule(
      self.state,
      dynamicActions.action.Id        
    );    
    
    if (ovverideRule){
      if (ovverideRule.isEnabled != null){
        isEnabled = ovverideRule.isEnabled;
      }
    }

    if (isEnabled == false){
      dynamicActions.action.Type = DynamicStepActionTypes.dateReadonly;
    } 
    else{
      dynamicActions.action.Type = DynamicStepActionTypes.date;
    }    
    
    // if (dynamicActions.action.Type = DynamicStepActionTypes.date){
    //   dynamicActions.action.Type = DynamicStepActionTypes.date;
    // } 
    // else{
    //   dynamicActions.action.Type = DynamicStepActionTypes.dateReadonly;
    // }    
    
    isShowlabel = isShowlabel || false;        
    let dateValue = Date.parse(dynamicActions.data.Data);
    if (Number.isNaN(dateValue)){
      dateValue = dynamicActions.data.Data;
    }            
    return (
      <Fragment>
        {isShowlabel && (
          <Fragment>
            <DynamicActionLabel
              title={dynamicActions.action.Name}
              description={dynamicActions.action.Description}
            />
            <br></br>
          </Fragment>
        )}
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>

            {isEnabled  == true && (
            <ReactDatetime
              id={stepId + "/" + dynamicActions.action.Id}
              inputProps={{
                placeholder: dynamicActions.action.Description,
              }}
              timeFormat={false}
              name={stepId + "/" + dynamicActions.action.Id}
              value={dateValue}
              onChange={(value) =>
                self.updateInputValue(
                  isEnabled,
                  value,
                  stepId,
                  dynamicActions.action.Id
                )
              }              
            />
            )}
            {isEnabled  == false && (
             <DynamicInput
              self={self}
              dynamicActions={dynamicActions}
              stepId={stepId}
              isEnabled={isEnabled}
              isShowlabel={false}                    
            />
            )}
          </InputGroup>
        </FormGroup>
      </Fragment>
    );
 
};
