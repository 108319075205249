import React from "react";
import { useEffect, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import InstanceExistingSpecificFlowStep from "../InstanceExistingSpecificFlowStep";
import InstanceExistingSpecificFlowStepVoucher from "../InstanceExistingSpecificFlowStepVoucher";
import {
  getVoucherInstanceIdAsync,
  getExtraInstanceIdAsync,
} from "api/apiCallerHelper.instances";
export const CustomOverridesLayout = ({ layoutParameters, defaultContent }) => {
  //Tabs should not be rendered with map , issue with react state refresh rerendered enforced
  // this layout to be rendered directly on dom
  const [voucherId, setVoucherId] = useState(undefined);
  const [extraId, setExtraId] = useState(undefined);
  let tabLayouts = layoutParameters.tabsLayout;

  const findWorkflowInstanceId = (tabLayoutsObject) => {
    // Iterate over each object in the array
    for (var i = 0; i < tabLayoutsObject.length; i++) {
      // Check if the object has the workflowInstanceId property
      if (tabLayoutsObject[i].workflowInstanceId) {
        // Return the workflowInstanceId value
        return tabLayoutsObject[i].workflowInstanceId;
      }
    }

    // Return null if no workflowInstanceId found
    return null;
  };

 useEffect(() => {
   const fetchVoucherInstanceId = async () => {
     const res = await getVoucherInstanceIdAsync(
       tabLayouts[2].workflowInstanceId
     );
     setVoucherId(res.data);
   };

   const fetchExtraInstanceId = async () => {
     const extra = await getExtraInstanceIdAsync(
       findWorkflowInstanceId(tabLayouts)
     );
     setExtraId(extra?.data ?? undefined);
   };

   if (tabLayouts.length === 3) {
     fetchVoucherInstanceId();
   } else {
     fetchExtraInstanceId();
   }
 }, []);

  return (
    <>
      {tabLayouts.length === 2 || voucherId !== undefined ? (
        <Tabs defaultActiveKey="0" className={"tool-tabs-title--bold"}>
          <Tab eventKey={"0"} title={tabLayouts[0].title} key={"0"}>
            <>{defaultContent}</>
          </Tab>
          <Tab eventKey={"1"} title={tabLayouts[1].title} key={"1"}>
            {tabLayouts.length === 3 ? (
              <InstanceExistingSpecificFlowStepVoucher
                workflowInstanceId={voucherId}
              />
            ) : (
              <InstanceExistingSpecificFlowStep
                workflowInstanceId={tabLayouts[1].workflowInstanceId}
                statusToShow={tabLayouts[1].workflowStepStatus}
              />
            )}
          </Tab>
          {(tabLayouts.length === 3 || extraId) && (
            <Tab
              eventKey={"2"}
              title={
                extraId == undefined
                  ? tabLayouts[2].title
                  : "Πρόσθετες Εργασίες"
              }
              key={"2"}
            >
              
              <InstanceExistingSpecificFlowStep
                workflowInstanceId={
                  extraId == undefined
                    ? tabLayouts[2].workflowInstanceId
                    : extraId
                }
                statusToShow={
                  extraId == undefined ? tabLayouts[2].workflowStepStatus : 1
                }
              />
            </Tab>
          )}
        </Tabs>
      ) : null}
    </>
  );
};

export default CustomOverridesLayout;
