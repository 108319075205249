import styled from 'styled-components'

export const Styles = styled.div`
  
  .tool-loader--container {  
      opacity:0.8;
      background-color:#ccc;
      position:fixed;
      width:100%;
      height:100%;
      top:0px;
      left:0px;
      z-index:999000;
  }

  .tool-loader--spinner {          
      text-align:center;
      width:100%;
      position:fixed;
      left: 50%;
      right: 50%;
      top:50%; /* Keep at the very top of the viewport */    
  }


`