import { useForm, Controller } from "react-hook-form";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  InputGroup
} from "reactstrap";
import Select from 'react-select'
import {EVOReactSelectHelper} from "./evo.react-select-helper";

export const EVOReactHookFormHelper = {
  RenderErrorIfAny:({errors, fieldName, fieldTitle}) => {
    if (errors[fieldName]?.type === "required")
    {
      return (<p class="text-warning">To πεδίο {fieldTitle} είναι υποχρεωτικό</p>)
    }        
  },
  Input: ({ control, errors, name, rules, ...fields}) => {     
    return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Input
            {...field}                                  
            {...fields}            
          />
        )}
      />
      {EVOReactHookFormHelper.RenderErrorIfAny({
        errors: errors,
        fieldName: name,
      })}
    </>
  )},
  Select: ({ control, errors, name, rules, options, isDisabled, ...fields}) => (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {                            
        return (
          <>
            <Select
              {...field}
              {...fields}
              options={options}              
              //isMulti
              isDisabled={isDisabled}                              
              styles={EVOReactSelectHelper.getDefaultStyles()}
            />
            {EVOReactHookFormHelper.RenderErrorIfAny({
              errors: errors,
              fieldName: name,
            })}
          </>
        );
      }}
    />
  ),    
  
}
export default EVOReactHookFormHelper;