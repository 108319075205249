import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";
import EVOAlertComponent from "../components/common/EvoAlertComponent";

export const WorkCategoryEnum = {
  X00: "X00",
  X01: "X01",
  X02: "X02",
  X03: "X03",
};

export async function getErgolavoi(workCategoryEnum) {
  var route = "/api/external/data/";
  let data = { Table: "ergolavoi" };
  data.Parameter = "teParamType";
  data.Value = workCategoryEnum;
  let asyncResult = await postAsync(route, data);
  let result = [];
  if (asyncResult.isSuccess === false) {
    EVOAlertComponent.Alert({ message: asyncResult.errorMessage });
  } else {
    result = asyncResult.response.VScTErgolaboi ?? [];
  }
  return result;
}

export async function getErgolavoiProductPrices(contractorCode) {
  var route = "/api/external/data/";
  let data = { Table: "work_prices" };
  data.Parameter = "wppeErgId";
  data.Value = contractorCode;
  let asyncResult = await postAsync(route, data);
  let result = [];
  if (asyncResult.isSuccess === false) {
    EVOAlertComponent.Alert({ message: asyncResult.errorMessage });
  } else {
    let values = asyncResult.response.VScTWorkpricesPerErgolavo;
    values.forEach((el) => {
      el.__productDescription = el.wvpDescription;
      el.__productQA = el.wvpMm;
      el.__productPrice = el.wppePrice;
      el.__productCode = el.wppeSapId;
      el.__productCategoryType = el.wvpCatDesc;
      el.__productTechnDescr = el.wvpTechnDescr;
      result = values;
    });
  }
  return result;
  // console.log(result);
  // return {
  //   ContractorProductCodes : [
  //     { __productDescription: 'Υλικο Πλαστικός Σωλήνας 1', __productQA : "TMX", __productPrice: 10, __productCode: 'Code1', __productCategoryType : "15-ΥΔΡΑΥΛΙΚΑ"},
  //     { __productDescription: 'Υλικο Πλαστικός Σωλήνας 2', __productQA : "TMX", __productPrice: 20, __productCode: 'Code2', __productCategoryType : "15-ΥΔΡΑΥΛΙΚΑ"},
  //     { __productDescription: 'Υλικο Πλαστικός Σωλήνας 2', __productQA : "TMX", __productPrice: 30, __productCode: 'Code3', __productCategoryType : "15-ΥΔΡΑΥΛΙΚΑ"},
  //     { __productDescription: 'Υλικο Πλαστικός Σωλήνας 3', __productQA : "TMX", __productPrice: 40, __productCode: 'Code4', __productCategoryType : "15-ΥΔΡΑΥΛΙΚΑ"},
  //     { __productDescription: 'Υλικο Πλαστικός Σωλήνας 3', __productQA : "TMX", __productPrice: 50, __productCode: 'Code5', __productCategoryType : "15-ΥΔΡΑΥΛΙΚΑ"},
  //   ]
  // }
}

export async function getHouseInfoById(externalHouseId) {
  var route = "/api/external/data/";
  let data = { Table: "houses" };
  data.Parameter = "hHouseid";
  data.Value = externalHouseId;
  let asyncResult = await postAsync(route, data);
  let result = [];
  if (asyncResult.isSuccess === false) {
    EVOAlertComponent.Alert({ message: asyncResult.errorMessage });
  } else {
    result = asyncResult.response.VScTHouses ?? [];
  }
  return result;
}

export async function getHouses(filters) {
  var route = "/api/external/data/";
  let data = { 
    Table: "houses",
    Mock: false,
    Parameters: filters
  };
  let asyncResult = await postAsync(route, data);
  let result = [];
  if (asyncResult.isSuccess === false) {
    EVOAlertComponent.Alert({ message: asyncResult.errorMessage });
  } else {
    result = asyncResult.response.VScTHouses ?? [];
  }
  return result;
}