import React, { useState, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';

import { fetchNotifications } from './NotificationsFeed';
import ApiCallerHelper from 'api/apiCallerHelper';

const NotificationBell = ({ currentUser }) => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchAndSetNotifications = async () => {
            try {
                const notificationsData = await fetchNotifications();
                setNotifications(notificationsData);
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        fetchAndSetNotifications();
    }, []); // Empty dependency array ensures this runs once when the component mounts

    const toggleNotifications = (event) => {
        setShowNotifications(!showNotifications);
        setAnchorEl(event.currentTarget);
    };

    const closeNotifications = () => {
        setShowNotifications(false);
        setAnchorEl(null);
    };

    const changeState = async (index, notificationId) => {
        const updatedNotifications = [...notifications];
        updatedNotifications[index].unread = !updatedNotifications[index].unread;
        await ApiCallerHelper.Notifications.setNotificationsAsReadAsync(notificationId, !updatedNotifications[index].unread)
        setNotifications(updatedNotifications);
    };

    const itemsPerPage = 5;
    const totalPages = Math.ceil(notifications.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Define the width of the popover
    const popoverWidth = 400; // You can adjust this value to your preferred width

    return (
        <div style={{ position: 'relative' }}>
            <div
                className='notifications'
                style={{ marginTop: '8px', marginRight: '15px', cursor: 'pointer' }}
                onClick={toggleNotifications}
            >
                <Badge badgeContent={notifications.filter((n) => n.unread).length} color="primary">
                    <NotificationsIcon color="action" />
                </Badge>
            </div>
            <Popover
                open={showNotifications}
                anchorEl={anchorEl}
                onClose={closeNotifications}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{ style: { maxWidth: popoverWidth } }} // Set the maxWidth to control the width
            >
                <List>
                    {notifications.slice(startIndex, endIndex).map((notification, index) => (
                        <div key={index}>
                            <ListItem
                                style={{
                                    color: notification.unread ? 'blue' : 'gray',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    changeState(startIndex + index, notification.id);
                                }}
                            >
                                {startIndex + index + 1}.&nbsp;
                                {notification.message}
                            </ListItem>
                            {index < itemsPerPage - 1 && <Divider />}
                        </div>
                    ))}
                </List>
                {/* Pagination */}
                <div style={{ textAlign: 'center', margin: '16px' }}>
                    {Array.from({ length: totalPages }, (_, page) => (
                        <span
                            key={page}
                            style={{
                                cursor: 'pointer',
                                marginRight: '8px',
                                fontWeight: page + 1 === currentPage ? 'bold' : 'normal',
                            }}
                            onClick={() => setCurrentPage(page + 1)}
                        >
                            {page + 1}
                        </span>
                    ))}
                </div>
            </Popover>
        </div>
    );
}

export default NotificationBell;
