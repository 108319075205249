import React, { useState } from "react";
import { Fragment } from "react";

export const DynamicActionLabel = ({ title, description }) => {

  const Title = ({ text }) => {
    let showValue = (text ?? "").length > 0;
    if (showValue) {
      return <label className="form-control-label">
        {text}
      </label>
    }
    return <Fragment />;
  };

  const Description = ({ text }) => {
    let showValue = (text ?? "").length > 0;
    if (showValue) {
      return (
      <Fragment><br></br><label className="form-control-label text-muted">
        {text}
        </label>
      </Fragment>   )   
    }
    return <Fragment />;
  };

  return(
    <Fragment>     
     <Title text={title} />
     <Description text={description} />
   </Fragment>
  );
};

export default DynamicActionLabel;
