import React, { useState } from "react";
import { UsersService } from "../../common/UsersService";
import { WorkflowService } from "../../common/WorkflowService";
import { LocationService } from "../../common/LocationService";
import { ApiCallerHelper } from "../../api/apiCallerHelper"

class EVOBaseComponent extends React.Component {
    Base = {
        Services : {
            UsersService :UsersService,
            WorkflowService :WorkflowService,   
            LocationService : LocationService         
        },
        Security : {
            isUserLogged : () => {                
                return this.state.base.loggedUser;
            },
            getLoggedUser: () => {
                return this.state.base.loggedUser;
            }
        }
    }        
    
    constructor(props) {
        super(props);
        this.state = {
            base : {
                loggedUser : null,
                error : null
            }
        }        
    }    

    componentDidMount(setStateCallback) {                        
        this._componentDidMount(setStateCallback);
    };

    /* base class implementation method*/
    _componentDidMount = async (setStateCallback) => {
        //Get current user
        let currentUser = await ApiCallerHelper.Users.getCurrentAsync();
        //Load Cached services
        let promiseResults = await Promise.all([
            WorkflowService._initCache(),
            UsersService._initCache(),
            LocationService._initCache()
        ]);  

        //Set state
        this.state = {
            base : {                
                ...this.state.base,
                loggedUser : currentUser.data,
            }
        }   
        //Update state and continue
        this.setState({base: this.state.base}, setStateCallback);              
    }      
}

export default EVOBaseComponent;