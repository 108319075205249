import React, { useState, useEffect } from "react";
import {
    Button,
    Input,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col 
} from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import Select from 'react-select';
import { useSpring, animated } from 'react-spring';
import { ApiCallerHelper } from '../../api/apiCallerHelper';
import { Clone } from "common/utils";
import debounce from 'lodash/debounce';

export const Properties = () => {
    const [areFiltersShown, setFiltersShown] = useState(false);
    const [houses, setHouses] = useState([]);
    const [filters, setFilters] = useState({});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Update windowWidth when the window is resized
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures the effect runs only once on mount

    // Define the blueprint for mdbdatatable
    const ColumnsDefinition = { 
        columns: [
            {
                label: 'Μητρώο Κατοικίας',
                field: 'hHouseid',
                sort: 'asc',
                maxWidth: 50
            },
            {
                label: 'Κατηγορία Κατοικίας ▼',
                field: 'hHousecateg',
                sort: 'asc',
                width: 100
            },
            {
                label: 'Οδός ▼',
                field: 'hAddrstreet',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Αριθμός ▼',
                field: 'hAddrnumber',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Πόλη ▼',
                field: 'hAddrcity',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Αριθμός Υπνοδωματίων ▼',
                field: 'hBedroomnumber',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Προορισμός Ακινήτου ▼',
                field: 'hUsedestination',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Τομέας Κατοικίας ▼',
                field: 'hDomain',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Υπεύθυνος Οικισμού ▼',
                field: 'hResponsible',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Τύπος Κατασκευής ▼',
                field: 'hConstrtype',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Όροφος ▼',
                field: 'hFloor',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Εμβαδόν (Τ.Μ.) ▼',
                field: 'hAreasm',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Πάροχος Ηλ. Ρεύμ. ▼',
                field: 'hEpikoinParoxos',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Αρ. Μετρ. Ρεύμ. ▼',
                field: 'hNumofdehcounter',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Αρ. Μετρ. Ρεύμ. 2 ▼',
                field: 'hNumofdehcounter2',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Αρ. Μετρ. Ρεύμ. 3 ▼',
                field: 'hNumofdehcounter3',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Αριθμός Π.Ε.Α. ▼',
                field: 'hPeaNumcertif',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Ημερομ. Άφιξης ▼',
                field: '',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Κατηγορία Ενοίκου ▼',
                field: '',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Καθεστώς Στέγασης ▼',
                field: '',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Κινητό Τηλέφωνο ▼',
                field: '',
                sort: 'asc',
                width: 150
            },
        ],
        getAndPrepareDataset: () => {
            let datasource = Clone(houses); 
            return datasource;
        }
    }

    // Use blueprint for mdbdatatable
    let data = {
        columns : ColumnsDefinition.columns,
        rows : ColumnsDefinition.getAndPrepareDataset()
    } 

    // Function to clear filters
    const clearFilters = () => {
        setFilters({
            hHouseid: '',
            hPeaNumcertif: '',
            hContrstartdate: '',
            hAddrstreet: '',
            hAddrnumber: '',
            hAreasm: '',
            hNumofdehcounter: '',
            hNumofdehcounter2: '',
            hNumofdehcounter3: '',
            phoneNumber: '',
            hHousecateg: '',
            hAddrcity: '',
            hBedroomnumber: '',
            hUsedestination: '',
            houseDomain: '',
            responsible: '',
            hConstrtype: '',
            hFloor: '',
            hEpikoinParoxos: '',
            occCategory: '',
            accomodation: '',
        })
    }

    // Pass filters as the body of the ApiCallerHelper.External.getHouses call
    const getHouses = async () => {
        let housesResponse = await ApiCallerHelper.External.getHouses(filters);
        setHouses(housesResponse);
    }

    // Show / Hide filters animation
    const animation = useSpring({
        height: areFiltersShown ? '350px' : '0',
        opacity: areFiltersShown ? 1 : 0,
        from: { height: '0', opacity: 0 },
    });

    // Style for the div containing the filters
    const animatedDivStyle = {
        overflow: windowWidth < 800 ? "auto" : "visible", 
        transition: 'height 0.1s ease-out, opacity 0.1s ease-out', 
        ...animation
    };

    // Style for centering buttons vertically inside row
    const buttonWrapperStyle = {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%' 
    };

    // Utility function for rendering specific filter item
    let renderFilterColumn = (columnName, columnWidth, isInput, value, onChangeFunc, options = []) => {

        const select_options = options.map((option) => {
            return { value: option, label: option }
        });

        return (
            <>
                <Col md={columnWidth}>
                    <label className="tool-theme_typing--input-label">
                        {columnName}
                    </label>
                    {isInput && <Input value={value} onChange={onChangeFunc}></Input>}
                    {!isInput && <Select value={{label: value}} onChange={onChangeFunc} options={select_options}></Select>}
                </Col>
            </>
        )
    }

    // RenderHelper, similar to MonthlyVouchers
    const RenderHelper = {
        Filters : () => {
            return (
                <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Card className="shadow-none no-padding">
                                <CardHeader className="border-0 no-padding">
                                    <h3>Φίλτρα</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        {renderFilterColumn("Μητρώο Κατοικίας", 3, true, filters.hHouseid, (e) => { setFilters({...filters, hHouseid: e.target.value}) })}
                                        {renderFilterColumn("Αριθμός Π.Ε.Α.", 3, true, filters.hPeaNumcertif, (e) => { setFilters({...filters, hPeaNumcertif: e.target.value}) })}
                                        <Col md={2}>
                                            <div style={buttonWrapperStyle}>
                                                <Button
                                                    color="primary"
                                                    onClick={getHouses}
                                                >
                                                    Αναζήτηση
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div style={buttonWrapperStyle} onClick={() => {
                                                setFiltersShown(!areFiltersShown)
                                            }}>
                                                {areFiltersShown && "▲ Εμφάνιση λιγότερων επιλογών"}
                                                {!areFiltersShown && "▼ Εμφάνιση περισσότερων επιλογών"}
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <animated.div style={animatedDivStyle}>
                                        <Row>
                                            {renderFilterColumn("Ημ/νία Άφιξης", 3, true,)}
                                            {renderFilterColumn( "Οδός", 3, true, filters.hAddrstreet, debounce((e) => { setFilters({...filters, hAddrstreet: e.target.value}) }, 500))}
                                            {renderFilterColumn("Αριθμός", 3, true, filters.hAddrnumber, (e) => { setFilters({...filters, hAddrnumber: e.target.value}) })}
                                            {renderFilterColumn("Εμβαδόν (Τ.Μ.)", 3, true, filters.hAreasm, (e) => { setFilters({...filters, hAreasm: e.target.value}) })}
                                        </Row>
                                        <Row>
                                            {renderFilterColumn("Αρ. Μετρ. Ρεύμ.", 3, true, filters.hNumofdehcounter, (e) => { setFilters({...filters, hNumofdehcounter: e.target.value}) })}
                                            {renderFilterColumn("Αρ. Μετρ. Ρεύμ. 2", 3, true, filters.hNumofdehcounter2, (e) => { setFilters({...filters, hNumofdehcounter2: e.target.value}) })}
                                            {renderFilterColumn("Αρ. Μετρ. Ρεύμ. 3", 3, true, filters.hNumofdehcounter3, (e) => { setFilters({...filters, hNumofdehcounter3: e.target.value}) })}
                                            {renderFilterColumn("Κινητό Τηλέφωνο", 3, true)}
                                        </Row>
                                        <Row>
                                            {renderFilterColumn("Κατηγορία Κατοικίας", 2, false, filters.hHousecateg, (e) => { setFilters({...filters, hHousecateg: e.target.value}) }, [])}
                                            {renderFilterColumn("Πόλη", 2, false,  filters.hAddrcity, (e) => { setFilters({...filters, hAddrcity: e.target.value}) }, [])}
                                            {renderFilterColumn("Αριθμός Υπνοδωμ.", 2, false,  filters.hBedroomnumber, (e) => { setFilters({...filters, hBedroomnumber: e.target.value}) }, [])}
                                            {renderFilterColumn("Προορ. Ακινήτου", 2, false,  filters.hUsedestinationdest, (e) => { setFilters({...filters, hUsedestination: e.target.value}) }, [])}
                                            {renderFilterColumn("Τομέας Κατοικίας", 2, false, filters.hDomain, (e) => { setFilters({...filters, hDomain: e.target.value}) }, [])}
                                            {renderFilterColumn("Υπεύθυνος Οικισμού", 2, false, filters.hResponsible, (e) => { setFilters({...filters, hResponsible: e.target.value}) }, [])}
                                        </Row>
                                        <Row>
                                            {renderFilterColumn("Τύπος Κατασκευής", 2, false, filters.hConstrtype, (e) => { setFilters({...filters, hConstrtype: e.target.value}) }, [])}
                                            {renderFilterColumn("Όροφος", 2, false, filters.hFloor, (option) => { setFilters({...filters, hFloor: option.value}) }, ["-1", "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"])}
                                            {renderFilterColumn("Πάροχος Ηλ. Ρεύμ.", 2, false, filters.hEpikoinParoxos, (e) => { setFilters({...filters, hEpikoinParoxos: e.target.value}) }, [])}
                                            {renderFilterColumn("Κατηγορία Ενοίκου", 2, false)}
                                            {renderFilterColumn("Καθεστώς Στέγασης", 2, false)}  
                                            <Col md={2}>
                                                <div style={buttonWrapperStyle}>
                                                    <Button
                                                        color="danger"
                                                        onClick={clearFilters}
                                                    >
                                                        Εκκαθάριση Φίλτρων
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </animated.div>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                </>
            )
        },
        Results : (data) => {
            return (
                <>
                    <MDBDataTable
                    barReverse={true}
                    bordered
                    hover
                    strip="true"
                    responsive
                    data={data}
                    keyfield="hHouseId"
                    id={"mdbdttable"}
                    entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                    nodatacomponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    snext="Suivant"
                    paginationLabel={['Προηγούμενο', 'Επόμενο']}
                    infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                    entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                    /> 
                </>
            )
        }
    }

    return (
        <Container className="mt-0" fluid>          
          <Row className="mt-0">
              <Col className="mb-5 mb-xl-0" md="12">
                <Card className="shadow-none no-padding">
                  <CardHeader className="border-0 no-padding">
                    <Row className="align-items-center" style={{marginBottom: '20px'}}>
                      <div className="col">
                        <h3 className="mb-0">Πληροφορίες Ακινήτων</h3>
                      </div>
                      <div className="col text-right"></div>
                    </Row>                                                                    
                  </CardHeader>
                  <CardBody>
                        {RenderHelper.Filters()}
                        {RenderHelper.Results(data)}
                  </CardBody>
                </Card>
              </Col>
            </Row>              
        </Container>
    )
}

export default Properties;