import { authActions } from "./user-slice";
import { ApiCallerHelper } from "../../api/apiCallerHelper";

export const fetchCurrentUserAction = () => {
    return async (dispatch) => {
        try {
            let res = await ApiCallerHelper.Users.getCurrentAsync();
            dispatch(authActions.setCurrentUser(res.data));
        }
        catch(err) {
            console.log(err);
        }
    }
}

export const clearCurrentUserAction = () => {
    return async (dispatch) => {
        try {
            dispatch(authActions.clearCurrentUser());            
        }
        catch(err) {
            console.log(err);
        }
    }
}
