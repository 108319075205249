// import { WorkflowService }from "../../../../../common/WorkflowService"
import { useRef } from "react";

import React, { Fragment, useEffect } from "react";
import { WorkflowService } from "../../../common/WorkflowService";
import { UsersService } from "../../../common/UsersService";
import EvoActionsDynamicGridComponent from "../../../components/DynamicGridComponent/EvoActionsDynamicGridComponent";
import EVOReactTableDynamicGridHelper from "../../../components/common/evo.react-table-dynamic-grid-helper";

import {ApiCallerHelper} from "../../../api/apiCallerHelper"
import {RedirectHelper} from "../../../common/redirectHelper"

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import EVOAlertComponent from "../../../components/common/EvoAlertComponent";

export const VouchersExtra = ({workflowInstanceId, workflowInfoDetails, redirectFunction, isAddButtonsVisible }) => {  
  // const initData = async () => {
  //   const data = await UsersService._initCache(true);
  // };
  // initData();  
  //const dataset = [];
  const reactGridDynamicGridComponentRefs = React.createRef();
  const getColumnMetaDataDefinitions = () => {
    let _columnMetadataDefinitions = [];

    if (workflowInfoDetails.data.length > 0 &&
        workflowInfoDetails.data[0].workflowInstancesVouchers.length > 0
      ) {
      let metaDataJson = [];
      metaDataJson =
        workflowInfoDetails.data[0].workflowInstancesVouchers[0]
          .wfiChildVoucherIdData[0].wfiChildVoucher_DeltioErgasion_MetaDataJson;
      let metaData = JSON.parse(metaDataJson);
      if (metaData){
        _columnMetadataDefinitions =
          EVOReactTableDynamicGridHelper.DynamicAction.getGridDefinitionByColumnMetadataValue(
            metaData
          );
      }
    }

    let newColumnDefinitions = [      
      {
        id: "__customColumnTypeDateUpdated",
        title: "Ημ/νία Καταχώρησης",
        type: "cell_template",
        isEditable: false,
        width: 100,
        rowTemplate: (rowData, onDelete, onChangeValue, onUpdate) => {
          let dateUpdated = rowData.original["__dateUpdated"];
          return <>{dateUpdated}</>;
        },
      },
      {
        id: "__customColumnTypeExtraVoucher",
        title: "Τύπος Καταχώρησης",
        type: "cell_template",
        isEditable: false,
        width: 100,
        rowTemplate: (rowData, onDelete, onChangeValue, onUpdate) => {
          let isExtra = rowData.original["__isExtra"];
          let value = isExtra == true ? "Extra" : "Voucher";
          let className = isExtra == true ? "badge-warning" : "badge-primary";
          return (
            <>
              <span className={className}>{value}</span>
            </>
          );
        },
      },
      {
        id: "__customColumnUser",
        title: "Χρήστης Καταχώρησης",
        type: "cell_template",
        isEditable: false,
        width: 100,
        rowTemplate: (rowData, onDelete, onChangeValue, onUpdate) => {
          let userId = rowData.original["__userId"];
          let userDisplayName =
            UsersService.getUserDisplayNameById(userId);
          return <>{userDisplayName}</>;
        },
      },
      {
        id: "__customColumnDeclined",
        title: "Κατάσταση Καταχώρησης",
        type: "cell_template",
        isEditable: false,
        width: 100,
        rowTemplate: (rowData, onDelete, onChangeValue, onUpdate) => {
          //console.log(rowData);
          let isDeclined = rowData.original["__isDeclined"];
          let isCompleted = rowData.original["__isCompleted"];
          let value = "Εκκρεμεί";
          let className = "badge-primary";
          if (isDeclined == false && isCompleted == false){

          }else if (isDeclined == false) {
            value = "Με αποδοχή";
            className = "badge-success";
          } else if (isDeclined == true) {
            value = "Χωρίς αποδοχή";
            className = "badge-warning";
          }
          return (
            <>
              <span className={className}>{value}</span>
            </>
          );
        },
      },
    ];

    _columnMetadataDefinitions = newColumnDefinitions.concat(
      _columnMetadataDefinitions
    );


    let finalNonDeclinedColumnDefinitions = [
      {
        id: "__finalNonDeclinedQuantity",
        title: "Τελικά Τεμάχια",
        type: "number",        
        footerCalculationType : "sum",
        isEditable: false,
        width: 100       
      },
      {
        id: "__finalNonDeclinedPrice",
        title: "Τελική Τιμή",
        type: "number",        
        footerCalculationType : "sum",
        isEditable: false,
        width: 100       
      },
      {
        id: "__finalNonDeclinedFinalPrice",
        title: "Τελικό Σύνολο",
        type: "number",        
        footerCalculationType : "sum",
        isEditable: false,
        width: 100       
      }
    ]

    _columnMetadataDefinitions = _columnMetadataDefinitions.concat(
      finalNonDeclinedColumnDefinitions
    );

    return _columnMetadataDefinitions;
  };

  const getDatasource = () => {
    if (workflowInfoDetails.data.length == 0) {
      return [];
    }
    let datasMerged = [];    
    workflowInfoDetails.datas.forEach((datas) => {
      datasMerged = datasMerged.concat(datas.rows);
    });            
    return datasMerged;
  };


  const createNewFlow = async (attribute) => {
    let promptResult = await EVOAlertComponent.Prompt({message:"Επιθυμείτε να δημιουργήσετε νέα εργασία;"});
    if (promptResult.isConfirmed === true) {
      let newFlow = await ApiCallerHelper.Instances.createChildFlowAsync(attribute, workflowInstanceId);
      //console.log(newFlow);
      redirectFunction(newFlow);
    }
  }

  return (
    <Fragment>
      <Row>
        <Col md="12">
          {isAddButtonsVisible && 
          <>
          {/* <Button
            className="float-right ml-2"
            color="danger"
            onClick={() =>
              createNewFlow('voucher')
            }
            size="lg"
          >
            <i className="fas fa-plus" />  Νέο Δελτίο Εργασίας
          </Button> */}
          {/* <Button
            className="float-right ml-2"
            color="danger"
            onClick={() =>
              createNewFlow('extras')
            }
            size="lg"
          >
            <i className="fas fa-plus" />  Νέα Πρόσθετη Εργασία
          </Button> */}
          </>
          }          
          <Button
            className="float-right mb-2"
            color="primary"
            onClick={() =>
              reactGridDynamicGridComponentRefs.current.onExportExcel(
                "ExportedFile",
                "Sheet1"
              )
            }
            size="lg"
          >
            Εξαγωγή
          </Button>
        </Col>
      </Row>
      <EvoActionsDynamicGridComponent
        className={"tool-theme_dynamic-table"}
        dataset={getDatasource()}
        ref={reactGridDynamicGridComponentRefs}
        columnMetadataDefinitions={getColumnMetaDataDefinitions()}
        innerScrollXOverflow={"auto"}
        isEditable={false}
      ></EvoActionsDynamicGridComponent>
    </Fragment>
  );
};

export default VouchersExtra;