import React, { useState, useEffect, Fragment } from "react";
import { Switch, Redirect } from "react-router-dom";
import AdminFooter from "components/Footers/AdminFooter.js";
import BasicNavbar from "../components/Navbars/BasicNavbar";
import Routes from "../routes";
import GuardedRoute from "../guardedRoute";
import GenericHeader from "../components/Headers/GenericHeader";
import LayoutComponentTypes from "../common/enums/layoutComponentTypes.enum";
import AuthService from "../common/AuthService";
import BasicLayoutCustomOverrides from "./basicLayout.Custom.Overrides"
import GlobalParametersHelper from "../common/GlobalParametersHelper";
const BasicLayout = ({}) => {
  let routes = Routes.getRoutesByLayoutComponentType(
    LayoutComponentTypes.BASIC_LAYOUT
  );

  const GuardedRoutes = ({}) => {    
    let currentUser = AuthService.getCurrentUser();

    return (
      <>
        {routes.map((route, key) => (
          <GuardedRoute
            key={key}
            path={route.routePath}
            Component={route.component}
            auth={route.getAuth(currentUser)}
          />
        ))}
      </>
    );
  };

  if (!AuthService.getCurrentUser()) {
    return <></>;
  }

  const Body = ({}) => {
    return (
      <Switch>
        <GuardedRoutes />
      </Switch>
    );
  };

  let RenderLayoutSidebar = ({ isSideBarVisible }) => {
    return (
      <div>
        <BasicNavbar
          navbarExpand={"lg"}
          isProfileNavItemVisible={BasicLayoutCustomOverrides.GetIsProfileNavItemVisible()}
          isHamburgerVisible={true}
          isCustomSidebar={GlobalParametersHelper.Theme.SelectedTheme.NavBar.IsCustomSidebar}
          isSideBarVisible={isSideBarVisible}
        />
        <div className="main-content" 
          // style={{marginTop:"57px"}}
        >
          {GlobalParametersHelper.Theme.SelectedTheme.IsHeaderEnabled == true &&            
            <>
              <GenericHeader style={{marginTop:"-57px"}}></GenericHeader>
              <div style={{marginTop:"-57px"}}/>
            </>
          }          
          {/* show default header when menu on the left is visible*/}
          <Body />
        </div>
      </div>
    );
  };

  return (
    <>
      <RenderLayoutSidebar
        isSideBarVisible={BasicLayoutCustomOverrides.GetIsSideBarVisible(routes)}        
      />
    </>
  );
};

export default BasicLayout;
