import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import {EVOReactSelectHelper} from "../../../components/common/evo.react-select-helper";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";

import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  InputGroup
} from "reactstrap";

import { useForm } from "react-hook-form";
import EVOAlertComponent from "../../../components/common/EvoAlertComponent";
import EVOReactHookFormHelper from "../../../components/common/evo.react-hook-form-helper";
import { LocationService } from "../../../common/LocationService";

const PolygonEditModal =  ({isShown, onHideModal, polygonId}) => {   
    const [_polygonModel, setPolygonModel] = React.useState(null);
    const _formId = "polygonEditModal"    
    const {
        register,
        handleSubmit,
        setValue,
        control,
        watch,
        reset,
        formState: { errors },
      } = useForm({});      

    useEffect(() => {                          
        refreshSetPolygonModelByPolygonId();
    },[polygonId]);

    useEffect(() => {                          
        refreshValidationFormValues();
    },[_polygonModel]);

    const refreshSetPolygonModelByPolygonId = async () => {
        let polygon = LocationService.Factory.GetNewPolygon({});
        if (polygonId != null) {
            polygon = await LocationService.getLocationsById(polygonId);              
        }            
        setPolygonModel(polygon);
    }     

    const refreshValidationFormValues = () => {       
        [
          "Name",
          "Description",          
          "IntegrationCode"          
        ].forEach(element => {
          setValue(element, _polygonModel?.[element]??'' );
        }); 
        let dataJson = JSON.stringify( _polygonModel?.Data??{})        
        setValue("DataJson", dataJson);           
    }

    const onSubmit = async (data) => {          
        let promptResult = await EVOAlertComponent.Prompt({});      
        if (promptResult.isConfirmed == true) {          
          let updateResult = await LocationService.saveData( 
            { Id : polygonId,
              Name : data.Name,
              Description: data.Description,
              Data: data.DataJson,
              IntegrationCode: data.IntegrationCode,              
            }
          );
          if (updateResult === true){
            onHideModal(true);
          }        
        }             
    };

    if (_polygonModel == null || _polygonModel.Id != polygonId){
        return (<></>)
    }      
    return (
      <Modal
        show={isShown}
        onHide={() => onHideModal(false)}
        //dialogClassName="modal-90w"
        dialogClassName="tool-theme_modal-dialog"
        aria-labelledby="example-custom-modal-styling-title"
        style={{ opacity: 1 }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Στοιχεία Πολυγώνου
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id={_formId} onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">Όνομα Πολυγώνου</label>
                  <EVOReactHookFormHelper.Input
                    name="Name"
                    placeholder="Όνομα Πολυγώνου"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">Περιγραφή</label>
                  <EVOReactHookFormHelper.Input
                    name="Description"
                    placeholder="Περιγραφή"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    rules={{ required: false }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-stepAction-edit-description"
                  >
                    Συστημικός Κωδικός Διασύνδεσης
                  </label>
                  <EVOReactHookFormHelper.Input
                    name="IntegrationCode"
                    placeholder="Συστημικός Κωδικός Διασύνδεσης"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    rules={{ required: false }}
                  />
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-stepAction-edit-description"
                  >
                    Δεδομένα
                  </label>
                  <EVOReactHookFormHelper.Input
                    name="DataJson"
                    placeholder="Δεδομένα"
                    className="form-control-alternative"
                    type="textarea"
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="col text-right">
            <Button color="success" size="md" form={_formId}>
              Αποθήκευση
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );   
  
}      

export default PolygonEditModal;