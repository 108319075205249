import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { forwardRef, useImperativeHandle } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  FeatureGroup,
} from "react-leaflet";
import { Icon, marker } from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.js";
import { coordinates } from "common/coordinates.js";
// import markerIcon from "../../../../../assets/img/marker-icon.png";
// import markerIconSuccess from "../../../../../assets/img/marker-icon-success.png";

import { Row } from "reactstrap";

import { ApiCallerHelper } from "../../api/apiCallerHelper";

// const center = {
//   lat: 38.3910962,
//   lng: 22.6631478,
// }

let markerPosition = {};
let instance = "";

async function getLocationData(coordinates) {
  let locationCalculationResult =
    await ApiCallerHelper.Locations.calculateInstanceLocation({
      Coordinates: coordinates,
    });
  let polygonResult = {
    locationResult: locationCalculationResult,
    coords: coordinates,
  };
  return polygonResult;
}

function DraggableMarker({ isEnabled, latLng, onDragEnd }) {
  const [position, setPosition] = useState(latLng);
  const markerRef = useRef(null);

  const updatePositionWithCallback = async () => {
    const marker = markerRef.current;
    if (marker != null) {
      markerPosition = marker.getLatLng();
      setPosition(marker.getLatLng(), instance);

      let polygonResult = await getLocationData(markerPosition);
      // if (polygonResult) {
      //   setMarkerIcon(markerIconSuccess);
      // }else{
      //   setMarkerIcon(defaultMarkerIcon);
      // }
      onDragEnd(polygonResult);
    }
  };

  useEffect(() => {
    (async () => {
      await updatePositionWithCallback();
    })();
  }, [latLng]);
  //const [markerIcon, setMarkerIcon] = useState(defaultMarkerIcon)

  const defaultMarkerIcon = new Icon({
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png", //{markerIcon},
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
  });

  const markerIconSuccess = new Icon({
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png", //{markerIconSuccess},
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
  });

  const eventHandlers = useMemo(
    () => ({
      async dragend() {
        await updatePositionWithCallback();
      },
    }),
    []
  );

  const markerIcon = new Icon({
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
  });

  return (
    <Marker
      // draggable={draggable}
      draggable={isEnabled}
      eventHandlers={eventHandlers}
      position={latLng}
      icon={markerIcon}
      ref={markerRef}
    >
      {/* <Popup minWidth={90}>
        <span onClick={toggleDraggable}>
          {draggable
            ? 'Marker is draggable'
            : 'Click here to make marker draggable'}
        </span>
      </Popup> */}
    </Marker>
  );
}

const Map = forwardRef(
  ({ isEnabled, value, onDragEnd, workflowInstanceId }, ref) => {
    function getLatLngFromValueOrDefault() {
      let defaultLocation = {
        lat: 38.3910962,
        lng: 22.6631478,
      };
      if (value) {
        defaultLocation.lat = value?.coords?.lat ?? defaultLocation.lat;
        defaultLocation.lng = value?.coords?.lng ?? defaultLocation.lng;
      }
      //   let point = [defaultLocation.lat, defaultLocation.lng];
      //   if(isWithinBounds(coordinates.NotiosTomeas, point))
      //     console.log("NotiosTomeas")
      //   else if(isWithinBounds(coordinates.VoreiosTomeas, point))
      //     console.log("VoreiosTomeas")
      //   else if(isWithinBounds(coordinates.AgiosNikolaos, point))
      //     console.log("AgiosNikolaos")
      //   else
      //     console.log("OUTSIDE ")
      // }
      return defaultLocation;
    }

    const [markerPosition, setMarkerPosition] = useState(
      getLatLngFromValueOrDefault()
    );

    // function isWithinBounds( polygon, point) {//checks if a point is inside a polygon
    //   const x = point[0];
    //   const y = point[1];
    //   let inside = false;
    //   for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    //     const xi = polygon[i][0];
    //     const yi = polygon[i][1];
    //     const xj = polygon[j][0];
    //     const yj = polygon[j][1];

    //     const intersect =
    //       yi > y !== yj > y &&
    //       x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    //     if (intersect) inside = !inside;
    //   }
    //   return inside;
    // }

    function getLatLngFromValueOrDefaultAsArray() {
      let value = getLatLngFromValueOrDefault();
      return [value.lat, value.lng];
    }

    getLatLngFromValueOrDefault();

    useImperativeHandle(ref, () => ({
      getData: async () => {
        let polygonResult = await getLocationData(markerPosition);
        return polygonResult;
      },
    }));

    const handlePolygonClick = (event) => {
      setMarkerPosition(event.latlng);
      // Add your custom logic here
    };

    useEffect(() => {
    }, [markerPosition]);

    return (
      <Row className="mt-0" style={{ width: "100%" }}>
        <MapContainer
          center={getLatLngFromValueOrDefaultAsArray()}
          zoom={14}
          scrollWheelZoom={true}
          style={{ height: "50vh", width: "100%" }}
        >
          <TileLayer
            attribution="&copy; EvolutionProjects+"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <FeatureGroup>
            <DraggableMarker
              isEnabled={isEnabled}
              latLng={markerPosition}
              onDragEnd={onDragEnd}
            />
            <Polygon
              positions={coordinates.VoreiosTomeas}
              color="green"
              fillOpacity={0.2}
              eventHandlers={{
                click: handlePolygonClick,
              }}
            />
            <Polygon
              positions={coordinates.AgiosNikolaos}
              color="brown"
              fillOpacity={0.2}
              eventHandlers={{
                click: handlePolygonClick,
              }}
            />
            <Polygon
              positions={coordinates.NotiosTomeas}
              color="blue"
              fillOpacity={0.2}
              eventHandlers={{
                click: handlePolygonClick,
              }}
            />
          </FeatureGroup>
        </MapContainer>
      </Row>
    );
  }
);

export default Map;
