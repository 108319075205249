import {GetEnumsIntoKeyValue} from "../../common/utils"
export function GetDynamicStepActionTypesOptionList() {
    return GetEnumsIntoKeyValue(DynamicStepActionTypesDescription, "title");    
}

export const DynamicStepActionTypes = {
    text : 'text',
    button : 'button',
    checkbox : 'checkbox',
    date : 'date',
    dateReadonly : 'dateReadonly',   
    file : 'file',
    textarea : 'textarea',
    button : 'button',
    number : 'number',
    rating : 'rating',
    mappin : 'mappin',
    dynamicReactSelect : 'dynamicReactSelect',  
    dynamicReactTable : 'dynamicReactTable'    
}	

export const DynamicStepActionTypesDescription = {
    text : 'Κείμενο',
    button : 'Πλήκτρο',
    checkbox : 'Ναι/Όχι',
    date : 'Ημερομηνία',   
    file : 'Αρχείο',
    button : 'Κουμπί',
    textarea : 'Πεδίο μεγάλου κειμένου',
    number : 'Αριθμός',
    rating : 'Βαθμολογία',
    mappin : 'Πινέζα σε Χάρτη',
    dynamicReactSelect : 'Δυναμική Λίστα',  
    dynamicReactTable : 'Δυναμικός Πίνακας'    
}	