
const  VoreiosTomeas = [
        [38.3973666, 22.6516306],
        [38.3974003, 22.6515985],
        [38.3968958, 22.6510942],
        [38.3966099, 22.6508152],
        [38.3963744, 22.6509011],
        [38.3962399, 22.6514804],
        [38.3961558, 22.6518559],
        [38.3960297, 22.6519847],
        [38.3945161, 22.6515126],
        [38.3929857, 22.6510191],
        [38.3924896, 22.6507509],
        [38.3919514, 22.6505578],
        [38.3913460, 22.6507509],
        [38.3905219, 22.6507938],
        [38.3899248, 22.6508904],
        [38.3899164, 22.6510298],
        [38.3899584, 22.6513946],
        [38.3900341, 22.6519632],
        [38.3900846, 22.6524568],
        [38.3901350, 22.6528645],
        [38.3902359, 22.6533902],
        [38.3902443, 22.6537013],
        [38.3902612, 22.6538730],
        [38.3902696, 22.6540232],
        [38.3900846, 22.6542914],
        [38.3901939, 22.6545918],
        [38.3905050, 22.6544952],
        [38.3906648, 22.6541090],
        [38.3912703, 22.6540446],
        [38.3924644, 22.6538944],
        [38.3937930, 22.6537979],
        [38.3946591, 22.6536906],
        [38.3954999, 22.6537549],
        [38.3961054, 22.6541305],
        [38.3967192, 22.6543236],
        [38.3971312, 22.6542592],
        [38.3973162, 22.6538193],
        [38.3975432, 22.6535082],
        [38.3976273, 22.6529503],
        [38.3973835, 22.6522422],
        [38.3973666, 22.6516306]        
    ];
    const AgiosNikolaos = [
       [38.3697986, 22.6825941],
       [38.3699836, 22.6818752],
       [38.3705724, 22.6812530],
       [38.3717584, 22.6804161],
       [38.3729192, 22.6794291],
       [38.3737267, 22.6785922],
       [38.3742650, 22.6777983],
       [38.3748370, 22.6771116],
       [38.3749715, 22.6768327],
       [38.3760734, 22.6788068],
       [38.3757958, 22.6790535],
       [38.3753332, 22.6795900],
       [38.3749968, 22.6800084],
       [38.3747781, 22.6805556],
       [38.3746435, 22.6810169],
       [38.3743996, 22.6813388],
       [38.3739706, 22.6813281],
       [38.3736005, 22.6813281],
       [38.3731463, 22.6815426],
       [38.3724482, 22.6819611],
       [38.3717921, 22.6822293],
       [38.3712454, 22.6824546],
       [38.3706565, 22.6826262],
       [38.3700509, 22.6827550],
       [38.3697902, 22.6826155]
    ];
    const NotiosTomeas = [
      [38.3899753, 22.6546240],
      [38.3901939, 22.6540768],
      [38.3901855, 22.6536477],
      [38.3901434, 22.6532722],
      [38.3900930, 22.6526606],
      [38.3900257, 22.6520061],
      [38.3898996, 22.6511157],
      [38.3898659, 22.6509440],
      [38.3892100, 22.6511478],
      [38.3888484, 22.6512444],
      [38.3884952, 22.6514268],
      [38.3882177, 22.6513731],
      [38.3880159, 22.6511264],
      [38.3877636, 22.6505685],
      [38.3875702, 22.6501286],
      [38.3872254, 22.6494205],
      [38.3869647, 22.6489055],
      [38.3867376, 22.6484656],
      [38.3863424, 22.6480687],
      [38.3859639, 22.6475751],
      [38.3853416, 22.6469100],
      [38.3846268, 22.6458156],
      [38.3839960, 22.6446247],
      [38.3833821, 22.6442921],
      [38.3839792, 22.6454508],
      [38.3847361, 22.6473176],
      [38.3851482, 22.6490128],
      [38.3853079, 22.6504397],
      [38.3854593, 22.6518774],
      [38.3856696, 22.6538837],
      [38.3858462, 22.6559544],
      [38.3858462, 22.6562762],
      [38.3868133, 22.6558256],
      [38.3881252, 22.6552570],
      [38.3891680, 22.6546776],
      [38.3895548, 22.6544738],
      [38.3897230, 22.6546454],
      [38.3899837, 22.6546454],
      [38.3899753, 22.6546240]
    ];

    export const coordinates = {
        VoreiosTomeas,
        NotiosTomeas,
        AgiosNikolaos
    };