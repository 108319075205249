import { getBoolean } from "./utils";
import { WorkflowIdTypes } from "../common/enums/workflowIdTypes.enum";

/* 
    Contains global parameters settings for web site
*/
const GlobalParametersHelper = {
  //constants default breakpoints
  BreakPoints: {
    md_minWidth: 768,
    sm_maxWidth: 767,
    lg_maxWidth: 767,
    custom_md_1_maxWidth: 992,
  },
  getResponsiveBreakPointWidth: () => {
    return 992;
  },
  getIsDefault: () => {
    const isDemo = getBoolean(process.env.REACT_APP_ISDEMO);
    return false;
  },
  Token: {
    //app validation token
    _TOKEN_KEY: "token",
    get: () => {
      const token = localStorage.getItem(
        GlobalParametersHelper.Token._TOKEN_KEY
      );
      return token;
    },
    set: (value) => {
      localStorage.setItem(GlobalParametersHelper.Token._TOKEN_KEY, value);
    },
    remove: () => {
      localStorage.removeItem(GlobalParametersHelper.Token._TOKEN_KEY);
    },
  },
  getReactAppVersion: () => {
    return `${process.env.REACT_APP_NAME ?? ""} ${
      process.env.REACT_APP_VERSION ?? ""
    }`;
  },
  Theme: { //THEME RELATED PARAMETERS   
    SetSelectedTheme(){
      if (GlobalParametersHelper.getIsDefault()) {
        GlobalParametersHelper.Theme.SelectedTheme = GlobalParametersHelper.Theme.Themes.Default;
      }else {
        GlobalParametersHelper.Theme.SelectedTheme = GlobalParametersHelper.Theme.Themes.CustomAS;
      }
    },
    SelectedTheme: {
        ApplicationName : null,
        Flows: {
           DefaultNewWorkflowInstanceId : null,
           IsAccordeonEnabled: null,
        },
        NavBar: {
           IsCustomSidebar: null,
           LogoHorizontialHeight : null,
           IsLogoHorizontialVisible : null,
           IsLogoVerticalVisible : null,
        },
        ShowLogo: null,
        ShowCustomSidebar: null,
        IsHeaderEnabled: null,
    },
    Themes:
      {
        Default: {
          ApplicationName : "Intelliflow Core",
          Flows: {
            DefaultNewWorkflowInstanceId : null,
            IsAccordeonEnabled: false,            
          },
          NavBar: {
            IsCustomSidebar: false,
            LogoHorizontialHeight : null,
            IsLogoHorizontialVisible : false,
            IsLogoVerticalVisible : true,
          },
          ShowLogo: true,
          ShowCustomSidebar: false,
          IsHeaderEnabled: true,
        },
        CustomAS: {
          ApplicationName : "AS Citizent Portal",
          Flows: {
            DefaultNewWorkflowInstanceId : WorkflowIdTypes.FAULTS,
            IsAccordeonEnabled: false,            
          },
          NavBar: {
            IsCustomSidebar: true,
            LogoHorizontialHeight : 44,
            IsLogoHorizontialVisible : true,
            IsLogoVerticalVisible : false,
          },
          ShowLogo: true,
          ShowCustomSidebar: true,
          IsHeaderEnabled: false,
        },
    },               
  }  
};

export default GlobalParametersHelper;