import React from "react";
import { Container } from "reactstrap";
import { Fragment } from "react";

const GenericHeader = ({}) => {
  return (
    <Fragment>
      <div
        className="header pb-8 pt-5"
        style={{ background: "linear-gradient(to right, #a8e6ff, #095d80)" }}
      >
        <Container fluid>
          <div className="header-body" />
        </Container>
      </div>
    </Fragment>
  );
};

export default GenericHeader;
