import React from "react";
import { Styles } from "./monthlyPrint.sv";

class MonthlyPrint extends React.Component {
  constructor(props) {
    super(props);
    this.data = this.getData(10);
  }

  getData = (total) => {
    let resultLength = 2;
    let rowsLength = 10;

    let results = {
        voucher: {
            voucherNo: 123,
            serialNumber: 456,
            da: "DA",            
        },
        vouchers : [],
        sums : {
           amount: 0,
           vat: 0,
        }
    };    
    for (let resultLengthSize = 0; resultLengthSize < resultLength; resultLengthSize++) {
    
        let result = {
        chargeNo: 123123,
        rows: [],
        sums: {
            amount : 0,
            vat: 0,
            discount: 0,
        }
        };

        for (let i = 0; i < rowsLength; i++) {
        let row = {
            createdDate: "09/09/22",
            voucherSerialNumber: i,
            houseId: "CL0" + i,
            categoryValue: "X001" + i,
            subCategoryValue: "CL0" + i,
            subCategoryDescription: "12-Μεταλλουργικά",
            technician: "ΧΧΧΧΧΧ",
            contractor: " xxxxx",
            initialWorkDate: "01/01/22",
            chargedDate: "01/01/22",
            totalValueMinusVat: 100,
            vatAmmount: 24,
            vatPercentageDescription: "24%",
            vatPercentage: 0.24,
            em: "M",
            discounAmount: 0,
        };
        result.rows.push(row);
        }
        
        result.rows.forEach(element => {
            result.sums.amount =+ element.totalValueMinusVat;
            result.sums.vat =+ element.vatAmmount;
            result.sums.discount =+ element.discounAmount;
        });
     
        results.vouchers.push(result)
    }  
    
    results.vouchers.forEach(element => {
        results.sums.amount =+ element.sums.amount;
        results.sums.vat =+ element.sums.vat;
        results.sums.discount =+ element.sums.discount;        
    });

    return results;
  };

  componentDidMount() {}

  getVoucherDetails = (voucherData) => {
    return (
      <table width={"100%"} className="print-voucher-body-container--table">
        
          <thead>
            <tr style={{ textDecoration: "underline" }}>
              <td>ΗΜ.ΚΑΤΑΧ</td>
              <td>ΑΡ.ΔΕΛΤ.</td>
              <td>Μ/Κ</td>
              <td>Β.Τ</td>
              <td>ΠΕΡΙΓΡΑΦΗ</td>
              <td>ΤΕΧΝΙΚΟΣ</td>
              <td>ΕΡΓΟΛΑΒΟΣ</td>
              <td>ΠΡΟΒ. ΕΚΤΕΛ.</td>
              <td>ΗΜ. ΤΙΜΟΛ.</td>
              <td>ΠΟΣΟΝ</td>
              <td>Φ.Π.Α.</td>
              <td>ΦΠΑ%</td>
              <td>Ε/Μ</td>
              <td>ΕΚΠ</td>
            </tr>
          </thead>
          <tbody>
            {voucherData.rows.map((item, i) => (
              <tr className="width--default" key={i}>
                <td >{item.createdDate}</td>
                <td>{item.voucherSerialNumber}</td>
                <td>{item.houseId}</td>
                <td>{item.categoryValue}</td>
                {/* <td>{item.subCategoryValue}</td> */}
                <td style={{width:200}}>{item.subCategoryDescription}</td>
                <td style={{width:150}}>{item.technician}</td>
                <td style={{width:150}}>{item.contractor}</td>
                <td>{item.initialWorkDate}</td>
                <td>{item.chargedDate}</td>
                <td className="text-right">{item.totalValueMinusVat}</td>
                <td className="text-right">{item.vatAmmount}</td>
                <td className="text-right">{item.vatPercentageDescription}</td>
                <td>{item.em}</td>
                <td className="text-right">{item.discounAmount}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td></td>              
              <td></td> 
              <td colSpan={4}>ΥΠΟΣΥΝΟΛΟ</td>                                                        
              <td></td>
              <td></td>
              <td></td>
              <td className="text-right">{voucherData.sums.amount}</td>
              <td className="text-right">{voucherData.sums.vat}</td>
              <td></td>
              <td></td>
              <td className="text-right">{voucherData.sums.discount}</td>
            </tr>
          </tfoot>        
      </table>
    );
  };
  
  render() {    
    return (
      <Styles>
        <div className="print-container">
          <div className="print-header">ΜΗΝΙΑΙΕΣ ΠΙΣΤΟΠΟΙΗΣΕΙΣ</div>

          <div className="print-voucher-header-info">
            <div className="print-voucher-container">
              <div>
                <div className="entry-title">Νο. ΤΙΜΟΛΟΓΙΟΥ :</div>
                <div className="entry-title">
                  {this.data.voucher.serialNumber}
                </div>
              </div>
              <div>
                <div className="entry-title">Νο. DA :</div>
                <div className="entry-title">{this.data.voucher.da}</div>
              </div>
              <div>
                <div className="entry-title">ΑΡΙΘΜΟΣ ΣΥΜΒΑΣΗΣ :</div>
                <div className="entry-title">{this.data.voucher.voucherNo}</div>
              </div>
            </div>
          </div>

          {/* VOUCHERS RENDER */}
          {this.data.vouchers.map(
            (item, i) => (
              <div className="print-voucher-body-container" key={i}>
                <div className="print-voucher-body-container--header">
                  ΧΡΕΩΣΗ : {item.chargeNo}
                </div>
                <div className="print-voucher-body-container--body">
                  {this.getVoucherDetails(this.data.vouchers[0])}
                </div>
              </div>
            ),
            this
          )}

          {/* VOUCHERS SUM */}
          <div className="print-vouchers--footer">
            <div>
              <div className="entry-title">ΓΕΝΙΚΟ ΣΥΝΟΛΟ</div>
              <div className="entry-title">ΠΛΗΡΩΤΕΑ ΠΟΣΑ</div>

              <div className="entry-title">{this.data.sums.amount}</div>
              <div className="entry-title">{this.data.sums.vat}</div>
            </div>
          </div>
        </div>
      </Styles>
    );
  }
}

export default MonthlyPrint;
