import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";

import {
  React,
  Fragment,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { StepLabel } from "@mui/material";

export const EvoFlowStepper = ({
  flowSteps,
  activeStepStatus,
  onActiveStepIndexChanged,
}) => {
  const [_steps, setSteps] = useState([]);
  const [_activeStepStatus, setActiveStepStatus] = useState(1);

  useEffect(() => {
    if (onActiveStepIndexChanged) {
      onActiveStepIndexChanged(activeStepStatus);
    }
    setActiveStepStatus(activeStepStatus);
  }, [activeStepStatus]);

  useEffect(() => {
    setSteps(getSteps());
  }, [flowSteps, _activeStepStatus]);

  function getSteps() {
    function getCurrentStepIsCompleted(stepStatus) {
      return stepStatus < _activeStepStatus;
    }

    let steps = flowSteps.map((item, key) => {
      let step = item.step;
      //let status = getCurrentStepStatus(step.status);
      let isCompleted = getCurrentStepIsCompleted(step.Status);
      return {
        index: step.Id,
        title: step.Name,
        //description: step.Description,
        isCompleted: isCompleted,
        status: step.Status,
      };
    });

    return steps;
  }

  return (
    <div>
      <Stepper nonLinear activeStep={(_activeStepStatus ?? 1) - 1}>
        {_steps.map((item, key) => (
          <Step key={key} completed={item.isCompleted}>
            <StepLabel>{item.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
export default EvoFlowStepper;
