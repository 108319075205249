export const LiteralsHelperGridsCustomer = {
    GridCommon : {
        SerialNumber: "Α/Α ▼",
        DateCreated : "Ημ/νία Δημιουργίας ▼",
        StatusDescription : "Κατάσταση Αιτήματος ▼",
        CreatedByuser : "Δημιουργήθηκε Από ▼",
        AssignedUser : 'Ανατέθηκε σε ▼',        
        Actions : 'Ενέργειες ▼',        
        ParentSerialNumber : 'Α/Α Εργασίας ▼',
        AssignedTechnician : 'Τεχνικός ▼',
        AssignedContractor : 'Εργολάβος ▼',
        Completed : 'Ολοκληρωμένη ▼',
        WithDecline : 'Με Απόρριψη ▼',

        faultDescription : 'Αίτημα/Βλάβη ▼',
        faultTypeDescription : 'Κατηγορία βλάβης ​ ▼',
    }
}
export default LiteralsHelperGridsCustomer;