import {
  React,
  Fragment,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import EVOReactSelectHelper from "../../components/common/evo.react-select-helper";
import { DynamicStepActionTypes } from "../../common/enums/dynamicStepActionTypes.enum";
import { OverrideRules } from "../../views/instances/custom/custom.InstanceBase.overrides.aspra-spitia";
import DynamicActionLabel from "./DynamicActionLabel";
import { WorkflowStepActionIdTypes } from "common/enums/workflowStepActionIdTypes.enum";

export const DynamicReactSelect = ({
  self,
  isEnabled,
  dynamicActions,
  stepId,
  isShowlabel,
}) => {
  if (dynamicActions.action.Type != DynamicStepActionTypes.dynamicReactSelect) {
    return <></>;
  }
  // console.log(dynamicActions.action)
  let metaData = EVOReactSelectHelper.getReactSelectMetaDataFromStepActionData(
    dynamicActions.action
  );
  let options =
    EVOReactSelectHelper.getReactSelectOptionsFromMetaData(metaData);

  let data = dynamicActions.data;
  let value = data?.Data ?? null;
  let valueData = JSON.parse(data?.MetaDataJson ?? null)?.valueData ?? null;

  //OVERIDE RULES

  // if (isEnabled) {
  //   let ovverideRule = OverrideRules.getOnRenderControlOverrideRule(
  //     self.state,
  //     dynamicActions.action.Id
  //   );
  //   if (ovverideRule) {
  //     if (ovverideRule.isEnabled != undefined){
  //       isEnabled = ovverideRule.isEnabled;
  //     }
  //     if (ovverideRule.options != undefined){
  //       options = ovverideRule.options;
  //     }
  //   }
  // }
  let subcategory;
  if (isEnabled) {
    let ovverideRule = OverrideRules.getOnRenderControlOverrideRule(
      self.state,
      dynamicActions.action.Id
    );
    let isVisible = true;
    if (ovverideRule) {
      isVisible = ovverideRule.isVisible;
      if (ovverideRule.isEnabled != undefined) {
        isEnabled = ovverideRule.isEnabled;
      }
      if (ovverideRule.options != undefined) {
        options = ovverideRule.options;
      }
      // if(ovverideRule.isReadonly !== null && dynamicActions.action.Id === ""){
      //   alert(ovverideRule.isReadonly)
      //   isReadonly = ovverideRule.isReadonly;
      // }
    }
    if (isVisible === false) {
      return <></>;
    }
    if (dynamicActions.action.Id === WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultPublicSubcategories) {
      if (ovverideRule.isReadonly) {
        isEnabled = false;
      }
      if (ovverideRule.subcategory) {
        subcategory = ovverideRule.subcategory;
      }
    }
    // dynamicActions.action.isReadonly = isReadonly;
  }

  let isVisible = true;
  let ovverideRule = OverrideRules.getOnRenderControlOverrideRule(
    self.state,
    dynamicActions.action.Id
  );

  if (ovverideRule) {
    isVisible = ovverideRule?.isVisible ?? true;
  }

  if (isVisible === false) {
    return <></>;
  }

  EVOReactSelectHelper.addAditionalMissingOptionValue(
    value,
    options,
    valueData
  );

  if (options.length === 0) {
    isEnabled = false;
  }
  return (
    <Fragment>
      {isShowlabel && (
        <Fragment>
          <DynamicActionLabel
            title={dynamicActions.action.Name}
            description={dynamicActions.action.Description}
          />
          <br></br>
        </Fragment>
      )}
      <EVOReactSelectHelper.Select
        customMetaData={metaData}
        name={dynamicActions.action.Id}
        options={options.filter((option) => {
          if (
            dynamicActions.action.Id === WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultPublicSubcategories
          ) {
            return option.value === subcategory;
          } else {
            return { options };
          }
        })}
        // defaultValue={dynamicActions.action.Name}
        onChange={(value) =>
          self.updateInputValue(
            isEnabled,
            value.value,
            stepId,
            dynamicActions.action.Id,
            EVOReactSelectHelper.getSelectedValueInfo(value)?.metaData ?? null
          )
        }
        isDisabled={!isEnabled}
        value={EVOReactSelectHelper.getSelectedOptionFromValue(value, options)}
      />
    </Fragment>
  );
};
