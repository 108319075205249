import styled from 'styled-components'

export const Styles = styled.div`

.print-container {
    border: 2px solid black;
    font-size : 15px;
    max-width : 1200px;
    overflow-x: auto;
}

.print-header {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding-top: 10px;
}


.print-voucher-container {
    padding: 15px;
}

.print-voucher-container .entry-title {
    min-width: 250px;
    font-weight: bold;    
    display: inline-block;
    font-size : 20px;
}

.print-voucher-body-container {
    border: 2px solid black;    
    margin: 15px;    
}

.print-voucher-body-container--header {
    border-bottom: 2px solid black;           
    padding-left: 15px;
    font-weight: bold;
    font-size : 20px;
}

.print-voucher-body-container--body {
    padding-left: 10px;        
    padding-right: 10px;        
    padding-top: 10px;        
}

.print-voucher-body-container--table table, 
.print-voucher-body-container--table tbody {
    width: 100%;
}

.print-voucher-body-container--table td {
    padding:5px;
}

.print-voucher-body-container--table tfoot td {
    padding:10px;
    font-weight: bold;
}
.print-voucher-body-container--table tfoot tr {
    border-top:2px solid black;     
} 

.print-vouchers--footer {
    padding-top: 20px;
    padding-bottom: 20px;    
    padding-left: 20px;   
}

.print-vouchers--footer .entry-title {
    min-width: 250px;
    font-weight: bold;    
    display: inline-block;
    font-size : 20px;
}

.text-right : {
    text-align: right;
}

.width--default td: {
    width: 70px;
}

.width--date : {
    width: 70px;
}
 
`