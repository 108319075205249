
export const createDocumentBody = (rows, metadata, ws) => {
  const firstRow = ws.getRow(1);
  const header = [
    'Αύξων Αριθμός Πιστοποίησης',
    'Ημερομηνία Καταχώρησης',
    'Αριθμός Δελτίου',
    'Μητρώο Κατοικίας',
    'Χρέωση',
    'Εκδότης',
    'Σύμβαση',
    'Προβλεπόμενη Εκτέλεση',
    'Ημερομηνία Εκτέλεσης',
    'Αριθμός Παραγγελίας SAP',
    'Αριθμός Σύμβασης',
    'Αριθμός Τιμολογίου',
    'Ημερομηνία Τιμολογίου',
    'Ποσό τελικό Χ ΦΠΑ',
    'ΦΠΑ%',
  ];

  firstRow.height = 40;
  
  firstRow.values = header;
  firstRow._cells.forEach(cell => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ff92d050'}
    }
    cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true
    }
  });

  for (let i = 1; i < 15; i++) {
    const column = ws.getColumn(i);
    column.width = 25;
  }

  const dataRows = rows.map(row => [
    'Αύξων Αριθμός Πιστοποίησης',
    `${row.voucher_started_at}`,
    `${row.voucher_serial_number}`,
    `${row.external_house_id}`,
    `${row.voucher_billing}`,
    `${row.mechanic_displayname}`,
    'Σύμβαση',
    `${row.completion_estimate}`,
    `${metadata.sap_number}`,
    `${row.voucher_contract_number}`,
    `${metadata.invoice_number}`,
    `${metadata.invoice_date}`,
    `${row.voucher_price_without_vat}`,
    '24%',
  ]);

  ws.addRows(dataRows);
};