import {
    get, getAsync,
    post, postAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    } from "./apiCallerHelper.core";

    export async function getUserCurrentAsync() {
      var route = "/api/users/current/";
      return getAsync(route);
    }

    async function getUserCurrent(successCallback) {
        let res = await getUserCurrentAsync();
        if (res) {
          successCallback(res);
        }
      }

    export async function getAllUsers() {
        var route = "/api/users/all/";
        return getAsync(route);
    }

    export async function toggleUserActive(userId) {
        var route = "/api/users/toggle/" + userId + "/";
        return getAsync(route);
    }

    export async function createNewUser(data) {
        var route = "/api/users/new/";
        return postAsync(route, data);
    }

    export async function editUser(data) {
        var route = "/api/users/edit/";
        return putAsync(route, data);
    }  

    export async function passwordchange(passwordFirst, passwordSecond) {
        let data = {
            PasswordFirst : passwordFirst,
            PasswordSecond : passwordSecond
        };
        var route = "/api/users/passwordchange/";
        return postAsync(route, data);
    }    