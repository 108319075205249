import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import BasicLayout from "layouts/basicLayout";
import Login from "views/security/Login.js";

import GuardedRoute from "../guardedRoute";
import { useState, useContext, createContext, useEffect } from "react";
import { Base } from "contexts/BaseContext";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentUserAction } from "redux/auth/user-actions";
import AuthService from "common/AuthService";
import GlobalParametersHelper from "../common/GlobalParametersHelper";
import { RedirectHelper } from "../common/redirectHelper";
import { RoutesTypes } from "../common/enums/routesTypes.enum";
import { LayoutComponentTypes } from "../common/enums/layoutComponentTypes.enum";
import Routes from "../routes";

function App() {
  const dispatch = useDispatch();
  const baseContext = useContext(Base);
  const token = GlobalParametersHelper.Token.get();
  GlobalParametersHelper.Theme.SetSelectedTheme();
  useEffect(() => {
    const isAllowedPage = [
      RoutesTypes.LOGOUT,
      RoutesTypes.LOGIN,
      RoutesTypes.AUTH,
      RoutesTypes.HEALTH_CHECK,
    ].some((route) => RedirectHelper.IsWindowLocationPage(route));  
    if (!isAllowedPage) {
      dispatch(fetchCurrentUserAction());
    }
  }, [token]);

  const layoutComponents = [
    { key: LayoutComponentTypes.BASIC_LAYOUT, component: BasicLayout },
  ];

  const routes = Routes.getBrowserRoutes(layoutComponents);  
    if (
        !AuthService.getCurrentUser() &&
        !token &&
        !(
        RedirectHelper.IsWindowLocationPage(RoutesTypes.URI_TOKEN_LOGIN) ||
        RedirectHelper.IsWindowLocationPage(RoutesTypes.HEALTH_CHECK)
        )
    ) {                   
        return <Login />;
    }

  //if (AuthService.getCurrentUser()){
  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route, key) => {            
          return (
            <GuardedRoute
              key={key}
              path={route.path}
              Component={route.component}
              auth={true}
            />
          );
        })}
        <Redirect from="/" to={Routes.getURLByRouteType(RoutesTypes.HOME)} />
      </Switch>
    </BrowserRouter>
  );
  //}
}

export default App;
