import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import {EVOReactSelectHelper} from "../../../components/common/evo.react-select-helper";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";

import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  InputGroup
} from "reactstrap";

import { useForm } from "react-hook-form";


import { ApiCallerHelper } from "../../../api/apiCallerHelper";
import { UserRoleHelper} from "../../../common/userRoleHelper";
import { UserGroupHelper} from "../../../common/userGroupHelper";
import { RedirectHelper } from "../../../common/redirectHelper";
import { UsersService } from "../../../common/UsersService";
import { WorkflowService } from "../../../common/WorkflowService";

import { LocationService } from "../../../common/LocationService";
import EVOAlertComponent from "../../../components/common/EvoAlertComponent";
import EVOReactHookFormHelper from "../../../components/common/evo.react-hook-form-helper";

const FlowsEditModal =  ({isShown, onHideModal, modelId}) => {         
    const [_model, setModel] = React.useState(null);
    const _formId = "flowsEditModal"
    const {
      register,
      handleSubmit,
      setValue,
      control,
      watch,
      reset,
      formState: { errors },
    } = useForm({});

    useEffect(() => {                          
      refreshModelById()
    },[modelId]);

    useEffect(() => {                          
      refreshValidationFormValues();
    },[_model]);

    const refreshModelById = async () => {
      let model = WorkflowService.Factory.GetNewWorkflow({});
      if (modelId != null) {
        model = await WorkflowService.getWorkflowById(modelId);      
      }    
      setModel(model);
    }    

    const refreshValidationFormValues = () => {       
      [
        "Name",
        "Description",          
        "Priority",
        "Duration",
        "EndingDate",
        "StartingDate",
        "MetaDataJson"
      ].forEach(element => {
        setValue(element, _model?.[element]??'' );
      });       
    }      

    const onSubmit = async (data) => {          
      let promptResult = await EVOAlertComponent.Prompt({});      
      if (promptResult.isConfirmed == true) {          
        let updateResult = await WorkflowService.saveData( 
          { Id : modelId,
            Name : data.Name,
            Description: data.Description,
            Priority: data.Priority,
            // Duration: data.Duration,    
            EndingDate: data.EndingDate,              
            StartingDate: data.StartingDate,              
            //MetaDataJson: data.MetaDataJson                     
          }
        );
        if (updateResult === true){
          onHideModal(true);
        }        
      }             
    };
   

    if (_model == null || _model.Id != modelId){
      return (<></>)
    }   
    return (
      <Modal
        show={isShown}
        onHide={() => onHideModal(false)}
        //dialogClassName="modal-90w"
        dialogClassName="tool-theme_modal-dialog"
        aria-labelledby="example-custom-modal-styling-title"
        style={{ opacity: 1 }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Στοιχεία Ροής
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id={_formId} onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">Όνομα Πεδίου</label>
                  <EVOReactHookFormHelper.Input
                    name="Name"
                    placeholder="Όνομα"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Περιγραφή
                  </label>
                  <EVOReactHookFormHelper.Input
                    name="Description"
                    placeholder="Περιγραφή"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Προτεραιότητα Ροής
                  </label>
                  <EVOReactHookFormHelper.Input
                    name="Priority"
                    placeholder="Προτεραιότητα Ροής"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    type="number"
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Αρχική Ημ/νια Ροής
                  </label>
                  <EVOReactHookFormHelper.Input
                    name="StartingDate"
                    placeholder="Αρχική Ημ/νια Ροής"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    type="date"
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                  >
                    Τελική Ημ/νια Ροής
                  </label>
                  <EVOReactHookFormHelper.Input
                    name="EndingDate"
                    placeholder="Τελική Ημ/νια Ροής"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    type="date"
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
            </Row>
                     
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="col text-right">
            <Button color="success" size="md" form={_formId}>
              Αποθήκευση
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );   

  }

export default FlowsEditModal;