import React, { useState, useEffect } from "react";
import {
    Button,
    Input,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col
  } from "reactstrap";
import ExcelJS from 'exceljs';
import pdfMake from  'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Checkbox } from 'antd';
import Select from 'react-select'
import {Clone} from "../../common/utils"
import { MDBDataTable } from 'mdbreact';
import { createDocumentBody } from "common/MonthlyVoucherHelper.xlsx";
import { createDocDefinition, sortRowsByVoucherBilling } from "common/MonthlyVoucherHelper.pdf";
import { filterOptions } from "common/MonthlyVoucherHelper.options";
import ApiCallerHelper from "api/apiCallerHelper";
import DateHelper from "common/dateHelper";
import EVOAlertComponent from "components/common/EvoAlertComponent";


pdfMake.vfs = pdfFonts.pdfMake.vfs;


export const MonthlyVouchers = () => {
  const [vouchers, setVouchers] = useState({columns: [], data: []});
  const [filters, setFilters] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [metadata, setMetadata] = useState({
    da_number: '',
    sap_number: '',
    invoice_number: '',
    invoice_date: '',
    contract_number: '',
  });

  const handleCheckboxChange = (rowId) => {
    // Toggle selection of the row
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(rowId)) {
        return prevSelectedRows.filter((id) => id !== rowId);
      } else {
        return [...prevSelectedRows, rowId];
      }
    });
  };

  const fetchMonthlyVouchers = async () => {
    const response = await ApiCallerHelper.Statistics.getMonthlyVouchers(filters);
    setVouchers(response);
    setSelectedRows(response.data.map(row => row.voucher_serial_number));
  }

  useEffect(() => {
    fetchMonthlyVouchers();
  }, []);

  // Define the blueprint for mdbdatatable
  const ColumnsDefinition = {
    _setRowsAddintionalColumns: (rows) => {
      rows.forEach((row) => {
        row.checkbox = (
          <Checkbox
            className='tool-theme-checkbox'
            checked={selectedRows.includes(row.voucher_serial_number)} // Replace 'id' with the actual identifier of your rows
            onChange={() => handleCheckboxChange(row.voucher_serial_number)}
          />
        );
      });
    },
    getAndPrepareDataset: () => {
      let datasource = Clone(vouchers.data);
      ColumnsDefinition._setRowsAddintionalColumns(datasource);
      return datasource;
    },
  };

  // Function to clear filters
  const clearFilters = () => {
    setFilters({
      contractor_displayname: '',
      voucher_contract_number: '',
      voucher_start_month: null,
      voucher_start_year: null,
      voucher_billing: null,
    })
  }

  const metadataNullChecker = (metadata) => {
    return (
      metadata.da_number.trim() === '' 
      || metadata.sap_number.trim() === '' 
      || metadata.invoice_number.trim() === '' 
      || metadata.invoice_date.trim() === ''
      || metadata.contract_number.trim() === '')
  }

  const handlePdfExport = async (rowIds) => {
    const response = await ApiCallerHelper.Statistics.newMonthlyVouchers(metadata, selectedRows);
      
    const promptResult = await EVOAlertComponent.Alert({ message: response.NewMonthlyVoucher 
    ? `Δημιουργήθηκε νέα πιστοποίηση με αριθμό ${response.Number}!` 
    : `Η πιστοποίηση με αυτά τα στοιχεία υπάρχει ήδη!` });
    
    if (promptResult.isConfirmed === true) {
      const rows = vouchers.data.filter(row => {
        return rowIds.includes(row.voucher_serial_number)
      });
      exportToPdf(rows);
    }

  }

  const handleExcelExport = async (rowIds) => {
    const response = await ApiCallerHelper.Statistics.newMonthlyVouchers(metadata, selectedRows);

    const promptResult = await EVOAlertComponent.Alert({ message: response.NewMonthlyVoucher 
    ? `Δημιουργήθηκε νέα πιστοποίηση με αριθμό ${response.Number}!` 
    : `Η πιστοποίηση με αυτά τα στοιχεία υπάρχει ήδη!` });

    if (promptResult.isConfirmed === true) {
      const rows = vouchers.data.filter(row => {
        return rowIds.includes(row.voucher_serial_number)
      });
      exportToExcel(rows);
    }
  
  }

  const exportToPdf = (rows) => {
    const sortedRows = sortRowsByVoucherBilling(rows);
    const docDefinition = createDocDefinition(sortedRows, metadata, metadata.contract_number);
    pdfMake.createPdf(docDefinition).download(`${filters.voucher_start_month ?? DateHelper.getCurrentMonthOption().value + 1}-${filters.voucher_start_year ?? DateHelper.getCurrentYearOption().value}.pdf`);
  }

  const exportToExcel = async (rows) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Μηνιαίες Πιστοποιήσεις');
    createDocumentBody(rows, metadata, worksheet);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filters.voucher_start_month ?? DateHelper.getCurrentMonthOption().value + 1}-${filters.voucher_start_year ?? DateHelper.getCurrentYearOption().value}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  let data = {
    columns : vouchers.columns,
    rows : ColumnsDefinition.getAndPrepareDataset()
  } 

  // Utility function for rendering specific filter item
  let renderFilterColumn = (columnName, columnWidth, isInput, value, onChangeFunc, options = []) => {
      const select_options = options.map((option) => {
          return option;
      });

      return (
          <Col md={columnWidth}>
              <label className="tool-theme_typing--input-label">
                  {columnName}
              </label>
              {isInput && <Input value={value} onChange={onChangeFunc}></Input>}
              {!isInput && <Select value={{label: value}} onChange={onChangeFunc} options={select_options}></Select>}
          </Col>
      )
  }

  const RenderHelper = {
    Filters : () => {
        return (
          <>
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card className="shadow-none no-padding">
                          <CardHeader className="border-0 no-padding">
                                <h3>Στοιχεία Πιστοποίησης</h3>
                                <h4>Επιλεγμένα δελτία: {selectedRows.length}</h4>
                                <h4>Ποσό επιλεγμένων δελτίων: {
                                  vouchers.data.filter(row => selectedRows.includes(row.voucher_serial_number)).reduce((accumulator, row) => {
                                    return accumulator + parseInt(row.voucher_price_without_vat);
                                  }, 0)
                                }</h4>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              {renderFilterColumn("Αριθμός DA*", 3, true, metadata.da_number, (e) => { setMetadata({...metadata, da_number: e.target.value}) })}
                              {renderFilterColumn("Αριθμός Παραγγελίας SAP*", 3, true, metadata.sap_number, (e) => { setMetadata({...metadata, sap_number: e.target.value}) })}
                              {renderFilterColumn("Αριθμός Τιμολογίου*", 3, true, metadata.invoice_number, (e) => { setMetadata({...metadata, invoice_number: e.target.value}) })}
                              {renderFilterColumn("Ημερομηνία Τιμολογίου*", 3, true, metadata.invoice_date, (e) => { setMetadata({...metadata, invoice_date: e.target.value}) })}
                            </Row>
                          </CardBody>
                        </Card>
                        <Card className="shadow-none no-padding">
                            <CardHeader className="border-0 no-padding">
                                <h3>Φίλτρα</h3>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    {renderFilterColumn("Εργολάβος", 3, true, filters.contractor_displayname, (e) => { setFilters({...filters, contractor_displayname: e.target.value}) })}
                                    {renderFilterColumn("Σύμβαση", 3, true, filters.voucher_contract_number, (e) => { 
                                      setFilters({...filters, voucher_contract_number: e.target.value});
                                      setMetadata({...metadata, contract_number: e.target.value});
                                    })}
                                    {renderFilterColumn("Μήνας", 3, false, filters.voucher_start_month, (option) => { setFilters({...filters, voucher_start_month: option.value}) }, filterOptions.month)}
                                    {renderFilterColumn("Έτος", 3, false, filters.voucher_start_year, (option) => { setFilters({...filters, voucher_start_year: option.value}) }, filterOptions.year)}
                                    {renderFilterColumn("Επιλογή χρέωσης", 4, false, filters.voucher_billing, (option) => { setFilters({...filters, voucher_billing: option.value}) }, filterOptions.xreosi)}
                                    <Col md={6} className="d-flex align-items-center">
                                      <div style={{ marginTop: '35px' }}>
                                        <Button color="primary" onClick={() => fetchMonthlyVouchers(filters)}>
                                          Αναζήτηση
                                        </Button>
                                      </div>
                                      <div style={{ marginTop: '35px', marginLeft: '20px' }}>
                                          <Button
                                              color="danger"
                                              onClick={clearFilters}
                                          >
                                              Εκκαθάριση Φίλτρων
                                          </Button>
                                      </div>
                                      <div style={{ marginTop: '35px', marginLeft: '20px' }}>
                                          <Button
                                              color="danger"
                                              onClick={() => handlePdfExport(selectedRows)}
                                              disabled={selectedRows.length === 0 || metadataNullChecker(metadata)}
                                          >
                                              Εξαγωγή PDF
                                          </Button>
                                      </div>
                                      <div style={{ marginTop: '35px', marginLeft: '20px' }}>
                                          <Button
                                              color="success"
                                              onClick={() => handleExcelExport(selectedRows)}
                                              disabled={selectedRows.length === 0 || metadataNullChecker(metadata)}
                                          >
                                              Εξαγωγή Excel
                                          </Button>
                                      </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
          </>
        )
    },
    Results : (data) => {
      return (
          <>
              <MDBDataTable
              barReverse={true}
              bordered
              hover
              strip="true"
              responsive
              data={data}
              keyfield="contractor"
              id={"mdbdttable"}
              entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
              nodatacomponent="Δεν υπάρχουν δεδομένα"
              searchLabel="Αναζήτηση"
              snext="Suivant"
              paginationLabel={['Προηγούμενο', 'Επόμενο']}
              infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
              entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
              /> 
          </>
      )
    }
  }

  return (
    <Container className="mt-0" fluid>          
      <Row className="mt-0">
          <Col className="mb-5 mb-xl-0" md="12">
            <Card className="shadow-none no-padding">
              <CardHeader className="border-0 no-padding">
                <Row className="align-items-center" style={{marginBottom: '20px'}}>
                  <div className="col">
                    <h3 className="mb-0">Μηνιαίες Πιστοποιήσεις / Τιμολόγηση</h3>
                  </div>
                  <div className="col text-right"></div>
                </Row>                                                                    
              </CardHeader>
              <CardBody>
                    {RenderHelper.Filters()}
                    {RenderHelper.Results(data)}
              </CardBody>
            </Card>
          </Col>
        </Row>              
    </Container>
  )
}

export default MonthlyVouchers