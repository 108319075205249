// ** React Imports
import { useEffect, useState, createContext } from 'react';
import { ApiCallerHelper } from "../api/apiCallerHelper";
import { UsersService } from "../common/UsersService";
import { LocationService } from "../common/LocationService";
import { WorkflowService } from "../common/WorkflowService";

// ** Create Context
const Base = createContext();

const BaseContext = ({ children }) => {
  // ** State
  const [baseCommon, setBaseCommon] = useState({});

  //** ComponentDidMount
  useEffect(() => {
    if (window === 'undefined') {return ;}            
    (async () => { 
      const currentUserResult = await ApiCallerHelper.Users.getCurrentAsync();
      const initCachedResponse = await initCachedServices();                    
      const obj = {
        loggedUser: currentUserResult?.data??null
      }           
      setBaseCommon({ ...obj })
    } )()   
  }, [])

  return <Base.Provider value={{ baseCommon }}>{children}</Base.Provider>
}

async function initCachedServices() {
  let promiseResults = await Promise.all([
    WorkflowService._initCache(),
    UsersService._initCache(),
    LocationService._initCache(),
  ]);
  return promiseResults;
}

export { Base, BaseContext }
