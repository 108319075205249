import {
  React,
  Fragment,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { DynamicStepActionTypes } from "../../common/enums/dynamicStepActionTypes.enum";
import { WorkflowStepActionIdTypes } from "common/enums/workflowStepActionIdTypes.enum";
import { OverrideRules } from "../../views/instances/custom/custom.InstanceBase.overrides.aspra-spitia";
import { RedirectHelper } from "../../common/redirectHelper";
import { ApiCallerHelper } from "../../api/apiCallerHelper";
import EVOAlertComponent from "../common/EvoAlertComponent";
import DynamicActionLabel from "./DynamicActionLabel";
import { Row, Col, Button } from "reactstrap";
import { WorkflowStepIdTypes } from "common/enums/workflowStepIdTypes.enum";
import { useParams } from "react-router-dom"; // Assuming you're using React Router for routing

export const DynamicButton = ({
  self,
  isEnabled,
  dynamicActions,
  stepId,
  user,
  isShowlabel,
  preCallbackAsync,
  stepStatus,
}) => {
  isShowlabel = isShowlabel || false;

  const { id } = useParams(); // Get instanceId from URL using React Router
  const [isInstanceDeleted, setIsInstanceDeleted] = useState(false);
  const [isSubmittedByMechanic, setIsSubmittedByMechanic] = useState(false);

  useEffect(() => {
    if (dynamicActions.action.Type == DynamicStepActionTypes.button) {
      (async () => {
        let res = await ApiCallerHelper.Instances.getIsDeletedInstanceAsync(id);
        setIsInstanceDeleted(res.data);
      })();



    }
  }, []);

  if (dynamicActions.action.Type != DynamicStepActionTypes.button) {
    return <></>;
  }

  // console.log(stepId);
  // console.log(dynamicActions)

  let ovverideRule = OverrideRules.getOnRenderControlOverrideRule(
    self.state,
    dynamicActions.action.Id,
    user
  );

  let overrideRulesActionDisplayName = null;
  let renderOnCompletion = null;
  let pressAgain = null;
  let isVisible = null;

  if (ovverideRule != null) {
    if (ovverideRule.isEnabled == false) {
      isEnabled = false;
    }


    if (
      ovverideRule.actionDisplayName != null
    ) {
      overrideRulesActionDisplayName = ovverideRule.actionDisplayName;
    }

    renderOnCompletion = ovverideRule.renderOnCompletion
    pressAgain = ovverideRule.pressAgain;
    isVisible = ovverideRule.isVisible;
  }

  const dynamicCall = async (actionId, actionMetaData, description) => {
    let promptResult = await EVOAlertComponent.Prompt({
      titleMessage: description,
    });
    if (promptResult.isConfirmed === true) {
      self.setState({ isActionsVisible: false });
      if (preCallbackAsync) {
        let preCallbackResult = await preCallbackAsync(stepStatus);
      }
      let result = await ApiCallerHelper.Dynamic.buttonPressedAsync(
        actionId,
        self.state.instance.Id,
        actionMetaData
      );
      if ((result?.isRedirect ?? false) && result.isRedirect === true) {
        RedirectHelper.RedirectTo.dynamicRedirect(result.redirectUrl);
        return;
      }
      RedirectHelper.RedirectTo.reloadPageFromCache();
    }
  };

  if(isSubmittedByMechanic) {
    dynamicCall(
      dynamicActions.action.Id,
      dynamicActions.action.MetaDataJson,
      dynamicActions.action.Name
    )
  }

  let button = (
    <Fragment>
      {isShowlabel && (
        <Fragment>
          <DynamicActionLabel
            title={dynamicActions.action.Name}
            description={dynamicActions.action.Description}
          />
          <br></br>
        </Fragment>
      )}
      <Button
        color="primary"
        onClick={(evt) =>
          dynamicCall(
            dynamicActions.action.Id,
            dynamicActions.action.MetaDataJson,
            dynamicActions.action.Name
          )
        }
        size="lg"
      >
        {overrideRulesActionDisplayName != null ? overrideRulesActionDisplayName : dynamicActions.action.Name}
      </Button>
    </Fragment>
  );

  let buttonPressed = (
    <Fragment>
      {isShowlabel && (
        <Fragment>
          <DynamicActionLabel
            title={dynamicActions.action.Name}
            description={dynamicActions.action.Description}
          />
          <br></br>
          <i className="fa fa-check text-success"></i>&nbsp; Επιλέχθηκε στις
          &nbsp;
          {new Date(dynamicActions.data.DateUpdated).toLocaleString(undefined, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </Fragment>
      )}
      {/* <Button color="primary" size="lg" disabled={true}>
        {dynamicActions.action.Name}
      </Button> */}
    </Fragment>
  );

  const isPressedButton = () => {
    if (dynamicActions.data.Data) {
      let data = JSON.parse(dynamicActions.data.Data);
      return data.isPressed;
    }
    return false;
  };

  if(isVisible === false && !renderOnCompletion) {
    return <></>;
  }

  // Show button as pressed
  if (
    (isPressedButton() && !pressAgain) ||
    (renderOnCompletion === true && isPressedButton())
  ) {
    return buttonPressed;
  }

  // Show button as pressable
  if (isEnabled || pressAgain) {
    if (!isInstanceDeleted) return button;
  }

};
