import React, {
  Fragment,
  useState,
  useContext,
  createContext,
  useEffect,
} from "react";
import { EVOReactTableDynamicGridHelper } from "../../components/common/evo.react-table-dynamic-grid-helper";
import EvoActionsDynamicGridComponent from "../DynamicGridComponent/EvoActionsDynamicGridComponent";
import { DynamicStepActionTypes } from "../../common/enums/dynamicStepActionTypes.enum";
import { OverrideRules } from "../../views/instances/custom/custom.InstanceBase.overrides.aspra-spitia";
import { Button, Row, Col, Util } from "reactstrap";
import DynamicActionLabel from "./DynamicActionLabel";
import DynamicReactTableRowAdder from "./DynamicReactTableRowAdder";
import AuthService from "common/AuthService";

export const DynamicReactTable = ({
  self,
  isEnabled,
  dynamicActions,
  stepId,
  isShowlabel,
  showAddInlineRowButton,
  customButtonActionInfo,
}) => {
  const reactGridDynamicGridComponentRefs = React.createRef(); //create reference of the react grid so as to call inner functions
  const DATATABLE_REVIEW_RESIDENT = "aee3174c-6f7d-4b19-9a6d-6b8b4cb6f84a";
  const [_isModalShown, setIsModalShown] = React.useState(false);
  const current_user_group = AuthService.getCurrentUserGroup();
  if (dynamicActions.action.Type != DynamicStepActionTypes.dynamicReactTable) {
    return <></>;
  }
  // console.log(dynamicActions, showAddInlineRowButton, customButtonActionInfo);
  isShowlabel = isShowlabel || false;
  showAddInlineRowButton = showAddInlineRowButton || true;
  let showCustomButtonInfo = false;
  let hasCustomButtonInfo = false;
  let isVisible = true;
  
  let metaData =
    EVOReactTableDynamicGridHelper.DynamicAction.getDatasetAndColumnDefinitionsFromStepActionsData(
      dynamicActions.action
    );
  let columnMetaDataDefinitions =
    EVOReactTableDynamicGridHelper.DynamicAction.getGridDefinitionByColumnMetadataValue(
      metaData
    );

  function setOverrideRulesIfAny() {
    //SETS CUSTOM OVERRIDE RULES
    let ovverideRule = OverrideRules.getOnRenderControlOverrideRule(
      self.state,
      dynamicActions.action.Id
    );
    // console.log(dynamicActions.action.Id);
    // console.log(ovverideRule);

    if (ovverideRule) {
      if (
        ovverideRule.isVisible != null && ovverideRule.isVisible == false
      ) {
        
        isVisible = false;
      }

      if (ovverideRule.isEnabled != null && ovverideRule.isEnabled === false) {
        isEnabled = false;
      }
      if (
        ovverideRule.columnMetaDataDefinitions != null &&
        ovverideRule.columnMetaDataDefinitions.length > 0
      ) {
        columnMetaDataDefinitions = ovverideRule.columnMetaDataDefinitions;
      } else {
        if (dynamicActions.action.Id != "e03451bc-b296-4535-9bf9-2c1a4cab96cc" && dynamicActions.action.Id != DATATABLE_REVIEW_RESIDENT)
          isEnabled = false;
      }

      //define custom row picker
      if (ovverideRule.customButtonActionInfo != null) {
        customButtonActionInfo = ovverideRule.customButtonActionInfo;
        hasCustomButtonInfo = true;
        showCustomButtonInfo = true;
      }

      if (ovverideRule.showAddInlineRowButton != null) {
        showAddInlineRowButton = ovverideRule.showAddInlineRowButton;
      }
    }

    if (dynamicActions.action.Id == DATATABLE_REVIEW_RESIDENT)
      isEnabled = false;
      
  }

  setOverrideRulesIfAny();

  let dataset = dynamicActions.data.Data;
  if (dataset === null || dataset === "") {
    dataset = [];
  } else {
    dataset = JSON.parse(dynamicActions?.data?.Data ?? []);
  }

  const CustomButton = ({ _customButtonTitle, _color }) => {
    _color = _color || "primary";
    return (
      <Button
        className="float-right mb-2"
        color={_color}
        onClick={() => {
          setIsModalShown(true);
        }}
        size="md"
      >
        {_customButtonTitle}
      </Button>
    );
  };

  function onDynamicReactTableRowAdderOnRowSelectionCallback(value) {
    setIsModalShown(false);
    if (!hasCustomButtonInfo) {
      return;
    }
    let enforcedRowDataValues =
      customButtonActionInfo.rowAdder.getNewRowValue(value);
    reactGridDynamicGridComponentRefs.current.onClickAddRow(
      enforcedRowDataValues
    );

    // function getMappedModel(initialInput) {
    //   const filteredObjectWithoutReactElements = Object.keys(initialInput)
    //     .filter(key => key.startsWith('__column____') == false
    //     && key != "__action"
    //     )
    //     .reduce((obj, key) => {
    //       obj[key] = initialInput[key];
    //       return obj;
    //     }, {});
    //   let input = Clone(filteredObjectWithoutReactElements);
    //   let data = {
    //     __productCode: input.__productCode,
    //     __productFinalPrice: input.__productPrice,
    //     __productPrice: input.__productPrice,
    //     __productQA: input.__productQA,
    //     __productQuantity: 1,
    //     __productTechnDescr: input.__productTechnDescr,
    //     __productCategoryType : {
    //       value : input.wvpCatDesc,
    //       label :  input.wvpCatDesc,
    //       item : {
    //         value : input.wvpCatDesc,
    //         label :  input.wvpCatDesc,
    //       }
    //     },
    //     __productDescription : {
    //       ...input,
    //       item : {...input},
    //       value : input.wppeSapId,
    //       label : input.wvpDescription
    //     }
    //   };
    //   return data;
    // }
    // let enforcedRowDataValues = getMappedModel(value);
    // if customButtonActionInfo
    // setIsModalShown(false);
    //console.log(reactGridDynamicGridComponentRefs.current.getData(), enforcedRowDataValues)
    //reactGridDynamicGridComponentRefs.current.onClickAddRow(enforcedRowDataValues);
  }

  let renderActionButtons = () => {

    if (!isEnabled) {
      return (
        <Row>
          <Col md="12">
            <Button
              className="float-right mb-2"
              color="primary"
              onClick={() =>
                reactGridDynamicGridComponentRefs.current.onExportExcel(
                  "ExportedFile",
                  "Sheet1"
                )
              }
              size="lg"
            >
              Εξαγωγή
            </Button>
          </Col>
        </Row>
      );
    }
    return (
      <Row>
        <Col md="12">
          <DynamicReactTableRowAdder
            dynamicGridId={dynamicActions.action.Id}
            isShown={_isModalShown}
            onHideModal={() => {
              setIsModalShown(false);
            }}
            onRowSelectionCallback={
              onDynamicReactTableRowAdderOnRowSelectionCallback
            }
            title={customButtonActionInfo?.rowAdder?.title}
            dataSource={customButtonActionInfo?.rowAdder?.dataSource}
            gridView={customButtonActionInfo?.rowAdder?.gridView}
          />
          {showCustomButtonInfo && (
            <CustomButton
              _customButtonTitle={customButtonActionInfo.title}
              _customButtonCallback={customButtonActionInfo.callback}
              _color={customButtonActionInfo.color}
            />
          )}
          {showAddInlineRowButton &&
            dynamicActions.action.Id !=
              "e03451bc-b296-4535-9bf9-2c1a4cab96cc" && (
              <Button
                className="float-right mb-2"
                color="primary"
                onClick={() =>
                  reactGridDynamicGridComponentRefs.current.onClickAddRow()
                }
                size="md"
              >
                + Προσθήκη Εγγραφής
              </Button>
            )}
          <Button
            className="float-right mb-2 mr-2"
            color="warning"
            onClick={() =>
              reactGridDynamicGridComponentRefs.current.onClickResetData()
            }
            size="md"
          >
            Αρχικοποίηση
          </Button>
        </Col>
      </Row>
    );
  };

  if(!isVisible) {
    return (<></>);
  }

  return (
    <Fragment>
      {isShowlabel && (
        <Fragment>
          <DynamicActionLabel
            title={dynamicActions.action.Name}
            description={dynamicActions.action.Description}
          />
          <br></br>
        </Fragment>
      )}
      {renderActionButtons()}
      <Row>
        <Col md="12">
          <EvoActionsDynamicGridComponent
            className={"tool-theme_dynamic-table"}
            dataset={dataset}
            columnMetadataDefinitions={columnMetaDataDefinitions}
            ref={reactGridDynamicGridComponentRefs}
            innerScrollXOverflow={"auto"}
            isEditable={isEnabled}
            onUpdateCallback={(data) =>
              self.updateInputValue(
                isEnabled,
                JSON.stringify(data),
                stepId,
                dynamicActions.action.Id
              )
            }
          />
        </Col>
      </Row>
    </Fragment>
  );
};
