/*
* Needs values refactor
*/
export const WorkflowStepActionIdTypes = {
    ERGODOTIS : 'dc5c2341-0fe4-4912-b09c-2497855f7776',
    EPIVLEPON : 'fcf1056c-4d54-46c8-9494-0c0c0d5dc6bc',
    NOMOS_DIMOS : '6cc71d92-7162-4f30-b38c-8a953381b571',


    step_1_stepActionWorkVoucherTable : "285c23f4-12a5-4fc0-b748-c7b5b78cf3ae",
    flow_2_step_1_stepActionIdContractor : '2e7e72e1-06c7-4667-95ca-8ccbb185fa56', 
    flow_2_step_1_stepActionIdContractNumber : '82d5c32f-cfa4-42c1-acb6-c4e062ebc216', 
    flow_2_step_1_stepActionIdExpectedExecutionDate : '623c0294-f80d-486a-b574-926c392faa13', //Προβλεπόμενη Εκτέλεση

    flow_2_step_1_stepAcionIdVoucherSumProductFinalPrice : 'be4d968f-c7de-4fc6-9a43-534ab2584277',//Συνολικό Ποσό
    flow_2_step_1_stepAcionIdVoucherSumVatProductFinalPrice : 'c308f05e-fbbf-4942-8821-c5a42bdfb0d0',//Σύνολικό Ποσό με ΦΠΑ
    
    flow_1_step_1_stepActionFaultCategory: '46b0ede2-48bd-41fd-8f03-3f37e2473ebf', //Κατηγορία Βλάβης    
    flow_1_step_1_stepActionLocation: 'd14b2dde-75c4-487c-97a9-7d17812a8aa2', //Τοποθεσία
    flow_1_step_1_stepActionAddress: '03472a86-0b25-41c7-8cab-ddd3334175d5', //Διεύθυνση Οικίας
    flow_1_step_1_stepActionExternalHouseId: 'd0dd979e-a2f0-4464-a53d-a725c29c0386', //Μητρώο Κατοικίας
    flow_1_step_1_stepActionFaultPublicCategories: 'b90e1a4f-7c6b-4e6d-92f8-d65f2e857a30', // Κοινόχρηστες κατηγορίες
    flow_1_step_1_stepActionFaultPublicSubcategories: 'a2f6d85e-4b9a-47d7-9e78-8463e66a7e42', // Κοινόχρηστες υποκατηγορίες
    flow_1_step_1_stepActionFaultPublicSubCategoriesOther: 'bbb9afd2-61b9-4a58-839c-03d5b407ee86', // Κοινόχρηστες υποκατηγορίες "Άλλο"
    flow1_step2_stepActionAcceptInstance: '55a5c3c8-b901-4a9a-b90e-ab93310f93de',

    flow_1_step_2_stepActionAcceptWithoutVoucher: 'acf63931-118e-4529-9eef-278fac3e8218',
    flow_1_step_2_stepActionRejectFault: 'bd0378d3-e58a-47df-806b-fbb1b322ff4c',

    flow_1_step_3_stepActionRating: '8a14481b-4b6c-4a06-b6e7-22af32d5db1f',
    flow_1_step_3_stepActionNotes: '9b16c679-b5c1-45b5-adb5-7fc7762e58af',
    flow_1_step_3_stepActionConfirm: '661720e0-0549-436a-b48b-fec8e32b92ce',
    flow_1_step_3_stepActionPreview: 'aee3174c-6f7d-4b19-9a6d-6b8b4cb6f84a',
    flow_1_step_3_stepActionExtrasPreview: 'fba3aa59-b31c-4359-9f9f-c0fd32ac7d35',

    flow_2_step_2_stepActionApproveVoucher: '692de2d6-dc40-4bf5-8ebf-eb6ae0cba1d7',
    flow_2_step_2_stepActionRejectVoucher: 'fb921506-8ca5-43df-9a86-213c3a7f590e',
    flow_2_step_2_stepActionRejectVoucherComments: 'b5943b0e-1144-42b2-88ab-6b7646240327',

    flow_2_step_3_stepActionAcceptAssignment: '10b60e7b-0d7f-4e46-82d6-8cd452f9367c',
    flow_2_step_3_stepActionRejectAssignment: '71d22ce2-ffd2-4128-b9bd-a7564ed01ae8',
    
    flow_2_step_4_stepActionAdditionalWork: '9dd1ef0b-9266-40c2-8ce7-351ab831caf8',
    flow_2_step_4_stepActionAdditionalWorkPreview: 'f83c54ab-1b3d-4ca8-8963-c6ad7122126e',
    flow_2_step_4_stepActionCompleteWork: '37772f36-f50f-48a9-9088-c9601ce6d71e',

    flow_2_step_5_stepActionAdditionalWorkFromMechanic: 'c385185d-3305-4dd5-8bbe-e7b79ea7d0d7',
    flow_2_step_5_stepActionConfirmWork : "915f09c5-1835-4c12-b0bc-20b86bba77a2",
    flow_2_step_5_stepActionEditWorkVoucher : '1df4bf94-d29f-494b-847a-f55f53bf026c',

    flow_2_step_6_stepActionExportFinalVoucher: 'e487e023-d215-419c-8966-c958766d43fd',
    flow_2_step_6_stepActionRejectFinalVoucher: 'ae8901cd-06e4-4726-9d71-5d191775300c',

    flow_2_CategoryOfWork : '62f652b0-cb55-4d30-8f78-04043559c6cb',    //Κατηγορία Εργασίας

    flow_3_step1_WorkExtras : 'e03451bc-b296-4535-9bf9-2c1a4cab96cc',    //Πρόσθετες Εργασίες
    flow_3_step_1_stepActionExtrasSumProductFinalPrice: 'b3844805-652f-4d05-beb3-7ad9e37224e7',
    flow_3_step_1_stepActionExtrasSumVatProductFinalPrice: 'df77a5e5-13e9-463d-8be5-c40165386eda',
    
    flow_3_step_2_stepActionCompleteExtras : "023e3692-e703-4173-bfc7-7d30ed904f72",
    
    flow_3_step_2_approve_extras : '390e06ba-47fd-427b-bc18-8799973c005d',   //Έγκριση Εργασιών
    flow_3_step_2_decline_extras : '9287e568-2b41-4b6f-b95a-d4aff3b326c0',    //Έγκριση Εργασιών
    flow_3_step_2_edit_extras : 'fbcf74d5-349c-412d-8ee0-4416e86a04a7',     // Αναθεώρηση Εργασιών
}	
