import React, { useState, useEffect, Fragment } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Container, Row, Col, Button } from "reactstrap";
import AssetHelper from "../../../common/AssetHelper"
import ApiCallerHelper from "../../../api/apiCallerHelper";
import RedirectHelper from "../../../common/redirectHelper";
import CustomIndexResidentFilterFaults from "./custom.Index.Resident.Filter";
import {isNullOrEmptyString} from "../../../common/utils";

const CustomIndexResidentMobile = ({ gridViewId, customActionsCallbackMethods }) => {  
  let dynamicGridId = dynamicGridId || gridViewId;
  const [_gridViewData, setGridViewData] = React.useState({});
  const [_viewModels, setViewModels] = React.useState([]);
  const [_appliedFilter, setAppliedFilter] = React.useState(() => {});

  useEffect(() => {
    (async () => {
      let result = await ApiCallerHelper.Views.getGridViewDataById(gridViewId);             
      setGridViewData(result.data);
    })();
  }, [gridViewId]);

  useEffect(() => {        
    const viewModels = getViewModelsFromInitialDatasource();     
    setViewModels(viewModels);
  }, [_gridViewData]); 

    
  useEffect(() => {  
    const viewModels = getViewModelsFromInitialDatasourceFiltered();    
    setViewModels(viewModels);
  }, [_appliedFilter]); 

  const onFilterClick = (value) => {
    setAppliedFilter(value);
  };

  const getInitialDataSource = () => {
    return _gridViewData?.datasource??[];
  }

  const getViewModelsFromInitialDatasource = () => {
    const viewModels = (getInitialDataSource()).map(model => ({ 
      SerialNumber : model.wfiParent_SerialNumber,
      FaultType : model.faultTypeDescription,
      StatusDescription : model.calculatedFaultStatusDescription,
      DateCreated : model.wfiParent_StartedAt_formatted,
      AssignedUser : model._usrwfidvouchermixanikosanathesis_displayname??' - ',
      WorkflowInstanceId : model.workflowInstanceId,
      Comment : model.wfiParent_perigrafh_vlavhs_Data,
      CalculatedSubStatus : model.CalculatedSubStatus,
      
      //comments
      wfiParent_sxolio_dateUpdated_formatted : model.wfiParent_sxolio_dateUpdated_formatted,
      wfiParent_sxolio_Data : model.wfiParent_sxolio_Data,
      _usrparent_sxolio_displayname : model._usrparent_sxolio_displayname,
      hasMessage : (isNullOrEmptyString(model.wfiParent_sxolio_Data) == false)
    }));
    return viewModels
  }

  const getViewModelsFromInitialDatasourceFiltered = () => {
    const _applyCustomFilters = (datasource, filters) => {
      let filteredRows = datasource;
      if (datasource && (filters?.filter ?? null)) {
        filteredRows = datasource.filter(filters.filter);
      }
      return filteredRows;
    };

    let filteredRows = _applyCustomFilters(getViewModelsFromInitialDatasource(), _appliedFilter);    
    return filteredRows; 
  }

  const style = {    
    border: "1px solid gray",
    margin: 0,
    padding: 2,
    paddingTop: 10,
    borderRadius:10,
    boxShadow: "3px 3px lightblue"
  };

//EXAMPLE FOR PAGINATION LIKE
{
//  const [items, setItems] = React.useState(Array.from({ length: 20 }));
//   const fetchMoreData = () => {
//     // a fake async api call like which sends
//     // 20 more records in 1.5 secs
//     setTimeout(() => {
//       let data = items.concat(Array.from({ length: 20 }));
//       setItems(data);

//       //   this.setState({
//       //     items: this.state.items.concat(Array.from({ length: 20 }))
//       //   });
//     }, 500);
//   };
}

  return (
    <div>
      <CustomIndexResidentFilterFaults
        filterClick={onFilterClick}
        datasource={getInitialDataSource()}
        showFilteredSums={true}
      />
      <h2>Λίστα Αιτημάτων</h2>
      <hr />
      <InfiniteScroll
        dataLength={_viewModels.length}
        //next={fetchMoreData}
        // hasMore={this.state.hasMore}
        //loader={<h4>Loading...</h4>}
        height={500}
        // endMessage={
        //   <p style={{ textAlign: "center" }}>
        //     <b>Yay! You have seen it all</b>
        //   </p>
        // }
      >
        {_viewModels.map((item, index) => (
          <div style={style} key={index}>
            <Container fluid className="tool-theme-label-infite">
              <Row>
                <Col xs="6">
                  <span className="tool-theme-label-infite--info">Α/Α</span> :{" "}
                  {item.SerialNumber}
                </Col>
                <Col xs="6">
                  <span className="tool-theme-label-infite--primary bold float-right">
                    {item.FaultType}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs="9">
                  <span className="tool-theme-label-infite--info">Ημ/νία</span>{" "}
                  :{item.DateCreated}
                </Col>
                <Col xs="3">
                  {item.hasMessage == true && (
                    <Button
                      className="float-right"
                      color="link"
                      size="sm"
                      title="Μήνυμα"
                      onClick={() => {
                        customActionsCallbackMethods.showMessageStep1Approver(
                          item
                        );
                      }}
                    >
                      <img
                        style={{ width: 39, height: "auto" }}
                        src={AssetHelper.Icons.messages}
                      ></img>
                    </Button>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="9">
                  <span className="tool-theme-badge badge badge-primary">
                    {item.StatusDescription}
                  </span>
                </Col>
                <Col xs="3">
                  <Button
                    className="float-right"
                    color="link"
                    size="sm"
                    title="Αίτηση"
                    onClick={() => {
                      RedirectHelper.RedirectTo.flowInstance(
                        item.WorkflowInstanceId
                      );
                    }}
                  >
                    <img src={AssetHelper.Icons.edit}></img>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <span className="tool-theme-label-infite--info">
                    Ανατέθηκε σε
                  </span>{" "}
                  : {item.AssignedUser}
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <span className="tool-theme-label-infite--info">Σχόλιο</span>{" "}
                  : {item.Comment}
                </Col>
              </Row>
            </Container>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default CustomIndexResidentMobile;
