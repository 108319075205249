import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import {EVOReactSelectHelper} from "../../../components/common/evo.react-select-helper";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";

import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  InputGroup
} from "reactstrap";

import { useForm } from "react-hook-form";


import { ApiCallerHelper } from "../../../api/apiCallerHelper";
import { UserRoleHelper} from "../../../common/userRoleHelper";
import { UserGroupHelper} from "../../../common/userGroupHelper";
import { RedirectHelper } from "../../../common/redirectHelper";
import { UsersService } from "../../../common/UsersService";
import { LocationService } from "../../../common/LocationService";
import EVOAlertComponent from "../../../components/common/EvoAlertComponent";
import EVOReactHookFormHelper from "../../../components/common/evo.react-hook-form-helper";

const UserEditModal =  ({isShown, onHideModal, userId}) => {         
    const [_userModel, setUserModel] = React.useState(null);
    const [_groups, setGroups] = React.useState([]);
    const _formId = "userEditModal"
    const {
      register,
      handleSubmit,
      setValue,
      control,
      watch,
      reset,
      formState: { errors },
    } = useForm({});

    /*
    Update user id
    then update user groups
    then refresh form validation with extra values
    */

    useEffect(() => {                          
      refreshSetUserModelByUserId()
    },[userId]);

    useEffect(() => {   
      refreshSetGroupsByRoleName(_userModel?.PersonRole??'');                          
    },[_userModel]);       

    useEffect(() => {     
      refreshValidationFormValues()                              
    },[_groups]);

   
    const refreshSetUserModelByUserId = async () => {
      let user = UsersService.Factory.GetNewUser({});
      if (userId != null) {
        user = await UsersService.getUserById(userId);      
      }    
      setUserModel(user);
    }           

    const refreshSetGroupsByRoleName = async (roleName) => {       
      let roleId = UsersService.getRoleByRoleName(roleName)?.Id??null;      
      if (roleId != null) {
        const groups = await ApiCallerHelper.Roles.getGroupsByRole(roleId);      
        setGroups(groups.data);
      } else{
        setGroups([]);
      }                
    }

    const refreshValidationFormValues = () => {       
      [
        "Email",
        "DisplayName",
        "PhoneNumber",
        "UserName",
        "ExternalUserId"
      ].forEach(element => {
        setValue(element, _userModel?.[element]??'' );
      });   
      let selectedPersonRole = FormItemHelpers.GetRolesInfo().selectedOption;
      setValue("PersonRole", selectedPersonRole??null);
      let selectedGroups = FormItemHelpers.GetGroupsInfo().selectedOption;      
      setValue("Groups", selectedGroups??null);
      let selectedLocation = FormItemHelpers.GetLocationsInfo().selectedOption;
      setValue("Location", selectedLocation??null);              
    }

    const handleFieldChange = (evt, fieldName) => {
      handleFieldChangeByValue(evt.target.value, fieldName);    
    }
  
    const handleFieldChangeByValue = (value, fieldName) => {  
      let editedUserModel = {..._userModel}
      editedUserModel[fieldName] = value;           
      setUserModel(editedUserModel);      
    }
   
    const FormItemHelpers = {   
      GetGroupsInfo:()=>{
        let options = _groups.map((dynamicGroups, key) => {
          return { value: dynamicGroups.Id, label: dynamicGroups.Name };
        });
        let userGroups = _userModel?.Groups ?? [];        
        let selectedOptions = options.filter((x) => userGroups.includes(x.label));        
        return {
          options: options,
          selectedOption : selectedOptions
        }
      },    
      GetRolesInfo:()=>{
        let roles = UsersService._cache.allRoles ?? [];
        let options = roles.map((dynamicRoles, key) => {
          return { value: dynamicRoles.Id, label: dynamicRoles.Name };
        });
        let defaultValue = _userModel?.PersonRole;
        let selectedOption =
          options.filter((x) => x.label == defaultValue)[0] ?? null;
        return {
          options: options,
          selectedOption : selectedOption
        }
      },     
      GetLocationsInfo:()=>{
        let options = LocationService.getAllLocations().map(
          (dynamicLocations, key) => {
            return {
              value: dynamicLocations.Id,
              label: dynamicLocations.Name,
            };
          }
        );

        let defaultValue = _userModel?.LocationId??null;
        let selectedOption =
          options.filter((x) => x.value == defaultValue)[0] ?? null;

        return {
          options: options,
          selectedOption : selectedOption
        }
      },    
    };  
   
    const onSubmit = async (data) => {          
      let promptResult = await EVOAlertComponent.Prompt({});      
      if (promptResult.isConfirmed == true) {          
        let updateResult = await UsersService.saveUserData( 
          { Id : userId,
            Email : data.Email,
            DisplayName: data.DisplayName,
            PhoneNumber: data.PhoneNumber,
            UserName: data.UserName,
            ExternalUserId: data.ExternalUserId,
            PersonRoleId: data.PersonRole?.value??null,
            GroupIds: data.Groups?.map(x=> x.value)??null,
            LocationId: data.Location?.value??null}
        );
        if (updateResult === true){
          onHideModal(true);
        }        
      }             
    };

    if (_userModel == null || _userModel.Id != userId){
      return (<></>)
    }   
    return (
      <Modal
        show={isShown}
        onHide={() => onHideModal(false)}
        //dialogClassName="modal-90w"
        dialogClassName="tool-theme_modal-dialog"
        aria-labelledby="example-custom-modal-styling-title"
        style={{ opacity: 1 }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Στοιχεία Χρήστη
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id={_formId} onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">Email Χρήστη</label>
                  <EVOReactHookFormHelper.Input
                    name="Email"
                    placeholder="Email Χρήστη"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-stepAction-edit-description"
                  >
                    Ονοματεπώνυμο Χρήστη
                  </label>
                  <EVOReactHookFormHelper.Input
                    name="DisplayName"
                    placeholder="Ονοματεπώνυμο Χρήστη"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-stepAction-edit-description"
                  >
                    Username
                  </label>
                  <EVOReactHookFormHelper.Input
                    name="UserName"
                    placeholder="Username"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    rules={{ required: false }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-stepAction-edit-description"
                  >
                    Κωδικός Μητρώου Κατοικίας
                  </label>
                  <EVOReactHookFormHelper.Input
                    name="ExternalUserId"
                    placeholder="Κωδικός Μητρώου Κατοικίας"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    rules={{ required: false }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-stepAction-edit-description"
                  >
                    Τηλέφωνο Χρήστη
                  </label>
                  <EVOReactHookFormHelper.Input
                    name="PhoneNumber"
                    placeholder="Τηλέφωνο Χρήστη"
                    className="form-control-alternative"
                    control={control}
                    errors={errors}
                    rules={{ required: false }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-stepAction-edit-description"
                  >
                    Τοποθεσία
                  </label>
                  <EVOReactHookFormHelper.Select
                    name="Location"
                    placeholder="Τοποθεσία"
                    options={FormItemHelpers.GetLocationsInfo().options}
                    isClearable={true}
                    // onChange={(value) => {
                    //   handleFieldChangeByValue(value?.value??null, "LocationId");
                    // }}
                    styles={EVOReactSelectHelper.getDefaultStyles()}
                    control={control}
                    errors={errors}
                    rules={{ required: false }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-stepAction-edit-description"
                  >
                    Ρόλος Χρήστη
                  </label>
                  <EVOReactHookFormHelper.Select
                    name="PersonRole"
                    placeholder="Ρόλος Χρήστη"
                    options={FormItemHelpers.GetRolesInfo().options}
                    isClearable={true}
                    onChange={(value) => {
                      let label = value.label;
                      handleFieldChangeByValue(label, "PersonRole");
                    }}
                    styles={EVOReactSelectHelper.getDefaultStyles()}
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-stepAction-edit-description"
                  >
                    Ομάδα Χρήστη
                  </label>
                  <EVOReactHookFormHelper.Select
                    name="Groups"
                    placeholder="Ομάδα Χρήστη"
                    options={FormItemHelpers.GetGroupsInfo().options}
                    isMulti
                    isClearable={true}                   
                    styles={EVOReactSelectHelper.getDefaultStyles()}
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                  />                 
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="col text-right">
            <Button color="success" size="md" form={_formId}>
              Αποθήκευση
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );   

  }

export default UserEditModal;