import React from "react";
// node.js library that concatenates classes (strings)
import { MDBDataTable } from 'mdbreact';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";

import 'moment/locale/el';

import { UserGroupHelper } from "../../../common/userGroupHelper";
import { Clone } from "../../../common/utils";
import { WorkflowIdTypes } from "../../../common/enums/workflowIdTypes.enum";
import { Fragment } from "react";
import { RedirectHelper } from "../../../common/redirectHelper";
import EVOBaseComponent from "../../../components/common/EvoBaseComponent"
import CustomIndexResidentMobile from "./custom.Index.Resident.Mobile";
import MediaQuery from "react-responsive";
import AssetHelper from "../../../common/AssetHelper";
import { Checkbox } from 'antd';
import EVOMDBDataTableHelper from "../../../components/common/evo.mdbDatatable-helper"
import LiteralsHelperGridsCustomer from "../../../common/literalsHelper.grids.customer";

class History extends EVOBaseComponent {
  constructor(props) {
    super(props);
    this.state = {      
      faults: [],
      vouchers: [],
      extras: [],
      ...this.state,
    };
  }
  componentDidMount() {
    super.componentDidMount(this.getAndSetAllInstances);
  }
  
  ColumnsDefinition = {
    getColumnsBase: (worfklowIdType) => {
      let dataSourceFieldName = "";
      if (worfklowIdType ==  WorkflowIdTypes.FAULTS) {
        dataSourceFieldName = "faults";
      }
      if (worfklowIdType ==  WorkflowIdTypes.VOUCHERS) {
        dataSourceFieldName = "vouchers";
      }
      if (worfklowIdType ==  WorkflowIdTypes.EXTRA) {
        dataSourceFieldName = "extras";
      }
      return [
        {
          label: LiteralsHelperGridsCustomer.GridCommon.SerialNumber,
          field: "SerialNumber",
          sort: "asc",
        },
        {
          label: LiteralsHelperGridsCustomer.GridCommon.DateCreated,
          field: "DateCreated",
          sort: "asc",
        },
        {
          //label: "Βήμα ▼",
          label: (<Fragment><a onClick={()=>{EVOMDBDataTableHelper.customSort(this, "StatusDescription", dataSourceFieldName)}}>{LiteralsHelperGridsCustomer.GridCommon.StatusDescription}</a></Fragment>),
          field: "_StatusDescription",
          sort: "asc",
        },
        {
          //label: ("Ολοκληρωμένη ▼"),
          label: (<Fragment><a onClick={()=>{EVOMDBDataTableHelper.customSort(this, "Completed", dataSourceFieldName)}}>{LiteralsHelperGridsCustomer.GridCommon.Completed}</a></Fragment>),          
          field: "Completed",
          sort: "asc",
        },
        {
          label: LiteralsHelperGridsCustomer.GridCommon.WithDecline,
          label: (<Fragment><a onClick={()=>{EVOMDBDataTableHelper.customSort(this, "Declined", dataSourceFieldName)}}>{LiteralsHelperGridsCustomer.GridCommon.WithDecline}</a></Fragment>),                    
          field: "Declined",
          sort: "asc",
        },        
        {
          label: LiteralsHelperGridsCustomer.GridCommon.CreatedByuser,
          field: "CreatedByuser",
          sort: "asc",
        },
        {
          label: LiteralsHelperGridsCustomer.GridCommon.AssignedUser,
          field: "AssignedUser",
          sort: "asc",
        },
        {
          label: LiteralsHelperGridsCustomer.GridCommon.Actions,
          field: "Buttons",
          sort: "asc",
        },
      ];
    },
    getColumnsFault: () => {
      return this.ColumnsDefinition.getColumnsBase(WorkflowIdTypes.FAULTS);
    },   
    getColumnsVoucher: () => {
      let columns = this.ColumnsDefinition.getColumnsBase(WorkflowIdTypes.VOUCHERS);
      EVOMDBDataTableHelper.addColumnsAfterColumn(columns, "SerialNumber", [{
        label: LiteralsHelperGridsCustomer.GridCommon.ParentSerialNumber,
        field: "ParentSerialNumber",
        sort: "asc",
      }]);     
      
      EVOMDBDataTableHelper.addColumnsAfterColumn(columns, "AssignedUser", [
        {
          label: LiteralsHelperGridsCustomer.GridCommon.AssignedTechnician,
          field: "AssignedTechnician",
          sort: "asc",
        },
        {
          label: LiteralsHelperGridsCustomer.GridCommon.AssignedContractor,
          field: "AssignedContractor",
          sort: "asc",
        },
      ]);  
           
      return columns;
    },  
    getColumnsExtras: () => {
      let columns = this.ColumnsDefinition.getColumnsBase(WorkflowIdTypes.EXTRA);
      EVOMDBDataTableHelper.addColumnsAfterColumn(columns, "SerialNumber", [{
        label: LiteralsHelperGridsCustomer.GridCommon.ParentSerialNumber,
        field: "ParentSerialNumber",
        sort: "asc",
      }]);     
           
      return columns;
    },
    _setRowsAdditionalColumns: (rows, workflowIdTypes) => {
      rows.forEach((row) => {
        let showButtonDetails = UserGroupHelper.isNotResidentOrGuest(
          this.Base.Security.isUserLogged()
        );

        row._StatusDescription = (
          <React.Fragment>
            <div style={{ textAlign: "center" }}>
              <span className="tool-theme-badge badge badge-primary">{row.StatusDescription}</span>              
            </div>
          </React.Fragment>
        );

        row.Completed = (
          <React.Fragment>
            <div style={{ textAlign: "center" }}>
              <Checkbox
                className="tool-theme-checkbox"
                name={row.cellName}
                checked={row.Completed}
                enabled={false}                
              ></Checkbox>
            </div>
          </React.Fragment>
        );

        row.Declined = (
          <React.Fragment>
            <div style={{ textAlign: "center" }}>
              <Checkbox
                className="tool-theme-checkbox"
                name={row.cellName}
                checked={row.Declined}
                enabled={false}
              ></Checkbox>
            </div>
          </React.Fragment>
        );

        row.Buttons = (
          <React.Fragment>
            <Button
              color="link"              
              onClick={() => { RedirectHelper.RedirectTo.details(row.WorkflowInstanceId);}}
              size="sm"
              title="Βήματα"
            >
              {/* Βήματα */}
              <img src={AssetHelper.Icons.edit}></img>
            </Button>
            {showButtonDetails && (
              <Button
                color="link"                
                onClick={() => { RedirectHelper.RedirectTo.details(row.wfiParentId);}}
                size="sm"
                title="Στοιχεία"
              >
                <img src={AssetHelper.Icons.info}></img>
                {/* Στοιχεία */}
              </Button>
            )}
          </React.Fragment>
        );
      });
    },
  };

  async getAndSetAllInstances() {
    let promiseResults = await Promise.all([
      this.Base.Services.WorkflowService.getAllFaultViewModelsAsync(),
      this.Base.Services.WorkflowService.getAllVoucherViewModelsAsync(),
      this.Base.Services.WorkflowService.getAllExtraViewModelsAsync(),
    ]);

    this.setState({
      faults: promiseResults[0],
      vouchers: promiseResults[1],
      extras: promiseResults[2],
    });
  }

  RenderHelper = {
    _defaultTable: (
      workflowIdTypes,
      title,
      workflowInstances
    ) => {

      let data = {
        columns: this.ColumnsDefinition.columns,
        rows: workflowInstances,
      };
      if (workflowIdTypes == WorkflowIdTypes.FAULTS) {
        data.columns = this.ColumnsDefinition.getColumnsFault();
      }
      if (workflowIdTypes == WorkflowIdTypes.VOUCHERS) {
        data.columns = this.ColumnsDefinition.getColumnsVoucher();
      }
      if (workflowIdTypes == WorkflowIdTypes.EXTRA) {
        data.columns = this.ColumnsDefinition.getColumnsExtras();
      }

      return (
        <Fragment>
          <h2>{title}</h2>
          <EVOMDBDataTableHelper.MDBDataTable
            barReverse={true}
            data={data}
          />          
        </Fragment>
      );
    },
    TableFaults: () => {
      let loggedUser = this.Base.Security.getLoggedUser();
      if (UserGroupHelper.isContractor(loggedUser)) {
        {
          return <></>;
        }
      }
      let data = Clone(this.state.faults);
      this.ColumnsDefinition._setRowsAdditionalColumns(
        data,
        WorkflowIdTypes.FAULTS
      );
      return this.RenderHelper._defaultTable(
        WorkflowIdTypes.FAULTS,
        "Καταγραφές Βλαβών",
        data        
      );
    },
    TableVouchers: () => {
      let loggedUser = this.Base.Security.getLoggedUser();
      if (!UserGroupHelper.isNotResidentOrGuest(loggedUser)) {
        {
          return <></>;
        }
      }

      let data = Clone(this.state.vouchers);
      this.ColumnsDefinition._setRowsAdditionalColumns(
        data,
        WorkflowIdTypes.VOUCHERS
      );
      return this.RenderHelper._defaultTable(
        WorkflowIdTypes.VOUCHERS,
        "Δελτία Εργασίας & Επιδιόρθωσεις Βλάβων",
        data        
      );
    },
    TableExtras: () => {
      let loggedUser = this.Base.Security.getLoggedUser();

      if (!UserGroupHelper.isNotResidentOrGuest(loggedUser)) {
        {
          return <></>;
        }
      }
      let data = Clone(this.state.extras);
      this.ColumnsDefinition._setRowsAdditionalColumns(
        data,
        WorkflowIdTypes.EXTRA
      );
      return this.RenderHelper._defaultTable(
        WorkflowIdTypes.EXTRA,
        "Πρόσθετες Εργασίες",
        data        
      );
    },
  };

  render() {
    if (!this.Base.Security.isUserLogged()) {
      return <></>;
    }

    const responsiveBreakPointWidth = 992;
    let defaultResidentTemplateView = (
      <>
        <MediaQuery maxWidth={responsiveBreakPointWidth}>
          <CustomIndexResidentMobile faults={this.state.faults}></CustomIndexResidentMobile>
        </MediaQuery>
        <MediaQuery minWidth={responsiveBreakPointWidth}>
          {this.RenderHelper.TableFaults()}
        </MediaQuery>
      </>
    );

    let defaultTemplateView = (
      <>
        {this.RenderHelper.TableFaults()}
        {this.RenderHelper.TableVouchers()}
        {this.RenderHelper.TableExtras()}
      </>
    );

    const getTemplateView = () => {
      let isResidentOrGuest = !UserGroupHelper.isNotResidentOrGuest(
        this.Base.Security.isUserLogged()
      );
      if (isResidentOrGuest) {
        return defaultResidentTemplateView;
      }
      return defaultTemplateView;
    };

    return (
      <>
        {/* Page content */}
        <Container className="mt-0" fluid>
          <Row className="mt-0">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow-none no-padding">
                <CardHeader className="border-0 no-padding">
                  <Row
                    className="align-items-center"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="col">
                      <h3 className="mb-0">Ιστορικό Αιτημάτων</h3>
                    </div>                   
                  </Row>

                  {getTemplateView()}
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <div className="push"></div>
        </Container>
      </>
    );
  }
}

export default History;
