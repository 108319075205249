import React, { useState, useEffect, Fragment } from "react";
import DynamicGridView from "../../../components/DynamicPages/DynamicGridView/dynamicGridView";
import CustomIndexResidentFilterFaults from "./custom.Index.Resident.Filter";
import MediaQuery from "react-responsive";
import GlobalParametersHelper from "../../../common/GlobalParametersHelper"
import CustomIndexResidentMobile from "./custom.Index.Resident.Mobile";
const CustomIndexResident = ({customActionsCallbackMethods}) => {
  const DEFAULT_GRID_VIEW_ID_FAULTS = 'c1983fe1-5584-44c1-aecf-486692e0766c'     
  const [_appliedFilter, setAppliedFilter] = React.useState(() => {});
  const [_dynamicGridViewDatasource, setDynamicGridViewDatasource] =
    React.useState([]);
  const responsiveBreakPointWidth =
    GlobalParametersHelper.getResponsiveBreakPointWidth();

  const onFilterClick = (value) => {
    setAppliedFilter(value);
  };

  const onFetchGridViewData = (value) => {
    //return datasource to filters
    let dataSource = value.data.datasource ?? [];
    setDynamicGridViewDatasource(dataSource);
  };

  return (
    <div>
      <MediaQuery maxWidth={responsiveBreakPointWidth}>
        {/* <ResidentFaults faults={this.state.faults}></ResidentFaults> */}
        <CustomIndexResidentMobile
         gridViewId={DEFAULT_GRID_VIEW_ID_FAULTS}
         customActionsCallbackMethods = {customActionsCallbackMethods}     
         />
      </MediaQuery>
      <MediaQuery minWidth={responsiveBreakPointWidth}>
        <CustomIndexResidentFilterFaults
          filterClick={onFilterClick}
          datasource={_dynamicGridViewDatasource}
          showFilteredSums={true}
        />
        <DynamicGridView
          gridViewId={DEFAULT_GRID_VIEW_ID_FAULTS}
          dynamicGridId={DEFAULT_GRID_VIEW_ID_FAULTS}
          filter={_appliedFilter}
          onFetchGridViewData={onFetchGridViewData}
          customActionsCallbackMethods = {customActionsCallbackMethods}          
        />
      </MediaQuery>
    </div>
  );
};
export default CustomIndexResident;
