import { WorkflowIdTypes } from "./enums/workflowIdTypes.enum";
import { ApiCallerHelper } from "../api/apiCallerHelper";

export const WorkflowService_aspra_spitia = {
  WorkflowIdTypes: WorkflowIdTypes,
  _initCache: async () => {
    // if (WorkflowService._cache.getWorkflowsInfoAsync === null){
    //   let result = await ApiCallerHelper.Flows.getWorkflowsInfoAsync();
    //   WorkflowService._cache.getWorkflowsInfoAsync = result?.data??null;
    // }
  },
  _cache: {
    // getWorkflowsInfoAsync : null
  },
  getWorkflowInfoByExtraWorkflowInstanceIdAsync: async (workflowInstanceId) => {
    let result = await ApiCallerHelper.Instances.getInstanceMappingInfos({
      workflowInstanceId: workflowInstanceId,
    });

    //check wfiChildVoucherCompleted || wfiChildVoucherDeclined
    let voucher =
      result.data[0]?.workflowInstancesVouchers[0]?.wfiChildVoucherIdData[0] ??
      null;
    // if (voucher !== null) {
    //   return null;
    // }
    let voucherData = {
      workCategory: voucher.wfiChildVoucher_KatigoriaErgasias_Data ?? null,
      workSubCategory:
        voucher.wfiChildVoucher_YpokathgoriaErgasias_Data ?? null,
      contractorId: voucher.wfiChildVoucher_Ergolabos_Data ?? null,
      workItemsData: JSON.parse(
        voucher.wfiChildVoucher_DeltioErgasion_Data ?? null
      ),
    };
    return voucherData;
  },
  getWorkflowInfoDetailsByWorkflowInstanceIdAsync: async ({
    workflowInstanceId,
  }) => {
    let result = await ApiCallerHelper.Instances.getInstanceMappingInfos({
      workflowInstanceId: workflowInstanceId,
    });
    function _getWorkDataRows(workflowDataRows) {
      function _parseJson(json) {
        if (json) {
          return JSON.parse(json);
        }
        return [];
      }

      //needs refactor
      let datas = [];
      workflowDataRows[0]?.workflowInstancesVouchers?.forEach((x) => {
        x.wfiChildVoucherIdData.forEach((data) => {
          let rows = _parseJson(data.wfiChildVoucher_DeltioErgasion_Data);
          rows.forEach((row) => {
            row.__dateCreated =
              data.wfiChildVoucher_DeltioErgasion_Data_DateCreated;
            row.__dateUpdated =
              data.wfiChildVoucher_DeltioErgasion_Data_DateUpdated;
            row.__userId = data.wfiChildVoucher_DeltioErgasion_Data_UserId;
            row.__isVoucher = true;
            row.__isExtra = false;
            row.__isDeclined = data.wfiChildVoucher_Declined;
            row.__isCompleted = data.wfiChildVoucher_Completed;
          });
          datas.push({
            rows: rows,
            isVoucher: true,
            isExtra: false,
            dateCreated: data.wfiChildVoucher_DeltioErgasion_Data_DateCreated,
            dateUpdated: data.wfiChildVoucher_DeltioErgasion_Data_DateUpdated,
            userId: data.wfiChildVoucher_DeltioErgasion_Data_UserId,
            isDeclined: data.wfiChildVoucher_Declined,
            isCompleted: data.wfiChildVoucher_Completed,
            metaData: data,
          });
        });
      });

      workflowDataRows[0]?.workflowInstancesExtras?.forEach((x) => {
        x.wfiChildExtraIdData.forEach((data) => {
          let rows = _parseJson(data.wfiChildExtras_ProsthetesErgasies_Data);
          rows.forEach((row) => {
            row.__dateCreated =
              data.wfiChildExtras_ProsthetesErgasies_Data_DateCreated;
            row.__dateUpdated =
              data.wfiChildExtras_ProsthetesErgasies_Data_DateUpdated;
            row.__userId = data.wfiChildExtras_ProsthetesErgasies_Data_UserId;
            row.__isVoucher = false;
            row.__isExtra = true;
            row.__isDeclined = data.wfiChildExtras_Declined;
            row.__isCompleted = data.wfiChildExtras_Completed;
          });
          datas.push({
            rows: rows,
            isVoucher: false,
            isExtra: true,
            dateCreated:
              data.wfiChildExtras_ProsthetesErgasies_Data_DateCreated,
            dateUpdated:
              data.wfiChildExtras_ProsthetesErgasies_Data_DateUpdated,
            userId: data.wfiChildExtras_ProsthetesErgasies_Data_UserId,
            isDeclined: data.wfiChildExtras_Declined,
            isCompleted: data.wfiChildExtras_Completed,
            metaData: data,
          });
        });
      });

      datas.forEach((data) => {
        data.rows.forEach((row) => {
          if (data.isDeclined === true) {
            row.__finalNonDeclinedQuantity = 0;
            row.__finalNonDeclinedPrice = 0;
            row.__finalNonDeclinedFinalPrice = 0;
          } else {
            row.__finalNonDeclinedQuantity = row.__productQuantity;
            row.__finalNonDeclinedPrice = row.__productPrice;
            row.__finalNonDeclinedFinalPrice = row.__productFinalPrice;
          }
        });
      });
      //console.log(datas);
      return datas;
    }
    let datas = _getWorkDataRows(result.data);
    // console.log({
    //   data: result.data,
    //   datas: datas,
    //   parentWorkflowInstanceId: result.data[0]?.wfiParentId ?? null,
    // })
    return {
      data: result.data,
      datas: datas,
      parentWorkflowInstanceId: result.data[0]?.wfiParentId ?? null,
    };
  },
};

export default WorkflowService_aspra_spitia;
