import styled from 'styled-components'

export const Styles = styled.div`
  /* Layout Properties */
  top: 90px;
  width: 226px;
  height: 100%;
  position: relative;  

  /* UI Properties */
  background: #F4F4F4 0% 0% no-repeat padding-box;
  border-radius: 30px 5px 5px 30px;
  opacity: 1;
  
  /* Fonts */
  font-size: 22px !important;
  font-family:'PFDinDisplayPro-Regular' !important;
  text-align: left!important;
  letter-spacing: 0.0px !important;  
  .nav-link {
    color: #2602F5;
    font-weight: 500!important;
    letter-spacing: 0.44px !important;
  }  

  opacity: 1;

  list-style: none;

  hr {
    color: '#02497F';
    border-top: 2px solid #d8d8d8;
    margin-top:5px !important;
    margin-bottom:5px !important;
    margin-right: 20px;
  }

  .tool__basic_sidebar__container {    
    padding : 25px 0px 25px 10px;   
  }

  /* Active Selection */  
  .nav-link.tool__nav-item--active{
    color: white !important;
    background-color: #2602F5 !important;       
    --padding-right: 20px;
    --margin-right: 5px; 
    margin-left: -10px; 
    padding-left: 18px;
    border-radius: 5px 0px 0px 0px;      
  }

`