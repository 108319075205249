
export const filterOptions = {
    month: [
      {value: "1", label: "Ιανουάριος"},
      {value: "2", label: "Φεβρουάριος"},
      {value: "3", label: "Μάρτιος"},
      {value: "4", label: "Απρίλιος"},
      {value: "5", label: "Μάιος"},
      {value: "6", label: "Ιούνιος"},
      {value: "7", label: "Ιούλιος"},
      {value: "8", label: "Αύγουστος"},
      {value: "9", label: "Σεπτέμβριος"},
      {value: "10", label: "Οκτώβριος"},
      {value: "11", label: "Νοέμβριος"},
      {value: "12", label: "Δεκέμβριος"},
    ],
    year: [
      {value: "2024", label: "2024"},
      {value: "2023", label: "2023"},
      {value: "2022", label: "2022"},
      {value: "2021", label: "2021"},
      {value: "2020", label: "2020"},
      {value: "2019", label: "2019"},
      {value: "2018", label: "2018"},
    ],
    xreosi: [
      {value: "8610", label: "8610 - ΈΞΟΔΑ ΔΙΑΧΕΊΡ. ΟΙΚΙΣ"},
      {value: "8621", label: "8621 - ΣΥΝΤΉΡ. ΚΑΤΟΙΚ.ΠΡΟΣ."},
      {value: "8630", label: "8630 - ΥΠ. ΚΟΙΝ. ΩΦΕΛ. ΟΙΚ."},
      {value: "8623", label: "8623 - ΣΥΝΤ. AΤΟΜ.ΘΈΡΜΑΝΣΗΣ"},
      {value: "8228", label: "8228 - ΟΙΚΉΜΑΤΑ ΕΠΙΦΥΛΑΚΉΣ"},
      {value: "8165", label: "8165 - ΑΘΛΗΤ.ΕΓΚΑΤΑΣΤΆΣΕΙΣ"},
    ]
};

