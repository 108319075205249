import { store } from "../redux";

const AuthService = {
  getCurrentUser: () => {
    return store.getState().auth.currentUser;
  },
  getCurrentUserGroup: () => {
    return store.getState().auth.currentUser.Groups[0];
  },
};

export default AuthService;
