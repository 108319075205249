import { ApiCallerHelper } from "../api/apiCallerHelper";
import EVOAlertComponent from "../components/common/EvoAlertComponent";
import { Clone, Sort } from "../common/utils"

export const LocationService = {  
  _initCache : async (forceReset) => {  
      if (forceReset || LocationService._cache.getAllLocations === null){
      let result = await ApiCallerHelper.Locations.getAllPolygons();         
      LocationService._cache.getAllLocations = result?.data??null;
    }    
  },
  _cache : {      
    getAllLocations : null,    
  },
  Factory : {
    GetNewPolygon : (
      {
        Id,
        Name,
        Description,
        Data,
        IntegrationCode
      }
    ) => {
      let polygon = {
        Id: Id,
        Name: Name,
        Description: Description,
        Data: Data,
        IntegrationCode: IntegrationCode
      };
      return polygon;
  }},
  getAllLocations : () => {
    let allLocations = LocationService._cache.getAllLocations;
    if (!allLocations) {return null;}
    return Clone(allLocations??[]);      
  },
  getLocationsById : (locationId) => {
    let allLocations = LocationService._cache.getAllLocations;
    if (!allLocations) {return null;}
    return allLocations.find(x=>x.Id === locationId)??null;      
  },
  deletePolygon : async (polygonId) => {
    await ApiCallerHelper.Locations.deletePolygon(polygonId)
    LocationService._initCache(true);
  },
  saveData : async(
    {
      Id,
      Name,
      Description,
      Data,
      IntegrationCode,
    }) => {
      try {        
        let editModel = LocationService.Factory.GetNewPolygon(
          {
            Id: Id,
            Name: Name,
            Description: Description,
            Data: Data,
            IntegrationCode: IntegrationCode
          }
        )
        let result = null;
        if (editModel.Id != null) {
          result = await ApiCallerHelper.Locations.addNewPolygon(editModel);
        } else {
          result = await ApiCallerHelper.Locations.editPolygon(editModel);
        }
        if (result.isSuccess == false) {
          throw result.errorMessage;
        }       
      } catch (error) {            
          EVOAlertComponent.Alert({ message: error });    
        return false;
      } finally {        
        await LocationService._initCache(true);
      }      
      return true;        
  },
};
