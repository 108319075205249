import React, { useState, useEffect } from "react";
import GlobalParametersHelper from "../../common/GlobalParametersHelper"
import {RedirectHelper} from "../../common/redirectHelper"
import { clearCurrentUserAction } from "redux/auth/user-actions";
import { useDispatch} from 'react-redux';

const Logout =  () => { 
  const dispatch = useDispatch();
  useEffect(() => {              
    dispatch(clearCurrentUserAction());                    
    GlobalParametersHelper.Token.remove();
    RedirectHelper.RedirectTo.login();
  },[]); 

  return (
    <div>Redirecting To Home Page Please wait...</div>
  )
}

export default Logout;