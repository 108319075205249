
import React from 'react'
import { EVOReactTableDynamicGridHelper } from "../common/evo.react-table-dynamic-grid-helper";
import { EvoActionsDynamicGridComponentEditableCellExtensions } from "./EvoActionsDynamicGridComponent.EditableCellExtensions";

export const EvoActionsDynamicGridComponentEditableCell = { 
  GetDefaultEditableCell : ({
    value: initialValue,
    row: { index, original, values},
    column: { id },
    onUpdate, 
    onDelete,
    columnMetadataDefinitions,
    onCustomCallbacks   
    // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
  
    let cellName = EVOReactTableDynamicGridHelper.getCellName(index, id);
    let columnMetadataDefinition = EVOReactTableDynamicGridHelper.getColumnDefinitionMetadataById(id, columnMetadataDefinitions)           

    let rowData = {
      rowIndex : index,
      columnId : id,
      initialValue : initialValue,
      original : original,
      values : values,
      cellName : cellName,
      columnMetadataDefinition : columnMetadataDefinition,
      columnMetadataDefinitions : columnMetadataDefinitions
    }
    
    const _onChange = (val, isUpdateData) => {            
      setValue(val);      
      if (isUpdateData){
        onUpdate(index, id, val);
      }
    }    
  
    const _onUpdate = (val) => {                   
      onUpdate(index, id, val);
    }    
    
    const _onDelete = () => {          
      onDelete(index, id)
    }  
    
    const _onCustomCallbacks = () => {  
      console.log('test');
      onCustomCallbacks(index, id)
    } 

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue)
    }, [initialValue])
              
    //return <input value={value} type={columnMetadataDefinition.type} onChange={onChange} onBlur={onUpdateData} />   
    
    return EvoActionsDynamicGridComponentEditableCellExtensions.getRenderedCellExtentionByType(
      rowData,  
      value,       
      _onUpdate,
      _onChange,
      _onDelete,
      _onCustomCallbacks     
    );
  }
}
