import {
    get, getAsync,
    post, postAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    } from "./apiCallerHelper.core";

const ROUTE_PATH = "/api/views"

export async function getGridViewDataById(gridViewId, customArguments) {
    var route = `${ROUTE_PATH}/gridViewData/${gridViewId}/`;
    if (customArguments?.arg1??null){
        route += customArguments.arg1 + "/";
    }
    return getAsync(route);
}


export async function getPageViewDataById(pageViewId) {
    var route = `${ROUTE_PATH}/pageViewData/${pageViewId}/`;
    return getAsync(route);
}