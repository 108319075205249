import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
} from "./apiCallerHelper.core";

export async function getAllInstancesAsync() {
  var route = "/api/instances/all/";
  return getAsync(route);
}

export async function getInstanceAsync(workflowInstanceId) {
  var route = "/api/instances/instance/" + workflowInstanceId + "/";
  return getAsync(route);
}

export async function getDetailsAsync(workflowInstanceId) {
  var route = "/api/instances/details/" + workflowInstanceId + "/";
  return getAsync(route);
}

export async function updateInstanceAsync(workflowInstanceId, data) {
  var route = "/api/instances/update/" + workflowInstanceId + "/";
  return postAsync(route, data);
}

export async function submitInstanceAsync(workflowInstanceId, data) {
  var route = "/api/instances/submit/" + workflowInstanceId + "/";
  return postAsync(route, data);
}

export async function approveInstanceAsync(workflowInstanceId, data) {
  var route = "/api/instances/approve/" + workflowInstanceId + "/";
  return postAsync(route, data);
}

export async function declineInstanceAsync(workflowInstanceId, data) {
  var route = "/api/instances/decline/" + workflowInstanceId + "/";
  return postAsync(route, data);
}

export async function assignInstanceAsync(workflowInstanceId, userId) {
  var route = "/api/instances/assign/";
  let data = {
    InstanceId: workflowInstanceId,
    UserId: userId,
  };
  return postAsync(route, data);
}

export async function initialiseInstanceAsync(workflowId) {
  var route = "/api/instances/initiliaze/" + workflowId + "/";
  return getAsync(route);
}

export async function newInstance(
  workflowInstanceId,
  workflowId,
  instanceStepsData,
  isApprove
) {
  var route = "/api/instances/newInstance/";
  let data = {
    InstanceId: workflowInstanceId,
    WorkflowId: workflowId,
    IsApprove: isApprove,
    ...instanceStepsData,
  };
  return postAsync(route, data);
}

// export async function newInstanceAsync(workflowInstanceId, workflowId) {
//   var route = "/api/instances/new/";
//   let data = {
//     InstanceId: workflowInstanceId,
//     WorkflowId: workflowId
//   }
//   return postAsync(route, data);
// }

export async function newInternalCommentAsync(workflowInstanceId, comment) {
  var route = "/api/instances/internal/";
  let data = {
    InstanceId: workflowInstanceId,
    Comment: comment,
  };
  return putAsync(route, data);
}

export async function fetchParentInstanceDetailsAsync(workflowInstanceId) {
  var route = "/api/instances/details/";
  let data = {
    ParentId: workflowInstanceId,
  };
  return postAsync(route, data);
}

export async function fetchChildInstanceDetailsAsync(
  workflowInstanceId,
  childId
) {
  var route = "/api/instances/details/";
  let data = {
    ParentId: workflowInstanceId,
    ChildId: childId,
  };
  return postAsync(route, data);
}

export async function fetchChildDetailsByAttribute(
  workflowInstanceId,
  attribute
) {
  var route = "/api/instances/details/";
  let data = {
    ParentId: workflowInstanceId,
    Attribute: attribute,
  };
  return postAsync(route, data);
}

export async function getInstanceMappingInfos({ workflowInstanceId }) {
  var route = "/api/instances/get_instance_mapping_infos/";
  if (workflowInstanceId) {
    route += workflowInstanceId + "/";
  }
  return getAsync(route);
}

async function getInstancesDetailsAsync(type, isCompleted) {
  var route = "/api/instances/get_instances_details/";
  route += type + "/";
  if (isCompleted == undefined) {
    route = route += "-1/";
  } else if (isCompleted == false) {
    route = route += "0/";
  } else if (isCompleted == true) {
    route = route += "1/";
  }

  return getAsync(route);
}

export async function getInstancesDetailsFaultAsync(isCompleted) {
  return getInstancesDetailsAsync("fault", isCompleted);
}
export async function getInstancesDetailsFaultResidentAsync() {
  return getInstancesDetailsAsync("fault_resident");
}

export async function getInstancesDetailsVoucherAsync(isCompleted) {
  return getInstancesDetailsAsync("voucher", isCompleted);
}

export async function getInstancesDetailsExtraAsync(isCompleted) {
  return getInstancesDetailsAsync("extra", isCompleted);
}

export async function createChildFlowAsync(attribute, parentId) {
  var route = "/api/instances/newchild/";
  let data = {
    ParentId: parentId,
    Attribute: attribute,
  };
  return postAsync(route, data);
}

export async function getParentInstanceIdAsync(workflowInstanceId) {
  var route = "/api/instances/get_parent_id/" + workflowInstanceId + "/";
  return getAsync(route);
}

export async function getVoucherInstanceIdAsync(workflowInstanceId) {
  var route = "/api/instances/get_voucher/" + workflowInstanceId + "/";
  return getAsync(route);
}

export async function getExtraInstanceIdAsync(workflowInstanceId) {
  var route = "/api/instances/get_extras/" + workflowInstanceId + "/";
  return getAsync(route);
}

export async function deleteInstanceAsync(workflowInstanceId) {
  var route = "/api/instances/" + workflowInstanceId + "/";
  return deleteAsync(route);
}

export async function getIsDeletedInstanceAsync(workflowInstanceId) {
  var route = "/api/instances/isDeleted/" + workflowInstanceId + "/";
  return getAsync(route);
}

export async function getIsIntanceSubmittedByMechanic(workflowInstanceId) {
  var route = "/api/instances/isSubmittedByMechanic/" + workflowInstanceId + "/";
  return getAsync(route);
}

export async function getVoucherDatatable(workflowInstanceId) {
  var route = "/api/datatableseed/voucher/voucher_details/" + workflowInstanceId + "/";
  return getAsync(route);
}


// export async function getFaultDetails(workflowInstanceId) {
//   var route = "/api/instances/fault/" + workflowInstanceId + "/";
//   return getAsync(route);
// }
