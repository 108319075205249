import React, { Fragment, useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import Accordion from "react-bootstrap/Accordion";

import { ValidationHelper } from "../../common/validationHelper";
import { ApiCallerHelper } from "../../api/apiCallerHelper";
import InstanceBase, { ViewTypeEnum } from "./InstanceBase";
import { RedirectHelper } from "../../common/redirectHelper";
import EVOAlertComponent from "../../components/common/EvoAlertComponent";
import EvoFlowStepper from "../../components/common/evo.flow-stepper";
import OverrideRules from "./custom/custom.InstanceBase.overrides.aspra-spitia";
import CustomOverridesLayout from "./custom/custom.InstanceBase.overrides.layout";
import InstanceHeader from "../../views/instances/instanceHeader";
import EvoCard from "../../components/common/evo.card";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";
import InstanceExistingSpecificFlowStep from "./InstanceExistingSpecificFlowStep";
class InstanceExisting extends InstanceBase {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  componentDidMount() {
    super.componentDidMount(this.__initializeComponent);
  }

  __initializeComponent = async () => {
    let workflowInstanceId = this.props.match.params.id;
    let instanceResult = await ApiCallerHelper.Instances.getInstanceAsync(
      workflowInstanceId
    );
    let fileStatusResult =
      await ApiCallerHelper.Files.getFileStatusByWorkflowInstanceIdAsync(
        workflowInstanceId
      );
    // console.log(WorkflowService.getWorkflowInfo(instanceResult.data.workflow.Id))
    // console.log(instanceResult.data.workflow)
    // this.state.workflow.Description
    let instance = instanceResult.data.instance;
    let workflow = instanceResult.data.workflow;

    let layoutParametersResult = await OverrideRules.getLayoutParametersAsync(
      workflowInstanceId,
      workflow.Id
    );

    // console.log(layoutParametersResult);
    this.setState(
      {
        loading: false,
        instance: instance,
        workflow: workflow,
        user: instanceResult.data.user,
        //logged_user: instanceResult.data.logged_user,
        steps: instanceResult.data.stepsInfused,
        fileUpload: null,
        files: fileStatusResult.data,
        layoutParameters: layoutParametersResult,
      },
      async () => {
        let workflowId = instanceResult.data.instance.WorkflowId;
        let status = instanceResult.data.instance.Status;
        await this.ExecuteOverridesOnMountAsync(
          this,
          this.state,
          workflowId,
          status,
          workflowInstanceId
        );
      }
    );
  };

  // saveInstance = async stepStatus => {
  //     let promptResult = await EVOAlertComponent.Prompt({message:"Επιθυμείτε να προχωρήσετε με την Προσωρινή Αποθήκευση;"});
  //     if (promptResult.isConfirmed === true) {
  //         this.setState({ isActionsVisible: false });
  //         var data = this.state.steps[stepStatus - 1];
  //         let result = await ApiCallerHelper.Instances.updateInstanceAsync(this.state.instance.Id, data);
  //         RedirectHelper.RedirectTo.reloadPageFromCache();
  //     }
  // }

  submitInstance = async (stepStatus) => {
    let isValidStep = this.getIsValidStepAndSetWarning(
      this.state.steps[stepStatus - 1]
    );
    if (isValidStep == false) {
      return;
    }

    let promptResult = await EVOAlertComponent.Prompt({
      message: "Επιθυμείτε να προχωρήσετε με την Υποβολή της Εγγραφής;",
    });
    if (promptResult.isConfirmed === false) {
      return;
    }

    //validate instance
    this.setState({ isActionsVisible: false });
    var data = this.state.steps[stepStatus - 1];
    let result = await ApiCallerHelper.Instances.submitInstanceAsync(
      this.state.instance.Id,
      data
    );
    RedirectHelper.RedirectTo.reloadPageFromCache();
  };

  assignInstance = async (stepStatus) => {
    let result = await ApiCallerHelper.Instances.assignInstanceAsync(
      this.state.instance.Id,
      this.state.base.loggedUser.Id
    );
    RedirectHelper.RedirectTo.reloadPageFromCache();
  };

  declineInstance = async (stepStatus) => {
    this.setState({ isActionsVisible: false });
    var data = this.state.steps[stepStatus - 1];
    let result = await ApiCallerHelper.Instances.declineInstanceAsync(
      this.state.instance.Id,
      data
    );
    RedirectHelper.RedirectTo.adminDetails(this.state.instance.Id);
  };

  _RenderHelper = {
    InstanceUserButtonActions: {
      SaveInstance: (dynamicData) => {
        return (
          <Button
            color="primary"
            onClick={() => {
              this.onSaveInstanceClick(dynamicData.step.Status);
            }}
            size="md"
          >
            Προσωρινή Αποθήκευση
          </Button>
        );
      },
      // DeclineInstance: (dynamicData) => {
      //     return (
      //     <Button
      //         color="warning"
      //         onClick={() => { this.declineInstance(dynamicData.step.Status) }}
      //         size="sm"
      //     >
      //         Επανεξέταση
      //     </Button>);
      // },
      AssigneeInstance: (dynamicData) => {
        return (
          <Button
            color="success"
            onClick={() => {
              this.assignInstance(dynamicData.step.Status);
            }}
            size="lg"
          >
            Έναρξη Εργασίας
          </Button>
        );
      },
      SubmitInstance: (dynamicData) => {
        return (
          <Button
            color="success"
            onClick={() => {
              this.submitInstance(dynamicData.step.Status);
            }}
            size="lg"
          >
            Υποβολή
          </Button>
        );
      },
      Render: (dynamicData) => {
        if (dynamicData.step.Conditional) {
          return <></>;
        }

        if (
          !(
            (
              ValidationHelper.Steps.Permission.hasViewPermission(
                this.state.base.loggedUser,
                dynamicData.step
              ) &&
              ValidationHelper.Instances.isCurrentDynamicStepStatus(
                this.state.instance,
                dynamicData.step
              ) &&
              !this.state.instance.Completed
            )
            // ValidationHelper.Instances.isSubmitionStep(this.state.instance)
          )
        ) {
          return <></>;
        }

        // if (ValidationHelper.Instances.isAssignedToUser(this.state.loggedUser, this.state.instance)){
        if (this.state.isActionsVisible) {
          return (
            <div style={{ display: "inline-block" }} className="float-right">
              {this._RenderHelper.InstanceUserButtonActions.SaveInstance(
                dynamicData
              )}
              {this.ButtonApproveComponent({
                instanceId: this.state.instance.Id,
                instanceStepsData:
                  this.state.steps[dynamicData.step.Status - 1],
              })}
            </div>
          );
        }
        // }
        // else if (!this.state.instance.IsAssigned && !UserRoleHelper.isClient(this.state.loggedUser)){
        //     return (
        //         <Col className="text-right" xs="4">
        //             {this._RenderHelper.InstanceUserButtonActions.AssigneeInstance(dynamicData)}
        //         </Col>
        //     )
        // }
        // else if (UserRoleHelper.isClient(this.state.base.loggedUser)){
        //     return (
        //         <Col className="text-right" xs="4">
        //             {this._RenderHelper.InstanceUserButtonActions.SaveInstance(dynamicData)}
        //             {this._RenderHelper.InstanceUserButtonActions.SubmitInstance(dynamicData)}
        //         </Col>
        //     )
        // }
        return <></>;
      },
    },
    CompletedMessage: () => {
      if (!this.state.instance.Completed) {
        return <></>;
      }

      return (
        <>
          <br />
          <Row style={{ textAlign: "center" }}>
            <Col xl="2" />
            <Col className="order-xl-1" xl="8" style={{ textAlign: "center" }}>
              <div
                className={
                  this.state.instance.Declined
                    ? "alert alert-danger"
                    : "alert alert-success"
                }
                id={"completed-alert"}
                role="alert"
              >
                Τα κάτωθι στοιχεία αφορούν εργασία που έχει ολοκληρωθεί{" "}
                {this.state.instance.Declined
                  ? "ανεπιτυχώς (αποτυχία ή απόρριψη)."
                  : "επιτυχώς."}
              </div>
            </Col>
          </Row>
        </>
      );
    },
    RenderStepsHeader: (dynamicData, key) => {
      return (
        <Accordion.Toggle as={CardHeader} eventKey={dynamicData.step.Status}>
          <Row>
            <Col md="12">
              <h3
                className="mb-0 tool-theme-h3--primary"
                style={{ display: "inline-block" }}
              >
                {dynamicData.step.Status}. {dynamicData.step.Name}
              </h3>
              {this._RenderHelper.InstanceUserButtonActions.Render(dynamicData)}
            </Col>
          </Row>
        </Accordion.Toggle>
      );
    },
    RenderStepInstances: () => {
      if (!this.state.steps || this.state.steps.length === 0) {
        return <></>;
      }
      let defaultActiveKey = !this.state.instance.Completed
        ? this.state.instance.Status
        : 0;

      let render = (dynamicData, key) => {let isHiddenStepDueToStatus =
          parseInt(dynamicData.step.Status) >
          parseInt(this.state.instance.Status);
        if (isHiddenStepDueToStatus) {
          return <Fragment key={key} />;
        }

        return (
          <EvoCard
            key={key}
            eventKey={dynamicData.step.Status}
            isAccordionEnabled={
              GlobalParametersHelper.Theme.SelectedTheme.Flows
                .IsAccordeonEnabled
            }
            Header={this._RenderHelper.RenderStepsHeader(dynamicData, key)}
            Body={this.RenderHelper.RenderStepBody(dynamicData)}
          ></EvoCard>
        );
      };

      if (GlobalParametersHelper.Theme.SelectedTheme.Flows.IsAccordeonEnabled) {
        return (
          <>
            {this._RenderHelper.CompletedMessage()}
            <Accordion defaultActiveKey={defaultActiveKey}>
              {this.state.steps.map((dynamicData, key) => {
                return (
                  <Fragment key={key}>{render(dynamicData, key)}</Fragment>
                );
              })}
            </Accordion>
          </>
        );
      } else {
        return (
          <>
            {this._RenderHelper.CompletedMessage()}
            {this.state.steps.map((dynamicData, key) => {
              {
                return (
                  <Fragment key={key}>{render(dynamicData, key)}</Fragment>
                );
              }
            })}
          </>
        );
      }
    },
    RenderStepStepper: () => {
      if (
        OverrideRules._isStepperEnabledForWorflowId(
          this.state.instance.WorkflowId
        ) == false
      ) {
        return <Fragment />;
      }

      return (
        <Container fluid className="mt-4">
          <Row>
            <Col md="12">
              <EvoFlowStepper
                activeStepStatus={this.state.instance.Status}
                flowSteps={this.state.steps}
              ></EvoFlowStepper>
            </Col>
          </Row>
        </Container>
      );
    },

    RenderContent: ({ layoutParameters }) => {
      let defaultContent = (
        <>
          {this._RenderHelper.RenderStepStepper()}
          {this._RenderHelper.RenderStepInstances()}
        </>
      );
      //  return <>{defaultContent}</>
      //if no tabs return default
      if ((layoutParameters?.isTabVisible ?? false) == false) {
        return <>{defaultContent}</>;
      }

      //apply custom override layout ()
      if ((layoutParameters?.isOverrideLayout ?? false) == true) {
        return (
          <>
            <CustomOverridesLayout
              layoutParameters={layoutParameters}
              defaultContent={defaultContent}
            />
          </>
        );
      }

      const ContentPickerComponent = ({ tabLayout }) => {
        if ((tabLayout?.isDefaultRender ?? false) == true) {
          return <>{defaultContent}</>;
        }
        return (
          <>
            <InstanceExistingSpecificFlowStep
              workflowInstanceId={tabLayout.workflowInstanceId}
              statusToShow={tabLayout.workflowStepStatus}
            />
          </>
        );
      };

      return (
        <Tabs defaultActiveKey="0" className={"tool-tabs-title--bold"}>
          {console.log(layoutParameters)}
          {layoutParameters.tabsLayout.map((tabLayout, key) => {
            return (
              <>
                <Tab eventKey={key} title={tabLayout.title} key={key}>
                  <ContentPickerComponent tabLayout={tabLayout} />
                </Tab>
              </>
            );
          })}
        </Tabs>
      );
    },
  };

  render() {
    if (!this.Base.Security.isUserLogged()) {
      return (
        <>
          {/* <GenericHeader /> */}
          {/* <CommonLoading /> */}
        </>
      );
    }

    return (
      <>
        <h3>{this.state.workflow.Description}</h3>
        <InstanceHeader
          workflowId={this.state.instance.WorkflowId}
          workflowInstanceId={this.state.instance.Id}
        />
        {this._RenderHelper.RenderContent({
          layoutParameters: this.state.layoutParameters,
        })}
      </>
    );
  }
}
export default InstanceExisting;
