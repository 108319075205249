import GlobalParametersHelper from "../common/GlobalParametersHelper";
import userGroupHelper from "../common/userGroupHelper";
import AuthService from "../common/AuthService";
import RedirectHelper from "../common/redirectHelper";
export const BasicLayoutCustomOverrides = {    
        GetIsCustomSidebar:() => {
          return GlobalParametersHelper.Theme.SelectedTheme.NavBar.IsCustomSidebar;
        },
        GetIsSideBarVisible: (routes) => {           
          //special rule based on customer            
          let isSideBarVisible = userGroupHelper.isNotResidentOrGuest(
            AuthService.getCurrentUser()
          );
          if (isSideBarVisible == false) {return false;}
          
          //Generic rule
          function _getCurrentRouteIsSidebarVisible(){   
            let isSideBarVisible = true;         
            routes.forEach(route => {
                if (!route.name || isSideBarVisible == false){return;}                
                if (RedirectHelper.IsWindowLocationPage(route.name)){
                    isSideBarVisible = route?.navStyle?.isSidebarVisible??true;                                
                }
            });
            return isSideBarVisible;
          }
          isSideBarVisible = _getCurrentRouteIsSidebarVisible();          
          return isSideBarVisible;
        },
        GetIsProfileNavItemVisible: () => true,      
}

export default BasicLayoutCustomOverrides;
