import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync
} from "./apiCallerHelper.core";

export async function getNotificationsAsync() {
  var route = "/api/notifications/";
  return getAsync(route);
}

export async function setNotificationsAsReadAsync(notification_id, read_status) {
  var route = `/api/notifications/mark_as_read/${notification_id}/${read_status}/`;
  return postAsync(route);
}
