import {
    get, getAsync,
    post, postAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    ContentTypeEnum
    } from "./apiCallerHelper.core";
    
    export async function getAllPolygons() {
        var route = "/api/locations/all/";
        return getAsync(route);
    }

    export async function getPolygon(polygonId) {
        var route = "/api/locations/polygon/" + polygonId + "/";
        return getAsync(route);
    }

    export async function calculateInstanceLocation(data) {
        var route = "/api/locations/find_location/";
        return postAsync(route, data);
    }
       
    export async function addNewPolygon(data) {
        var route = "/api/locations/new/";        
        return postAsync(route, data);
    }

    export async function editPolygon(data) {
        var route = "/api/locations/edit/";
        return putAsync(route, data);
    }  

    export async function deletePolygon(polygonId) {    
        var route = "/api/locations/" + polygonId + '/';
        return deleteAsync(route, ContentTypeEnum.ApplicationFormUrlEncoded);
    }
      