import { UserRoleTypes } from "./enums/userRoleTypes.enum";
import { UserGroupTypes } from "./enums/userGroupTypes.enum";

export const UserRoleHelper = {
  isUser: isUser,
  isAdmin: isAdmin,
  isClient: isClient,
  isMaster: isMaster,
  isMechanic: isMechanic,
  isContractor: isContractor,
  isASEmployee : isASEmployee
};

function isUser(user) {
  return user.PersonRole.toLowerCase() === UserRoleTypes.USER.toLowerCase();
}

function isClient(user) {
  return user.PersonRole.toLowerCase() === UserRoleTypes.CLIENT.toLowerCase();
}

function isAdmin(user) {
  return user.PersonRole.toLowerCase() === UserRoleTypes.ADMIN.toLowerCase();
}

function isMaster(user) {
  return user.PersonRole.toLowerCase() === UserRoleTypes.MASTER.toLowerCase();  
}

function isMechanic(user) {
  const result = user.Groups.findIndex(element => {
    return element.toLowerCase() === UserGroupTypes.MECHANIC.toLowerCase();
  });
  return (result != -1) 
}

function isContractor(user) {
  const result = user.Groups.findIndex(element => {
    return element.toLowerCase() === UserGroupTypes.CONTRACTOR.toLowerCase();
  });
  return (result != -1)
}

function isASEmployee(user) {
  return isAdmin(user) || isMaster(user) || isMechanic(user);
}

export default UserRoleHelper;