import {
  get, getAsync,
  post, postAsync,
  put, putAsync,
  del, deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,   
  ContentTypeEnum
  } from "./apiCallerHelper.core";
  

  export async function getAllStepsByWorkflowIdAsync(workflowId) {
    var route = "/api/steps/all/" + workflowId + "/";
    return getAsync(route);
  }

  export async function addNewAsync(stepName, stepDescription, workflowId) {
    var route = "/api/steps/new/";
    let data = {
      name: stepName,
      description: stepDescription,
      // 'priority': this.state.stepPriority,
      workflowId: workflowId,
    };
    return postAsync(route, data);
  }

  export async function deleteWorkflowStepAsync(stepId) {    
    var route = "/api/steps/delete/" + stepId + "/"
    return deleteAsync(route, ContentTypeEnum.ApplicationFormUrlEncoded);
  }
  
  