import React from 'react';

import {
    Button,
    Row,
    Col,
    Input,
    Table
  } from "reactstrap";

import axios from 'axios';

import StarRatings from 'react-star-ratings';
import Moment from "moment";
import 'moment/locale/el';

const Rating = ({ name, isReadOnly, onUpdate, rating }) => {
    const [newRating, setNewRating] =  React.useState(rating)
    function setNumber(value){
        try{
            if (!value){
                value = 0;
            }else {
                value = Number(value);
            }
        }catch(e){
            value = 0;
        }
        return value;
    }
    function changeRating(value) {
        setNewRating(value);        
        if (onUpdate){            
            onUpdate(name, value);
        }        
      }

    rating = setNumber(rating);    
    isReadOnly = isReadOnly || false;
    let editable = isReadOnly === false;

    return (
        <StarRatings
            rating={rating}
            starRatedColor="red"
            changeRating={editable ? changeRating : ()=>{}}
            numberOfStars={5}
            name={name}
            isReadOnly = {editable === false}
        />
    );
}

export default Rating;