import { UserGroupTypes } from "./enums/userGroupTypes.enum";
export const UserGroupHelper = {
  isMechanic: isMechanic,
  isContractor: isContractor,
  isMaster: isMaster,
  isNotResidentOrGuest: isNotResidentOrGuest,
  // /isResidentOrGuestOnly: isResidentOrGuestOnly
};

//To lower array includes helper function
function _isIncludesinArrayToLower(array, values){
  let arrayToLower = array.map(element => {
    return element.toLowerCase();
  });  

  let isIncluded = false;
  values.forEach(element => {
    if (isIncluded) { return; }
    isIncluded = arrayToLower.includes(element.toLowerCase());    
  });
  return isIncluded;
}

function isMechanic(user) {
  const result = user.Groups.findIndex((element) => {
    return element.toLowerCase() === UserGroupTypes.MECHANIC.toLowerCase();
  });
  return result != -1;
}

function isContractor(user) {
  const result = user.Groups.findIndex((element) => {
    return element.toLowerCase() === UserGroupTypes.CONTRACTOR.toLowerCase();
  });
  return result != -1;
}

function isMaster(user) {
  if (!user) {return false;}
  return _isIncludesinArrayToLower(user.Groups, [
    UserGroupTypes.ASMASTER,
    UserGroupTypes.EVOMASTER,
  ]);
}

function isNotResidentOrGuest(user) {
  if (!user){return false;}  
  return _isIncludesinArrayToLower(user.Groups, [
    UserGroupTypes.RESIDENT,
    UserGroupTypes.GUEST,
  ]) == false;    
}

// function isResidentOrGuestOnly(user) {
//   if (!user){return false;}  
//   let isResident = _isIncludesinArrayToLower(user.Groups, [UserGroupTypes.RESIDENT]);
//   let isGuest = _isIncludesinArrayToLower(user.Groups, [UserGroupTypes.GUEST]);

//   if ((isResident || isGuest) && user.Groups.length == 1) {
//     return true;
//   }
//   if (isResident && isGuest && user.Groups.length == 2) {
//     return true;
//   }
//   return false;
// }

export default UserGroupHelper;