import React from "react";
import { Link } from "react-router-dom";
import { ApiCallerHelper } from "../../api/apiCallerHelper";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";
import RedirectHelper from "../../common/redirectHelper";
import UsersService from "../../common/UsersService";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Nav,
  Media,
} from "reactstrap";

class ProfileNavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: [],
    };
  }

  setUserDetails = async () => {
    const result = await ApiCallerHelper.Users.getCurrentAsync();
    this.setState({
      isLoaded: true,
      user: result.data,
    });
  };

  componentDidMount() {
    this.setUserDetails();
  }

  render() {
    return (
      <Nav className="align-items-center d-none d-sm-flex mr-2" navbar>
        <UncontrolledDropdown nav>
          <DropdownToggle className="pr-0 pt-2" nav>
            <Media className="align-items-center">
              <Media className="ml-2 d-none d-md-block">
                <span
                  className="mb-0 text-sm font-weight-bold"
                  style={
                    GlobalParametersHelper.getIsDefault()
                      ? { color: "rgba(38, 2, 245, 0.95)" }
                      : {}
                  }
                >
                  {UsersService.getUserPreviewInfo(this.state.user)}{" "}
                  <i className="fa fa-caret-down" />
                </span>
              </Media>
            </Media>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            {/* <DropdownItem to="/basicLayout/profile" tag={Link}>
              <i className="ni ni-single-02" />
              <span>Προφίλ</span>
            </DropdownItem> */}
            <DropdownItem
              to={Link}
              tag={Link}
              onClick={() => {
                RedirectHelper.RedirectTo.passwordChange();
              }}
            >
              <i className="ni ni-settings-gear-65" />
              <span>Αλλαγή Κωδικού</span>
            </DropdownItem>
            {/* <DropdownItem to="/basicLayout/support" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Υποστήριξη</span>
                  </DropdownItem> */}
            <DropdownItem divider />
            <DropdownItem
              onClick={() => {
                RedirectHelper.RedirectTo.logout();
              }}
            >
              <i className="ni ni-button-power" />
              <span>Αποσύνδεση</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    );
  }
}

export default ProfileNavItem;
