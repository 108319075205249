import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import ApiCallerHelper from "api/apiCallerHelper";
import RedirectHelper from "common/redirectHelper";
import AuthService from "common/AuthService";
import { UserGroupTypes } from "common/enums/userGroupTypes.enum";
import DynamicGridView from "components/DynamicPages/DynamicGridView/dynamicGridView";
import CustomIndexResident from "views/instances/custom/custom.index.Resident";

const PageHeader = () => {
    return (
        <Card className="shadow-none no-padding">
            <CardHeader className="border-0 no-padding">
                <Row className="align-items-center" style={{ marginBottom: "20px" }}>
                    <div className="col">
                        <h3 className="mb-0">Αρχική</h3>
                    </div>
                    <div className="col text-right">
                        {AuthService.getCurrentUserGroup() == UserGroupTypes.RESIDENT &&

                            < Button
                                color="primary"
                                className="tool-button--large"
                                size="sm"
                                onClick={() => {
                                    RedirectHelper.RedirectTo.newInstance();
                                }}
                            >
                                Καταχώρηση Νέας Βλάβης
                            </Button>
                        }
                        {AuthService.getCurrentUserGroup() == UserGroupTypes.MECHANIC &&

                            < Button
                                color="primary"
                                className="tool-button--large"
                                size="sm"
                                onClick={() => {
                                    RedirectHelper.RedirectTo.newInstance();
                                }}
                            >
                                Καταχώρηση Νέου Δελτίου Εργασίας
                            </Button>
                        }
                    </div>
                </Row>
            </CardHeader>
        </Card >
    );
};

const TabNav = ({ filteredTabs, activeTab, toggleTab }) => {
    return (
        <Nav tabs>
            {filteredTabs.map((tab, index) => (
                <NavItem key={index}>
                    <NavLink
                        className={classnames({ active: activeTab === index })}
                        onClick={() => toggleTab(index)}
                    >
                        {tab.name}
                    </NavLink>
                </NavItem>
            ))}
        </Nav>
    );
};

const TabContentContainer = ({ filteredTabs, activeTab, pageViewData }) => {
    const getGridByCode = (code) => {
        return pageViewData.GridViewGroups.find(
            (group) => group.GridViewGroupCode === code
        );
    };

    return (
        <TabContent activeTab={activeTab}>
            {filteredTabs.map((tab, index) => {
                const grid = getGridByCode(tab.gridCode);

                return (
                    <TabPane key={index} tabId={index}>
                        {grid && (
                            <Col className="mt-4">
                                <DynamicGridView
                                    gridViewId={grid.GridViewGroupCodeItem.Id}
                                />
                            </Col>
                        )}
                    </TabPane>
                );
            })}
        </TabContent>
    );
};


const Home = () => {
    const view_id = "c0f3309b-85c8-44c5-b204-aca160a30ebf";
    const user_group = AuthService.getCurrentUserGroup();

    const defaultTabNames = [
        {
            gridCode: "EXTRAS",
            name: user_group === UserGroupTypes.MECHANIC ? "Εισερχόμενα - Νέα Αιτήματα" : "Λίστα Αιτημάτων Βλαβών",
            visible: () => {
                if (user_group === UserGroupTypes.SUPERVISOR || user_group === UserGroupTypes.CONTRACTOR) return false;
                return true;
            },
        },
        {
            gridCode: "TEMPORARY_SAVED",
            name: "Προσωρινά Αποθηκευμένα",
            visible: () => {
                if (user_group === UserGroupTypes.MECHANIC) return true;
                return false;
            },
        },
        {
            gridCode: "VOUCHER",
            name: "Δελτία Εργασίας Σε Επεξεργασία",
            visible: () => {
                if (user_group === UserGroupTypes.SUPERVISOR || user_group === UserGroupTypes.CONTRACTOR) return false;
                return true;
            },
        },
        {
            gridCode: "FAULT_VOUCHER",
            name: "Πρόσθετες εργασίες από εργολάβο",
            visible: () => {
                if (user_group === UserGroupTypes.SUPERVISOR || user_group === UserGroupTypes.CONTRACTOR) return false;
                return true;
            },
        },
        {
            gridCode: "FAULT_VOUCHER_DELIVERABLE",
            name: "Παράδοση Παραλαβή Έργου",
            visible: () => {
                if (user_group === UserGroupTypes.SUPERVISOR || user_group === UserGroupTypes.CONTRACTOR) return false;
                return true;
            },
        },
        {
            gridCode: "VOUCHER_CONTRACTOR",
            name: "Εγκεκριμένα Δελτία Εργασίας",
            visible: () => {
                if (user_group === UserGroupTypes.CONTRACTOR) return true;
                return false;
            },
        },
        {
            gridCode: "VOUCHER_CONTRACTOR_EXTRAS",
            name: "Δελτία Πρόσθετων Εργασιών",
            visible: () => {
                if (user_group === UserGroupTypes.CONTRACTOR) return true;
                return false;
            },
        },
        {
            gridCode: "FAULT_VOUCHER_PROISTAMENOS",
            name: "Έγκριση Δ.Ε και προβλεπόμενου κόστους",
            visible: () => {
                if (user_group === UserGroupTypes.SUPERVISOR) return true;
                return false;
            },
        },
        {
            gridCode: "FAULT_VOUCHER_PROISTAMENOS_APPROVED",
            name: "Έγκριση Τελικού Ποσού Δ.Ε",
            visible: () => {
                if (user_group === UserGroupTypes.SUPERVISOR) return true;
                return false;
            },
        },

    ];

    const [_pageViewData, setPageViewData] = useState(undefined);
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        (async () => {
            let result = await ApiCallerHelper.Views.getPageViewDataById(view_id);

            // sort item list (needs refactor)
            function sortGridViewOrder(a, b) {
                return a.GridViewOrder - b.GridViewOrder;
            }
            result.data.GridViewGroups = (result.data.GridViewGroups ?? []).sort(
                sortGridViewOrder
            );

            setPageViewData(result.data);
        })();
    }, []);

    const toggleTab = (tabIndex) => {
        if (activeTab !== tabIndex) {
            setActiveTab(tabIndex);
        }
    };

    const filteredTabs = defaultTabNames.filter((tab) => tab.visible());

    if (user_group !== UserGroupTypes.RESIDENT && _pageViewData != undefined)
        return (
            <>
                <Container className="mt-0" fluid>
                    <Row className="mt-0">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <PageHeader />
                        </Col>
                    </Row>
                    <TabNav
                        filteredTabs={filteredTabs}
                        activeTab={activeTab}
                        toggleTab={toggleTab}
                    />
                    <TabContentContainer
                        filteredTabs={filteredTabs}
                        activeTab={activeTab}
                        pageViewData={_pageViewData}
                    />
                </Container>

            </>
        );

    return (
        <>
            <Container className="mt-0" fluid>
                <Row className="mt-0">
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <PageHeader />
                    </Col>
                </Row>
                <CustomIndexResident />
            </Container>
        </>
    );
};

export default Home;
