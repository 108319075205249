import Moment from "moment";
import 'moment/locale/el';
export const DateHelper = {
    getMonthOptions : getMonthOptions,
    getYearsOptions : getYearsOptions,
    getCurrentMonthOption : getCurrentMonthOption,
    getCurrentYearOption : getCurrentYearOption,
    getFormatDateTitle: getFormatDateTitle,
    getFormatDate: getFormatDate,
    getCurrentDate: getCurrentDate,
    getCurrentDatePlusDays: getCurrentDatePlusDays,
    getCurrentDateTimeUnix: getCurrentDateTimeUnix
}

function getMonthOptions() {
    let months = 
    [
        {value : 0, label : 'Ιανουάριος'},
        {value : 1, label : 'Φεβρουάριος'},
        {value : 2, label : 'Μάρτιος'},
        {value : 3, label : 'Απρίλιος'},
        {value : 4, label : 'Μάιος'},
        {value : 5, label : 'Ιούνιος'},
        {value : 6, label : 'Ιούλιος'},
        {value : 7, label : 'Αύγουστος'},
        {value : 8, label : 'Σεπτέμβριος'},
        {value : 9, label : 'Οκτώβριος'},
        {value : 10, label : 'Νοέμβριος'},
        {value : 11, label : 'Δεκέμβριος'}
    ];
    return months;
}     

function getYearsOptions() {
    let years = [];    
    let startYear = 2022;
    let yearsLength = startYear + 10;
    for (let i = startYear; i < yearsLength; i++) {
        years.push({value : i, label : i});
    }    
    return years;   
}    

function getCurrentMonthOption() {
    let currentMonth = new Date().getMonth();
    return DateHelper.getMonthOptions().filter(x=>x.value === currentMonth)[0];
}

function getCurrentYearOption() {
    let currentYear = new Date().getFullYear();
    return DateHelper.getYearsOptions().filter(x=>x.value === currentYear)[0];
}

function getFormatDateTitle(value) {
    return Moment(value)
              .locale("el")
              .format("dddd, DD MMM YYYY")
}

function getFormatDate(value) {
    return Moment(value)
              .locale("el")
              .format("DD/MM/YYYY")
}

function getCurrentDate(){
    return Date.now()
}


function getCurrentDatePlusDays(daysToAdd) {
    let currentDate = Moment();       
    let daysProjection = Moment(currentDate).add(daysToAdd, 'days');
    return daysProjection;
}

function getCurrentDateTimeUnix(){
    const unixTime = Math.round(new Date().getTime() / 1000);
    return unixTime
}

export default DateHelper;