import React, { Fragment, useState } from "react";
import { DynamicStepActionTypes } from "../../common/enums/dynamicStepActionTypes.enum";
import { OverrideRules } from "../../views/instances/custom/custom.InstanceBase.overrides.aspra-spitia";
import DynamicActionLabel from "./DynamicActionLabel";

import Map from "./Map"

export const DynamicMap = ({ self, isEnabled, dynamicActions, stepId, isShowlabel }) => {
  const reactDynamicMapComponentRefs = React.createRef();  
  isShowlabel = isShowlabel || false;

  if (dynamicActions.action.Type != DynamicStepActionTypes.mappin) {
    return <></>;
  }  


  function getValueFromData(){
    let valueJson = dynamicActions.data.Data;
    if (!valueJson) {return null;}
    let value = null;
    try {
      value = JSON.parse(valueJson);
    } catch (error) {
      console.error("invalidJsonMessage=" + valueJson)
    }
    return value;
  }
  function onDragEnd(value) {
    let valueJson = JSON.stringify(value);
    self.updateInputValue(isEnabled, valueJson, stepId, dynamicActions.action.Id);
  }

  // async function setValue() {
  //   let value = await reactDynamicMapComponentRefs.current.getData();  
  //   let valueJson = JSON.stringify(value);
  //   self.updateInputValue(isEnabled, valueJson, stepId, dynamicActions.action.Id);
  // }

  //OVERIDE RULES
  let ovverideRule = OverrideRules.getOnRenderControlOverrideRule(
    self.state,
    dynamicActions.action.Id
  );
  let isVisible = true;
  if (ovverideRule) {   
    isVisible = ovverideRule.isVisible;
  }

  if (isVisible === false) {
    return <></>;
  }  

  // let actionButtons = (
  //   <Row>
  //     <Col md="12">
  //       <Button
  //         className="float-right mb-2"
  //         color="primary"
  //         onClick={() => setValue()}
  //         size="md"
  //       >
  //         Αναζήτηση Στίγματος
  //       </Button>
  //     </Col>
  //   </Row>
  // );

  return (
    <Fragment>
      {/* {isEnabled && actionButtons}      */}
      {isShowlabel && (
        <Fragment>
          <DynamicActionLabel
            title={dynamicActions.action.Name}
            description={dynamicActions.action.Description}
          />
          <br></br>
        </Fragment>
      )}
      <Map
        ref={reactDynamicMapComponentRefs}
        isEnabled={isEnabled}
        value={getValueFromData()}
        onDragEnd={onDragEnd}
      ></Map>
    </Fragment>
  );
};
