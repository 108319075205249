import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum,
} from "./apiCallerHelper.core";
import EVOAlertComponent from "../components/common/EvoAlertComponent";

export async function getMonthlyVouchers(filters) {
  var route = "/api/statistics/monthly_vouchers";

  if(filters.contractor_displayname != undefined && filters.contractor_displayname.trim() === '') filters.contractor_displayname = null;
  if(filters.voucher_contract_number != undefined && filters.voucher_contract_number.trim() === '') filters.voucher_contract_number = null;
  if(filters.voucher_start_month != undefined && filters.voucher_start_month.length === 1) filters.voucher_start_month = `0${filters.voucher_start_month}`;

  let asyncResult = await postAsync(route, filters);
  let result = [];
  if (asyncResult.isSuccess === false) {
    EVOAlertComponent.Alert({ message: asyncResult.errorMessage });
  } else {
    result = asyncResult.response;
  }
  return result;
}

export async function newMonthlyVouchers(metadata, vouchers) {
  const route = "/api/statistics/monthly_vouchers/new";
  const body = {
    metadata: metadata,
    vouchers: vouchers
  };
  
  let asyncResult = await postAsync(route, body);
  let result = [];
  if (asyncResult.isSuccess === false) {
    EVOAlertComponent.Alert({ message: asyncResult.errorMessage });
  } else {
    result = asyncResult.response;
  }
  return result;
}