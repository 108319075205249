// import { useHistory } from 'react-router-dom';
import {RoutesTypes} from "../common/enums/routesTypes.enum"
import Routes from "../routes"

export const RedirectHelper = {
    IsWindowLocationPage : (routeType) => {
      let windowLocation = window.location.pathname;      
      let isWindowLocationpage = windowLocation.toLowerCase().includes(Routes.getURLByRouteType(routeType).toLowerCase());            
      return isWindowLocationpage;
    },
    RedirectTo: {
        dynamicRedirect: (url) => _location(url),
        reloadPage : () => { window.location.reload();},
        reloadPageFromCache : () => { window.location.reload(false);},
        home: () => _location(Routes.getURLByRouteType(RoutesTypes.HOME)),
        login: () => _location(Routes.getURLByRouteType(RoutesTypes.LOGIN)),
        logout: () => _location(Routes.getURLByRouteType(RoutesTypes.LOGOUT)),
        passwordChange: () => _location(Routes.getURLByRouteType(RoutesTypes.PASSWORD_CHANGE)),        
        monthlyPrint: () => _location(Routes.getURLByRouteType(RoutesTypes.MONTHLY_PRINT)),  
        properties: () => _location(Routes.getURLByRouteType(RoutesTypes.PROPERTIES)),
        //adminDetails: (workflowInstanceId) => _location(Routes.getRouteURLByRouteType(RoutesTypes.ADMIN_DETAILS), workflowInstanceId),
        stepAction: (stepActionId) => _location(Routes.getURLByRouteType(RoutesTypes.FLOW_ACTION_STEP_EDIT), stepActionId),          

        flowInstance: (workflowInstanceId) => _location(Routes.getURLByRouteType(RoutesTypes.INSTANCE), workflowInstanceId),
        newInstance: () => _location(Routes.getURLByRouteType(RoutesTypes.INSTANCE_NEW)),
        details: (workflowInstanceId) => _location(Routes.getURLByRouteType(RoutesTypes.INSTANCE_DETAILS), workflowInstanceId),                    
        flowEdit: (flowId) => _location(Routes.getURLByRouteType(RoutesTypes.FLOW_EDIT), flowId),
      },
    };
    
    function _locationRedirectReact(self, route) {
      self.props.history.push(route)
    }
    
    function _location(route, arg) {               
      if (Array.from(route)[0] !== "/"){
        route = "/" + route;        
      }
      if (arg){        
        route = route + arg;
      }       
      window.location.replace(route);
    }

export default RedirectHelper