import { WorkflowIdTypes } from "./enums/workflowIdTypes.enum";
import { ApiCallerHelper } from "../api/apiCallerHelper";
import EVOAlertComponent from "../components/common/EvoAlertComponent";
import { Clone, Sort } from "../common/utils"
import WorkflowService_aspra_spitia from "./WorkflowService.aspra-spitia";
import moment, { defineLocale } from "moment";

const CalculatedStatus = [
  {key: '000', value : 'Άγνωστο'},
  {key: '001', value : 'Απορρίφθηκε'},
  {key: '002', value : 'Ολοκληρώθηκε'},
  {key: '003', value : 'Προσωρινή αποθήκευση'},
  {key: '004', value : 'Δημιουργήθηκε'},
  {key: '005', value : 'Ανατέθηκε σε'},
  {key: '006', value : 'Αποκατάσταση σε εξέλιξη'},
];

const CalculatedSubStatus = [
  {key: '101', value : 'Απορριφθέντα'},
  {key: '102', value : 'Ολοκληρωμένα'},
  {key: '103', value : 'Προσωρινά αποθηκευμένα'},
  {key: '104', value : 'Σε εξέλιξη'}
];

const FaultType = [
  {key: '002', value : 'Οικία'},
  {key: '001', value : 'Κοινόχρηστη'}
];

export const WorkflowService = {
  Extentions : {
    AspraSpitia : WorkflowService_aspra_spitia
  },
  WorkflowIdTypes: WorkflowIdTypes,
  _initCache : async (forceReset) => {    
    if (forceReset || WorkflowService._cache.getWorkflowsInfoAsync === null){
      let result = await ApiCallerHelper.Flows.getWorkflowsInfoAsync();      
      WorkflowService._cache.getWorkflowsInfoAsync = result?.data??null;
    }

    if (forceReset || WorkflowService._cache.allWorkflows === null){
      let result = await ApiCallerHelper.Flows.getAllAsync();      
      WorkflowService._cache.allWorkflows = result?.data??null;      
    }    
  },
  _cache : {    
    allWorkflows : null,  
    getWorkflowsInfoAsync : null
  }, 
  Factory : {
    GetNewWorkflow : (
      {
        Id,
        Name,
        Description,
        Priority,
        Duration,
        EndingDate,
        StartingDate,
        MetaDataJson
      }
    ) => {
      let workflow = {
        Id: Id,
        Name: Name,
        Description: Description,
        Priority: Priority,
        Duration: Duration,
        EndingDate: EndingDate,
        StartingDate: StartingDate,
        MetaDataJson: MetaDataJson
      };
      return workflow;
    }
  },
  saveData : async(
    {
      Id,
      Name,
      Description,
      Priority,
      Duration,
      EndingDate,
      StartingDate,
      MetaDataJson,
    }) => {
      try {        
        let editModel = {
          workflowId: Id,
          name: Name,
          description: Description,
          priority: Priority,
          startingDate: StartingDate,
          endingDate: EndingDate,
        };
        //console.log(editModel);
        let result = null;
        if (editModel.Id != null) {
          result = await ApiCallerHelper.Flows.newWorkflowAsync(editModel);
        } else {
          result = await ApiCallerHelper.Flows.editWorkflowAsync(editModel);
        }
        if (result.isSuccess == false) {
          throw result.errorMessage;
        }       
      } catch (error) {            
          EVOAlertComponent.Alert({ message: error });    
        return false;
      } finally {        
        await WorkflowService._initCache(true);
      }      
      return true;        
  },
  _mappers : {
    getFaultForResidentsViewModels(faults) {
      //console.log(faults);
      let result = faults.map(x=> 
        {
          let calculatedStatusDescription = CalculatedStatus.filter(item=>item.key === x.CalculatedStatus)[0]?.value??'';
          if (x.CalculatedStatus === '005') { //{key: '005', value : 'Ανατέθηκε σε'},
            calculatedStatusDescription = calculatedStatusDescription + x._usrwfidvouchermixanikosanathesis_displayname;
          }
          let calculatedSubStatusDescription = CalculatedSubStatus.filter(item=>item.key === x.CalculatedSubStatus)[0]?.value??'';
          let faultTypeDescription = FaultType.filter(item=>item.key === x.wfiParent_kathgoria_vlavhs_Data)[0]?.value??'';

          return { 
            wfiParentId : x.wfiParentId,
            WorkflowInstanceId : x.wfiParentId,
            SerialNumber: x.wfiParent_SerialNumber,            
            CreatedByuser: x._usrparent_displayname,                        
            DateCreated : moment(x.wfiParent_StartedAt).format('YYYY/MM/DD hh:mm'),
            Status : x._ws_parent_Status,
            StatusDescription: x._ws_parent_Name,
            Completed : x.wfiParent_Completed,
            Declined : x.wfiParent_Declined,
            IsAssigned : x._usrparentassigned_active,
            AssignedUser: x._usrparentassigned_displayname,
            AssignedUser: x._usrparentassigned_displayname,
            CalculatedStatus :x.CalculatedStatus,
            CalculatedSubStatus : x.CalculatedSubStatus,
            workflowIdType : x.workflowIdType,
            faultDescription : x.wfiParent_perigrafh_vlavhs_Data,
            faultType : x.wfiParent_kathgoria_vlavhs_Data,
            usrwfidvouchermixanikosanathesis_displayname : x._usrwfidvouchermixanikosanathesis_displayname,
            calculatedStatusDescription : calculatedStatusDescription,
            calculatedSubStatusDescription : calculatedSubStatusDescription,
            faultTypeDescription : faultTypeDescription
          };
        });
        //console.log(result)
      return result;
    },
    getFaultViewModels(faults) {
      //console.log(faults);
      let result = faults.map(x=> 
        {
          return { 
            wfiParentId : x.wfiParentId,
            WorkflowInstanceId : x.wfiParentId,
            SerialNumber: x.wfiParent_SerialNumber,            
            CreatedByuser: x._usrparent_displayname,                        
            DateCreated : moment(x.wfiParent_StartedAt).format('YYYY/MM/DD hh:mm'),
            Status : x._ws_parent_Status,
            StatusDescription: x._ws_parent_Name,
            Completed : x.wfiParent_Completed,
            Declined : x.wfiParent_Declined,
            IsAssigned : x._usrparentassigned_active,
            AssignedUser: x._usrparentassigned_displayname,
          };
        });
      return result;
    },
    getVoucherViewModels(vouchers) {
      //console.log(vouchers);      
      let result = vouchers.map(x=> 
        {          
          return { 
            wfiParentId : x.wfiParentId,
            ParentSerialNumber : x.wfiParent_SerialNumber,
            WorkflowInstanceId : x.wfiChildVoucherId,
            SerialNumber: x.wfiChildVoucher_SerialNumber,            
            CreatedByuser: x._usrwfichildvoucher_displayname,                        
            DateCreated : moment(x.wfiParent_StartedAt).format('YYYY/MM/DD hh:mm'),
            Status : x.wfiChildVoucher_Status,
            StatusDescription: x._ws_childvoucher_Name,
            Completed : x.wfiChildVoucher_Completed,
            Declined : x.wfiChildVoucher_Declined,
            //IsAssigned : x._usrparentassigned_active,
            AssignedUser: x._usrwfichildvoucherassigned_displayname,
            AssignedContractor: x._usrwfidvoucherergolabos_displayname,
            AssignedTechnician: x._usrwfidvouchermixanikosanathesis_displayname            
          };
        });
      return result;
    },
    getExtraViewModels(extras) {
      //console.log(extras);
      let result = extras.map(x=> 
        {
          return { 
            wfiParentId : x.wfiParentId,
            ParentSerialNumber : x.wfiParent_SerialNumber,
            WorkflowInstanceId : x.wfiChildExtraId,
            SerialNumber: x.wfiChildExtras_SerialNumber,            
            CreatedByuser: x._usrwfichildextras_displayname,                        
            DateCreated : moment(x.wfiParent_StartedAt).format('YYYY/MM/DD hh:mm'),
            Status : x.wfiChildExtras_Status,
            StatusDescription: x._ws_childextras_Name,
            Completed : x.wfiChildExtras_Completed,
            Declined : x.wfiChildExtras_Declined,
            //IsAssigned : x._usrparentassigned_active,
            AssignedUser: x._usrwfichildextrasassigned_displayname,
            //AssignedContractor: x._usrwfidvoucherergolabos_displayname,
            //AssignedTechnician: " - "
          };
        });
      return result;
    }  
  },
  getAllWorkflows : () => {
    return Sort(Clone(WorkflowService._cache.allWorkflows??[]),"Priority");
  },
  getWorkflowById : (workflowId) => {  
    return WorkflowService.getAllWorkflows().find(x=>x.Id === workflowId)??null;      
  },
  getWorkflowInfo : (worfklowId) => {
    let workflowInfos = WorkflowService._cache.getWorkflowsInfoAsync;   
    if (!workflowInfos) {return null;}
    let workflow = workflowInfos.find(x=>x.wfId === worfklowId)??null;        
    return workflow
  },
  getWorkflowStep : (worfklowId, status) => {
    let workflow  = WorkflowService.getWorkflowInfo(worfklowId);
    if (!workflow) {return null;}  
    let worflowStep = workflow.WorkflowSteps.find(x=> x.wfsStatus === status);
    if (!worflowStep) { return null;}       
    return worflowStep
  },
  // getWorkflowCurrentStatusDescription : (worfklowId, status) => {
  //   let worflowStep = WorkflowService.getWorkflowStep(worfklowId, status);
  //   return worflowStep?.wfsName??status;  
  // },  
  // getAllFaultViewModelsForResidentAsync : async (isCompleted) => {    
  //   let getInstancesDetailsFaultResult = await ApiCallerHelper.Instances.getInstancesDetailsFaultResidentAsync(isCompleted);
  //   let data = getInstancesDetailsFaultResult.data;
  //   return WorkflowService._mappers.getFaultForResidentsViewModels(data);
  // },
  getAllFaultViewModelsAsync : async (isCompleted) => {    
    let getInstancesDetailsFaultResult = await ApiCallerHelper.Instances.getInstancesDetailsFaultAsync(isCompleted);
    let data = getInstancesDetailsFaultResult.data;
    return WorkflowService._mappers.getFaultViewModels(data);
  },
  
  getAllVoucherViewModelsAsync : async (isCompleted) => {
    let getInstancesDetailsFaultResult = await ApiCallerHelper.Instances.getInstancesDetailsVoucherAsync(isCompleted);
    let data = getInstancesDetailsFaultResult.data;
    return WorkflowService._mappers.getVoucherViewModels(data);
  },

  getAllExtraViewModelsAsync : async (isCompleted) => {
    let getInstancesDetailsFaultResult = await ApiCallerHelper.Instances.getInstancesDetailsExtraAsync(isCompleted);
    let data = getInstancesDetailsFaultResult.data;
    return WorkflowService._mappers.getExtraViewModels(data);
  }
};

export default WorkflowService