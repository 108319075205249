import React, { useState, useEffect, Fragment } from "react";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";
const CustomIndexResidentFilterFaults = ({
  filterClick,
  datasource,
  showFilteredSums,
}) => {
  const [_filter, setFilter] = React.useState(null);
  const [_calculatedSums, setCalculatedSums] = React.useState({});

  const filters = {
    pending: (x) => x.CalculatedSubStatus == "104",
    tempSave: (x) => x.CalculatedSubStatus == "103",
    completed: (x) => x.CalculatedSubStatus == "102",
    declined: (x) => x.CalculatedSubStatus == "101",
  };

  const buttons = [
    {
      label: "Σε εξέλιξη",
      value: "pending",
      sum: _calculatedSums.pending,
    },
    {
      label: "Ολοκληρωμένα",
      value: "completed",
      sum: _calculatedSums.completed,
    },
    {
      label: "Απορριφθέντα",
      value: "declined",
      sum: _calculatedSums.declined,
    },
    {
      label: "Προσωρινά Aποθ/μένα",
      value: "tempSave",
      sum: _calculatedSums.tempSave,
    },
  ];

  useEffect(() => {
    const getAndSetCalculatedSums = () => {
      if (_calculatedSums == false) {
        return;
      }
      let sums = {};
      Object.keys(filters).forEach((x) => {
        sums[x] = datasource.filter(filters[x]).length;
      });
      setCalculatedSums(sums);
    };

    getAndSetCalculatedSums();
  }, [datasource]);

  useEffect(() => {
    filterClick(_filter);
  }, [_filter]);

  const onFilterClick = (value) => {
    if (!value) {
      setFilter(null);
      return;
    }
    setFilter({ filter: filters[value] });
  }; 

  return (    
      <Container>
        <Row>
          {buttons.map((button, key) => (
            <Col md="3" sm="6" xs="12" key={key}>
              <Button
                color="link"
                size="md"
                onClick={() => {
                  onFilterClick(button.value);
                }}
                disabled = {button.sum == 0}
              >
                <u>
                  {button.label} ({button.sum})
                </u>
              </Button>
            </Col>
          ))}
        </Row>
      </Container>    
  );
};

export default CustomIndexResidentFilterFaults;
