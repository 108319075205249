import { WorkflowStepActionIdTypes } from "../../../common/enums/workflowStepActionIdTypes.enum";
import { WorkflowIdTypes } from "../../../common/enums/workflowIdTypes.enum";
import { WorkflowStepIdTypes } from "../../../common/enums/workflowStepIdTypes.enum";
import ApiCallerHelper from "../../../api/apiCallerHelper";
import {
  EVOReactTableDynamicGridHelper,
  GridActions,
} from "../../../components/common/evo.react-table-dynamic-grid-helper";
import { WorkflowService } from "../../../common/WorkflowService";

import { UserRoleHelper } from "../../../common/userRoleHelper";
import { DateHelper } from "../../../common/dateHelper";
import { WorkCategoryEnum } from "../../../api/apiCallerHelper.External";
import ExternalApiService from "../../../common/ExternalApiService";
import { Clone } from "../../../common/utils";
import AuthService from "common/AuthService";

export const OverrideRules = {
  _getStepActionByStepActionId: (steps, stepActionId) => {
    let step =
      steps.filter(
        (x) => x.actions.filter((a) => a.action.Id === stepActionId).length > 0
      )[0] ?? null;
    if (step === null) {
      return;
    }
    let stepAction = step.actions.filter(
      (a) => a.action.Id === stepActionId
    )[0];
    return stepAction;
  },
  /*add events that should happen upon rendering of a specific flow step*/
  onMountAsync: async (
    self,
    state,
    workflowId,
    workflowStepId,
    workflowInstanceId
  ) => {
    await OverrideRules._onMountRules.flow2.step1.onLoad.executeAsync(
      self,
      state,
      workflowId,
      workflowStepId,
      workflowInstanceId
    );
    await OverrideRules._onMountRules.flow3.step1.onLoad.executeAsync(
      self,
      state,
      workflowId,
      workflowStepId,
      workflowInstanceId
    );
  },
  /* add events that should happen on input update of a specific action*/
  onUpdateInputValue: (self, state, isEnabled, actionId, value) => {
    ////ADD MORE ASYNC CUSTOM RULES HERE
    if (isEnabled === null || isEnabled === false) {
      return;
    }
    //ADD MORE CUSTOM RULES HERE
    OverrideRules._onUpdateInputValueRules.flow2.step1.updatedWorkCategoryAsync.executeAsync(
      self,
      state,
      actionId,
      value
    );
    OverrideRules._onUpdateInputValueRules.flow2.step1.updatedContractorAsync.executeAsync(
      self,
      state,
      actionId,
      value
    );
    OverrideRules._onUpdateInputValueRules.flow2.step1.updatedWorkVoucherAsync.executeAsync(
      self,
      state,
      actionId,
      value
    );
    OverrideRules._onUpdateInputValueRules.flow3.step1.updateContractorExtraWorkDatatableValues.executeAsync(
      self,
      state,
      actionId,
      value
    );
  },
  /* add events that should happen on when a specific action is rendered*/
  getOnRenderControlOverrideRule: (state, actionId, user) => {
    ////ADD MORE CUSTOM RULES HERE
    const ruleFunctions = [
      OverrideRules._onRenderControlRules.flow1.step1
        .stepActionFaultPublicCategories, ///
      OverrideRules._onRenderControlRules.flow1.step1
        .stepActionFaultPublicSubcategories, ///
      OverrideRules._onRenderControlRules.flow1.step1
        .stepActionFaultPublicSubCategoriesOther, ///
      OverrideRules._onRenderControlRules.flow1.step1.stepActionLocation,
      OverrideRules._onRenderControlRules.flow1.step1.stepActionAddress,
      OverrideRules._onRenderControlRules.flow1.step1.stepActionExternalHouseId,
      OverrideRules._onRenderControlRules.flow1.step3.stepActionRating,
      OverrideRules._onRenderControlRules.flow1.step3.stepActionNotes,
      OverrideRules._onRenderControlRules.flow1.step3.stepActionConfirm,
      OverrideRules._onRenderControlRules.flow1.step3.stepActionPreview,
      OverrideRules._onRenderControlRules.flow1.step3.stepActionExtrasPreview,
      OverrideRules._onRenderControlRules.flow2.step1.stepActionContractor,
      OverrideRules._onRenderControlRules.flow2.step1.stepActionWorkVoucher,
      OverrideRules._onRenderControlRules.flow2.step1.stepActionContractNumber,
      OverrideRules._onRenderControlRules.flow2.step1
        .stepActionIdExpectedExecutionDate,
      OverrideRules._onRenderControlRules.flow2.step1
        .stepAcionIdVoucherSumProductFinalPrice,
      OverrideRules._onRenderControlRules.flow2.step1
        .stepAcionIdVoucherSumVatProductFinalPrice,
      OverrideRules._onRenderControlRules.flow2.step2
        .stepActionApproveVoucher,
      OverrideRules._onRenderControlRules.flow2.step2
        .stepActionRejectVoucher,
      OverrideRules._onRenderControlRules.flow2.step2
        .stepActionRejectVoucherComments,
      OverrideRules._onRenderControlRules.flow2.step3
        .stepActionAcceptAssignment,
      OverrideRules._onRenderControlRules.flow2.step4
        .stepActionAdditionalWork,
      OverrideRules._onRenderControlRules.flow2.step4
        .stepActionCompleteWork,
      OverrideRules._onRenderControlRules.flow2.step5
        .stepActionConfirmWork,
      OverrideRules._onRenderControlRules.flow2.step5
        .stepActionAdditionalWorkFromMechanic,
      OverrideRules._onRenderControlRules.flow2.step5
        .stepActionEditWorkVoucher,
      OverrideRules._onRenderControlRules.flow3.step1.stepActionExtras,
      OverrideRules._onRenderControlRules.flow3.step1.stepActionExtrasSumProductFinalPrice,
      OverrideRules._onRenderControlRules.flow3.step1.stepActionExtrasSumVatProductFinalPrice,
      OverrideRules._onRenderControlRules.flow3.step2
        .stepActionAcceptExtras,
      OverrideRules._onRenderControlRules.flow3.step2
        .stepActionDeclineExtras,
      OverrideRules._onRenderControlRules.flow3.step2
        .stepActionEditExtras,
      OverrideRules._onRenderControlRules.flow3.step3.stepActionCompleteExtras,
      OverrideRules._onRenderControlRules.all.completed
    ];

    const results = [];

    for (const ruleFunction of ruleFunctions) {
      const result = ruleFunction(state, actionId, user);
      if (result !== null) {
        results.push(result);
      }
    }
    
    let combinedResult = null;

    if (results.length > 0) {
      combinedResult = results.reduce((accumulator, result) => ({ ...accumulator, ...result }), {});
    }

    return combinedResult;
  },
  /* add events that changes the instanse flow layout */
  getLayoutParametersAsync: async (
    workflowInstanceId,
    workflowId,
    parentId
  ) => {
    return await OverrideRules._getLayoutParametersAsync(
      workflowInstanceId,
      workflowId,
      parentId
    );
  },
  _common: {
    SetContractorOptionsAndSelectedCategoryCache: async (
      workCategoryTypeValue
    ) => {
      let contractorsResultAsync = await ExternalApiService.getContractors(
        workCategoryTypeValue
      );
      let result = contractorsResultAsync.models;

      let options = result;
      options.forEach((element) => {
        element.value = element.teIdErg;
        element.label = element.teIdErg + " - " + element.teName;
      });
      OverrideRules._cache.flow2.step1 = {
        ...OverrideRules._cache.flow2.step1,
        ...{
          contractorsOptions: options,
          selectedWorkCategoryType: workCategoryTypeValue,
        },
      };
    },
    SetCalculatedGridColumnCache: async (steps) => {
      function getContractorData(steps) {
        let contractorData = null;
        try {
          let result = steps
            .find(
              (x) => x.step.Id == WorkflowStepIdTypes.flow2_step_1_work_voucher
            )
            .actions.find(
              (x) =>
                x.action.Id ==
                WorkflowStepActionIdTypes.flow_2_step_1_stepActionIdContractor
            );

          if (!result.data.MetaDataJson) {
            return null;
          }
          contractorData =
            JSON.parse(result.data.MetaDataJson)?.valueData ?? null;
        } catch (error) {
          console.error(error);
        }
        return contractorData;
      }

      let contractorData = getContractorData(steps);
      if (!contractorData) {
        return;
      }
      let contractorDataValue = contractorData.value;
      let stepActionVoucerTable = OverrideRules._getStepActionByStepActionId(
        steps,
        WorkflowStepActionIdTypes.step_1_stepActionWorkVoucherTable
      );
      let gridStepActionData = stepActionVoucerTable.action;
      let columnMetaDataDefinitions =
        EVOReactTableDynamicGridHelper.DynamicAction.getGridDefinitionByColumnstepActionDataValue(
          gridStepActionData
        );

      await OverrideRules._common.SetCommonColumnMetadataDefinitionsGridMaterialErgolavoiProductPricesAsync(
        columnMetaDataDefinitions,
        contractorDataValue
      );
      OverrideRules._cache.flow2.step1 = {
        ...OverrideRules._cache.flow2.step1,
        ...{
          isEnabled: contractorDataValue != null,
          selectedContractorDataValue: contractorDataValue,
          selectedContractorData: contractorData,
          workVoucherColumnMetaDataDefinitions: columnMetaDataDefinitions,
        },
      };
      let productPricesResultAsync =
        await ExternalApiService.getContractorProductPrices(
          contractorDataValue
        );
      let productPricesResult = productPricesResultAsync.models;
      OverrideRules._cache.flow2.step1.workVoucherCustomSearchAddRowButtonInfo.rowAdder.dataSource =
        productPricesResult;
    },
    SetCommonColumnMetadataDefinitionsGridMaterialErgolavoiProductPricesAsync:
      async (columnMetaDataDefinitions, contractorId) => {
        let productPricesResultAsync =
          await ExternalApiService.getContractorProductPrices(contractorId);
        // console.log(productPricesResultAsync);
        let productPricesResult = productPricesResultAsync.models;
        let filteredProductCategoryType = [
          ...new Set(productPricesResult.map((x) => x.wvpCatDesc)),
        ];
        let filteredProductCategoryTypeOptions =
          filteredProductCategoryType.map((x) => {
            return {
              value: x,
              label: x,
            };
          });

        let productCategoryType = columnMetaDataDefinitions.find(
          (x) => x.id === "__productCategoryType"
        );
        productCategoryType.displayKey = "label";
        productCategoryType.valueKey = "value";
        productCategoryType.options = filteredProductCategoryTypeOptions;

        let productDescription = columnMetaDataDefinitions.find(
          (x) => x.id === "__productDescription"
        );
        productDescription.displayKey = "__productDescription";
        productDescription.valueKey = "__productCode";

        productDescription.options = productPricesResult;
        productDescription.optionsFilterSourceField = "__productCategoryType";
        productDescription.optionsFilterTargetField = "wvpCatDesc";

        // let productQuantity = columnMetaDataDefinitions.find(x=>x.id === "__productQuantity");
        // productQuantity.isEditable = false;
        // console.log(1)
        OverrideRules._cache.flow3.step1.workVoucherCustomSearchAddRowButtonInfo.rowAdder.dataSource = productPricesResult;
        return {};
      },
    IsStep(actionId, workflowStepActionIdTypes) {
      return actionId == workflowStepActionIdTypes;
    },
  },
  _onUpdateInputValueRules: {
    flow2: {
      step1: {
        updatedWorkCategoryAsync: {
          executeAsync: async (self, state, actionId, value) => {
            if (actionId !== WorkflowStepActionIdTypes.flow_2_CategoryOfWork) {
              return;
            }
            if (
              OverrideRules._cache.flow2.step1.selectedWorkCategoryType == value
            ) {
              return;
            }
            await OverrideRules._common.SetContractorOptionsAndSelectedCategoryCache(
              value
            );
            self.setState({ steps: self.state.steps });
          },
        },
        updatedContractorAsync: {
          executeAsync: async (self, state, actionId, value) => {
            if (
              actionId !==
              WorkflowStepActionIdTypes.flow_2_step_1_stepActionIdContractor
            ) {
              return;
            }
            if (
              OverrideRules._cache.flow2.step1.selectedContractorDataValue ==
              value
            ) {
              return;
            }

            await OverrideRules._common.SetCalculatedGridColumnCache(
              self.state.steps
            );
            self.setState({ steps: self.state.steps });
          },
        },
        updatedWorkVoucherAsync: {
          executeAsync: async (self, state, actionId, value) => {
            if (
              actionId !==
              WorkflowStepActionIdTypes.step_1_stepActionWorkVoucherTable
            ) {
              return;
            }

            if (!value) {
              return;
            }

            function _getProductFinalPricesSums(valueStr) {
              let values = JSON.parse(valueStr);
              let productFinalPriceSum = 0;
              let productFinalPriceSumVat = 0;
              //calculate sum
              values.forEach((val) => {
                productFinalPriceSum =
                  productFinalPriceSum + val.__productFinalPrice;
              });
              //calculate sum with vat (TODO needs refactor for 0.24)
              if (productFinalPriceSum != 0) {
                productFinalPriceSumVat = productFinalPriceSum * 1.24;
                productFinalPriceSumVat = Number(
                  productFinalPriceSumVat.toFixed(2)
                );
              }
              return {
                productFinalPriceSum: productFinalPriceSum,
                productFinalPriceSumVat: productFinalPriceSumVat,
              };
            }
            let productFinalPriceSums = _getProductFinalPricesSums(value);
            OverrideRules._cache.flow2.step1.productFinalPriceSum =
              productFinalPriceSums.productFinalPriceSum;
            OverrideRules._cache.flow2.step1.productFinalPriceSumVat =
              productFinalPriceSums.productFinalPriceSumVat;
            self.setState({ steps: self.state.steps });
          },
        },
      },
    },
    flow3: {
      step1: {
        updateContractorExtraWorkDatatableValues: {
          executeAsync: async (self, state, actionId, value) => {
            if (
              actionId !==
              WorkflowStepActionIdTypes.flow_3_step1_WorkExtras
            ) {
              return;
            }

            if (!value) {
              return;
            }

            function _getProductFinalPricesSums(valueStr) {
              let values = JSON.parse(valueStr);
              let productFinalPriceSum = 0;
              let productFinalPriceSumVat = 0;
              //calculate sum
              values.forEach((val) => {
                productFinalPriceSum =
                  productFinalPriceSum + val.__productFinalPrice;
              });
              //calculate sum with vat (TODO needs refactor for 0.24)
              if (productFinalPriceSum != 0) {
                productFinalPriceSumVat = productFinalPriceSum * 1.24;
                productFinalPriceSumVat = Number(
                  productFinalPriceSumVat.toFixed(2)
                );
              }
              return {
                productFinalPriceSum: productFinalPriceSum,
                productFinalPriceSumVat: productFinalPriceSumVat,
              };
            }
            let productFinalPriceSums = _getProductFinalPricesSums(value);
            OverrideRules._cache.flow3.step1.productFinalPriceSum =
              productFinalPriceSums.productFinalPriceSum;
            OverrideRules._cache.flow3.step1.productFinalPriceSumVat =
              productFinalPriceSums.productFinalPriceSumVat;
            self.setState({ steps: self.state.steps });
          },
        },
      },
    },
  },
  _onRenderControlRules: {
    all: {
      completed: (state, actionId) => {
        if (!state.instance.Completed){
          return null;
        }

        const toBeRendered = [
          WorkflowStepActionIdTypes.flow1_step2_stepActionAcceptInstance,
          WorkflowStepActionIdTypes.flow_1_step_2_stepActionAcceptWithoutVoucher,
          WorkflowStepActionIdTypes.flow_1_step_2_stepActionRejectFault,
          WorkflowStepActionIdTypes.flow_1_step_3_stepActionConfirm,
          WorkflowStepActionIdTypes.flow_2_step_2_stepActionApproveVoucher,
          WorkflowStepActionIdTypes.flow_2_step_2_stepActionRejectVoucher,
          WorkflowStepActionIdTypes.flow_2_step_2_stepActionRejectVoucherComments,
          WorkflowStepActionIdTypes.flow_2_step_3_stepActionAcceptAssignment,
          WorkflowStepActionIdTypes.flow_2_step_3_stepActionRejectAssignment,
          WorkflowStepActionIdTypes.flow_2_step_4_stepActionCompleteWork,
          WorkflowStepActionIdTypes.flow_2_step_5_stepActionConfirmWork,
          WorkflowStepActionIdTypes.flow_2_step_6_stepActionExportFinalVoucher,
          WorkflowStepActionIdTypes.flow_2_step_6_stepActionRejectFinalVoucher,
          WorkflowStepActionIdTypes.flow_3_step_2_stepActionCompleteExtras,
          WorkflowStepActionIdTypes.flow_3_step_3_approve_extras,
          WorkflowStepActionIdTypes.flow_3_step_3_decline_extras,
        ];
        
        return {
          renderOnCompletion: toBeRendered.includes(actionId),
        }
      }
    },
    flow1: {
      step1: {
        stepActionFaultPublicSubCategoriesOther: (state, actionId) => {
          /// "Άλλο"
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultPublicSubCategoriesOther
            )
          ) {
            return null;
          }
          let selectedFaultPublicSubcategories =
            OverrideRules._getStepActionByStepActionId(
              state.steps,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultPublicSubcategories
            )?.data?.Data ?? null;
          let selectedFaultCategory =
            OverrideRules._getStepActionByStepActionId(
              state.steps,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultCategory
            )?.data?.Data ?? null;
          return {
            isVisible: selectedFaultCategory === "001",
            isEnabled: selectedFaultPublicSubcategories === "999",
          };
        },
        stepActionFaultPublicSubcategories: (state, actionId) => {
          /// Κοινόχρηστες Υποκατηγορίες
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultPublicSubcategories
            )
          ) {
            return null;
          }
          let selectedFaultPublicCategory =
            OverrideRules._getStepActionByStepActionId(
              state.steps,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultPublicCategories
            )?.data?.Data ?? null;
          let selectedFaultCategory =
            OverrideRules._getStepActionByStepActionId(
              state.steps,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultCategory
            )?.data?.Data ?? null;
          return {
            isVisible: selectedFaultCategory === "001",
            isReadonly: selectedFaultPublicCategory === "",
            subcategory: selectedFaultPublicCategory,
          };
        },
        stepActionFaultPublicCategories: (state, actionId) => {
          /// Κοινόχρηστες κατηγορίες
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultPublicCategories
            )
          ) {
            return null;
          }
          let selectedFaultCategory =
            OverrideRules._getStepActionByStepActionId(
              state.steps,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultCategory
            )?.data?.Data ?? null;
          return {
            isVisible: selectedFaultCategory === "001",
          };
        },
        stepActionLocation: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionLocation
            )
          ) {
            return null;
          }

          let selectedFaultCategory =
            OverrideRules._getStepActionByStepActionId(
              state.steps,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultCategory
            )?.data?.Data ?? null;
          return {
            isVisible: selectedFaultCategory === "001", //koinoxrhsth
          };
        },
        stepActionAddress: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionAddress
            )
          ) {
            return null;
          }

          let selectedFaultCategory =
            OverrideRules._getStepActionByStepActionId(
              state.steps,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultCategory
            )?.data?.Data ?? null;
          return {
            isVisible: selectedFaultCategory === "002", //οικία
          };
        },
        stepActionExternalHouseId: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionExternalHouseId
            )
          ) {
            return null;
          }

          let selectedFaultCategory =
            OverrideRules._getStepActionByStepActionId(
              state.steps,
              WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultCategory
            )?.data?.Data ?? null;
          return {
            isVisible: selectedFaultCategory === "002" && UserRoleHelper.isMechanic(AuthService.getCurrentUser()), //οικία
            isEnabled: true,
          };
        },
      },
      step3: {
        stepActionRating: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_3_stepActionRating
            )
          ) {
            return null;
          }
          
          return {
            isVisible: UserRoleHelper.isClient(AuthService.getCurrentUser()), 
          };
        },
        stepActionNotes: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_3_stepActionNotes
            )
          ) {
            return null;
          }
          
          return {
            isVisible: UserRoleHelper.isClient(AuthService.getCurrentUser()), 
          };
        },
        stepActionConfirm: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_3_stepActionConfirm
            )
          ) {
            return null;
          }
          
          return {
            isEnabled: UserRoleHelper.isClient(AuthService.getCurrentUser()), 
          };
        },
        stepActionPreview: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_3_stepActionPreview
            )
          ) {
            return null;
          }
          
          return {
            isEnabled: UserRoleHelper.isClient(AuthService.getCurrentUser()), 
            isVisible: state.steps[2].actions[0].data.Data != ""
          };
        },
        stepActionExtrasPreview: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_1_step_3_stepActionExtrasPreview
            )
          ) {
            return null;
          }

          return {
            isEnabled: UserRoleHelper.isClient(AuthService.getCurrentUser()), 
            isVisible: state.steps[2].actions[1].data.Data != ""
          };
        },
      }
    },
    flow2: {
      step1: {
        stepActionContractor: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_2_step_1_stepActionIdContractor
            )
          ) {
            return null;
          }
          let cache = OverrideRules._cache.flow2.step1;
          return {
            isEnabled: cache.contractorsOptions.length > 0,
            options: cache.contractorsOptions,
          };
        },
        stepActionWorkVoucher: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.step_1_stepActionWorkVoucherTable
            )
          ) {
            return null;
          }
          let cache = OverrideRules._cache.flow2.step1;
          let isVisible = false;

          return {
            isEnabled: cache.workVoucherColumnMetaDataDefinitions.length > 0,
            columnMetaDataDefinitions:
              cache.workVoucherColumnMetaDataDefinitions,
            customButtonActionInfo:
              cache.workVoucherCustomSearchAddRowButtonInfo,
            showAddInlineRowButton: cache.workVoucherShowAddInlineRowButton,
          };
        },
        stepActionContractNumber: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_2_step_1_stepActionIdContractNumber
            )
          ) {
            return null;
          }

          let cache = OverrideRules._cache.flow2.step1;
          return {
            value: cache.selectedContractorData?.teAgreementno ?? null,
          };
        },
        stepActionIdExpectedExecutionDate: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_2_step_1_stepActionIdExpectedExecutionDate
            )
          ) {
            return null;
          }
          let cache = OverrideRules._cache.flow2.step1;
          if (cache && cache.selectedWorkCategoryType != null) {
            //SPECIAL LOGIC
            let selectedWorkCategoryType = cache.selectedWorkCategoryType;
            let daysToAdd = 1;
            let isEnabled = null;
            if (selectedWorkCategoryType == WorkCategoryEnum.X00) {
              daysToAdd = 10;
              isEnabled = false;
            } else if (selectedWorkCategoryType == WorkCategoryEnum.X01) {
              daysToAdd = 30;
              isEnabled = false;
            } else {
              return null;
            }
            let daysProjection = DateHelper.getCurrentDatePlusDays(daysToAdd);
            let daysProjectionFormatted =
              DateHelper.getFormatDate(daysProjection);
            return {
              value: daysProjectionFormatted,
              isEnabled: isEnabled,
            };
          }
          return null;
        },
        stepAcionIdVoucherSumProductFinalPrice: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_2_step_1_stepAcionIdVoucherSumProductFinalPrice
            )
          ) {
            return null;
          }

          let cache = OverrideRules._cache.flow2.step1;
          return {
            value: cache.productFinalPriceSum ?? null,
          };
        },
        stepAcionIdVoucherSumVatProductFinalPrice: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_2_step_1_stepAcionIdVoucherSumVatProductFinalPrice
            )
          ) {
            return null;
          }

          let cache = OverrideRules._cache.flow2.step1;
          return {
            value: cache.productFinalPriceSumVat ?? null,
          };
        },
      },
      step2: {
        stepActionApproveVoucher: (state, actionId) => {
          if (actionId !== WorkflowStepActionIdTypes.flow_2_step_2_stepActionApproveVoucher) {
            return null;
          }

          return {
            isEnabled: UserRoleHelper.isAdmin(AuthService.getCurrentUser()), 
          };
        },
        stepActionRejectVoucher: (state, actionId) => {
          if (actionId !== WorkflowStepActionIdTypes.flow_2_step_2_stepActionRejectVoucher) {
            return null;
          }

          return {
            isEnabled: UserRoleHelper.isAdmin(AuthService.getCurrentUser()), 
          };
        },
        stepActionRejectVoucherComments: (state, actionId) => {
          if (actionId !== WorkflowStepActionIdTypes.flow_2_step_2_stepActionRejectVoucherComments) {
            return null;
          }

          return {
            isVisible: UserRoleHelper.isAdmin(AuthService.getCurrentUser()), 
          };
        }
      },
      step3: {
        stepActionAcceptAssignment: (state, actionId) => {
          if (actionId !== WorkflowStepActionIdTypes.flow_2_step_3_stepActionAcceptAssignment) {
            return null;
          }

          return {
            pressAgain: state.instance.Status == 3 && state.steps[4].actions[2].data.Data == "{\"pressedHistory\": [], \"isPressed\": true}",
            isVisible: (state.instance.Status == 3 && UserRoleHelper.isContractor(AuthService.getCurrentUser())) || state.instance.Status > 3, 
          }
        }
      },
      step4: {
        stepActionAdditionalWork: (state, actionId) => {
          if (actionId !== WorkflowStepActionIdTypes.flow_2_step_4_stepActionAdditionalWork) {
            return null;
          }

          return {
            pressAgain: true,
            actionDisplayName: state.steps[3].actions[0].data.Data !== "" ? "Προβολή Πρόσθετων Εργασιών" : "Προσθήκη Εργασιών",
            isVisible: UserRoleHelper.isContractor(AuthService.getCurrentUser()) && state.instance.Status == 4, 
          }
        },
        stepActionCompleteWork: (state, actionId) => {
          if (actionId !== WorkflowStepActionIdTypes.flow_2_step_4_stepActionCompleteWork) {
            return null;
          }

          return {
            pressAgain: state.instance.Status == 4 && state.steps[4].actions[2].data.Data == "{\"pressedHistory\": [], \"isPressed\": true}",
            isVisible: (state.instance.Status == 4 && UserRoleHelper.isContractor(AuthService.getCurrentUser())) || state.instance.Status > 4,
          }          
        }
      },
      step5: {
        stepActionConfirmWork: (state, actionId) => {
          if(actionId != WorkflowStepActionIdTypes.flow_2_step_5_stepActionConfirmWork) {
            return null;
          }

          return {
            pressAgain: UserRoleHelper.isMechanic(AuthService.getCurrentUser()) && state.instance.Status <= 5,
            isVisible: (state.instance.Status == 5 && UserRoleHelper.isMechanic(AuthService.getCurrentUser())) || state.instance.Status > 5, 
          }
        },
        stepActionAdditionalWorkFromMechanic: (state, actionId) => {
          if(actionId !== WorkflowStepActionIdTypes.flow_2_step_5_stepActionAdditionalWorkFromMechanic) {
            return null;
          }

          return {
            pressAgain: true,
            actionDisplayName: state.steps[3].actions[0].data.Data !== "" ? "Προβολή Πρόσθετων Εργασιών" : "Προσθήκη Εργασιών",
            isVisible: UserRoleHelper.isMechanic(AuthService.getCurrentUser()) && state.instance.Status == 5, 
          }
        },
        stepActionEditWorkVoucher: (state, actionId) => {
          if(actionId !== WorkflowStepActionIdTypes.flow_2_step_5_stepActionEditWorkVoucher) {
            return null;
          }

          return {
            pressAgain: UserRoleHelper.isMechanic(AuthService.getCurrentUser()) && state.instance.Status == 5,
            isVisible: UserRoleHelper.isMechanic(AuthService.getCurrentUser()), 
          }
        }
      }
    },
    flow3: {
      step1: {
        stepActionExtras: (state, actionId) => {
          if (actionId !== WorkflowStepActionIdTypes.flow_3_step1_WorkExtras) {
            return null;
          }

          let cache = OverrideRules._onMountRules.flow3.step1.onLoad.cache;
          let cache_flow2 = OverrideRules._cache.flow2.step1;
          let cache_flow3 = OverrideRules._cache.flow3.step1;

          let old_cache = {
            // isEnabled:
            //   true,
            columnMetaDataDefinitions:
              cache_flow2.workVoucherColumnMetaDataDefinitions,
            customButtonActionInfo:
              cache_flow3.workVoucherCustomSearchAddRowButtonInfo,
            showAddInlineRowButton: cache.workVoucherShowAddInlineRowButton,
          };

          return {
            ...cache,
            ...old_cache,
          };
        },
        stepActionExtrasSumProductFinalPrice: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_3_step_1_stepActionExtrasSumProductFinalPrice
            )
          ) {
            return null;
          }

          let cache = OverrideRules._cache.flow3.step1;
          
          return {
            value: cache.productFinalPriceSum ?? null,
          };
        },
        stepActionExtrasSumVatProductFinalPrice: (state, actionId) => {
          if (
            !OverrideRules._common.IsStep(
              actionId,
              WorkflowStepActionIdTypes.flow_3_step_1_stepActionExtrasSumVatProductFinalPrice
            )
          ) {
            return null;
          }

          let cache = OverrideRules._cache.flow3.step1;
          return {
            value: cache.productFinalPriceSumVat ?? null,
          };
        },
      },
      step2: {
        stepActionAcceptExtras: (state, actionId) => {
          if (
            actionId !== WorkflowStepActionIdTypes.flow_3_step_2_approve_extras
          ) {
            return null;
          }

          return {
            isEnabled: UserRoleHelper.isMechanic(AuthService.getCurrentUser()) || UserRoleHelper.isMaster(AuthService.getCurrentUser()),
            pressAgain: ((UserRoleHelper.isMechanic(AuthService.getCurrentUser()) || UserRoleHelper.isMaster(AuthService.getCurrentUser())) && (state.instance.Status == 2 && !state.instance.Completed)),
            isVisible: (state.instance.Status >= 2)
          };
        },
        stepActionDeclineExtras: (state, actionId) => {
          if (
            actionId !== WorkflowStepActionIdTypes.flow_3_step_2_decline_extras
          ) {
            return null;
          }

          return {
            isEnabled: UserRoleHelper.isMechanic(AuthService.getCurrentUser()) || UserRoleHelper.isMaster(AuthService.getCurrentUser()),
            pressAgain: ((UserRoleHelper.isMechanic(AuthService.getCurrentUser()) || UserRoleHelper.isMaster(AuthService.getCurrentUser())) && !state.instance.Completed),
            isVisible: (
              // (UserRoleHelper.isMechanic(AuthService.getCurrentUser()) || UserRoleHelper.isMaster(AuthService.getCurrentUser())) && 
              state.instance.Status == 2)
          };
        },
        stepActionEditExtras: (state, actionId) => {
          if (
            actionId !== WorkflowStepActionIdTypes.flow_3_step_2_edit_extras
          ) {
            return null;
          }

          return {
            isEnabled: UserRoleHelper.isMechanic(AuthService.getCurrentUser()) || UserRoleHelper.isMaster(AuthService.getCurrentUser()),
            pressAgain: ((UserRoleHelper.isMechanic(AuthService.getCurrentUser()) || UserRoleHelper.isMaster(AuthService.getCurrentUser())) && state.instance.Status == 2),
            isVisible: ((UserRoleHelper.isMechanic(AuthService.getCurrentUser()) || UserRoleHelper.isMaster(AuthService.getCurrentUser())) && (state.instance.Status == 2 && !state.instance.Completed))
          };
        },
      },
      step3: {
        stepActionCompleteExtras: (state, actionId) => {

          if (
            actionId !== WorkflowStepActionIdTypes.flow_3_step_3_stepActionCompleteExtras
          ) {
            return null;
          }

          return {
            isEnabled: true,
            pressAgain: ((UserRoleHelper.isContractor(AuthService.getCurrentUser()) || UserRoleHelper.isMaster(AuthService.getCurrentUser())) && state.instance.Status == 2) ? true : null,
            isVisible: (UserRoleHelper.isContractor(AuthService.getCurrentUser()) || UserRoleHelper.isMaster(AuthService.getCurrentUser()) && state.instance.Status >= 2) || state.instance.Status.Completed,
          }
        }
      },
    },
  },
  _onMountRules: {
    flow2: {
      step1: {
        onLoad: {
          executeAsync: async (
            self,
            state,
            workflowId,
            workflowStepId,
            workflowInstanceId
          ) => {
            if (
              workflowStepId !== WorkflowStepIdTypes.flow2_step_1_work_voucher
            ) {
              return;
            }
            await OverrideRules._common.SetCalculatedGridColumnCache(
              self.state.steps
            );
            self.setState({ steps: self.state.steps });
          },
        },
      },
    },
    flow3: {
      step1: {
        onLoad: {
          cache: {
            workflowInstanceId: null,
            voucherDataResult: null,
            isEnabled: null,
            selectedContrator: null,
            columnMetaDataDefinitions: [],
          },
          executeAsync: async (
            self,
            state,
            workflowId,
            workflowStepId,
            workflowInstanceId
          ) => {
            if (
              workflowStepId !== WorkflowStepIdTypes.flow3_step_1_extra_work
            ) {
              return;
            }
            let voucherDataResult =
              await WorkflowService.Extentions.AspraSpitia.getWorkflowInfoByExtraWorkflowInstanceIdAsync(
                workflowInstanceId
              );

            // console.log(voucherDataResult);

            let stepActionExtrasTable =
              OverrideRules._getStepActionByStepActionId(
                state.steps,
                WorkflowStepActionIdTypes.flow_3_step1_WorkExtras
              );
            // console.log(stepActionExtrasTable);

            let columnMetaDataDefinitions =
              EVOReactTableDynamicGridHelper.DynamicAction.getGridDefinitionByColumnstepActionDataValue(
                stepActionExtrasTable.action
              );
            // console.log(columnMetaDataDefinitions);

            let res =
              await OverrideRules._common.SetCommonColumnMetadataDefinitionsGridMaterialErgolavoiProductPricesAsync(
                columnMetaDataDefinitions,
                parseInt(voucherDataResult.contractorId)
              );

            // console.log(res);

            OverrideRules._onMountRules.flow3.step1.onLoad.cache = {
              workflowInstanceId: workflowInstanceId,
              voucherDataResult: voucherDataResult,
              isEnabled: voucherDataResult.contractorId != null,
              selectedContrator: voucherDataResult.contractorId,
              columnMetaDataDefinitions: columnMetaDataDefinitions,
            };
            self.setState({ steps: self.state.steps });
          },
        },
      },
    },
  },
  _isStepperEnabledForWorflowId(workflowId) {
    if (workflowId == WorkflowIdTypes.FAULTS) {
      return true;
    }
    return false;
  },
  _cache: {
    flow2: {
      step1: {
        isEnabled: null,
        contractorsOptions: [],
        selectedContractorDataValue: null,
        selectedContractorData: null,
        workVoucherColumnMetaDataDefinitions: [],
        selectedWorkCategoryType: null,
        workVoucherCustomSearchAddRowButtonInfo: {
          //dynamic grid view search pop up item insert
          title: "+ Προσθήκη",
          color: "success",
          //callback: ()=>{isVisible=true},
          rowAdder: {
            title: "Τιμοκατάλογος Υλικών Sap",
            gridView: {
              Code: "102",
              InternalDescription: "faults main page admin",
              MetaData: {
                GridMetadata: {
                  GridType: "MDBDataTable",
                  Columns: [
                    {
                      id: "__action",
                      title: "Περισσότερα",
                      type: "action",
                      style: { maxWidth: "100px" },
                      customActions: [
                        {
                          title: "Προσθήκη",
                          color: "primary",
                          bindField: "wppeSapId",
                          isVisible: true,
                          callbackMethodName:
                            "onCustomActionRowSelectionCallback",
                        },
                      ],
                    },
                    {
                      id: "wppeSapId",
                      title: "Άρθρο SAP",
                      footerCalculationType: "count",
                      hasFilter: true,
                    },
                    {
                      id: "__productQA",
                      title: "Μονάδα Μέτρησης",
                      hasStyleBadge: true,
                    },
                    {
                      id: "__productDescription",
                      title: "Περιγραφή Εργασίας",
                      hasFilter: true,
                    },
                    {
                      id: "__productCategoryType",
                      title: "Κατηγορία",
                      hasFilter: true,
                    },
                    {
                      id: "__productPrice",
                      title: "Τιμή",
                      hasFilter: true,
                    },
                    {
                      id: "__productTechnDescr",
                      title: "Περιγραφή Τεχνική",
                      style: {
                        minWidth: "50px",
                        maxWidth: "9999px",
                        overflowX: "visisble",
                      },
                    },
                  ],
                },
              },
              Description: "Παρακαλώ πολυ επιλέξτε το Υλικό προς Προσθήκη",
              //  Title: "Λίστα Αιτημάτων",
            },
            getNewRowValue: (initialInput) => {
              //needs refactor to read custom parameterization
              const filteredObjectWithoutReactElements = Object.keys(
                initialInput
              )
                .filter(
                  (key) =>
                    key.startsWith("__column____") == false && key != "__action"
                )
                .reduce((obj, key) => {
                  obj[key] = initialInput[key];
                  return obj;
                }, {});
              let input = Clone(filteredObjectWithoutReactElements);
              let data = {
                __productCode: input.__productCode,
                __productFinalPrice: input.__productPrice,
                __productPrice: input.__productPrice,
                __productQA: input.__productQA,
                __productQuantity: 1,
                __productTechnDescr: input.__productTechnDescr,
                __productCategoryType: {
                  value: input.wvpCatDesc,
                  label: input.wvpCatDesc,
                  item: {
                    value: input.wvpCatDesc,
                    label: input.wvpCatDesc,
                  },
                },
                __productDescription: {
                  ...input,
                  item: { ...input },
                  value: input.wppeSapId,
                  label: input.wvpDescription,
                },
              };
              return data;
            },
          },
        },
        workVoucherShowAddInlineRowButton: false,
        productFinalPriceSum: 0,
        productFinalPriceSumVat: 0,
      },
    },
    flow3: {
      step1: {
        selectedWorkCategoryType: null,
        workVoucherCustomSearchAddRowButtonInfo: {
          //dynamic grid view search pop up item insert
          title: "+ Προσθήκη",
          color: "success",
          //callback: ()=>{isVisible=true},
          rowAdder: {
            title: "Τιμοκατάλογος Υλικών Sap",
            gridView: {
              Code: "102",
              InternalDescription: "faults main page admin",
              MetaData: {
                GridMetadata: {
                  GridType: "MDBDataTable",
                  Columns: [
                    {
                      id: "__action",
                      title: "Περισσότερα",
                      type: "action",
                      style: { maxWidth: "100px" },
                      customActions: [
                        {
                          title: "Προσθήκη",
                          color: "primary",
                          bindField: "wppeSapId",
                          isVisible: true,
                          callbackMethodName:
                            "onCustomActionRowSelectionCallback",
                        },
                      ],
                    },
                    {
                      id: "wppeSapId",
                      title: "Άρθρο SAP",
                      footerCalculationType: "count",
                      hasFilter: true,
                    },
                    {
                      id: "__productQA",
                      title: "Μονάδα Μέτρησης",
                      hasStyleBadge: true,
                    },
                    {
                      id: "__productDescription",
                      title: "Περιγραφή Εργασίας",
                      hasFilter: true,
                    },
                    {
                      id: "__productCategoryType",
                      title: "Κατηγορία",
                      hasFilter: true,
                    },
                    {
                      id: "__productPrice",
                      title: "Τιμή",
                      hasFilter: true,
                    },
                    {
                      id: "__productTechnDescr",
                      title: "Περιγραφή Τεχνική",
                      style: {
                        minWidth: "50px",
                        maxWidth: "9999px",
                        overflowX: "visisble",
                      },
                    },
                  ],
                },
              },
              Description: "Παρακαλώ πολυ επιλέξτε το Υλικό προς Προσθήκη",
              //  Title: "Λίστα Αιτημάτων",
            },
            getNewRowValue: (initialInput) => {
              //needs refactor to read custom parameterization
              const filteredObjectWithoutReactElements = Object.keys(
                initialInput
              )
                .filter(
                  (key) =>
                    key.startsWith("__column____") == false && key != "__action"
                )
                .reduce((obj, key) => {
                  obj[key] = initialInput[key];
                  return obj;
                }, {});
              let input = Clone(filteredObjectWithoutReactElements);
              let data = {
                __productCode: input.__productCode,
                __productFinalPrice: input.__productPrice,
                __productPrice: input.__productPrice,
                __productQA: input.__productQA,
                __productQuantity: 1,
                __productTechnDescr: input.__productTechnDescr,
                __productCategoryType: {
                  value: input.wvpCatDesc,
                  label: input.wvpCatDesc,
                  item: {
                    value: input.wvpCatDesc,
                    label: input.wvpCatDesc,
                  },
                },
                __productDescription: {
                  ...input,
                  item: { ...input },
                  value: input.wppeSapId,
                  label: input.wvpDescription,
                },
              };
              return data;
            },
          },
        },
        workVoucherShowAddInlineRowButton: false,
        productFinalPriceSum: 0,
        productFinalPriceSumVat: 0,
      },
    },
  },
  _getLayoutParametersAsync: async (workflowInstanceId, workflowId) => {
    let layoutParameters = {
      isOverrideLayout: false,
      isTabVisible: false,
      tabsLayout: [],
    };

    let parentInstanceIdResult =
      await ApiCallerHelper.Instances.getParentInstanceIdAsync(
        workflowInstanceId
      );
    let parentId = parentInstanceIdResult.data;
    if (parentId) {
      layoutParameters.tabsLayout = [
        {
          title: "Στοιχεία Βλάβης",
          workflowInstanceId: parentId,
          workflowStepStatus: 1,
        },
      ];
    }

    if (
      workflowId == WorkflowIdTypes.VOUCHERS ||
      workflowId == WorkflowIdTypes.EXTRA
    ) {
      layoutParameters.isOverrideLayout = true;
      layoutParameters.isTabVisible = true;
      layoutParameters.tabsLayout = [
        {
          title: "Δελτίο Εργασίας",
          isDefaultRender: true,
        },
      ].concat(layoutParameters.tabsLayout);
    }

    if (workflowId == WorkflowIdTypes.EXTRA) {
      layoutParameters.tabsLayout = [
        {
          title: "Επιπρόσθετες Εργασίας",
          isDefaultRender: true,
        },
      ].concat(layoutParameters.tabsLayout);
    }

    // console.log(layoutParameters);
    return layoutParameters;
  },
};

export default OverrideRules;
