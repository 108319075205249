import {
    get, getAsync,
    post, postAsync, postFileAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    getFileAsync,
  } from "./apiCallerHelper.core";
  
  const ROUTE_PATH = "/api/files"

  export async function getFileStatusByWorkflowInstanceIdAsync(workflowInstanceId) {
    var route = `${ROUTE_PATH}/status/${workflowInstanceId}/`;
    return getAsync(route);
  }

  // export async function addNewFileAsync(file){
  //   var route = `${ROUTE_PATH}/new/`;
  //   return postFileAsync(route, file);
  // }

  // export async function registerNewFileAsync(
  //   original_name,
  //   new_name,
  //   size,
  //   type,
  //   instanceId,
  //   stepId,
  //   actionId    
  //   ) {      
  //   var route = "/api/files/register/";
  //   let body = {
  //     OriginalName : original_name,
  //     NewName : new_name,
  //     Size : size,
  //     Type : type,
  //     InstanceId : instanceId,
  //     StepId : stepId,
  //     ActionId : actionId
  //   }   
  //   return postAsync(route, body);
  // }

  export async function uploadFile({file, instanceId, stepId, actionId}){    
    var route = `${ROUTE_PATH}/new/`;

    const formData = new FormData();
    formData.append(
      "file",
      file,
      file.name
    );
    formData.append("instance_id", instanceId);
    formData.append("step_id", stepId);
    formData.append("action_id", actionId);

    return postFileAsync(route, formData);
  }

  export async function deleteFileAsync(fileId) {    
    var route = `${ROUTE_PATH}/${fileId}/`;
    return deleteAsync(route);
  }

  export async function getAllFilesByWorkflowInstanceIdAsync(workflowInstanceId) {    
    var route = `${ROUTE_PATH}/instance/${workflowInstanceId}/`;
    return getAsync(route);
  }

  export async function getFileURLByIdAsync(fileId) {
    var route = `${ROUTE_PATH}/presigned-url/${fileId}/`;    
    return getAsync(route);
  }

  export async function getFile(fileId) {    
    var route = `${ROUTE_PATH}/get_file/${fileId}/`;
    return getFileAsync(route, fileId);
  }

