
import React, { useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal';
import DynamicGridView from  "../DynamicPages/DynamicGridView/dynamicGridView";
import {
    Row,
    Col
  } from "reactstrap";
const DynamicReactTableRowAdder = ({
  dynamicGridId,
  isShown, 
  onHideModal, 
  onRowSelectionCallback,
  title,
  dataSource,
  gridView
  }) => {  
    dynamicGridId = dynamicGridId || "DynamicReactTableRowAdder";
    title = title || "Πίνακας";

    return (
        <Modal
          show={isShown}
          onHide={() => onHideModal(false)}          
          //dialogClassName="modal-lg"
          dialogClassName="tool-theme_modal-dialog--large-90"
          aria-labelledby="example-custom-modal-styling-title"
          // style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
                {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>               
              <Row>
                <Col md="12">
                <DynamicGridView
                  key = {"ProductPicker"}
                  dynamicGridId = {"ProductPicker"}
                  //gridViewId={gridViewGroup.GridViewGroupCodeItem.Id}
                  customActionsCallbackMethods =
                  {{
                    onCustomActionRowSelectionCallback : value => {onRowSelectionCallback(value);}                    
                  }}
                  dataSource = {dataSource}
                  gridView = {gridView}    
                />     
                </Col>              
              </Row>            
          </Modal.Body>
          <Modal.Footer>
            <div className="col text-right">
              {/* <Button color="success" size="md" form={_formId}>
                Αποθήκευση
              </Button> */}
            </div>
          </Modal.Footer>
        </Modal>
      );   

}

export default DynamicReactTableRowAdder