const KNOWN_TABLES = {
  TEXNIKOS: {
    EISERXOMENA_LISTA_AITIMATWN_TEXNIKOS:
      "8094935a-242a-4044-b789-dfd5570e30a5",
    DELTIA_SE_EPEKSERGASIA_TEXNIKOS: "dccb8a9e-ca98-4fa7-8c42-d64d5c948150",
    PROSTHETES_ERGASIES_APO_ERGOLAVO_TEXNIKOS:
      "7562fd70-1dcd-4bfe-a4ae-efd1ca79a66e",
    PARADOSI_PARALAVI_ERGOU_TEXNIKOS: "7562fd70-1dcd-4bfe-a4ae-efd1ca79a66a",
  },
  ENOIKOS: {
    LISTA_AITIMATWN_ENOIKOS: "c1983fe1-5584-44c1-aecf-486692e0766c"
  }
};

const _is_any_of_datatables_mechanic = (gridViewId) => {
  const mechanicTableIds = Object.values(KNOWN_TABLES.TEXNIKOS);
  return mechanicTableIds.includes(gridViewId);
};

const add_delete_operation_to_all_mechanic_datatables = (
  current_user,
  gridViewId,
  data
) => {
  if (!_is_any_of_datatables_mechanic(gridViewId)) return data;

  data.data.datasource.forEach((row) => {
    if (row.ExternalUserId == null) {
      // row.__action__ = `${current_user.DisplayName}`;
    }
  });
  return data;
};

const texnikos_lista_eiserxomenwn_aitimatwn = (
  current_user,
  gridViewId,
  data
) => {
  if (
    gridViewId != KNOWN_TABLES.TEXNIKOS.EISERXOMENA_LISTA_AITIMATWN_TEXNIKOS
  ) {
    return data;
  }
  data.data.datasource.forEach((row) => {
    if (row.ExternalUserId == null) {
      row.ExternalUserId = `${current_user.DisplayName}`;
    }
  });

  return data;
};

const enoikos_lista_aitimatwn = (
  current_user,
  gridViewId,
  data
) => {
  if (
    gridViewId != KNOWN_TABLES.ENOIKOS.LISTA_AITIMATWN_ENOIKOS
  ) {
    return data;
  }
  data.data.datasource.forEach((row) => {
    if (row.CalculatedStatus == "000" && row.wfiChildVoucher_SerialNumber == null && row.wfiParent_Status == 3) {
      row.CalculatedStatus = "006";
      row.calculatedFaultStatusDescription = "Προς Αξιολόγηση";
      row.calculatedFaultStatusDescription_a = "Προς Αξιολόγηση";
    }
  });

  return data;
}

const rules_to_apply = [
  add_delete_operation_to_all_mechanic_datatables,
  texnikos_lista_eiserxomenwn_aitimatwn,
  enoikos_lista_aitimatwn
];

const get_modified_data = (current_user, gridViewId, data) => {
  for (const rule of rules_to_apply) {
    data = rule(current_user, gridViewId, data);
  }
  return data;
};

export const GRID_HARDCODED_RULES = {
  get_modified_data,
};
