import React, { useState, useEffect, Fragment } from "react";
import ApiCallerHelper from "../../../api/apiCallerHelper";
import AuthService from "../../../common/AuthService";
import RedirectHelper from "../../../common/redirectHelper";
import EvoActionsDynamicGridComponent from "../../DynamicGridComponent/EvoActionsDynamicGridComponent";
import DynamicGridViewComponentColumnDefinitions from "./DynamicGridViewComponent.ColumnDefinitions";
import { Clone } from "../../../common/utils";
import EVOMDBDataTableHelper from "../../../components/common/evo.mdbDatatable-helper";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from "reactstrap";
import { current } from "@reduxjs/toolkit";
import { GRID_HARDCODED_RULES } from "./gridHardcodedRules";

const DynamicGridView = ({
  gridViewId, //optional : used to fetch data from api else use direct dataSource or Gridview objects instead
  dataSource, //optional :datasource array of rows with data
  gridView, //optional : similar gridview object that contains any
  dynamicGridId,
  filter,
  onFetchGridViewData,
  customActionsCallbackMethods,
  customApiArguments,
}) => {
  dynamicGridId = dynamicGridId || gridViewId;
  const [_gridViewData, setGridViewData] = React.useState({});
  const [_gridDatasource, setGridDatasource] = React.useState({
    columns: [],
    rows: [],
  });
  const current_user_group = AuthService.getCurrentUserGroup();
  const current_user = AuthService.getCurrentUser();

  //FETCH DATA FROM API
  useEffect(() => {
    (async () => {
      let result = {
        data: {
          datasource: dataSource,
          gridView: gridView,
        },
      };
      if (gridViewId) {
        result = await ApiCallerHelper.Views.getGridViewDataById(
          gridViewId,
          customApiArguments
        );
      }

      if (onFetchGridViewData) {
        onFetchGridViewData(result);
      }

      result = GRID_HARDCODED_RULES.get_modified_data(
        current_user,
        gridViewId,
        result
      );

      setGridViewData(result.data);
    })();
  }, [gridViewId, dynamicGridId, dataSource, gridView]);

  useEffect(() => {
    let gridDatasource = getGridDatasource(_gridViewData, filter);

    setGridDatasource(gridDatasource); // Update state with the new object
  }, [_gridViewData, filter]);

  //Initial Validation Logic
  if (!AuthService.getCurrentUser()) {
    RedirectHelper.RedirectTo.logout();
    return <></>;
  }

  //APPLY CUSTOM FILTERS IF ANY
  const _applyCustomFilters = (datasource, filters) => {
    let filteredRows = datasource;
    if (datasource && (filters?.filter ?? null)) {
      filteredRows = datasource.filter(filters.filter);
    }
    return filteredRows;
  };

  const _applyCustomDynamicFilters = (datasource, dynamicFilters) => {
    let filteredRows = datasource.filter((row) => {
      if (!dynamicFilters || dynamicFilters.length === 0) {
        return true;
      }
      let assertResults = dynamicFilters.map((filter) => {
        return row[filter.fieldName] === filter.value;
      });
      return !assertResults.includes(false);
    });
    return filteredRows;
  };

  const getGridDatasource = (gridViewData, filters, dynamicFilters) => {
    let datasource = Clone(gridViewData?.datasource ?? []);
    let filteredRows = _applyCustomFilters(datasource, filters);
    filteredRows = _applyCustomDynamicFilters(filteredRows, dynamicFilters);
    let columnMetadataDefinitions =
      gridViewData.gridView?.MetaData?.GridMetadata?.Columns ?? [];
    let gridType =
      gridViewData.gridView?.MetaData?.GridMetadata?.GridType ?? null;
    let gridDatasource =
      DynamicGridViewComponentColumnDefinitions.getGridDatasource(
        filteredRows,
        columnMetadataDefinitions,
        gridType,
        customActionsCallbackMethods
      );
    return gridDatasource;
  };

  const GridTitle = ({ text }) => {
    let showValue = (text ?? "").length > 0;
    if (showValue) {
      return <h2>{text}</h2>;
    }
    return <Fragment />;
  };

  const GridDescription = ({ text }) => {
    let showValue = (text ?? "").length > 0;
    if (showValue) {
      return <h4 className="mb-2 text-muted">{text}</h4>;
    }
    return <Fragment />;
  };

  const onDynamicFiltersChange = (dynamicFilters) => {
    let gridDatasource = getGridDatasource(
      _gridViewData,
      filter,
      dynamicFilters
    );
    setGridDatasource(gridDatasource);
  };

  return (
    <Fragment>
      <GridTitle text={_gridViewData?.gridView?.Title} />
      {/* <GridDescription text={_gridViewData?.gridView?.Description} /> */}
      {_gridDatasource.gridType ==
        DynamicGridViewComponentColumnDefinitions.GridType
          .EvoActionsDynamicGridComponent && (
        <Fragment>
          <EvoActionsDynamicGridComponent
            id={dynamicGridId}
            dataset={_gridDatasource.rows}
            isEditable={false}
            columnMetadataDefinitions={
              _gridDatasource.columnMetadataDefinitions
            }
            onCustomCallbacks={"asdasdasdasd"}
          />
        </Fragment>
      )}
      {_gridDatasource.gridType ==
        DynamicGridViewComponentColumnDefinitions.GridType.MDBDataTable && (
        <div>
          {" "}
          {/* dom manipulation do not remove div */}
          <EVOMDBDataTableHelper.GridFilter.GridFilterForFunctionalComponent
            id={dynamicGridId}
            datasource={Clone(_gridViewData?.datasource ?? [])}
            onFiltersChanged={onDynamicFiltersChange}
            columnDefinitions={Clone(
              _gridViewData?.gridView?.MetaData?.GridMetadata?.Columns ?? []
            )}
          />
          <EVOMDBDataTableHelper.MDBDataTable
            className={dynamicGridId}
            id={dynamicGridId}
            barReverse={true}
            data={_gridDatasource}
            columns={_gridDatasource?.columns ?? []}
            //data={_gridDatasource}
          />
        </div>
      )}
    </Fragment>
  );
};

export default DynamicGridView;
