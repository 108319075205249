import {
  React,
  Fragment,
  useState,
  useContext,
  createContext,
  useEffect,
  useRef
} from "react";
import { DynamicStepActionTypes } from "../../common/enums/dynamicStepActionTypes.enum";
import "moment/locale/el";
import EvoInput from "../../components/common/evo.Input";
import DynamicActionLabel from "./DynamicActionLabel";
import { OverrideRules } from "../../views/instances/custom/custom.InstanceBase.overrides.aspra-spitia";
import AuthService from "common/AuthService";
import { WorkflowStepActionIdTypes } from "common/enums/workflowStepActionIdTypes.enum";
import ApiCallerHelper from "api/apiCallerHelper";

export const DynamicInput = ({
  self,
  isEnabled,
  dynamicActions,
  stepId,
  isShowlabel,
}) => {
  let ovverideRule = OverrideRules.getOnRenderControlOverrideRule(
    self.state,
    dynamicActions.action.Id
  );
  const current_user_group = AuthService.getCurrentUserGroup();
  const [isVisible, setIsVisible] = useState(true);
  const renderCountRef = useRef(0); // ref to keep track of render count

  useEffect(() => {
    // Increment the render count
    renderCountRef.current++;

    // Check if the render count is greater than 15
    if (renderCountRef.current > 15) {
      console.log("Render count limit exceeded.");
      return; // Exit the effect if re-render count is above 15
    }

    // Your existing logic
    if (
      !(
        dynamicActions.action.Type === DynamicStepActionTypes.number ||
        dynamicActions.action.Type === DynamicStepActionTypes.text ||
        dynamicActions.action.Type === DynamicStepActionTypes.textarea ||
        dynamicActions.action.Type === DynamicStepActionTypes.checkbox ||
        (dynamicActions.action.Type === DynamicStepActionTypes.dateReadonly &&
          isEnabled === false)
      )
    ) {
      return;
    }

    if (ovverideRule) {
      if (ovverideRule.value !== undefined) {
        dynamicActions.data.Data = ovverideRule.value;
      }
      if (ovverideRule.isVisible !== undefined) {
        setIsVisible(ovverideRule.isVisible ?? true);
      }
    }

    // You might want to reset the render count ref under certain conditions
    // renderCountRef.current = 0;
  }); // Dependencies array is omitted, so this effect runs after every render by default

  if (isVisible === false) {
    return <></>;
  }

  isShowlabel = isShowlabel || false;

  if (
    !(
      dynamicActions.action.Type === DynamicStepActionTypes.number ||
      dynamicActions.action.Type === DynamicStepActionTypes.text ||
      dynamicActions.action.Type === DynamicStepActionTypes.textarea ||
      dynamicActions.action.Type === DynamicStepActionTypes.checkbox ||
      (dynamicActions.action.Type === DynamicStepActionTypes.dateReadonly &&
        isEnabled == false)
    )
  ) {
    return <Fragment></Fragment>;
  }

  const isTemporarySaved =
    self.state.instance.Status == 1 &&
    self.state.instance.WorkflowId == "ed7fb92a-c93e-47fc-9e4b-1a95ab731e1c";

  const commonFields = {
    className: "form-control-alternative",
    id: stepId + "/" + dynamicActions.action.Id,
    placeholder: dynamicActions.action.Description,
    type: dynamicActions.action.Type,
    disabled: isTemporarySaved ? false : !isEnabled,
    metaData: dynamicActions.action.Metadata,
    onChange: (evt) =>
      self.updateInputValue(
        isTemporarySaved ? true : isEnabled,
        evt.target.value,
        stepId,
        dynamicActions.action.Id
      ),
  };

  if (
    dynamicActions.action.Id ===
    WorkflowStepActionIdTypes.flow_1_step_1_stepActionFaultPublicSubCategoriesOther
  ) {
    if (ovverideRule.isEnabled) {
      commonFields.disabled = false;
      isEnabled = false;
    } else {
      commonFields.disabled = true;
      isEnabled = true;
    }
  }

  // if(dynamicActions.action.Id === "bbb9afd2-61b9-4a58-839c-03d5b407ee86"){
  //   commonFields.disabled = isEnabled;
  // }
  let hasOvverideRuleValue = ovverideRule && ovverideRule.value !== undefined;
  if (hasOvverideRuleValue) {
    commonFields.value = dynamicActions?.data?.Data ?? "";
  } else {
    commonFields.defaultValue = dynamicActions.data.Data;
  }

  if (
    current_user_group == "Mechanic" &&
    dynamicActions.action.Id ==
      WorkflowStepActionIdTypes.flow_1_step_1_stepActionAddress
  ) {
    commonFields.disabled = false;
    let value = dynamicActions?.data?.Data ?? "Διεύθυνση Οικίας";

    commonFields.value = value;
    commonFields.disabled = true;
  }

  if (
    current_user_group == "Mechanic" &&
    dynamicActions.action.Id ==
      WorkflowStepActionIdTypes.flow_1_step_1_stepActionExternalHouseId
  ) {
    if (!isEnabled) setIsVisible(false);
    commonFields.onChange = async (evt) => {
      self.updateInputValue(
        isEnabled,
        evt.target.value,
        stepId,
        dynamicActions.action.Id
      );

      let res = await ApiCallerHelper.External.getHouseInfoById(
        evt.target.value
      );

      let house = null;

      if (res.length > 0) {
        house = res[0];

        self.updateInputValue(
          isEnabled,
          `${house.hAddrstreet} ${parseInt(house.hAddrnumber)}`,
          stepId,
          WorkflowStepActionIdTypes.flow_1_step_1_stepActionAddress
        );
      }
    };
  } else if(dynamicActions.action.Id == WorkflowStepActionIdTypes.flow_1_step_1_stepActionExternalHouseId) {
    self.updateInputValue(
      isEnabled,
      self.state.base.loggedUser.ExternalUserId,
      stepId,
      dynamicActions.action.Id
    );
  }

  return (
    <Fragment>
      {isShowlabel == true && (
        <Fragment>
          <DynamicActionLabel
            title={dynamicActions.action.Name}
            description={dynamicActions.action.Description}
          />
          <br></br>
        </Fragment>
      )}
      <EvoInput.Input {...commonFields} />
    </Fragment>
  );
};
