import React, { useState } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import "moment/locale/el";
import { ApiCallerHelper } from "../../api/apiCallerHelper";
import InstanceVoucher from "./instanceVoucher";

//RENDERS A SPECIFIC STEP
class InstanceExistingSpecificFlowStepVoucher extends InstanceVoucher {
  _workflowInstanceId = null;
  _statusToShow = null;

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
    this._workflowInstanceId = props.workflowInstanceId;
    this._statusToShow = 1;
  }

  componentDidMount() {
    super.componentDidMount(this.__initializeComponent);

    // console.log(this.props.workflowInstanceId);
  }

  __initializeComponent = async () => {
    if (!this._workflowInstanceId) {
      return;
    }
    let workflowInstanceId = this._workflowInstanceId;
    let instanceResult = await ApiCallerHelper.Instances.getInstanceAsync(
      workflowInstanceId
    );
    // console.log(instanceResult);
    let fileStatusResult =
      await ApiCallerHelper.Files.getFileStatusByWorkflowInstanceIdAsync(
        workflowInstanceId
      );
    this.setState(
      {
        loading: false,
        instance: instanceResult.data.instance,
        workflow: instanceResult.data.workflow,
        user: instanceResult.data.user,
        logged_user: instanceResult.data.logged_user,
        steps: instanceResult.data.stepsInfused,
        fileUpload: null,
        files: fileStatusResult.data,
      },
      async () => {
        let workflowId = instanceResult.data.instance.WorkflowId;
        let status = instanceResult.data.instance.Status;
        await this.ExecuteOverridesOnMountAsync(
          this,
          this.state,
          workflowId,
          status,
          workflowInstanceId
        );
      }
    );
  };

  _RenderHelper = {
    RenderStepStatus: () => {
      if (!this.state.steps || this.state.steps.length === 0) {
        return <></>;
      }

      let step = this.state.steps.filter(
        (x) => x.step.Status == this._statusToShow // && x.step.WorkflowId === WorkflowIdTypes.FAULTS
      )[0];


      return (
        <Card className="bg-secondary shadow">
          {/* <CardHeader className="bg-white border-0">
                  {this._RenderHelper.RenderStepsHeader(faultStep, 1)}
                </CardHeader> */}
          <CardBody>{this.RenderHelper.RenderStepBody(step, true)}</CardBody>
        </Card>
      );
    },
  };

  render() {
    if (!this.Base.Security.isUserLogged()) {
      return <>{/* <GenericHeader /> */}</>;
    }

    return <>{this._RenderHelper.RenderStepStatus()}</>;
  }
}
export default InstanceExistingSpecificFlowStepVoucher;
