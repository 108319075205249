import { ApiCallerHelper } from "../api/apiCallerHelper";
import { UserGroupTypes } from "../common/enums/userGroupTypes.enum"
import { Clone, Sort } from "../common/utils"
import EVOAlertComponent from "../components/common/EvoAlertComponent";

export const UsersService = {  
  _initCache : async (forceReset) => {  
      if (forceReset || UsersService._cache.allUsers === null){
      let result = await ApiCallerHelper.Users.getAllUsers();         
      UsersService._cache.allUsers = result?.data??null;
    }

    if (forceReset || UsersService._cache.allRoles === null){
      let result = await ApiCallerHelper.Roles.getAllRoles();      
      UsersService._cache.allRoles = result?.data??null;      
    }      
  },
  _cache : {      
    allUsers : null,
    allRoles : null,
  },
  Factory : {
    GetNewUser : (
      {
        Id,
        Email,
        DisplayName,
        PhoneNumber,
        UserName,
        ExternalUserId,
        PersonRoleId,
        GroupIds,
        LocationId,
      }
    ) => {
      let user = {
        Id: Id,
        Email: Email,
        DisplayName: DisplayName,
        PhoneNumber: PhoneNumber,
        UserName: UserName,
        ExternalUserId: ExternalUserId,
        PersonRoleId: PersonRoleId,
        GroupIds: GroupIds,
        LocationId: LocationId,
      };
      return user;
    }
  },
  getAllUsers : () => {
    return Sort(Clone(UsersService._cache.allUsers??[]),"DisplayName");
  },
  getAllRoles : () => {
    return Clone(UsersService._cache.allRoles??[]);
  },
  getRoleByRoleName : (roleName) => {
    return UsersService.getAllRoles().find(x=>x.Name === roleName)??null;         
  },
  getUserById : (userId) => {  
    return UsersService.getAllUsers().find(x=>x.Id === userId)??null;      
  },
  getUserDisplayNameById : (userId) => {
    let allUsers = UsersService.getAllUsers();
    if (!allUsers) {return null;}
    return allUsers.find(x=>x.Id === userId)?.DisplayName??' - ';      
  },
  saveUserData : async(
    {
      Id,
      Email,
      DisplayName,
      PhoneNumber,
      UserName,
      ExternalUserId,
      PersonRoleId,
      GroupIds,
      LocationId,
    }) => {
      try {
        let editModel = UsersService.Factory.GetNewUser({
          Id: Id,
          Email: Email,
          DisplayName: DisplayName,
          PhoneNumber: PhoneNumber,
          UserName: UserName,
          ExternalUserId: ExternalUserId,
          PersonRoleId: PersonRoleId,
          GroupIds: GroupIds,
          LocationId: LocationId,
        });
        let result = null;
        if (editModel.Id != null) {
          result = await ApiCallerHelper.Users.editUser(editModel);
        } else {
          result = await ApiCallerHelper.Users.createNewUser(editModel);
        }
        if (result.isSuccess == false) {
          throw result.errorMessage;
        }
      } catch (error) {
        EVOAlertComponent.Alert({ message: error });
        return false;
      } finally {
        await UsersService._initCache(true);
      }      
      return true;        
  },
  getAllUsersContractor: () => {
    let allUsers = UsersService._cache.allUsers??[];    
    let groupedByUsers = allUsers.filter(x=>x.Groups.includes(UserGroupTypes.CONTRACTOR));    
    return groupedByUsers;
  },
  getUserPreviewInfo:(user) => {
    //console.log(user);
    return user.DisplayName;
  }
};


export default UsersService