//CONTAINS UNIQUE NAMES FOR ROUTES TO BE USED WITH route.js
export const RoutesTypes = {
    AUTH : 'AUTH',  
    BASIC_LAYOUT : 'BASIC_LAYOUT',    
    PARAMETERIZATION : 'PARAMETERIZATION',

    HOME: 'HOME',
    LOGIN : 'LOGIN',
    LOGOUT : 'LOGOUT',
    PASSWORD_CHANGE : 'PASSWORD_CHANGE',

    HISTORY : 'HISTORY',
    MONTHLY_VOUCHERS : 'MONTHLY_VOUCHERS',
    MONTHLY_PRINT : 'MONTHLY_PRINT',
    
    PROPERTIES : 'PROPERTIES',
    
    INSTANCE_NEW : 'Καταχώριση Νέας Αίτησης',
    INSTANCE : 'Αίτηση',
    INSTANCE_DETAILS : 'Στοιχεία Αίτησης',


    USERS : 'USERS',
    MAPS : 'MAPS',

    FLOWS : 'FLOWS',
    FLOW_EDIT : 'Επεξεργασία Ροής',
    FLOW_ACTION_STEP_EDIT : 'Επεξεργασία Βήματος',
    
    HEALTH_CHECK : 'HEALTH_CHECK',
    HEALTH_CHECK_CLONE : 'HEALTH_CHECK_CLONE',


    URI_TOKEN_LOGIN : '/uriTokenLogin/',
    URI_TOKEN_LOGIN_ARGS : '/uriTokenLogin/:uri_token',
           
    SIGNUP : 'SIGNUP',    

    SANDBOX : 'SANDBOX',   

    TEST : 'TEST',   
}

export default RoutesTypes;