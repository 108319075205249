import React, { Fragment, useState } from "react";
import { CommonLoading } from "react-loadingg";
import { coordinates } from "common/coordinates.js";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import Moment from "moment";
import "moment/locale/el";
import { v4 as uuidv4 } from "uuid";

// import ReportIssue from "./ReportIssue";

import { ValidationHelper } from "../../common/validationHelper";
import { UserRoleHelper } from "../../common/userRoleHelper";

import { OverrideRules } from "./custom/custom.InstanceBase.overrides.aspra-spitia";

import { DynamicReactTable } from "../../components/actionTypes/DynamicReactTable";
import { DynamicReactSelect } from "../../components/actionTypes/DynamicReactSelect";
import { DynamicFile } from "../../components/actionTypes/DynamicFile";
import { DynamicInput } from "../../components/actionTypes/DynamicInput";
import { DynamicMap } from "../../components/actionTypes/DynamicMap";
import { DynamicButton } from "../../components/actionTypes/DynamicButton";
import { DynamicRating } from "../../components/actionTypes/DynamicRating";
import { DynamicDateTimePicker } from "../../components/actionTypes/DynamicDateTimePicker";
import { MockData } from "../mock/evo.layout-helper.mock";
import { EVOLayoutHelper } from "../../common/evo.layout-helper";
import EvoBaseComponent from "../../components/common/EvoBaseComponent";
import EVOAlertComponent from "../../components/common/EvoAlertComponent";
import { ApiCallerHelper } from "../../api/apiCallerHelper";
import { RedirectHelper } from "../../common/redirectHelper";
import { WorkflowStepActionIdTypes } from "common/enums/workflowStepActionIdTypes.enum";
export const ViewTypeEnum = {
  NEW: "NEW",
  EXISTING: "EXISTING",
};

class InstanceBase extends EvoBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      steps: [],
      instance: [],
      workflow: [],
      isActionsVisible: true,

      files: [],

      workflows: [],
      selectedWorkflow: "ed7fb92a-c93e-47fc-9e4b-1a95ab731e1c",
      instanceId: uuidv4(),
      ...this.state,
    };
  }

  componentDidMount(setStateCallback) {
    super.componentDidMount(setStateCallback);
  }

  updateInputValue(isEnabled, value, stepId, actionId, metaData) {
    //console.log(isEnabled,value)
    if (isEnabled === null || isEnabled === false) {
      return;
    }
    let currentStep = this.state.steps.filter((x) => x.step.Id === stepId)[0];
    let currentAction = currentStep.actions.filter(
      (x) => x.action.Id === actionId
    )[0];
    if (currentAction) {
      if (
        (currentAction.action.Target === "limit14" && value.length > 14) ||
        (currentAction.action.Target === "limit9" && value.length > 9)
      ) {
        let newValue = value.substring(0, value.length - 1);
        value = newValue;
      }
      currentAction.data.Data = value;
      currentAction.data.MetaDataJson = JSON.stringify(metaData);
    }

    this.setState(
      {
        steps: this.state.steps,
      },
      () => {
        OverrideRules.onUpdateInputValue(
          this,
          this.state,
          isEnabled,
          actionId,
          value
        );
      }
    );
  }

  getIsValidStepAndSetWarning(stepInfo) {
    function hasValue(value) {
      if (value == undefined) {
        return false;
      }
      if (value == null) {
        return false;
      }
      if (value == "" || String(value).trim() == "") {
        return false;
      }
      return true;
    }

    function isWithinBounds(point, polygons) {
      //checks if a point is inside a polygon

      for (const polygonName in polygons) {
        const polygon = polygons[polygonName];
        let inside = false;
        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
          const xi = polygon[i][0];
          const yi = polygon[i][1];
          const xj = polygon[j][0];
          const yj = polygon[j][1];

          const intersect =
            yi > point[1] !== yj > point[1] &&
            point[0] < ((xj - xi) * (point[1] - yi)) / (yj - yi) + xi;

          if (intersect) {
            inside = !inside;
          }
        }

        if (inside) {
          // Point is inside polygon
          return true;
        }
      }
      // Point is outside all polygons
      return false;
    }
    let hasRequiredFieldMissing = false;
    stepInfo.actions.forEach((step) => {
      if (hasRequiredFieldMissing) {
        return;
      }
      if (
        step.action.Id ==
        WorkflowStepActionIdTypes.flow_1_step_1_stepActionLocation
      ) {
        // Inside map field
        if (step.data.Data) {
          const dataToValidate = JSON.parse(step.data.Data);
          const coords = [dataToValidate.coords.lat, dataToValidate.coords.lng];
          // let coords = [dataToValidate.coords.lat,dataToValidate.coords.lng];
          // if(dataToValidate)
          //   console.log(dataToValidate.data);
          if (!isWithinBounds(coords, coordinates)) {
            EVOAlertComponent.Alert({
              message: "Η τοποθεσία που επιλέξατε δεν είναι έγκυρη",
            });
            hasRequiredFieldMissing = true;
          }
        }
        // return !hasRequiredFieldMissing;
      }
      if (step.action.Required == true) {
        let dataToValidate = step.data.Data;
        if (hasValue(dataToValidate) == false) {
          EVOAlertComponent.Alert({
            message:
              "Δεν έχετε συμπληρώσει το πεδίο [" + step.action.Name + "]",
          });
          hasRequiredFieldMissing = true;
        }
      }
    });
    return !hasRequiredFieldMissing;
  }

  RenderHelper = {
    isEnabled: (dynamicData) => {
      if (this.state.instance.Paused == true) {
        return false;
      }

      if (this.__ViewType === ViewTypeEnum.NEW) {
        return true;
      } else {
        if (this.state.instance.Completed) {
          return false;
        }
      }

      if (dynamicData.step.Status != this.state.instance.Status) {
        return false;
      }

      let isAssignedToCurrentUserOrIsAdmin =
        ValidationHelper.Instances.isAssignedToUser(
          this.state.base.loggedUser,
          this.state.instance
        ) || UserRoleHelper.isMaster(this.state.base.loggedUser);

      return isAssignedToCurrentUserOrIsAdmin;
    },
    RenderStepBodyElements: (dynamicData, dynamicActions, isReadOnly) => {
      if (
        !dynamicData.step.ViewPermission.includes(
          this.state.base.loggedUser.PersonRole
        )
      ) {
        // return <></>;
      }
       
      if (
        (dynamicActions.action.Id === "a2f6d85e-4b9a-47d7-9e78-8463e66a7e42" ||
          dynamicActions.action.Id ===
            "b90e1a4f-7c6b-4e6d-92f8-d65f2e857a30") &&
        dynamicData.actions[0].data.Data === "002"
      ) {
        //if oikiakh
        return <></>;
      }
      if ((dynamicActions.action.IsVisible ?? true) == false) {
        return <></>;
      }

      if (!dynamicActions.action.UserView) {
        return <></>;
      }
      let isEnabled = this.RenderHelper.isEnabled(dynamicData);
      //console.log(isEnabled, dynamicData);
      if (dynamicActions.action.IsReadonly ?? false === true) {
        isReadOnly = true;
      }
      if (isReadOnly == true) {
        isEnabled = false;
      }

      //console.log(isReadOnly, isEnabled, dynamicActions.action.Name)

      return (
        <FormGroup>
          {/* <label
                      className="form-control-label"
                      htmlFor="input-username"
                      >
                      {dynamicActions.action.Name}
                    </label> */}
          <DynamicFile
            self={this}
            dynamicActions={dynamicActions}
            stepId={dynamicData.step.Id}
            isEnabled={isEnabled}
            instanceId={this.state.instanceId}
            initialFiles={this.state.files}
            isShowlabel={true}
          />
          <DynamicButton
            self={this}
            dynamicActions={dynamicActions}
            stepId={dynamicData.step.Id}
            isEnabled={isEnabled}
            user={this.Base.Security.getLoggedUser()}
            isShowlabel={true}
            preCallbackAsync={this.onSaveInstance}
            stepStatus={dynamicData.step.Status}
          />
          <DynamicInput
            self={this}
            dynamicActions={dynamicActions}
            stepId={dynamicData.step.Id}
            isEnabled={isEnabled}
            isShowlabel={true}
          />
          <DynamicDateTimePicker
            self={this}
            dynamicActions={dynamicActions}
            stepId={dynamicData.step.Id}
            isEnabled={isEnabled}
            isShowlabel={true}
          />
          <DynamicReactSelect
            self={this}
            dynamicActions={dynamicActions}
            stepId={dynamicData.step.Id}
            isEnabled={isEnabled}
            isShowlabel={true}
          />
          <DynamicReactTable
            self={this}
            dynamicActions={dynamicActions}
            stepId={dynamicData.step.Id}
            isEnabled={isEnabled}
            isShowlabel={true}
          />
          <DynamicRating
            self={this}
            dynamicActions={dynamicActions}
            stepId={dynamicData.step.Id}
            isEnabled={isEnabled}
            isShowlabel={true}
          />
          <DynamicMap
            self={this}
            dynamicActions={dynamicActions}
            stepId={dynamicData.step.Id}
            isEnabled={isEnabled}
            isShowlabel={true}
          />
          {/* <ReportIssue
                      dynamicData={dynamicData}
                      dynamicActions={dynamicActions}
                      instance={this.state.instance}
                      logged_user={isEnabled}
                    /> */}
        </FormGroup>
      );
    },
    RenderStepBody: (dynamicData, isReadOnly) => {
      if (!this.state.steps || this.state.steps.length === 0) {
        return <></>;
      }

      isReadOnly = isReadOnly || false;

      //Dynamically position elements based on breakpoints
      dynamicData.actions.forEach((action) => {
        action.action.Metadata =
          JSON.parse(action.action?.MetaDataJson ?? "null") ?? null;
      });

      const Description = ({ text }) => {
        let showValue = (text ?? "").length > 0;
        if (showValue) {
          return (
            <Fragment>
              <h4 className="form-control-label text-muted">{text}</h4>
              <br></br>
            </Fragment>
          );
        }
        return <Fragment />;
      };

      return (
        <Fragment>
          <Description text={dynamicData.step.Description} />
          <Form id={dynamicData.step.Id} onSubmit={this.handleSubmit}>
            {EVOLayoutHelper.Renderer._getDataPerRow(dynamicData.actions).map(
              (actions, key) => (
                <Row key={key}>
                  {actions.map((action, key) => {
                    return (
                      <Col
                        key={key}
                        //sm={action.action?.Metadata.bsBreakpoint.sm ?? null}
                        md={action.action?.Metadata?.bsBreakpoint?.md ?? 12}
                        //lg={action.action?.Metadata.bsBreakpoint.lg ?? null}
                        //xl={action.action?.Metadata.bsBreakpoint.xl ?? null}
                        //xxl={action.action?.Metadata.bsBreakpoint.xxl ?? null}
                      >
                        {this.RenderHelper.RenderStepBodyElements(
                          dynamicData,
                          action,
                          isReadOnly
                        )}
                      </Col>
                    );
                  })}
                </Row>
              )
            )}
          </Form>
        </Fragment>
      );
    },
  };

  NavigateHomeButtonComponent = () => {
    const onClickRedirectHome = async () => {
      let promptResult = await EVOAlertComponent.Prompt({
        message:
          "Επιθυμείτε να επιστρέψετε στην Αρχική Οθόνη; Οι όποιες αλλαγές θα χαθούν.",
      });

      if (promptResult.isConfirmed === true) {
        RedirectHelper.RedirectTo.home();
      }
    };

    return (
      <Button
        color="primary"
        onClick={() => {
          onClickRedirectHome();
        }}
        size="lg"
      >
        Επιστροφή <i className="fa fa-undo"></i>
      </Button>
    );
  };

  ExecuteOverridesOnMountAsync = async (
    self,
    state,
    workflowId,
    status,
    worfklowInstanceId
  ) => {
    let workflowStep = this.Base.Services.WorkflowService.getWorkflowStep(
      workflowId,
      status
    );
    await OverrideRules.onMountAsync(
      self,
      state,
      workflowId,
      workflowStep.wfsId,
      worfklowInstanceId
    );
  };

  CreateButtonComponent = ({
    instanceId,
    selectedWorkflow,
    instanceStepsData,
    self,
  }) => {
    const onClickCreateInstance = async () => {
      let promptResult = await EVOAlertComponent.PromptWithDenyButton({
        message:
          "Επιθυμείτε να προχωρήσετε με τη δημιουργία νέας καταχώρησης βλάβης;",
        denyButtonText: "Προσωρινή Αποθήκευση",
        confirmButtonText: "Απευθείας Υποβολή",
      });

      if (promptResult.isConfirmed === true) {
        let isValidStep = self.getIsValidStepAndSetWarning(instanceStepsData);
        if (isValidStep == false) {
          return;
        }
        await ApiCallerHelper.Instances.newInstance(
          instanceId,
          selectedWorkflow,
          instanceStepsData,
          1
        );
        // RedirectHelper.RedirectTo.flowInstance(instanceId);
        RedirectHelper.RedirectTo.home();
      }

      if (promptResult.isDenied === true) {
        //temporal save
        await ApiCallerHelper.Instances.newInstance(
          instanceId,
          selectedWorkflow,
          instanceStepsData,
          0
        );
        // RedirectHelper.RedirectTo.flowInstance(instanceId);
        RedirectHelper.RedirectTo.home();
      }
    };

    return (
      <Button
        color="success"
        style={{ minWidth: 118, marginLeft: -1 }}
        onClick={() => {
          onClickCreateInstance();
        }}
        size="lg"
        id="submit-button-1"
        //style={{backgroundImage: "url(" + require("assets/img/btn-background.png") + ")"}}
      >
        Δημιουργία
      </Button>
    );
  };

  ButtonApproveComponent = ({ instanceId, instanceStepsData }) => {
    const approveInstance = async () => {
      let isValidStep = this.getIsValidStepAndSetWarning(instanceStepsData);
      if (isValidStep == false) {
        return;
      }

      let promptResult = await EVOAlertComponent.Prompt({
        message: "Επιθυμείτε να προχωρήσετε με την Υποβολή της Εγγραφής;",
      });
      if (promptResult.isConfirmed === false) {
        return;
      }


      let result = await ApiCallerHelper.Instances.approveInstanceAsync(
        instanceId,
        instanceStepsData
      );
      let currentStep = instanceStepsData.step.Id;
      if (currentStep == "f0458a32-530a-427d-a43b-897e0487533a") {
        RedirectHelper.RedirectTo.home();
      } else {
        RedirectHelper.RedirectTo.reloadPageFromCache();
      }
    };

    return (
      <Button
        color="success"
        onClick={() => {
          approveInstance();
        }}
        size="md"
      >
        Υποβολή
      </Button>
    );
  };

  onSaveInstanceClick = async (stepStatus) => {
    let promptResult = await EVOAlertComponent.Prompt({
      message: "Επιθυμείτε να προχωρήσετε με την Προσωρινή Αποθήκευση;",
    });
    if (promptResult.isConfirmed === true) {
      let result = await this.onSaveInstance(stepStatus);
      RedirectHelper.RedirectTo.reloadPageFromCache();
    }
  };

  onSaveInstance = async (stepStatus) => {
    var data = this.state.steps[stepStatus - 1];
    let result = await ApiCallerHelper.Instances.updateInstanceAsync(
      this.state.instance.Id,
      data
    );
    return result;
  };
}

export default InstanceBase;
