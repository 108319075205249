/**
 * Fetches a list of notifications.
 *
 * @param {boolean} isMock - Indicates whether to use mock data.
 * @returns {Array<Object>} An array of notification objects.
 * @throws {Error} Throws an error if the data cannot be fetched.
 *
 * @example
 * // Fetch notifications using mock data
 * const notifications = fetchNotifications(true);
 *
 * // Fetch notifications using real data
 * const notifications = fetchNotifications(false);
 */

import ApiCallerHelper from "api/apiCallerHelper";

const mapBackendSchemaToNotificationSchema = (backendResponse) => {
  // Define a mapping function for each notification object
  const mapNotification = (backendNotification) => {
    return {
      id: backendNotification.Id,
      message: backendNotification.Message,
      unread: !backendNotification.ReadStatus, // Invert ReadStatus to determine if it's unread
    };
  };

  // Map each notification in the backend response to the frontend format
  const frontendNotifications = backendResponse.map(mapNotification);

  return frontendNotifications;
};

export const fetchNotifications = async (isMock = false) => {
  let notificationsData = [];

  if (isMock) {
    notificationsData = [
      { id: 1, message: "Νέο Αίτημα Κοινόχρηστης Βλάβης", unread: true },
      {
        id: 2,
        message: "Ολοκληρώθηκαν οι εργασίες εργολάβου αιρθμού αίτησης ##",
        unread: true,
      },
      { id: 3, message: "Νέο Αίτημα Οικιακής Βλάβης", unread: true },
      { id: 4, message: "Νέο Αίτημα Κοινόχρηστης Βλάβης", unread: true },
    ];
  } else {
    try {
      // Fetch data from the API and store it in backendResponse
      const backendResponse = await ApiCallerHelper.Notifications.getNotificationsAsync();
      notificationsData = mapBackendSchemaToNotificationSchema(backendResponse.data);
    } catch (error) {
      // Handle errors from the API call
      console.error("Error fetching notifications:", error);
    }
  }

  return notificationsData;
};
