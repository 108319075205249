import React, { useState } from 'react';
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet.js';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';

import {ApiCallerHelper} from "../../../api/apiCallerHelper"
import {RedirectHelper} from "../../../common/redirectHelper"
import EVOAlertComponent from "../../../components/common/EvoAlertComponent";
import AssetHelper from "../../../common/AssetHelper"
import {LocationService} from "../../../common/LocationService"
import PolygonEditModal from "./polygonEditModal";
import { MDBDataTable } from 'mdbreact';

import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Input,
  FormGroup,
  Form,
  Table
} from "reactstrap";
import EvoBaseComponent from "../../../components/common/EvoBaseComponent";
import EVOMDBDataTableHelper from '../../../components/common/evo.mdbDatatable-helper';

const center = [38.3910962, 22.6631478]

const test = {
  "coords" : [
    [38.390878, 22.640144],
    [38.392401, 22.645344],
    [38.388435, 22.643282]
  ],
  "options" : {
    "color" : "yellow"
  }
}

class Polygons extends EvoBaseComponent {
  constructor(props){
      super(props);      
      this.state = {
        locationsDatasource: null,
        polygonId: null,     
        editModalVisible: false,      
        ...this.state,   
      };    
  };

  componentDidMount() {
    super.componentDidMount(     
      this.getAndSetAllPolygons 
    )
  }  

  hideModal = (forceUpdate) => {
    setTimeout(() => {
      let callBack;
      if (forceUpdate){
        callBack = this.getAndSetAllPolygons;
      }
      this.setState({      
        editModalVisible: false
      }, callBack);
    }, 300);
  }

  showModalEditPolygon = (polygonId) => {    
    this.setState({
      polygonId: polygonId,
      editModalVisible: true
    });    
  };

  deletePolygon = async (polygonId) => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      await LocationService.deletePolygon(polygonId);      
      RedirectHelper.RedirectTo.reloadPageFromCache(); 
    }
  
  }

  // initializeComponent = () => {
  //   this.setState({
  //     locationsDatasource : this.Base.Services.LocationService.getAllLocations()
  //   })
  // }


  async getAndSetAllPolygons() {          
    const data = this.Base.Services.LocationService.getAllLocations();
    let self = this;    
    data.forEach(function (row, index) {   
      row._Data_options_color = (
        <React.Fragment>      
          <div
            title={row.Data.options.color}
            style={
              {
                backgroundColor:row.Data.options.color,
                color:"white"
              }}
          > &nbsp;</div>
        </React.Fragment>
      )            
        
      row.Buttons = (
        <React.Fragment>          
          <Button
            color="link"
            onClick={() => self.showModalEditPolygon(row.Id)}
            size="sm"
            title="Επεξεργασία"
          >
            <img src={AssetHelper.Icons.edit}></img>
            {/* <i className="fas fa-pen" /> */}
          </Button>
          <Button
            color="link"
            onClick={() => self.deletePolygon(row.Id)}
            size="sm"
            title="Διαγραφή"
          >
            <img src={AssetHelper.Icons.delete}></img>
            {/* <i className="fas fa-pen" /> */}
          </Button>         
        </React.Fragment>
      );
    });
    this.state.locationsDatasource = {
      columns: [
        {
          label: "Ονομα τομέα ▼",
          field: "Name",
          sort: "asc",
          width: 100,
        },
        {
          label: "Περιγραφή ▼",
          field: "Description",
          sort: "asc",
          width: 150,
        },
        {
          label: "Χρωμ. Σήμανση ▼",
          field: "_Data_options_color",
          sort: "asc",
          width: 150,
        },  
        {
          label: "Συστημικός Κωδικός Διασύνδεσης ▼",
          field: "IntegrationCode",
          sort: "asc",
          width: 150,
        },      
        {
          label: "Ενεργειες",
          field: "Buttons",
          sort: "asc",
          width: 150,
        },
      ],
      rows: data,
    };
    
    this.setState({ locationsDatasource : this.state.locationsDatasource });    
  }

  render() {    
    if (this.state.locationsDatasource == null){
      return (<></>)
    }
        
    return (
      <Container className="mt-0" fluid>
        <Row className="mt-0">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow-none no-padding">
              <CardHeader className="border-0 no-padding">
                <Row className="align-items-center" style={{marginBottom: '20px'}}>
                  <div className="col">
                    <h3 className="mb-0">Τομείς</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary" 
                      className="tool-button--large"                     
                      onClick={() => this.showModalEditPolygon(null)}
                      size="md"
                    >
                      Προσθήκη νέου πολυγώνου                     
                    </Button>                       
                  </div>
                </Row>
                <MapContainer center={center} zoom={13} scrollWheelZoom={false} style={{ height: '50vh', width: '100%' }}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  { this.state.locationsDatasource.rows.map((dynamicPolygons, key) =>
                    <Polygon key={key} pathOptions={dynamicPolygons.Data?.options??null} positions={dynamicPolygons.Data?.coords??[]} />
                  )}
                  {/* <Polygon pathOptions={test.options} positions={test.coords} /> */}
                </MapContainer>
                <br/>
                <Row>
                  <Col md="12">
                    <h6 className="heading-small text-muted mb-4">
                        Πολυγωνα Τομεων
                    </h6>
                    <EVOMDBDataTableHelper.MDBDataTable     
                      barReverse={true}                  
                      data={this.state.locationsDatasource}
                    />                    
                  </Col>
                </Row>
                <br/>
              </CardHeader>
            </Card>
          </Col>
        </Row>
      
        <div className='push'></div>
        <PolygonEditModal
            isShown={this.state.editModalVisible}
            polygonId={this.state.polygonId}
            onHideModal={this.hideModal}
          ></PolygonEditModal>        
      </Container>
    );
  }
}

export default Polygons;
