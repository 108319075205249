import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
import Accordion from 'react-bootstrap/Accordion';

export const EvoCard = ({
    Header,
    Body,     
    eventKey, 
    isAccordionEnabled,    
    ...fields}) => {        
    let _isAccordionEnabled = isAccordionEnabled || false;    
    let _eventKey = eventKey || '-1';    
    return (
      <Container className="mt-2" fluid>
        <Row className="mb-5">
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                  {Header}
              </CardHeader>             
              {_isAccordionEnabled == true && (
                <Accordion.Collapse eventKey={_eventKey}>
                  <CardBody>
                    {Body}
                  </CardBody>
                </Accordion.Collapse>
              )}
              {_isAccordionEnabled == false && (
                <CardBody>
                  {Body}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };


  export default EvoCard;
