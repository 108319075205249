//import 'bootstrap/dist/css/bootstrap.min.css';

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./views/App.js";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/modals.css";
import "assets/css/swal2.css";

//import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from "react-redux";
import { store } from "./redux";
import { EvoLoadingIndicator } from "./components/common/EvoLoadingIndicator";
import { BaseContext } from "./contexts/BaseContext.js";
import GlobalParametersHelper from "./common/GlobalParametersHelper";
const AspraSpitiaTheme = React.lazy(() => import('./layouts/themes/as-theme'));
const ProductTheme = React.lazy(() => import('./layouts/themes/product-theme'));

const ThemeSelector = ({ children }) => {
  const demoMode = GlobalParametersHelper.getIsDefault();
  return (
    <>
      <React.Suspense fallback={<></>}>
        {demoMode && <ProductTheme />}
        {!demoMode && <AspraSpitiaTheme />}
      </React.Suspense>
      {children}
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeSelector>

    <Provider store={store}>
      <BaseContext>
        <div className="tool-theme">
          <App />
        </div>
        <EvoLoadingIndicator />
      </BaseContext>
    </Provider>
  </ThemeSelector>
);



// ReactDOM.render(
//   <div className="tool-theme">
//     <App />
//     <LoadingIndicator />
//   </div>,
//   document.getElementById("root")
// );
