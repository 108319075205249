import Login from "views/security/Login.js";
import Logout from "views/security/Logout.js";
import PasswordChange from "views/security/PasswordChange";
import UriTokenLogin from "views/security/UriTokenLogin";
import Flows from "views/parameterization/flows/Flows.js";
import Flow from "views/parameterization/flows/Flow.js";
import Step from "views/parameterization/flows/Step.js";
import NewInstance from "views/instances/InstanceNew";
import Instance from "views/instances/InstanceExisting";
import InstanceDetails from "views/instances/InstanceDetails";
import History from "views/instances/custom/History.js";
import Polygons from "views/parameterization/polygons/Polygons";
import UsersMenu from "views/parameterization/users/UsersMenu";
import MonthlyPrint from "views/prints/monthlyPrint";
import MonthlyVouchers from "views/statistics/MonthlyVouchers";
import Properties from "views/statistics/Properties";
import { UserGroupHelper } from "./common/userGroupHelper";
import SiteHealthCheck from "views/healthCheck/SiteHealthCheck";
import RoutesTypes from "./common/enums/routesTypes.enum";
import LayoutComponentTypes from "./common/enums/layoutComponentTypes.enum";
import Home from "views/home";

//import CustomIndex from "views/instances/custom/custom.index.Resident";
import CustomIndex from "views/instances/custom/custom.index";

var Routes = {
  paths: {
    base: [
      {
        path: "/uriTokenLogin",
        name: RoutesTypes.URI_TOKEN_LOGIN,
        layout: null,
        component: UriTokenLogin,
        getAuth: (user) => {
          return true;
        },
      },
      {
        path: "/sitehealthcheck",
        name: RoutesTypes.HEALTH_CHECK,
        layout: null,
        component: SiteHealthCheck,
        getAuth: (user) => {
          return true;
        },
      },
    ],
    auth: [
      {
        name: RoutesTypes.LOGIN,
        path: "/login",
        layout: null,
        component: Login,
        getAuth: (user) => {
          return true;
        },
      },
      {
        path: "/passwordchange",
        name: RoutesTypes.PASSWORD_CHANGE,
        layout: null,
        component: PasswordChange,
        getAuth: (user) => {
          return true;
        },
      },
      {
        path: "/logout",
        name: RoutesTypes.LOGOUT,
        layout: null,
        component: Logout,
        getAuth: (user) => {
          return true;
        },
      },
    ],
    basicLayout: [
      {
        path: "",
        name: RoutesTypes.BASIC_LAYOUT,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: Home,
        getAuth: (user) => {
          return user != null;
        },
      },
      {
        path: "/index",
        name: RoutesTypes.HOME,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: Home,
        getAuth: (user) => {
          return true;
        },
        menu: {
          isMenuEnabled: true,
          title: "Αρχική",
          fontAwesomeIcon: "fa-home",
          hasHrElement: true,
        },
      },
      {
        path: "/history",
        name: RoutesTypes.HISTORY,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: History,
        getAuth: (user) => {
          return true;
        },
        menu: {
          isMenuEnabled: false,
          title: "Δελτία Εργασίας Ιστορικό",
          fontAwesomeIcon: "fa-stream",
          hasHrElement: false,
        },
      },
      {
        path: "/vouchers",
        name: RoutesTypes.MONTHLY_VOUCHERS,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: MonthlyVouchers,
        getAuth: (user) => {
          return UserGroupHelper.isMechanic(user);
        },
        menu: {
          isMenuEnabled: true,
          title: "Δελτία Εργασίας/Αναφορές",
          fontAwesomeIcon: "fa-stream",
          hasHrElement: true,
        },
      },
      {
        path: "/properties",
        name: RoutesTypes.PROPERTIES,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: Properties,
        getAuth: (user) => {
          return UserGroupHelper.isMechanic(user);
        },
        menu: {
          isMenuEnabled: true,
          title: "Πληροφορίες Ακινήτων",
          fontAwesomeIcon: "fa-stream",
          hasHrElement: true,
        },
      },
      {
        path: "/propertiesVoucher",
        name: RoutesTypes.MONTHLY_VOUCHERS,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: MonthlyVouchers,
        getAuth: (user) => {
          return UserGroupHelper.isMechanic(user);
        },
        menu: {
          isMenuEnabled: true,
          title: "Δελτία Ακινήτων/Στατιστικά",
          fontAwesomeIcon: "fa-stream",
          hasHrElement: true,
        },
      },
      {
        path: "/statisticsVoucher",
        name: RoutesTypes.MONTHLY_VOUCHERS,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: MonthlyVouchers,
        getAuth: (user) => {
          return UserGroupHelper.isMechanic(user);
        },
        menu: {
          isMenuEnabled: true,
          title: "Στατιστικά Δελτίων Εργασίας",
          fontAwesomeIcon: "fa-stream",
          hasHrElement: true,
        },
      },
      {
        path: "/monthlyVouchers",
        name: RoutesTypes.MONTHLY_VOUCHERS,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: MonthlyVouchers,
        getAuth: (user) => {
          return UserGroupHelper.isMechanic(user);
        },
        menu: {
          isMenuEnabled: true,
          title: "Μηνιαίες Πιστοποιήσεις/Τιμολόγηση",
          fontAwesomeIcon: "fa-stream",
          hasHrElement: true,
        },
      },
      {
        path: "/newinstance",
        name: RoutesTypes.INSTANCE_NEW,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        navStyle: { isSidebarVisible: false },
        component: NewInstance,
        getAuth: (user) => {
          return true;
        },
      },
      {
        path: "/instance/:id",
        name: RoutesTypes.INSTANCE,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        navStyle: { isSidebarVisible: false },
        component: Instance,
        getAuth: (user) => {
          return true;
        },
      },
      {
        path: "/details/:id",
        name: RoutesTypes.INSTANCE_DETAILS,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        navStyle: { isSidebarVisible: false },
        component: InstanceDetails,
        getAuth: (user) => {
          return true;
        },
      },
    ],
    prints: [
      {
        //TODO TO BE REMOVED
        path: "/monthlyprint",
        name: RoutesTypes.MONTHLY_PRINT,
        layout: null,
        component: MonthlyPrint,
        getAuth: (user) => {
          return true;
        },
      },
    ],
    parameterization: [
      {
        path: "",
        name: RoutesTypes.PARAMETERIZATION,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: UsersMenu,
        getAuth: (user) => {
          return UserGroupHelper.isMaster(user);
        },
      },
      {
        path: "/users",
        name: RoutesTypes.USERS,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: UsersMenu,
        getAuth: (user) => {
          return UserGroupHelper.isMaster(user);
        },
        menu: {
          isMenuEnabled: true,
          title: "Χρήστες Συστήματος",
          fontAwesomeIcon: "fa-cog",
          hasHrElement: false,
        },
      },
      {
        path: "/maps",
        name: RoutesTypes.MAPS,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: Polygons,
        getAuth: (user) => {
          let result = UserGroupHelper.isMaster(user);
          return result;
        },
        menu: {
          isMenuEnabled: true,
          title: "Τομείς",
          fontAwesomeIcon: "fa-map",
          hasHrElement: false,
        },
      },
      {
        path: "/flows",
        name: RoutesTypes.FLOWS,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: Flows,
        getAuth: (user) => {
          let result = UserGroupHelper.isMaster(user);
          return result;
        },
        menu: {
          isMenuEnabled: true,
          title: "Διαμόρφωση Ροών",
          fontAwesomeIcon: "fa-stream",
          hasHrElement: true,
        },
      },
      {
        path: "/flow/:id",
        name: RoutesTypes.FLOW_EDIT,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: Flow,
        getAuth: (user) => {
          let result = UserGroupHelper.isMaster(user);
          return result;
        },
      },
      {
        path: "/step/:id",
        name: RoutesTypes.FLOW_ACTION_STEP_EDIT,
        layout: LayoutComponentTypes.BASIC_LAYOUT,
        component: Step,
        getAuth: (user) => {
          return UserGroupHelper.isMaster(user);
        },
      },
    ],
    healthcheck: [
      {
        path: "/sitehealthcheck",
        name: RoutesTypes.HEALTH_CHECK_CLONE,
        layout: null,
        component: SiteHealthCheck,
        getAuth: (user) => {
          return true;
        },
      },
    ],
    // mock : [
    //   {
    //     path: "/test",
    //     name: RoutesTypes.TEST,
    //     layout : LayoutComponentTypes.BASIC_LAYOUT,
    //     component: CustomIndex,
    //     getAuth:(user) => {
    //       return true
    //     },
    //   },
    // ]
  },
  _getAllRoutes: () => {
    let keys = Object.keys(Routes.paths);
    let results = [];
    keys.forEach((key) => {
      let routes = Routes.paths[key];
      routes.forEach((item) => {
        let navLink = "/" + key + item.path;
        if (key === "base") {
          navLink = item.path;
        }
        item.navLinkTo = navLink;
        item.routePath = navLink;
        results.push(item);
      });
    });
    return results;
  },
  getMenuRoutes: () => {
    let results = Routes._getAllRoutes().filter(
      (x) => x.menu?.isMenuEnabled ?? false === true
    );
    return results;
  },
  getRoutesByLayoutComponentType: (layoutComponentType) => {
    let routes = Routes._getAllRoutes();
    routes = routes.filter(
      (x) => x.component != null && x.layout === layoutComponentType
    );
    return routes;
  },
  getBrowserRoutes: (layoutComponents) => {
    let results = [];
    Routes._getAllRoutes().forEach((item) => {
      let component = item.component;
      if (item.layout != null) {
        component =
          layoutComponents.filter((x) => x.key === item.layout)[0]?.component ??
          null;
      }
      let browserRoute = {
        path: item.routePath, // "/" + key + item.path,
        component: component,
      };
      results.push(browserRoute);
    });
    return results;
  },
  getURLByRouteType: (routeTypeEnum) => {
    let keys = Object.keys(Routes.paths);
    var result = null;
    keys.forEach((key) => {
      let routes = Routes.paths[key];
      let item = routes.filter((x) => x.name == routeTypeEnum)[0] ?? null;
      if (item != null) {
        result = item.path.replace(":id", "");
        if (key !== "base") {
          result = key + result;
        }
      }
    });
    if (result == null) {
      result = routeTypeEnum;
    }
    return result;
  },
};
export default Routes;
