import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";


import Moment from "moment";
import 'moment/locale/el';

import { ApiCallerHelper } from "../../../api/apiCallerHelper";
import { RedirectHelper } from "../../../common/redirectHelper";
import { AssetHelper } from "../../../common/AssetHelper";
import EVOAlertComponent from "../../../components/common/EvoAlertComponent";
import UserEditModal from "./UserEditModal";
import EvoBaseComponent from "../../../components/common/EvoBaseComponent";
import EVOMDBDataTableHelper from '../../../components/common/evo.mdbDatatable-helper';

class UsersMenu extends EvoBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      usersDatasource: null,
      userId: null,
      editModalVisible: false,
      ...this.state,
    };
  }

  componentDidMount() {
    super.componentDidMount(this.getAndSetAllUsers);
  }

  hideModal = (forceUpdate) => {
    setTimeout(() => {
      let callBack;
      if (forceUpdate) {
        callBack = this.getAndSetAllUsers;
      }
      this.setState(
        {
          editModalVisible: false,
        },
        callBack
      );
    }, 500);
  };

  showModalEditUser = (userId) => {
    this.setState({
      userId: userId,
      editModalVisible: true,
    });
  };

  toggleUserActive = async (userId) => {
    let promptResult = await EVOAlertComponent.Prompt({
      message: "Επιθυμείτε να Ενεργοποιήσετε/Απενεργοποιήσετε τον χρήστη;",
    });
    if (promptResult.isConfirmed === true) {
      await ApiCallerHelper.Users.toggleUserActive(userId);
      this.getAndSetAllUsers();
    }
  };

  async getAndSetAllUsers() {
    const users = this.Base.Services.UsersService.getAllUsers();
    let self = this;
    users.forEach(function (user, index) {
      user.Login = <></>;
      if (user.LastLogin) {
        user.Login = (
          <>
            {Moment(user.LastLogin)
              .locale("el")
              .format("dddd, DD MMM YYYY h:mm:ss a")}
          </>
        );
      }

      // this[index].Creation = <>{Moment(this[index].DateCreation).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')} </>
      user.ActiveState = (
        <React.Fragment>
          <Button
            color={user.Active ? "success" : "danger"}
            disabled={true}
            size="sm"
          >
            {user.Active ? (
              <i className="fas fa-check" />
            ) : (
              <i className="fas fa-ban" />
            )}
          </Button>
        </React.Fragment>
      );
      user.Buttons = (
        <React.Fragment>
          <Button
            color="link"
            onClick={() => self.showModalEditUser(user.Id)}
            size="sm"
            title="Επεξεργασία"
          >
            <img src={AssetHelper.Icons.edit}></img>
            {/* <i className="fas fa-pen" /> */}
          </Button>
          <Button
            color={user.Active ? "danger" : "success"}
            onClick={() => self.toggleUserActive(user.Id)}
            size="sm"
          >
            {/* {this[index].Active ? <i className="fas fa-trash" /> : <i className="fas fa-check" />} */}
            <i className="fas fa-power-off" />
          </Button>
        </React.Fragment>
      );
    });
    this.state.usersDatasource = {
      columns: [
        {
          label: "Ονομα Χρηστη ▼",
          field: "DisplayName",
          sort: "asc",
          width: 100,
        },
        {
          label: "Τηλ. Επικοινωνίας ▼",
          field: "PhoneNumber",
          sort: "asc",
          width: 150,
        },
        {
          label: "Ρόλος Χρήστη ▼",
          field: "PersonRole",
          sort: "asc",
          width: 150,
        },
        {
          label: "Γκρουπ Χρήστη ▼",
          field: "Groups",
          sort: "asc",
          width: 150,
        },
        {
          label: "Τελ. Συνδεση ▼",
          field: "Login",
          sort: "asc",
          width: 150,
        },
        {
          label: "Κατασταση",
          field: "ActiveState",
          sort: "asc",
          width: 150,
        },
        {
          label: "Μεταβαση",
          field: "Buttons",
          sort: "asc",
          width: 150,
        },
      ],
      rows: users,
    };

    this.setState({ usersDatasource: this.state.usersDatasource });
  }

  render() {
    if (this.state.usersDatasource == null) {
      return <></>;
    }

    return (
      <>
        {/* Page content */}
        <Container className="mt-0" fluid>
          <Row className="mt-0">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow-none no-padding">
                <CardHeader className="border-0 no-padding">
                  <Row
                    className="align-items-center"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="col">
                      <h3 className="mb-0">Χρήστες Συστήματος</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="primary"
                        className="tool-button--large"
                        onClick={() => this.showModalEditUser(null)}
                        size="md"
                        // style={{backgroundImage: "url(" + require("assets/img/btn-background.png") + ")"}}
                      >
                        Προσθήκη νέου χρήστη
                      </Button>
                    </div>
                  </Row>
                  <EVOMDBDataTableHelper.MDBDataTable
                    barReverse={true}
                    data={this.state.usersDatasource}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <div className="push"></div>
          <UserEditModal
            isShown={this.state.editModalVisible}
            userId={this.state.userId}
            onHideModal={this.hideModal}
          ></UserEditModal>
        </Container>
      </>
    );
  }
}

export default UsersMenu;
