import React from "react";
// import background from "../../assets/img/background.jpg";
import AssetHelper from  "../../common/AssetHelper"
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import { ApiCallerHelper } from "../../api/apiCallerHelper";
import { RedirectHelper } from "../../common/redirectHelper";
import GlobalParameters from "../../common/GlobalParametersHelper";
import {
  Container,
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { Fragment } from "react";
import { useState } from "react";

const Literals = {  
  password: "Κωδικός",  
  warningWrongPassword : 'Ο κωδικός πρόσβασης είναι λάθος. Παρακαλούμε δοκιμάστε ξανά.',
  infoTypeNewCredentials : 'Παρακαλώ εισάγετε τα νέα διακρητικά.',
  infoVerifyPassword : 'Επιβεβαίωση Κωδικού',
  infoUpdatePassword : 'Ενημέρωση Κωδικού',
  warningPasswordValidation : 'Ο κωδικός θα πρέπει να ειναι μεγαλύτερος από 5 χαρακτήρες.'  
}

export default function PasswordChange({ setToken }) {
  const [passwordFirst, setPasswordFirst] = useState("");
  const [passwordSecond, setPasswordSecond] = useState("");
  const [hasInvalidPassword, setHasInvalidPassword] = useState("");

  function updatePassword() {
    checkAndSetHasInvalidPassword();
  }

  async function checkAndSetHasInvalidPassword() {
    let passwordsAreInvalid = false;
    if (
      passwordFirst !== passwordSecond ||
      passwordFirst.trim() == "" ||
      passwordFirst.trim().length < 6
    ) {
      passwordsAreInvalid = true;
    }

    setHasInvalidPassword(passwordsAreInvalid);
    if (!passwordsAreInvalid) {
      let result = await ApiCallerHelper.Users.passwordchange(passwordFirst, passwordSecond);
      if (result == true) {
        GlobalParameters.Token.remove();        
        RedirectHelper.RedirectTo.login();
      }else{
        setHasInvalidPassword(true);
      }
    }
  }

  let renderError = (
    <>
      {hasInvalidPassword && (
        <p style={{ color: "orange" }}>
          {Literals.warningWrongPassword}          
        </p>
      )}
    </>
  );

  let renderPasswordChange = (
    <>
      <div style={{ textAlign: "center", fontWeight: "200" }}>
        {Literals.infoTypeNewCredentials}        
      </div>
      <br></br>
      <FormGroup>
        <InputGroup className="input-group-alternative">
          
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          
          <Input
            placeholder={Literals.password}
            type="password"
            onChange={(e) => setPasswordFirst(e.target.value)}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-alternative">
          
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          
          <Input
            placeholder={Literals.infoVerifyPassword}
            type="password"
            onChange={(e) => setPasswordSecond(e.target.value)}
          />
        </InputGroup>
      </FormGroup>
      {renderError}
      <p style={{ color: "blue" }}>
        {Literals.warningPasswordValidation}        
      </p>
    </>
  );

  return (
    <div className="main-content">
      <AuthNavbar />
      <div className="header py-7 py-lg-8"></div>
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card
              className="shadow border-0"
              style={{ backgroundColor: "rgba(255,255,255, 0.95)" }}
            >
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form">
                  <img
                    src={AssetHelper.Logo.getMain()}
                    className="center-block"
                    style={{
                      width: "350px",
                      height: "auto",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />                  
                  {GlobalParameters.getIsDefault() &&
                    <h2 style={{ textAlign: 'center', fontWeight: '300' }}>{GlobalParameters.Theme.SelectedTheme.ApplicationName}</h2> 
                    }                                      
                  <br />
                  {renderPasswordChange}
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      onClick={() => {
                        updatePassword();
                      }}
                    >
                      {Literals.infoUpdatePassword}                      
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
