
import React, { Fragment } from "react";
 import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { Styles } from "./CustomSideBar.styles";
import Routes from "../../routes";
import { Base } from "contexts/BaseContext";
import { useState, useContext, createContext } from "react";

// reactstrap components
import {
  NavItem,
  NavLink,  
  Container,
  Row,
  Col
} from "reactstrap";

export const CustomSideBar = () => { 
  const baseContext = useContext(Base);       
  let loggedUser = baseContext.baseCommon.loggedUser;
  if (loggedUser == null){
    return <></>
  }

  const Links = () => {       
    return (
      <>      
      {Routes.getMenuRoutes().map(function(menuLink, i){
          let key = i + 1;
          let link = menuLink.navLinkTo;
          if (!menuLink.getAuth(loggedUser)){
            return ( <Fragment key={key}></Fragment>)
          }    
          return (
            <Fragment key={key}>
              <NavItem key={key} to={link}>
                <NavLink
                  key={key}
                  to={link}                                
                  activeClassName="tool__nav-item--active"    
                  tag={NavLinkRRD}            
                  >                  
                  {menuLink.menu.title}
                </NavLink>                  
              </NavItem>
              <hr />
            </Fragment>
          );                
        })}
      </>
    );
  };
    
  return (            
      <Styles>  
        <div className="tool__basic_sidebar__container">            
        <Links></Links> 
        </div> 
      </Styles>
  );  
}


export default CustomSideBar;
