import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import Routes from "../../routes";
import AssetHelper from "common/AssetHelper";
import ProfileNavItem from "../Navbars/ProfileNavItem";
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Fragment } from "react";
import { Base } from "contexts/BaseContext";
import { useState, useContext, createContext } from "react";
import { RedirectHelper } from "../../common/redirectHelper";
import GlobalParametersHelper from "../../common/GlobalParametersHelper";
import MediaQuery from "react-responsive";
import CustomSideBar from "../Sidebar/CustomSideBar";
import NotificationBell from "components/NotificationBell/NotificationBell";

export const BasicNavbar = ({
  navbarExpand = "", //md sets the expand visibility option
  isHamburgerVisible = true,
  isProfileNavItemVisible = true,
  isLogoVisible = true,
  isCustomSidebar,
  isSideBarVisible = true,
}) => {
  const baseContext = useContext(Base);
  const [isCollapseOpen, setIsCollapseOpen] = React.useState(false);
  if (isSideBarVisible == false) {
    navbarExpand = "";
  }

  let loggedUser = baseContext.baseCommon.loggedUser;
  if (loggedUser == null) {
    return <></>;
  }

  const Links = () => {
    return (
      <>
        {Routes.getMenuRoutes().map(function (menuLink, i) {
          let key = i + 1;
          if (!menuLink.getAuth(loggedUser)) {
            return <Fragment key={key}></Fragment>;
          }

          return (
            <Fragment key={key}>
              <NavItem key={key} to={menuLink.navLinkTo}>
                <NavLink
                  key={key}
                  to={menuLink.navLinkTo}
                  tag={NavLinkRRD}
                  onClick={this?.closeCollapse ?? null}
                  activeClassName="active"
                >
                  {
                    <i
                      className={
                        "fa " +
                        menuLink.menu.fontAwesomeIcon +
                        " text-primary mr-3"
                      }
                    />
                  }
                  {menuLink.menu.title}
                </NavLink>
                {menuLink.menu.hasHrElement > 0 && <hr className="my-3" />}
              </NavItem>
            </Fragment>
          );
        })}
        <NavItem>
          <NavLink
            onClick={() => {
              RedirectHelper.RedirectTo.passwordChange();
            }}
          >
            {<i className={"fa fa-key text-primary mr-3"} />}
            Αλλαγή Κωδικού
          </NavLink>
          <NavLink
            onClick={() => {
              RedirectHelper.RedirectTo.logout();
            }}
          >
            <i className="ni ni-button-power" />
            Αποσύνδεση
          </NavLink>
          <hr className="my-3" />
        </NavItem>
      </>
    );
  };

  const SideBarMenuComponent = () => {
    return (
      <>
        {/* Horizontal Bar Options*/}
        <MediaQuery
          maxWidth={GlobalParametersHelper.BreakPoints.custom_md_1_maxWidth}
        >
          <Nav navbar>
            <Links></Links>
          </Nav>
        </MediaQuery>
        {/* Vertical Bar Options*/}
        <MediaQuery
          minWidth={GlobalParametersHelper.BreakPoints.custom_md_1_maxWidth}
        >
          {GlobalParametersHelper.Theme.SelectedTheme.NavBar
            .IsLogoVerticalVisible && (
            <>
              <ImageLogoComponent />
              <div class="mt-3"></div>
            </>
          )}
          {isCustomSidebar == true && isSideBarVisible == true && (
            <CustomSideBar />
          )}
          {(isCustomSidebar == false || isSideBarVisible == false) && (
            <Nav navbar>
              <Links></Links>
            </Nav>
          )}
        </MediaQuery>
      </>
    );
  };

  const ImageLogoComponent = ({ minHeightOffset }) => {
    minHeightOffset = minHeightOffset || 0;
    let minHeight =
      GlobalParametersHelper.Theme.SelectedTheme.NavBar.LogoHorizontialHeight +
      minHeightOffset;
    return (
      <img
        style={{ width: "auto", minHeight: minHeight }}
        alt=""
        src={AssetHelper.Logo.getMain()}
      />
    );
  };

  const LogoNavItemComponent = () => {
    if (!isLogoVisible) {
      return;
    }
    // if (!GlobalParametersHelper.Theme.SelectedTheme.NavBar.IsLogoHorizontialVisible) {return;}
    return (
      <div className="navbar-inner  mr-auto ">
        <a className="navbar-brand">
          {/* adds extra spacer between logo and navbar hamburger */}
          <MediaQuery
            minWidth={GlobalParametersHelper.getResponsiveBreakPointWidth()}
          >
            {GlobalParametersHelper.Theme.SelectedTheme.NavBar
              .IsLogoHorizontialVisible && (
              <ImageLogoComponent minHeightOffset={15} />
            )}
          </MediaQuery>
          <MediaQuery
            maxWidth={GlobalParametersHelper.getResponsiveBreakPointWidth()}
          >
            <ImageLogoComponent />
          </MediaQuery>
        </a>
      </div>
    );
  };

  const ToggleNavItemComponent = () => {
    if (!isHamburgerVisible) {
      return;
    }
    return (
      <div className="navbar-inner">
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        >
          <span className="navbar-toggler-icon" />
        </button>
      </div>
    );
  };

  const ProfileNavItemComponent = () => {
    if (!isProfileNavItemVisible) {
      return;
    }
    return (
      <div className="navbar-inner">
        <ProfileNavItem />
      </div>
    );
  };

  let extraMarginForOpenedSidebar = "270px";
  return (
    <>
      <Nav className="navbar-horizontal navbar-light">
        {isSideBarVisible && (
          <>
            <MediaQuery
              minWidth={GlobalParametersHelper.BreakPoints.custom_md_1_maxWidth}
            >
              <div style={{ marginLeft: extraMarginForOpenedSidebar }}></div>
            </MediaQuery>
            {/* Toggle visibilitiy */}
            <MediaQuery
              maxWidth={GlobalParametersHelper.BreakPoints.custom_md_1_maxWidth}
            >
              <ToggleNavItemComponent />
            </MediaQuery>
          </>
        )}
        {isSideBarVisible == false && <ToggleNavItemComponent />}
        {/* Horizontal items visibility */}
        <LogoNavItemComponent />
        <NotificationBell />
        <ProfileNavItemComponent />
      </Nav>
      <Navbar
        className="navbar-vertical fixed-left navbar-light"
        expand={navbarExpand}
        style={{ paddingTop: 0, paddingBottom: 0 }}
        id="sidenav-main"
      >
        {/* Drop Down Menu */}
        <Container fluid>
          <Collapse navbar isOpen={isCollapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header">
              <Row>
                <>
                  <Col className="collapse-brand" xs="6">
                    <img
                      alt={"Logo"}
                      src={AssetHelper.Logo.getMain()}
                      onClick={() => RedirectHelper.RedirectTo.home()}
                    />
                  </Col>

                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={() => setIsCollapseOpen(!isCollapseOpen)}
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </>
              </Row>
            </div>
            {/* Navigation */}
            <SideBarMenuComponent />
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default BasicNavbar;
