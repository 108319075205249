
import React from 'react'
import Select from 'react-select'
import { DynamicCellItemType } from "../../components/common/evo.react-table-helper";
import ReactTooltip from 'react-tooltip';
import { Checkbox } from 'antd';
import {EVOReactSelectHelper} from "../../components/common/evo.react-select-helper"
import EVOAlertComponent from "../../components/common/EvoAlertComponent";

import EvoInput from "../../components/common/evo.Input";
import {DynamicInputValidatonType, GetDynamicInputValidatonTypeOptionList} from "../../common/enums/dynamicInputValidationTypes.enum"

export const EvoActionsDynamicGridComponentEditableCellExtensions = {
  getRenderedCellExtentionByType: (
    rowData,
    value,    
    onUpdate,
    onChangeValue,
    onDelete,
    onCustomCallbacks
  ) => {

    let type = rowData.columnMetadataDefinition.type;

    switch (type) {
      case DynamicCellItemType.cell_template:     
        return (
          <EvoActionsDynamicGridComponentEditableCellExtensions.CellExtentions.RowTemplate
            rowData = {rowData}
            onDelete={onDelete} 
            onChangeValue={onChangeValue} 
            onUpdate={onUpdate}           
            onCustomCallbacks={onCustomCallbacks}
          />
        );    
      case DynamicCellItemType.action:
        return (
          <EvoActionsDynamicGridComponentEditableCellExtensions.CellExtentions.Action
            rowData = {rowData}
            onChangeValue={onChangeValue}
            onUpdate={onUpdate}
            onDelete={onDelete}                    
          />
        );        
      case DynamicCellItemType.react_select:
        return (
          <EvoActionsDynamicGridComponentEditableCellExtensions.CellExtentions.SelectReact
            rowData = {rowData}
            value={value}            
            onChangeValue={onChangeValue}
            isClearable={true}
          />
        );   
      case DynamicCellItemType.checkbox: 
       return <EvoActionsDynamicGridComponentEditableCellExtensions.CellExtentions.Checkbox
            rowData = {rowData}
            value={value}            
            onChangeValue={onChangeValue}
            onUpdate={onUpdate}
          />
      case DynamicCellItemType.text:
      case DynamicCellItemType.number: 
      case DynamicCellItemType.integer:      
      default:
        return (
          <EvoActionsDynamicGridComponentEditableCellExtensions.CellExtentions.Input
            rowData = {rowData}
            value={value}            
            onChangeValue={onChangeValue}
            onUpdate={onUpdate}
          />
        );
    }
  },
  CellExtentions: {  
    DefaultCell : (value) => {      
      function getValueIfOption(value){
        if (
          typeof value == "object" ||
          typeof value == "function"
        ) {
          return value?.label ?? null; //it is an options value
        } 
        return value;
      }
      let actualValue = getValueIfOption(value)
      
      return (<div className='tool-theme_dynamic-table_default-column'>{actualValue}</div>); 
    },
    RowTemplate: ({ rowData, onDelete, onChangeValue, onUpdate, onCustomCallbacks}) => {                         
      return (
        rowData.columnMetadataDefinition.rowTemplate(rowData, onDelete, onChangeValue, onUpdate, onCustomCallbacks)
      );      
    },
    Action: ({ rowData, onDelete}) => {  
      
      function _onDelete(e){
        e.preventDefault();
        onDelete();
        // let promptResult = EVOAlertComponent.Prompt({message:"Διαγραφή Εγγραφής"});
        // if (promptResult.isConfirmed === true) {
        //   onDelete();
        // }
      }

      let columnMetadataDefinition = rowData.columnMetadataDefinition
      if (!columnMetadataDefinition.showDelete || columnMetadataDefinition.isEditable === false) {
        return EvoActionsDynamicGridComponentEditableCellExtensions.CellExtentions.DefaultCell("");
        //return (<div className='tool-theme_dynamic-table_default-column'></div>);      
      }
      let id = rowData.rowIndex + "__deleteToolTip";
      return (
      <div className='tool-theme_dynamic-table_action-column'>
        <ReactTooltip id={id} place="top" effect="solid">
          Αφαίρεση Εγγραφής
        </ReactTooltip>
        <a href="#" data-tip data-for={id} onClick={(e) => _onDelete(e)}><i className="fa fa-times-circle" aria-hidden="true"></i></a> 
      </div>
      );
    },
    Input: ({ rowData, value, onChangeValue, onUpdate }) => {
      let columnMetadataDefinition = rowData.columnMetadataDefinition
      let isCustomIntegerTypeValue = 
        columnMetadataDefinition.type == DynamicCellItemType.integer ||
        (columnMetadataDefinition.type?.value??null) == DynamicCellItemType.integer;
      let previousValue = null;
      if (columnMetadataDefinition.isEditable === false) {        
        return EvoActionsDynamicGridComponentEditableCellExtensions.CellExtentions.DefaultCell(value);   
      }      
      const _onChange = (e) => {    
        if (isCustomIntegerTypeValue){ //logic to edit value on click and clear previous value
          previousValue = e.target.value;
          //console.log(1, previousValue, e.target.value);
        }                
        onChangeValue(e.target.value);        
      };             

      const _onBlur = (e) => {             
        let currentValue = e.target.value;
        if (isCustomIntegerTypeValue){   //logic to edit value on click and clear previous value
            if (currentValue == ""){
              currentValue = previousValue;    
            }                    
            //console.log(2, previousValue, currentValue);
        }             
        onUpdate(currentValue);
      };   
      
      const _onFocus = (e) => {    
        //console.log(3);
        if (isCustomIntegerTypeValue){//logic to edit value on click and clear previous value
           previousValue = e.target.value;
           e.target.value = null;
        }                     
      };             
      if (isCustomIntegerTypeValue) {
        let type = DynamicCellItemType.number;
        let metaData = {
          validationTypeOption: "INTEGER_ONLY",
        };
        return (
          <EvoInput.Input
            metaData={metaData}
            className="tool-input--grid"
            style={{ width: "100%" }}
            name={rowData.cellName}
            value={value ?? ""}
            type={type}
            onChange={_onChange}
            onBlur={_onBlur}         
            onFocus={_onFocus}
          />
        );
      }

      return (        
        <input
          style={{width : '100%'}}
          name={rowData.cellName}
          value={value??''}
          type={columnMetadataDefinition.type}
          onChange={_onChange}
          onBlur={_onBlur}
          //checked={isChecked}
        />
      );
    },
    Checkbox : ({ rowData, value, onChangeValue, onUpdate }) => {
      let columnMetadataDefinition = rowData.columnMetadataDefinition
      // if (columnMetadataDefinition.isEditable === false) {        
      //     return (<>{value}</>);      
      // }      

      const _onChange = (e) => {
        let newValue = !!e.target.checked;                     
        onChangeValue(newValue);        
        onUpdate(newValue); 
      };     

      return(     
        <Checkbox             
            className='tool-theme-checkbox'         
            name={rowData.cellName}              
            checked={value}
            onChange={_onChange}
            //disabled={columnMetadataDefinition.isEditable==false}
            >
        </Checkbox>    
      )
    },
    SelectReact: ({ rowData, value, onChangeValue, isClearable }) => {      
      let columnMetadataDefinition =rowData.columnMetadataDefinition
      if (!columnMetadataDefinition.isEditable) {
        return EvoActionsDynamicGridComponentEditableCellExtensions.CellExtentions.DefaultCell(value);        
      }

      const _onChange = (newValue, actionMeta) => {        
        onChangeValue(newValue, true);
      };

      function getSelectedOptionFromValue() {      
        let selectedOption = reactSelectOptions.find(x=> x == value);
        if (!selectedOption){
          selectedOption =
            columnMetadataDefinition.options?.find(
              (x) => x[columnMetadataDefinition.valueKey] === value || 
              (x[columnMetadataDefinition.valueKey] === value?.value??null)
            ) ?? null;  
            if (selectedOption){
              selectedOption.value = selectedOption[columnMetadataDefinition.valueKey];
              selectedOption.label = selectedOption[columnMetadataDefinition.displayKey];
            }            
        }
       
        return selectedOption;
      }

      

      function getItemFromOptions(item){
        return {...item,
          ...{ 
            value: item[columnMetadataDefinition.valueKey], 
            label: item[columnMetadataDefinition.displayKey],
            item : item          
          }       
        }        
      }

      function getReactSelectOptions() {
        return columnMetadataDefinition.options.map(function (item) {
          return getItemFromOptions(item)
        });
      }

      function filterReactSelectOptions(reactSelectOptions) {
        if (columnMetadataDefinition.optionsFilterSourceField &&
            columnMetadataDefinition.optionsFilterTargetField
          ){
            let optionsFilterSourceField = columnMetadataDefinition.optionsFilterSourceField;
            let optionsFilterTargetField = columnMetadataDefinition.optionsFilterTargetField;

            let rowFieldValue = rowData.values[optionsFilterSourceField];            
            let rowFieldActualValue = null;
            if (
              typeof rowFieldValue == "object" ||
              typeof rowFieldValue == "function"
            ) {
              rowFieldActualValue = rowFieldValue?.value ?? null;
            } else {
              rowFieldActualValue = rowFieldValue;
            }
            reactSelectOptions = reactSelectOptions.filter(x=>x[optionsFilterTargetField] == rowFieldActualValue);
        }
        return reactSelectOptions;
      }

      let reactSelectOptions = getReactSelectOptions();
      reactSelectOptions = filterReactSelectOptions(reactSelectOptions);

      if (reactSelectOptions.length == 0){
        return EvoActionsDynamicGridComponentEditableCellExtensions.CellExtentions.DefaultCell(value);
      }      
        
      let defaultValue = getSelectedOptionFromValue();      
      return (                       
        <Select          
          name={rowData.cellName}
          options={reactSelectOptions}
          defaultValue={defaultValue}
          onChange={_onChange}
          isClearable={isClearable}
          styles={EVOReactSelectHelper.getDefaultStyles(true)}
        />        
      );
    }
  },
};

