import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import EVOAlertComponent from "../components/common/EvoAlertComponent";
import ApiCallerHelperErrors from "./apiCallerHelper.errors";
import GlobalParametersHelper from "../common/GlobalParametersHelper";
export const ContentTypeEnum = {
  ApplicationJson: 'application/json',
  ApplicationFormUrlEncoded: 'application/x-www-form-urlencoded',
  ApplicationFormData: 'multipart/form-data',
}
async function _handleError(error){
  let promptResult = await EVOAlertComponent.DefaultError({});
  if (promptResult.isConfirmed == true) {
    ApiCallerHelperErrors.HandleError(error);
  }
}
export async function get(route, successCallback) {
  if (!hasInitialisedToken()) {
    return;
  }
  const result = await getAsync(route);
  successCallback(result)
}
export function getAsync(route, responseType) {
  responseType = responseType || null;
  if (!hasInitialisedToken()) {
    return;
  }
  return trackPromise(
    axios
      .get(process.env.REACT_APP_API_LINK + route, {
        headers: getAuthorizationAndGenericHeader(),
        responseType : responseType,
      })
      .catch((error) => {
        console.error("ApiCallerHelper -> _get -> Catch ", error);
        (async => _handleError(error))();
        throw error;
      })
  );
}
export async function post(route, body, successCallback) {
  if (!hasInitialisedToken()) {
    return;
  }
  const result = await postAsync(route);
  successCallback(result)
}
export function postAsync(route, body, ignoreInitialisedToken) {
  ignoreInitialisedToken = ignoreInitialisedToken || false;
  if (!hasInitialisedToken() && !ignoreInitialisedToken) {
    return;
  }
  let headers = new Headers(getGenericHeader);
  if (!ignoreInitialisedToken){
    headers = new Headers(getAuthorizationAndGenericHeader());
  }
  return trackPromise(
    fetch(process.env.REACT_APP_API_LINK + route, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  })
  .then(res => res.json())
  .catch((error) => {
    EVOAlertComponent.DefaultError({});
    console.error("ApiCallerHelper -> postAsync -> Catch ", error);
    throw error;
  }));
}
export function postFileAsync(route, body) {
  if (!hasInitialisedToken()) {
    return;
  }
  return trackPromise(
    fetch(process.env.REACT_APP_API_LINK + route, {
    method: "POST",
    headers: new Headers(getAuthHeaderWithoutContentType()),
    body: body
  })
  .then(res => res.json())
  .catch((error) => {
    EVOAlertComponent.DefaultError({});
    console.error("ApiCallerHelper -> postAsync -> Catch ", error);
    throw error;
  }));
}

export function getFileAsync(route, fileName) {
  if (!hasInitialisedToken()) {
    return;
  }
  return trackPromise(

    axios({
      url: process.env.REACT_APP_API_LINK + route,
      method: 'GET',
      responseType: 'blob', // important
      headers: getAuthorizationAndGenericHeader(),
      })  
    .then(response => {      
      const href = URL.createObjectURL(response.data);      
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();        
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch((error) => {
      EVOAlertComponent.DefaultError({});
      console.error("ApiCallerHelper -> postAsync -> Catch ", error);
      throw error;
    }));
}

export async function put(route, body, successCallback) {
  if (!hasInitialisedToken()) {
    return;
  }
  const result = await putAsync(route);
  successCallback(result)
}
export function putAsync(route, body) {
  if (!hasInitialisedToken()) {
    return;
  }
  return fetch(process.env.REACT_APP_API_LINK + route, {
    method: "PUT",
    headers: new Headers(getAuthorizationAndGenericHeader()),
    body: JSON.stringify(body),
  })
  .then(res => res.json())
  .catch((error) => {
    EVOAlertComponent.DefaultError({});
    console.error("ApiCallerHelper -> putAsync -> Catch ", error);
    throw error;
  });
}
export async function del(route, successCallback) {
  if (!hasInitialisedToken()) {
    return;
  }
  const result = await deleteAsync(route);
  successCallback(result)
}
export async function deleteAsync(route, contentType) {
  if (!hasInitialisedToken()) {
    return;
  }
  return trackPromise(
    axios
      .delete(process.env.REACT_APP_API_LINK + route, {
        headers: getAuthorizationAndGenericHeader(contentType),
      })
      .catch((error) => {
        EVOAlertComponent.DefaultError({});
        console.error("ApiCallerHelper -> _delete -> Catch ", error);
      })
  );
}
export function getAuthorizationAndGenericHeader(contentType) {
  if (!hasInitialisedToken()) {
    throw Error("Error No Token found to execute api call");
  }
  let token = GlobalParametersHelper.Token.get();  
  contentType = contentType || ContentTypeEnum.ApplicationJson;
  return {
    Authorization: "Bearer " + token,
    'Content-Type': contentType
  };
}
export function getAuthHeaderWithoutContentType() {
  if (!hasInitialisedToken()) {
    throw Error("Error No Token found to execute api call");
  }
  let token = GlobalParametersHelper.Token.get();  
  return {
    Authorization: "Bearer " + token
  };
}
export function getGenericHeader(contentType) {
  contentType = contentType || ContentTypeEnum.ApplicationJson;
  return {
    'Content-Type': contentType
  };
}
export function hasInitialisedToken() {
  if (GlobalParametersHelper.Token.get()) {
    return true;
  }
  return false;
}