import {ApiCallerHelper} from "../../api/apiCallerHelper"
import {WorkflowService}from "../../common/WorkflowService"
import {RedirectHelper} from "../../common/redirectHelper"
import {DateHelper} from "../../common/dateHelper"
import {WorkflowIdTypes} from "../../common/enums/workflowIdTypes.enum"
import EVOAlertComponent from "../../components/common/EvoAlertComponent";
import React, { Fragment, useState, useEffect } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Table,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
const InstanceHeader = ({    
    workflowId,
    workflowInstanceId,
    customButton
    }) => { 
    workflowId = workflowId || WorkflowIdTypes.FAULTS
    const [_workflowInstanceInfo, setWorkflowInstanceInfo] = React.useState({});
    const [_viewModel, setViewModel] = React.useState({});

    useEffect(() => {        
        if (!workflowInstanceId){
            return;
        }
        (async () => {
          let result = await WorkflowService.Extentions.AspraSpitia.getWorkflowInfoDetailsByWorkflowInstanceIdAsync({
                workflowInstanceId:workflowInstanceId
            })                                   
          setWorkflowInstanceInfo(result.data);
        })();
      }, [workflowInstanceId]);

      useEffect(() => {           
        function getModelByType(workflowIdType){
            let model =
            _workflowInstanceInfo[0]?.wfiParentIdData?.find(
              (x) => x.workflowIdType == workflowIdType
            ) ?? null;  
            return model;
        }                  
        if (!_workflowInstanceInfo) {return;}   
        
        const modelTypeMap = {
          [WorkflowIdTypes.FAULTS]: "FAULT",
          [WorkflowIdTypes.VOUCHERS]: "VOUCHER",
          [WorkflowIdTypes.EXTRA]: "EXTRAS",
        };

        const model = getModelByType(modelTypeMap[workflowId]);
        if (!model) {
          return;
        }        

        let serialNumber;
        let serialNumberTitle = "A/A";
        let startedAt;
        switch (workflowId) {
          case WorkflowIdTypes.FAULTS:            
            serialNumber = model.wfiParent_SerialNumber;
            startedAt = DateHelper.getFormatDateTitle(model.wfiParent_StartedAt);
            break;
          case WorkflowIdTypes.VOUCHERS:
            //serialNumber = model.wfiChildVoucher_SerialNumber;
            serialNumberTitle = "Αρ. Δελτίου"
            serialNumber = model._wfi_c_voucher_WorkflowInstancesSeed_Id;
            startedAt = DateHelper.getFormatDateTitle(model.wfiChildVoucher_StartedAt);
            break;
          case WorkflowIdTypes.EXTRA:
            serialNumber = model.wfiChildExtras_SerialNumber;
            startedAt = DateHelper.getFormatDateTitle(model.wfiChildExtras_StartedAt);
            break;
        }
      //console.log(model);
        const viewModel = {  
          serialNumberTitle,        
          stepName: model._ws_parent_Name,
          parentSerialNumber: model.wfiParent_SerialNumber,
          parentStartedAt: DateHelper.getFormatDateTitle(model.wfiParent_StartedAt),          
          serialNumber,
          startedAt,
          parentId: model.wfiParentId,
          calculatedFaultStatusDescription: model.calculatedFaultStatusDescription,
          calculatedFaultSubStatusDescription: model.calculatedFaultSubStatusDescription,
          faultTypeDescription: model.faultTypeDescription,
          usrwfidvouchermixanikosanathesis_displayname : model._usrwfidvouchermixanikosanathesis_displayname,
        };

        setViewModel(viewModel);     
        
      }, [_workflowInstanceInfo]);
   
    // let CustomButtonRender = ({customButton}) => {      
    //   if (!customButton) {
    //     customButton = {
    //       onClick : () => RedirectHelper.RedirectTo.flowInstance(_viewModel.parentId),
    //       title : "Στοιχεία Βλάβης"
    //     }      
    //   }

    //   return (
    //     <Button
    //       color="primary"          
    //       onClick={customButton.onClick}          
    //       size="md"
    //       //style={{backgroundImage: "url(" + require("assets/img/btn-background.png") + ")"}}
    //     >
    //       {customButton.title}
    //     </Button>
    //   );
    // };

    let NavigateHomeButtonComponent = () => {
      const onClickRedirectHome = async () => {
        let promptResult = await EVOAlertComponent.Prompt({
          message:
            "Επιθυμείτε να επιστρέψετε στην Αρχική Οθόνη; Οι όποιες αλλαγές θα χαθούν."        
        });
  
        if (promptResult.isConfirmed === true) {        
          RedirectHelper.RedirectTo.home();
        }     
      };
  
      return (
        <Button
          color="primary"                   
          onClick={() => {
            onClickRedirectHome();
          }}
          size="md"                
        >
          Επιστροφή <i className="fa fa-undo"></i>
        </Button>
      );
    };


    return (
      <>
        <Card>
          {/* <CardHeader><h3>Ροή Εργασίας</h3></CardHeader> */}
          <CardBody className="no-padding">
            <Container fluid>
              <Row>
                <Col md="8">
                  <Row>
                    <Col md="3">
                      <div className="tool-theme_typing--input-label">{_viewModel.serialNumberTitle}:</div>
                      <div>{_viewModel.serialNumber}</div>
                    </Col>
                    <Col md="3">
                      <div className="tool-theme_typing--input-label">Ημερομηνία Έναρξης:</div>
                      <div>{_viewModel.startedAt}</div>
                    </Col>
                    <Col md="3">
                      <div className="tool-theme_typing--input-label">Κατάσταση Αιτήματος:</div>
                      <div>{_viewModel.calculatedFaultSubStatusDescription}</div>
                    </Col>
                    <Col md="3">
                      <div className="tool-theme_typing--input-label">Τεχνικός:</div>
                      <div>{_viewModel.usrwfidvouchermixanikosanathesis_displayname}</div>
                    </Col>                   
                  </Row>                  
                </Col>
                <Col md="4" >  
                <div  className="float-right">                                        
                 {/* <CustomButtonRender customButton={customButton}/>  */}
                  {/* &nbsp;     */}
                 <NavigateHomeButtonComponent/> 
                 </div>                   
                </Col>
              </Row>
              <Row>
                <Col md="12" className="mt-4">
                  <hr></hr>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      </>
    );    
}
export default InstanceHeader;
