import {
  get, getAsync,
  post, postAsync,
  put, putAsync,
  del, deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum
  } from "./apiCallerHelper.core";
  

  export async function getAllFlowsAsync() {
    var route = "/api/flows/all/";
    return getAsync(route);
  }

  export async function getAllAllowedAsync() {
    var route = "/api/flows/all_allowed/";
    return getAsync(route);
  }
  

  export async function getWorkflowsInfoAsync() {
    var route = "/api/flows/get_workflows_info/";
    return getAsync(route);
  }

  export async function newWorkflowAsync(data) {
    var route = "/api/flows/new/";
    return postAsync(route, data)
  }

  export async function editWorkflowAsync(data) {
    var route = "/api/flows/edit/";
    return putAsync(route, data)
  }

  export async function deleteWorkflowAsync(flow_id) {
    var route = "/api/flows/delete/" + flow_id + "/";
    return deleteAsync(route, ContentTypeEnum.ApplicationFormUrlEncoded)
  }
