import { React, Fragment, useState, useContext, createContext, useEffect } from "react";
import { DynamicStepActionTypes } from "../../common/enums/dynamicStepActionTypes.enum";
import { OverrideRules } from "../../views/instances/custom/custom.InstanceBase.overrides.aspra-spitia";
import Rating from "./Rating";
import DynamicActionLabel from "./DynamicActionLabel";

export const DynamicRating = ({ self, isEnabled, dynamicActions, stepId, isShowlabel }) => {
    isShowlabel = isShowlabel || false;

    if (dynamicActions.action.Type != DynamicStepActionTypes.rating) {
      return <></>;
    }

    let ovverideRule = OverrideRules.getOnRenderControlOverrideRule(
      self.state,
      dynamicActions.action.Id
    );

    if (ovverideRule.isVisible != null && !ovverideRule.isVisible) {
      return <></>;
    }
    
    return (
      <Fragment>
        {isShowlabel && (
          <Fragment>
            <DynamicActionLabel
              title={dynamicActions.action.Name}
              description={dynamicActions.action.Description}
            />
            <br></br>
          </Fragment>
        )}
        <Rating
          name={dynamicActions.action.Id}
          onUpdate={(key, value) => {
            self.updateInputValue(
              isEnabled,
              value,
              stepId,
              dynamicActions.action.Id
            );
          }}
          isReadOnly={isEnabled === false}
          rating={dynamicActions.data.Data}
        ></Rating>
      </Fragment>
    );  
};
