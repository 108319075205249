import { filterOptions } from "common/MonthlyVoucherHelper.options";

const pdfStyles = {
  header: {
    fontSize: 20,
    bold: true,
    alignment: 'justify',
    margin: [0, 20, 0, 20]
  },
  invoiceNumber: {
    fontSize: 14,
    bold: true,
    alignment: 'justify',
    margin: [0, 5, 0, 5],
  },		
  billingTable: {
    alignment: 'justify',
    margin: [0, 15, 0, 15],
  },
  tableHeader: {
    bold: true,
    fontSize: 13,
    color: '#020180',
  },
  columnName: {
    fontSize: 7,
    decoration: 'underline',
    alignment: 'center',
  },
  columnContent: {
    fontSize: 7,
    alignment: 'center',
  },
  lastRow: {
    fontSize: 10,
    color: '#020180',
  },
  totalSum: {
    alignment: 'justify',
    margin: [10, 15, 10, 15],
    fontSize: 10,
    color: '#840a0f',
  },
};

const createPdfElement = (text, style, alignment) => {
  return {
    text: text,
    style: style,
    alignment: alignment
  }
}

const createBillingsTables = (sortedRows) => {
  return sortedRows.reduce((billings, billingCategory) => {
    if (billingCategory.sum !== 0) {

      const rows = billingCategory.vouchers.map((voucher) => {
        const priceWithoutVat = parseFloat(voucher.voucher_price_without_vat);
        const Vat = parseFloat(voucher.voucher_price_with_vat) - parseFloat(voucher.voucher_price_without_vat);

        return [
          {text: voucher.voucher_started_at, style: 'columnContent', border: [true, false, false, false]},
          {text: voucher.voucher_serial_number, style: 'columnContent'},
          {text: voucher.external_house_id, style: 'columnContent'},
          {text: billingCategory.billing.label, style: 'columnContent'},
          {text: voucher.fault_description, style: 'columnContent'},
          {text: voucher.mechanic_displayname, style: 'columnContent'},
          {text: voucher.contractor_displayname, style: 'columnContent'},
          {text: voucher.completion_estimate, style: 'columnContent'},
          {text: '', style: 'columnContent'},
          {text: parseFloat(priceWithoutVat.toFixed(2)), style: 'columnContent'},
          {text: parseFloat(Vat.toFixed(2)), style: 'columnContent'},
          {text: '24%', style: 'columnContent', border: [false, false, true, false]},
        ]
      })

      billings.push({
			  style: 'billingTable',
        table: {
          widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
          headerRows: 2,
          body: [
            [
              {text: `ΧΡΕΩΣΗ ${billingCategory.billing.value}`, colSpan: 12, style: 'tableHeader', border: [true, true, true, true]}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}
            ],
            [
              {text: 'ΗΜ. ΚΑΤΑΧ.', style: 'columnName', border: [true, false, false, false]},
              {text: 'ΑΡ. ΔΕΛΤ.', style: 'columnName'},
              {text: 'Μ/Κ', style: 'columnName'},
              {text: 'Β.Τ.', style: 'columnName'},
              {text: 'ΠΕΡΙΓΡΑΦΗ', style: 'columnName'},
              {text: 'ΤΕΧΝΙΚΟΣ', style: 'columnName'},
              {text: 'ΕΡΓΟΛΑΒΟΣ', style: 'columnName'},
              {text: 'ΠΡΟΒΛ. ΕΚΤΕΛ.', style: 'columnName'},
              {text: 'ΗΜ. ΤΙΜΟΛ.', style: 'columnName'},
              {text: 'ΠΟΣΟΝ', style: 'columnName'},
              {text: 'Φ.Π.Α.', style: 'columnName'},
              {text: 'ΦΠΑ%', style: 'columnName', border: [false, false, true, false]},
            ],
            ...rows,
            [
              {text: "ΥΠΟΣYΝΟΛΟ", style: 'lastRow', colSpan: 9, border: [true, true, false, true]}, {}, {}, {}, {}, {}, {}, {}, {},
              {text: `${billingCategory.sum}`, style: 'lastRow', border: [false, true, false, true]}, 
              {text: `${billingCategory.sumVat}`, style: 'lastRow', colSpan: 2, border: [false, true, true, true]}, {}
            ],
          ]
        },
        layout: {
          defaultBorder: false,
        }
      });
    }

    return billings;
  }, []);

}

export const createDocDefinition = (sortedRows, metadata, voucher_contract_number) => {
  console.log(sortedRows)
  const header = createPdfElement('Μ Η Ν Ι Α Ι Ε Σ  Π Ι Σ Τ Ο Π Ο Ι Η Σ Ε Ι Σ', 'header', 'center');
  const invoiceNumber = createPdfElement(`No. ΤΙΜΟΛΟΓΙΟΥ          : ${metadata.invoice_number}`, 'invoiceNumber', 'left');
  const daNumber = createPdfElement(`No. DA                            : ${metadata.da_number}`, 'invoiceNumber', 'left');
  const contractNumber = createPdfElement(`ΑΡΙΘΜΟΣ ΣΥΜΒΑΣΗΣ : ${voucher_contract_number}`, 'invoiceNumber', 'left');

  const billings = createBillingsTables(sortedRows);

  let totalSum = 0;
  let totalSumVat = 0;

  sortedRows.forEach(billingOption => {
    totalSum += billingOption.sum;
    totalSumVat += billingOption.sumVat;
  })

  const totalSumHeader = {
    style: 'totalSum',
    table: {
      widths: [133, "auto", "auto", "auto", "auto", 133, "auto", "auto", "auto", "auto", "auto", "auto"],
      body: [
        [
          {text: 'ΓΕΝΙΚΟ ΣΥΝΟΛΟ', colSpan: 5}, {}, {}, {}, {},
          {text: 'ΠΛΗΡΩΤΕΑ ΠΟΣΑ', colSpan: 4}, {}, {}, {},
          {text: `${totalSum}`},
          {text: `${totalSumVat}`, colSpan: 2}, {}
        ]
      ],
    },
    layout: {
      defaultBorder: false,
    },
  };

  return {
    content: [
      header,
      invoiceNumber,
      daNumber,
      contractNumber,
      ...billings,
      totalSumHeader
    ],
    styles: pdfStyles
  };
};

export const sortRowsByVoucherBilling = (rows) => {
  const billingOptions = filterOptions.xreosi;
  const sortedRows = [];

  billingOptions.forEach(option => {
    const tempObject = {};
    let sum = 0;
    let sumVat = 0;

    tempObject.billing = option;
    tempObject.vouchers = rows.filter(voucher => {
      if(voucher.voucher_billing === option.value) {
        sum += parseFloat(voucher.voucher_price_without_vat);
        sumVat += parseFloat(voucher.voucher_price_with_vat) - parseFloat(voucher.voucher_price_without_vat);
      }
      return voucher.voucher_billing === option.value;
    });
    tempObject.sum = parseFloat(sum.toFixed(2));
    tempObject.sumVat = parseFloat(sumVat.toFixed(2));

    sortedRows.push(tempObject);
  });

  return sortedRows;
};