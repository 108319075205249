import { React, Fragment, useState, useContext, createContext, useEffect } from "react";
import axios from "axios";
import ReactTooltip from 'react-tooltip';

import { DynamicStepActionTypes } from "../../common/enums/dynamicStepActionTypes.enum";
import { OverrideRules } from "../../views/instances/custom/custom.InstanceBase.overrides.aspra-spitia";
import Moment from "moment";
import 'moment/locale/el';
import EVOAlertComponent from "../common/EvoAlertComponent";

import {
  Row,
  Col,
  Input,
  Table,
  Button
} from "reactstrap";

import { ApiCallerHelper } from "../../api/apiCallerHelper";
import DynamicActionLabel from "./DynamicActionLabel";

export const DynamicFile = ({  
  isEnabled,
  dynamicActions,
  stepId,
  instanceId,
  initialFiles,
  isShowlabel
}) => {
    const [files, setFiles] = useState(initialFiles)
    const [fileUpload, setFileUpload] = useState(null)

    isShowlabel = isShowlabel || false;

    const  uploadFile = async (evt, fileid) =>{
        var res = fileid.split("/");
        let stepId = res[0];
        let actionId = res[1];
        let file = evt.target.files[0];
        let resultAsync = await ApiCallerHelper.Files.uploadFile(
            {file, instanceId, stepId, actionId}
        )          
        let workflowInstanceFilesResultAsync = await ApiCallerHelper.Files.getAllFilesByWorkflowInstanceIdAsync(instanceId);
        setFiles(workflowInstanceFilesResultAsync.data);           
    }

    const deleteFile = async (fileId) => {
        let promptResult = await EVOAlertComponent.Prompt({});
        if (promptResult.isConfirmed === true) {
            let deleteResultAsync = await ApiCallerHelper.Files.deleteFileAsync(fileId);
            let filesResultAsync = await ApiCallerHelper.Files.getAllFilesByWorkflowInstanceIdAsync(instanceId);
            setFiles(filesResultAsync.data);
        }                        
    }

    const viewFile = async (fileName) => {
        await ApiCallerHelper.Files.getFile(fileName);
    }

    if (dynamicActions.action.Type != DynamicStepActionTypes.file){
        return (<></>)
    }     

    let addButton = (<></>)
    if (isEnabled){
        addButton =  (
            <Row>
                <Col md="12">
                    <label className="btn btn-primary" htmlFor={stepId + "/" + dynamicActions.action.Id}>
                        Επιλογή αρχείου
                    </label>
                    &nbsp;
                    <i className="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + stepId + dynamicActions.action.Id} aria-hidden="true"></i>
                    <ReactTooltip id={'info-form-control-alternative' + stepId + dynamicActions.action.Id} type='info'>
                        <span>Μέγιστο μέγεθος αρχείου 3,5ΜΒ.</span>
                        <div id="imageattooltip"></div>
                    </ReactTooltip>
                    <Input
                        className="form-control-alternative"
                        id={stepId + "/" + dynamicActions.action.Id}
                        style={{ visibility: "hidden" }}
                        type={dynamicActions.action.Type}
                        disabled={!isEnabled}
                        onChange={evt => uploadFile(evt, stepId + "/" + dynamicActions.action.Id)}
                    />
                </Col>
            </Row> 
        )
    }     

    return (
      <Fragment>
        {isShowlabel && (
          <Fragment>
            <DynamicActionLabel
              title={dynamicActions.action.Name}
              description={dynamicActions.action.Description}
            />
            <br></br>
          </Fragment>
        )}
        {addButton}
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Ονομα</th>
              <th scope="col">Ημερομηνια Υποβολης</th>
              <th scope="col">Ενεργειες</th>
            </tr>
          </thead>
          <tbody>
            {files.map((dynamicFiles, key) => (
              <Fragment key={key}>
                {dynamicActions.action.Id === dynamicFiles.ActionId && (
                  <>
                    <tr>
                      <td>{dynamicFiles.OriginalName}</td>
                      <td>
                        {Moment(dynamicFiles.UploadDate)
                          .locale("el")
                          .format("dddd, DD MMM YYYY h:mm:ss a")}
                      </td>
                      <td>
                        {dynamicFiles.Type.contains != "" && <></>}
                        <Button
                          color="primary"
                          target="_blank"
                          size="sm"
                          title="Προβολή"
                          onClick={() => viewFile(dynamicFiles.Id)}
                        >
                          <i className="fas fa-eye" />
                        </Button>
                        {isEnabled === true && (
                          <>
                            <Button
                              color="danger"
                              onClick={() => {
                                deleteFile(dynamicFiles.Id);
                              }}
                              size="sm"
                              title="Διαγραφή"
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          </>
                        )}
                      </td>
                    </tr>
                  </>
                )}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Fragment>
    );
};
