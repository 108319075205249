import {
    get, getAsync,
    post, postAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    } from "./apiCallerHelper.core";

    export async function buttonPressedAsync(stepActionId, workflowInstanceId, metadata) {
        var route = "/api/dynamic/pressed/";
        var data = {
          ActionId: stepActionId,
          InstanceId: workflowInstanceId,
          MetaData: metadata
        }
        return postAsync(route, data);
      }