import { RedirectHelper } from "../common/redirectHelper"
import GlobalParametersHelper from "../common/GlobalParametersHelper"
export const ApiCallerHelperErrors = { 
  ErrorStatus: {
    'Unauthorized': 401,    
  },
  IsErrorStatus : (error, errorStatus) => {
    return error?.response?.status??'' === errorStatus;           
  },
  HandleError : (error) => {
    if (ApiCallerHelperErrors.IsErrorStatus(error, ApiCallerHelperErrors.ErrorStatus.Unauthorized)){
      GlobalParametersHelper.Token.remove();
      RedirectHelper.RedirectTo.logout();
    }    
  }
}

export default ApiCallerHelperErrors;