import React, { useState, useEffect, Fragment } from "react";
import InstanceHeader from "../../../views/instances/instanceHeader";
import ApiCallerHelper from "../../../api/apiCallerHelper";
import WorkflowService from "../../../common/WorkflowService";
import InstanceExistingSpecificFlowStep from "../InstanceExistingSpecificFlowStep";
import VouchersExtra from "./VouchersExtras";
import RedirectHelper from "../../../common/redirectHelper";
import EVOAlertComponent from "../../../components/common/EvoAlertComponent";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Moment from "moment";
import "moment/locale/el";
import GlobalParametersHelper from "common/GlobalParametersHelper";

const CustomInstanceDetails = ({ workflowInstanceId }) => {
  const [_viewModel, setViewModel] = useState(null);  

  useEffect(() => {
    if (!workflowInstanceId) {
      return;
    }
    (async () => {
      let promiseResults = await Promise.all([
        ApiCallerHelper.Instances.getInstanceAsync(workflowInstanceId),
        ApiCallerHelper.Files.getAllFilesByWorkflowInstanceIdAsync(
          workflowInstanceId
        ),
        ApiCallerHelper.Instances.fetchChildDetailsByAttribute(
          workflowInstanceId,
          "voucher"
        ),
        WorkflowService.Extentions.AspraSpitia.getWorkflowInfoDetailsByWorkflowInstanceIdAsync(
          {
            workflowInstanceId: workflowInstanceId,
          }
        ),
      ]);
      //TODO needs refactor not needed all these calls
      let instanceResult = promiseResults[0];
      let allFiles = promiseResults[1];
      let voucherResult = promiseResults[2];
      let workflowInfoDetails = promiseResults[3];
      if(workflowInfoDetails.data[0].wfiParentIdData[0].faultTypeDescription === 'Οικία'){
        
      }

      let data = workflowInfoDetails?.data[0];
      let fault = instanceResult.data.instance;
      let isAddNewExtraAvailable = fault.Status > 1 && fault.Completed == false;
      let viewModel = {
        files: allFiles.data,
        comments: instanceResult.data.instance.Comments,
        workflowInfoDetails: workflowInfoDetails,
        workflowInstanceIdFault: data?.wfiParentId ?? null,
        workflowInstanceIdVoucher:
          data?.workflowInstancesVouchers[0]?.wfiChildVoucherId ?? null,
        isAddNewExtraAvailable: isAddNewExtraAvailable,
      };
      setViewModel(viewModel);
      //console.log(viewModel);
    })();
  }, [workflowInstanceId]);  

  //   notAvailableDemo = async () => {
  //     let promptResult = await EVOAlertComponent.Alert({message:"Μη διαθέσιμη λειτουργια Παρουσίασης"});
  //   }

  const InternalCommentsComponent = ({ workflowInstanceId, comments }) => {
    const [_comment, setComment] = useState(comments);

    async function saveInternalComment() {
      let promptResult = await EVOAlertComponent.Prompt({
        message:
          "Είστε σίγουροι ότι θέλετε να προχωρήσετε με την αποθήκευση των σχολίων;",
      });

      if (promptResult.isConfirmed === true) {
        let commentResult =
          await ApiCallerHelper.Instances.newInternalCommentAsync(
            workflowInstanceId,
            _comment
          );
      }
    }

    function updateComment(e) {
      let value = e.target.value;
      setComment(value);
    }

    return (
      <>
        <h6 className="heading-small text-muted mb-4">
          Εσωτερικο σχολιο βλαβης & εργασιων
        </h6>
        <Input
          className="form-control-alternative"
          id="input-email"
          defaultValue={comments}
          placeholder="Σχόλιο"
          type="textarea"
          // disabled={true}
          onChange={(e) => updateComment(e)}
        />
        <div className="float-right">
          <Button
            className="mt-3"
            color="primary"
            onClick={(e) => {
              saveInternalComment(e);
            }}
            size="md"
          >
            Αποθήκευση
          </Button>
        </div>
      </>
    );
  };

  const RelatedFilesComponent = ({ files }) => {
    const viewFile = async (fileId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_LINK}/api/files/presigned-url/${fileId}`, {
          headers: {
            Authorization: `Bearer ${GlobalParametersHelper.Token.get()}`,
          },
        });
        const data = await response.json();
        if (data.url) {
          window.open(data.url, "_blank"); // Open the presigned URL in a new tab
        }
      } catch (error) {
        console.error("Failed to get presigned URL", error);
      }
    };

    return (
      <>
        <h6 className="heading-small text-muted mb-4">Σχετικα Εγγραφα</h6>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">Ονομα αρχειου</th>
              <th scope="col">Ημερομηνια Αναρτησης</th>
              <th scope="col">Ενεργειες</th>
            </tr>
          </thead>
          <tbody>
            {files.map((dynamicFiles) => (
              <tr>
                <td>{dynamicFiles.OriginalName}</td>
                <td>
                  {Moment(dynamicFiles.UploadDate)
                    .locale("el")
                    .format("dddd, DD MMM YYYY")}
                </td>
                <td>
                  <Button
                    color="primary"
                    onClick={() => viewFile(dynamicFiles.Id)}
                    size="sm"
                    title="Προβολή"
                  >
                    <i className="fas fa-eye" />
                  </Button>
                  {/* <Button
                    color="danger"
                    // href={dynamicFiles.Link}
                    size="sm"
                    title="Διαγραφή"
                >
                    <i className="fas fa-trash" />
                </Button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };

  const AggregatedWorkVouchersExtrasComponent = ({
    workflowInstanceId,
    workflowInfoDetails,
    isAddNewExtraAvailable,
  }) => {
    function redirectFunction(instanceId) {
      RedirectHelper.RedirectTo.flowInstance(instanceId);
    }

    return (
      <>
        <h6 className="heading-small text-muted mb-4">
          Δελτίο Εργασιών - Σύνολο Υλικών SAP
        </h6>
        <VouchersExtra
          workflowInstanceId={workflowInstanceId}
          workflowInfoDetails={workflowInfoDetails ?? null}
          redirectFunction={redirectFunction}
          isAddButtonsVisible={isAddNewExtraAvailable}
        />
      </>
    );
  };

  if (_viewModel == null) {
    return;
  }
  return (
    <>
      <Card>
        <CardHeader>
          <h3>Στοιχεία Βλάβης</h3>
        </CardHeader>
        <InstanceHeader
          workflowInstanceId={_viewModel.workflowInstanceIdFault}
          //   customButton={{
          //     onClick: ()=> {this.setIsInstanceStepsModalVisible(true)},
          //     title: "Βήματα Ροών",
          //   }}
        />
        
        <CardBody className="no-padding mt-2">
        <Tabs defaultActiveKey="1" className={"tool-tabs-title--bold"}>    
            <Tab eventKey={"1"} title={"Λεπτομέρειες"}>
            <Container fluid>
                <Row className="mt-2">
                  <Col md="6">
                    <InternalCommentsComponent
                      comments={_viewModel.comments}
                      workflowInstanceId={_viewModel.workflowInstanceIdFault}
                    />
                  </Col>
                  <Col md="6">
                    <RelatedFilesComponent files={_viewModel.files} />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md="12">
                    <AggregatedWorkVouchersExtrasComponent
                      workflowInstanceId={_viewModel.workflowInstanceIdFault}
                      workflowInfoDetails={_viewModel.workflowInfoDetails}
                      isAddNewExtraAvailable={_viewModel.isAddNewExtraAvailable}
                    />
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey={"2"} title={"Στοιχεία Βλάβης"}>
            <InstanceExistingSpecificFlowStep
                workflowInstanceId={_viewModel.workflowInstanceIdFault}
                statusToShow={1}
              />
            </Tab>
            <Tab eventKey={"3"} title={"Δελτίο Εργασίας"}>
            <InstanceExistingSpecificFlowStep
                workflowInstanceId={_viewModel.workflowInstanceIdVoucher}
                statusToShow={1}
              />
            </Tab>            
          </Tabs>        
         
        </CardBody>
      </Card>
    </>
  );
};

export default CustomInstanceDetails;
