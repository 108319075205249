import React from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col
} from "reactstrap";

import GenericHeader from "../../../components/Headers/GenericHeader";
import 'moment/locale/el';
import RedirectHelper from "../../../common/redirectHelper";
import GlobalParametersHelper from "../../../common/GlobalParametersHelper";

class Step extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            userDetails: [],
            instances: [],
            dict: {},
            stepActions: []
        };
    };

    async componentDidMount() {
        this.setState({
            step_id: window.location.pathname.split('/')[3]
        })
        let step_id = window.location.pathname.split('/')[3];

        if (GlobalParametersHelper.Token.get()) {
            await fetch(process.env.REACT_APP_API_LINK + "/api/users/current/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + GlobalParametersHelper.Token.get(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    //console.log(result);

                    if (result.PersonRole != 'Master')
                        this.props.history.goBack();

                    this.setState({
                        userDetails: result
                    });
                })

            await fetch(process.env.REACT_APP_API_LINK + "/api/stepactions/all/" + step_id + '/', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + GlobalParametersHelper.Token.get(),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    //console.log(result);
                    this.setState({
                        stepActions: result
                    })
                })
        };
    }

    render() {
        return (
            <>
                {this.state.userDetails.PersonRole === 'Master' ?
                    <>
                        <GenericHeader />

                        <Container className="mt--7" fluid>
                            <Row className="mt-5">
                                <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow-none">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h3 className="mb-0">Διαμόρφωση Πεδίων</h3>
                                                </div>
                                                <div className="col text-right">
                                                    <Button
                                                        color="success"
                                                        href={"/basicLayout/newField/" + this.state.step_id}
                                                        size="sm"
                                                    >
                                                        Προσθήκη Πεδίου111

                                                    </Button>
                                                </div>

                                            </Row>
                                            <Table className="align-items-center table-flush" responsive style={{ marginTop: '20px' }}>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">ΣΕΙΡΑ ΠΕΔΙΟΥ</th>
                                                        <th scope="col">ΟΝΟΜΑ ΠΕΔΙΟΥ</th>
                                                        <th scope="col">ΠΕΡΙΓΡΑΦΗ ΠΕΔΙΟΥ</th>
                                                        <th scope="col">ΚΛΕΙΔΙ ΠΕΔΙΟΥ</th>
                                                        <th scope="col">ΕΙΔΟΣ ΠΕΔΙΟΥ</th>
                                                        <th scope="col">ΤΥΠΟΣ ΠΕΔΙΟΥ</th>
                                                        <th scope="col">ΕΝΕΡΓΕΙΕΣ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.stepActions.map((stepAction) =>
                                                        <tr key={stepAction.Name}>
                                                            <td>{stepAction.Order}</td>
                                                            <td>{stepAction.Name}</td>
                                                            <td>{stepAction.Description}</td>
                                                            <td>{stepAction.Target}</td>
                                                            <td>{stepAction.Type}</td>
                                                            <td>{stepAction.Required === true ? 'Υποχρεωτικό' : 'Μη Υποχρεωτικό'}</td>

                                                            <td><Button
                                                                color="primary"                                                                
                                                                size="sm"
                                                                onClick={()=> RedirectHelper.RedirectTo.stepAction(stepAction.Id) }
                                                            >
                                                                Επεξεργασία
                                                            </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </CardHeader>
                                    </Card>
                                </Col>
                            </Row>

                            <div className='push'></div>
                        </Container>
                    </>
                    :
                    <>
                    </>
                }


            </>
        );
    }
}

export default Step;