import React, { Fragment } from "react";
import {UserRoleHelper} from "../../../common/userRoleHelper"
import {ApiCallerHelper} from "../../../api/apiCallerHelper"
import {GetDynamicStepActionTypesOptionList, DynamicStepActionTypes} from "../../../common/enums/dynamicStepActionTypes.enum"
import EvoActionsDynamicGridComponent from "../../../components/DynamicGridComponent/EvoActionsDynamicGridComponent";
import {EVOReactTableDynamicGridHelper, GridActions} from "../../../components/common/evo.react-table-dynamic-grid-helper";
import EVOReactSelectHelper from "../../../components/common/evo.react-select-helper"
import Select from 'react-select'
import Rating from "../../../components/actionTypes/Rating"
import Map from "../../../components/actionTypes/Map"
import ReactDatetime from "react-datetime";
import AssetHelper from  "../../../common/AssetHelper"
import {DynamicInputValidatonType, GetDynamicInputValidatonTypeOptionList} from  "../../../common/enums/dynamicInputValidationTypes.enum"
import EvoInput from "../../../components/common/evo.Input"
import { Checkbox } from 'antd';
import GlobalParametersHelper from "../../../common/GlobalParametersHelper";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    InputGroup,
    Label,
    Table,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,     
    
    InputGroupText,    
} from "reactstrap";
import Modal from 'react-bootstrap/Modal';
import 'moment/locale/el';
import Accordion from 'react-bootstrap/Accordion';
import ReactTooltip from 'react-tooltip';
import EVOAlertComponent from "../../../components/common/EvoAlertComponent";

class Flow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            userDetails: null,
            instances: [],
            dict: {},
            steps: [],
            show: false,
            show2: false,
            isEditStepActionModalVisible: false,
            stepName: false,
            stepDescription: false,
            stepPriority: false,
            workflowId: '',
            stepActionName: false,
            stepActionDescription: false,
            stepActionPriority: false,
            stepActionKind: "text",
            stepActionRequired: true,
            stepActionIsReadonly: false,
            stepActionIsVisible: true,
            stepActionTarget: false,
            tempStep: '',
            stepActionData: [],
            dynamicButtonOption: "-",
            editMetadata: null
        };
        this.reactSelectDynamicGridComponentRef = React.createRef()
        this.reactGridDynamicGridComponentRef = React.createRef()
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    getAndSetUserAndFlowStepsAsync = async () => {
        const currentUserResult = await ApiCallerHelper.Users.getCurrentAsync()
        if (!UserRoleHelper.isMaster(currentUserResult.data)){
            this.props.history.goBack();
        }

        let workflowId = window.location.pathname.split('/')[3];

        const allFlowSteps = await ApiCallerHelper.Steps.getAllByWorkflowIdAsync(workflowId)  
        this.setState({
          userDetails: currentUserResult.data,
          steps: allFlowSteps.data,
          workflowId : workflowId
        });
      }  

    async componentDidMount() {
        this.getAndSetUserAndFlowStepsAsync();
    }

    setShow(boolean) {
        setTimeout(() => {
            this.setState({
                show: boolean,
                editMetadata : null            
            });  
        }, 500);     
    }

    setShow2(boolean, stepId) {
        setTimeout(() => {
            this.setState({
                show2: boolean,
                tempStep: stepId,
                editMetadata : null
            });
        }, 500);
    }
    
    setIsEditStepActionModalVisible = async (isEditStepActionModalVisible, stepActionId) => {
        if (!isEditStepActionModalVisible){
            this.setState({
                stepActionData: [],
                isEditStepActionModalVisible: isEditStepActionModalVisible
            }); 
        }
        if (!stepActionId){
            return;
        }
        const getCurrentStepActionDataAsync = await ApiCallerHelper.StepActions.getCurrentStepActionAsync(stepActionId);                
        this.setState({
            stepActionData: getCurrentStepActionDataAsync.data,
            isEditStepActionModalVisible: isEditStepActionModalVisible,
            editMetadata : null
        });        
    }

    handleChange(evt) {        
        const value = evt.target.value;        
        this.setState({
            ...this.state,
            [evt.target.name]: value
        });
    }
    
    handleDimensionsMetaData = {
        handleIsNewRow :  (evt) => {
            const value = evt.target.value??true;
            const metaData = JSON.parse(this.state?.stepActionData?.MetaDataJson??"{}");
            metaData.isBsNewRow = value;   
            this.state.stepActionData["MetaDataJson"] = JSON.stringify(metaData);        
        },
        handleBreakpoints : (evt) => {
            const value = evt.target.value??12;
            const metaData = JSON.parse(this.state?.stepActionData?.MetaDataJson??"{}");               
            let bp = value;
            metaData.bsBreakpoint = {
                sm: bp,
                md: bp,
                lg: bp,
                xl: bp,
                xxl: bp,
              };
            this.state.stepActionData["MetaDataJson"] = JSON.stringify(metaData);
        },
        getDefaultBreakpointValue : () => {
            const metaData = JSON.parse(this.state?.stepActionData?.MetaDataJson??"{}"); 
            return metaData?.bsBreakpoint?.md??12;
        },
        getDefaultIsNewRowValue : () => {
            const metaData = JSON.parse(this.state?.stepActionData?.MetaDataJson??"{}"); 
            return metaData?.isBsNewRow??true;
        }
    }

    handleFieldChange(evt) {        
        let value = evt.target.value;
        let initialValue = this.state.stepActionData[evt.target.name];             
        let isCheckbox = evt.target.type === 'checkbox';      
        function setValue(self, key, value, isRefreshState){
            self.state.stepActionData[key] = value;
            if (isRefreshState){
                self.setState({stepActionData : self.state.stepActionData});
            }            
        }
        if (isCheckbox){                                                       
            value = !(!!initialValue);                                                          
        }              
        setValue(this, evt.target.name, value, isCheckbox);
    }

    isFormValid() {
        const { stepName, stepDescription } = this.state;
        return stepName && stepDescription;
    }

    isFormValidnewField() {
        const { stepActionName, stepActionDescription, stepActionKind, stepActionRequired, stepActionTarget } = this.state;
        return stepActionName && stepActionDescription && stepActionTarget;
    }

    _setNewStepResults(steps) {
        this.setState({
            steps: steps,
            stepName: false,
            stepDescription: false,
            stepPriority: false,
            stepActionName: false,
            stepActionDescription: false,
            stepActionPriority: false,
            stepActionKind: "text",
            stepActionRequired: true,
            stepActionIsReadonly: false,
            stepActionIsVisible: true,
            stepActionTarget: false,
            tempStep: ''
        });
    }

    async handleSubmit() {   
        let promptResult = await EVOAlertComponent.Prompt({});
        if (promptResult.isConfirmed === true) {   
            let addNewStepResult = await ApiCallerHelper.Steps.addNewAsync(
                this.state.stepName, 
                this.state.stepDescription, 
                this.state.workflowId
                );
                this.setShow(false);
            let allStepsResult = await ApiCallerHelper.Steps.getAllByWorkflowIdAsync(this.state.workflowId);
            this._setNewStepResults(allStepsResult.data);   
        }                 
    }

    async handleSubmitNewField() {
        let promptResult = await EVOAlertComponent.Prompt({});
        if (promptResult.isConfirmed === true) {
            let addnewStepActionResult = await ApiCallerHelper.StepActions.addNewStepAction({
                'name': this.state.stepActionName,
                'description': this.state.stepActionDescription,
                // 'priority': this.state.stepActionPriority,
                'type': this.state.stepActionKind,
                'target': this.state.stepActionTarget,
                'required': this.state.stepActionRequired,
                'isReadonly': this.state.stepActionIsReadonly,
                'isVisible': this.state.stepActionIsVisible,
                'stepId': this.state.tempStep,
            })
            this.setShow2(false);
            let allStepsResult = await ApiCallerHelper.Steps.getAllByWorkflowIdAsync(this.state.workflowId);        
            this._setNewStepResults(allStepsResult.data);
        }
    }

    deleteStep = async (stepId) => {
        let promptResult = await EVOAlertComponent.Prompt({});
        if (promptResult.isConfirmed === true) {
          let deleteResultAsync = await ApiCallerHelper.Steps.deleteWorkflowStepAsync(stepId);
          const asyncResult = await ApiCallerHelper.Steps.getAllByWorkflowIdAsync(
              this.state.workflowId
            );
          this.setState({
            steps: asyncResult.data,
          });
        }        
    }

    deleteStepAction = async (stepactionId, stepId) => {
        let promptResult = await EVOAlertComponent.Prompt({});
        if (promptResult.isConfirmed === true) {
            let promptResult = await EVOAlertComponent.Prompt({});
            if (promptResult.isConfirmed === true) {
            let deleteResultAsync =
                await ApiCallerHelper.StepActions.deleteWorkflowStepActionAsync(
                stepId,
                stepactionId
                );
            const asyncResult =
                await ApiCallerHelper.Steps.getAllByWorkflowIdAsync(
                this.state.workflowId
                );
            this.setState({
                steps: asyncResult.data,
            });
            }
        }
    }

    changeFieldOrder = async (direction, fieldId, stepId) => {
        let changeOrderAsyncResult = await ApiCallerHelper.StepActions.changeOrderAsync(stepId, fieldId, direction);
        const allByWorkflowIdAsyncResult = await ApiCallerHelper.Steps.getAllByWorkflowIdAsync(this.state.workflowId);
        this.setState({
            steps: allByWorkflowIdAsyncResult.data
        });                  
    }

    changeFieldData = async (stepActionId) => { 
        let promptResult = await EVOAlertComponent.Prompt({});
        if (promptResult.isConfirmed === true) {
            
            this.RenderHelper.DynamicActions.DynamiReactSelect.UpdateStepMetaData(stepActionId);            
            this.RenderHelper.DynamicActions.DynamicInput.UpdateStepMetaData(stepActionId);
            this.RenderHelper.DynamicActions.DynamicReactTable.UpdateStepMetaData(stepActionId);

            let resultAsync = await ApiCallerHelper.StepActions.changefieldAsync(stepActionId, this.state.stepActionData);            
            let asyncResult = await ApiCallerHelper.Steps.getAllByWorkflowIdAsync(this.state.workflowId);
            this.setState({
                steps: asyncResult.data,
                stepActionData: [],
                isEditStepActionModalVisible: false
            });                        
        }
    }    

    RenderHelper = {
        ModalAddNewStep : () => {
            return (
                <Modal
                show={this.state.show}
                onHide={() => this.setShow(false)}
                dialogClassName="modal-90w"                
                aria-labelledby="example-custom-modal-styling-title"
                style={{ opacity: 1 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΒΗΜΑΤΟΣ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <h6 className="heading-small text-muted mb-4">
                            ΣΥΜΠΛΗΡΩΣΤΕ ΤΑ ΠΑΡΑΚΑΤΩ ΠΕΔΙΑ
                        </h6>
                        <div className="pl-lg-4">
                            <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-step-name"
                                        >
                                            Όνομα Βήματος
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-step-name"
                                            name="stepName"
                                            placeholder="Όνομα Βήματος"
                                            type="text"
                                            onChange={evt => this.handleChange(evt)}
                                        />
                                    </FormGroup>
                                </Col>
                                </Row>
                                <Row>
                                <Col md="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-step-description"
                                        >
                                            Περιγραφή Βήματος
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-step-description"
                                            placeholder="Περιγραφή Βήματος"
                                            name="stepDescription"
                                            type="textarea"
                                            onChange={evt => this.handleChange(evt)}
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>
                        </div>

                    </Form>                  
                </Modal.Body>
                <Modal.Footer>
                <div className="col text-right">
                        <Button
                            color="success"
                            onClick={this.handleSubmit}
                            size="md"
                            disabled={!this.isFormValid()}
                            >
                            Ολοκλήρωση
                        </Button>

                    </div>
                </Modal.Footer>
            </Modal>
            );
        },
        ModalNewStepAction : () => {            
            return (
                <Modal
                show={this.state.show2}
                onHide={() => this.setShow2(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                style={{ opacity: 1 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΠΕΔΙΟΥ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <h6 className="heading-small text-muted mb-4">
                            ΣΥΜΠΛΗΡΩΣΤΕ ΤΑ ΠΑΡΑΚΑΤΩ ΠΕΔΙΑ
                        </h6>
                        <div className="pl-lg-4">
                            <Row>
                                <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-stepAction-name"
                                        >
                                            Όνομα Πεδίου
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-stepAction-name"
                                            name="stepActionName"
                                            placeholder="Όνομα Πεδίου"
                                            type="text"
                                            onChange={evt => this.handleChange(evt)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-stepAction-description"
                                        >
                                            Περιγραφή Πεδίου
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-stepAction-description"
                                            placeholder="Περιγραφή Πεδίου"
                                            name="stepActionDescription"
                                            type="text"
                                            onChange={evt => this.handleChange(evt)}
                                        />
                                    </FormGroup>
                                </Col>
                                {/* <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-stepAction-priority"
                                        >
                                            Προτεραιότητα Πεδίου
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-stepAction-priority"
                                            placeholder="Προτεραιότητα Πεδίου"
                                            name="stepActionPriority"
                                            type="number"
                                            onChange={evt => this.handleChange(evt)}
                                        />
                                    </FormGroup>
                                </Col> */}
                                <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-first-name"
                                        >
                                            Είδος Πεδίου
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-first-name"
                                            type="select"
                                            name="stepActionKind"
                                            onChange={evt => this.handleChange(evt)}
                                            // value = {DynamicStepActionTypes.text}
                                        >
                                            {GetDynamicStepActionTypesOptionList().map((dynamicStepActionType, i) =>                                           
                                                <option key={i} value={dynamicStepActionType.value}>{dynamicStepActionType.title}</option>                                             
                                             )}                                           
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                        >
                                            Κλειδί Πεδίου
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-email"
                                            name="stepActionTarget"
                                            placeholder="Περιγραφή Πεδίου"
                                            type="text"
                                            onChange={evt => this.handleChange(evt)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-first-name"
                                        >
                                            Τύπος Πεδίου
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-first-name"
                                            name="stepActionRequired"
                                            type="select"
                                            onChange={evt => this.handleChange(evt)}
                                        >
                                            <option value={true}>Υποχρεωτικό</option>
                                            <option value={false}>Μη Υποχρεωτικό</option>

                                        </Input>
                                    </FormGroup>
                                </Col>                                                           
                            </Row>
                        </div>

                    </Form>
                    <div className="col text-right">
                        <Button
                            color="success"
                            onClick={() => this.handleSubmitNewField()}
                            size="md"
                            disabled={!this.isFormValidnewField()}
                        >
                            Ολοκλήρωση

                        </Button>

                    </div>
                </Modal.Body>
            </Modal>
            );
        },
        ModalEditStepAction : ()=> {
            return (
                <Modal
                    show={this.state.isEditStepActionModalVisible}
                    onHide={() => this.setIsEditStepActionModalVisible(false, null)}
                    dialogClassName="tool-theme_modal-dialog--large-90"
                    aria-labelledby="example-custom-modal-styling-title"
                    style={{ opacity: 1 }}                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            ΕΠΕΞΕΡΓΑΣΙΑ ΠΕΔΙΟΥ
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {/* <h6 className="heading-small text-muted mb-4">
                                ΣΥΜΠΛΗΡΩΣΤΕ ΤΑ ΠΑΡΑΚΑΤΩ ΠΕΔΙΑ
                            </h6> */}
                            <div className="pl-lg-4">
                                <Row>
                                    <Col lg="4">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-stepAction-edit-name"
                                            >
                                                Όνομα Πεδίου
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-stepAction-edit-name"
                                                name="Name"
                                                defaultValue={this.state.stepActionData.Name}
                                                type="text"
                                                onChange={evt => this.handleFieldChange(evt)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-stepAction-edit-description"
                                            >
                                                Περιγραφή Πεδίου
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-stepAction-edit-description"
                                                defaultValue={this.state.stepActionData.Description}
                                                name="Description"
                                                type="text"
                                                onChange={evt => this.handleFieldChange(evt)}
                                            />
                                        </FormGroup>                                            
                                    </Col>   
                                    <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-Required"
                                        >
                                            Τύπος Πεδίου
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-Required"
                                            name="Required"
                                            type="select"
                                            defaultValue={this.state.stepActionData.Required}
                                            onChange={evt => this.handleFieldChange(evt)}
                                        >
                                            <option value={true}>Υποχρεωτικό</option>
                                            <option value={false}>Μη Υποχρεωτικό</option>

                                        </Input>
                                    </FormGroup>
                                    </Col>     
                                    <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                        >
                                            Υπολογιζόμενο (Is ReadOnly)&nbsp;&nbsp;
                                        </label>
                                        <Checkbox             
                                            className='tool-theme-checkbox'         
                                            name="IsReadonly"   
                                            checked={this.state.stepActionData.IsReadonly??false}          
                                            //onChange={e=> { let newValue = !!e.target.checked; }}
                                            onChange={ evt => { this.handleFieldChange(evt)}}
                                            >
                                        </Checkbox>
                                    </FormGroup>
                                </Col>                                                                  
                                    <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                        >
                                            Εμφανές (Is Visible) &nbsp;&nbsp;
                                        </label>
                                        <Checkbox             
                                            className='tool-theme-checkbox'         
                                            name="IsVisible"   
                                            checked={this.state.stepActionData.IsVisible??true}          
                                            //onChange={e=> { let newValue = !!e.target.checked; }}
                                            onChange={ evt => { this.handleFieldChange(evt)}}
                                            >
                                        </Checkbox>
                                    </FormGroup>
                                </Col>                                                                  
                                </Row>
                                <Row>
                                <Col lg="12"><h2>Διαστάσεις</h2></Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="bsIsNewRow"
                                        >
                                            Τοποθέτηση σε νέα σειρά
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="bsIsNewRow"
                                            name="bsIsNewRow"
                                            type="select"
                                            onChange={evt => this.handleDimensionsMetaData.handleIsNewRow(evt)}
                                            defaultValue={this.handleDimensionsMetaData.getDefaultIsNewRowValue()}
                                        >
                                            <option value={true}>ΝΑΙ</option>
                                            <option value={false}>ΟΧΙ</option>
                                        </Input>
                                    </FormGroup>
                                </Col>                                
                                <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="bsBreakpoint"
                                        >
                                            Αναλογία Πεδίου
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="bsBreakpoint"
                                            name="bsBreakpoint"
                                            type="select"
                                            onChange={evt => this.handleDimensionsMetaData.handleBreakpoints(evt)}
                                            defaultValue={this.handleDimensionsMetaData.getDefaultBreakpointValue()}
                                        >
                                            <option value={2}>2/12</option>
                                            <option value={4}>4/12</option>
                                            <option value={6}>6/12</option>
                                            <option value={8}>8/12</option>
                                            <option value={10}>10/12</option>
                                            <option value={12}>12/12</option>

                                        </Input>
                                    </FormGroup>
                                </Col>                                                                        
                                </Row>
                                {this.RenderHelper.DynamicActions.DynamiReactSelect.Editor()} 
                                {this.RenderHelper.DynamicActions.DynamicReactTable.Editor()}     
                                {this.RenderHelper.DynamicActions.DynamicButton.Editor()}
                                {this.RenderHelper.DynamicActions.DynamicInput.Editor()}   
                            </div>
                        </Form>
                        <div className="col text-right">
                            <Button
                                color="success"
                                onClick={() => this.changeFieldData(this.state.stepActionData.Id)}
                                size="md"
                            // disabled={!this.isFormValid()}
                            >
                                Αποθήκευση
                            </Button>

                        </div>
                    </Modal.Body>
                </Modal>
            );
        },
        DynamicActions : {
            Common : {
                EditorButtons :(stepActions, stepActionData) => {                      
                        const isMoveUp = stepActionData.Order > 0;
                        const isMoveDown = stepActionData.Order < stepActions.count -1;                                                

                        function _moveDirection(self, isMoveUp) {
                            // const className = isMoveUp? "fas fa-long-arrow-alt-up" : "fas fa-long-arrow-alt-down";                            
                            let img = isMoveUp? AssetHelper.Icons.up : AssetHelper.Icons.down;                            
                            return (<>
                                <Button
                                    color="link"
                                    onClick={() => { self.changeFieldOrder(isMoveUp, stepActionData.Id, stepActionData.WorkflowStepId) }}
                                    size="sm"
                                    title="Μετακίνηση"
                                    style={{ marginTop: '40px' }}
                                >
                                    <img src={img}></img>
                                    {/* <i className={className} /> */}
                                </Button>     
                            </>)
                        }

                        return (
                            <div className="float-right">                            
                                <Button
                                    color="link"
                                    onClick={() => { alert(2) }}
                                    size="sm"
                                    title="Πληροφορίες"
                                    style={{ marginTop: '40px' }}
                                >
                                    <img src={AssetHelper.Icons.info}></img>
                                    {/* <i className="fas fa-info" /> */}
                                </Button>                                
                                <Button
                                    color="link"
                                    onClick={() => this.setIsEditStepActionModalVisible(true, stepActionData.Id)}
                                    size="sm"
                                    title="Επεξεργασία"
                                    style={{ marginTop: '40px' }}
                                >
                                    <img src={AssetHelper.Icons.edit}></img>
                                    {/* <i className="fas fa-pencil-alt" /> */}
                                </Button>
                                <Button
                                    color="link"
                                    onClick={() => { this.deleteStepAction(stepActionData.Id, stepActionData.WorkflowStepId) }}
                                    size="sm"
                                    title="Διαγραφή"
                                    style={{ marginTop: '40px' }}
                                >
                                    {/* <i className="fas fa-trash" /> */}
                                    <img src={AssetHelper.Icons.delete}></img>
                                </Button>                                
                                {isMoveUp &&
                                    <>{_moveDirection(this, true)}</>
                                }
                                {isMoveDown &&
                                    <>{_moveDirection(this, false)}</>
                                }                                                                                           
                        </div>)
                },
                HrBlock:(stepActions, stepActionData) => {
                    const isMoveUp = stepActionData.Order > 0;
                    const isMoveDown = stepActionData.Order < stepActions.count -1;   
                    let marginRight = 50;
                    if (isMoveUp && isMoveDown) {
                        marginRight = marginRight * 2;
                    }

                    return <Col md="12"><hr 
                    style={{marginRight:marginRight}}
                    className="tool-theme-hr tool-theme-hr--primary"></hr></Col>
                }
            },
            DynamicReactTable : {
                UpdateStepMetaData : (stepActionId)=> {                      
                    if (!this.dynamicControlsHelper._isStepWithDynamicStepActionType(DynamicStepActionTypes.dynamicReactTable, stepActionId)){
                        return;
                    }
                           
                    const metaData = JSON.parse(this.state?.stepActionData?.MetaDataJson??"{}"); 
                    metaData.reactTable =  EVOReactTableDynamicGridHelper.DynamicAction.getDatasetAndColumnDefinitionsFromStepActionsData(this.state?.stepActionData).reactTable;
                    metaData.reactTable.dataset = this.reactGridDynamicGridComponentRef.current.getData();
                    this.state.stepActionData["MetaDataJson"] = JSON.stringify(metaData);
                },
                Editor : () => {
                    if (EVOReactTableDynamicGridHelper.DynamicAction.isSkip(this.state?.stepActionData)){
                        return <></>
                    } 
                    let reactGridMetaData = EVOReactTableDynamicGridHelper.DynamicAction.getDatasetAndColumnDefinitionsFromStepActionsData(this.state?.stepActionData);                    
                    return (
                        <>
                          <Row>
                            <Col md="12">
                              <Button
                                className="float-right mb-2"
                                color="primary"
                                onClick={() =>
                                  this.reactGridDynamicGridComponentRef.current.onClickAddRow()
                                }
                                size="md"
                              >
                                + Προσθήκη Εγγραφής
                              </Button>
                              <Button
                                className="float-right mb-2 ml-2"
                                color="warning"
                                onClick={() =>
                                  this.reactGridDynamicGridComponentRef.current.onClickResetData()
                                }
                                size="md"
                              >
                                Αρχικοποίηση
                              </Button>  
                              </Col> 
                              </Row> 
                              <Row>
                                <Col md="12">                             
                                <EvoActionsDynamicGridComponent
                                    dataset={reactGridMetaData.reactTable.dataset}
                                    columnMetadataDefinitions={reactGridMetaData.reactTable.columnMetadataDefinitions}
                                    ref={this.reactGridDynamicGridComponentRef}
                                    innerScrollXOverflow = {'auto'}
                                ></EvoActionsDynamicGridComponent>
                                </Col> 
                              </Row>                                             
                        </>);
                },
                Action : (stepActions, stepActionData, onChange) => {   
                    if (EVOReactTableDynamicGridHelper.DynamicAction.isSkip(stepActionData)){
                        return <></>
                    }       
                    let metaData = EVOReactTableDynamicGridHelper.DynamicAction.getDatasetAndColumnDefinitionsFromStepActionsData(stepActionData);
                    let columnMetaDataDefinitions = EVOReactTableDynamicGridHelper.DynamicAction.getGridDefinitionByColumnstepActionDataValue(stepActionData);                  
                    let datasource = GridActions.getDatasourceWithNewRow([],columnMetaDataDefinitions);
                    return (<>                        
                        <Row>
                            <Col md="12">
                            <label
                                className="form-control-label form-control-label--primary"
                                htmlFor={stepActionData.Id}
                            >
                                {stepActionData.Name}
                            </label>
                            &nbsp;
                            <i className="fa fa-info-circle" data-tip data-for={'info-textarea' + stepActionData.Id} aria-hidden="true"></i>                             
                            <ReactTooltip id={stepActionData.Id} type='info'>
                                <span>{stepActionData.Description}</span>
                            </ReactTooltip>
                            <EvoActionsDynamicGridComponent
                                dataset={datasource}
                                columnMetadataDefinitions={columnMetaDataDefinitions}
                                ref={this.reactGridDynamicGridComponentRef}
                                //innerWidth = {'100%'}
                                innerScrollXOverflow = {'auto'}
                                // scrollXOverflow={"visible"}
                                // width={"100px"}
                                
                            ></EvoActionsDynamicGridComponent>

                            {/* <Select          
                                name={stepActionData.Id}
                                options={options}
                                defaultValue={stepActionData.Name}
                                // onChange={onChange}
                                isClearable={false}                            
                            /> */}
                            </Col>                                                  
                        </Row>
                        <Row>
                            <Col md="12">
                                <div className="pull-right">
                                    {this.RenderHelper.DynamicActions.Common.EditorButtons(stepActions, stepActionData)}
                                </div>
                            </Col> 
                            {this.RenderHelper.DynamicActions.Common.HrBlock(stepActions, stepActionData)}     
                        </Row>
                        </>)
                }
            },
            DynamiReactSelect : {
                _setMetadata : (key, value) => {
                    let editMetadata = this.state.editMetadata?? {};
                    editMetadata[key] = value;
                    this.setState({ editMetadata: editMetadata });
                },                
                onValueChangeUrl : (url) => {
                    let editMetadata = this.state.editMetadata?? {};
                    editMetadata.url = url;
                    this.setState({ editMetadata: editMetadata });
                },
                onValueChangeMethod : (method) => {
                    let editMetadata = this.state.editMetadata?? {};
                    editMetadata.method = method;
                    this.setState({ editMetadata: editMetadata });                    
                },
                onValueAdvancedChange : {
                    isClearable : (option) => {                        
                        this.RenderHelper.DynamicActions.DynamiReactSelect._setMetadata(
                            'isClearable' , option.value
                        )                        
                    },
                    isSearchable : (option) => {                        
                        this.RenderHelper.DynamicActions.DynamiReactSelect._setMetadata(
                            'isSearchable' , option.value
                        )                                          
                    }
                },
                UpdateStepMetaData : (stepActionId)=> {  
                    if (!this.dynamicControlsHelper._isStepWithDynamicStepActionType(DynamicStepActionTypes.dynamicReactSelect, stepActionId)){
                        return;
                    }            
                    // console.log(this.state?.stepActionData?.MetaDataJson??"{}");
                    // return;
                    const metaData = JSON.parse(this.state?.stepActionData?.MetaDataJson??"{}");                     
                    //let editMetadata = {...metaData?.reactTable?.endPointMetadataDefinitions??null, ...this.state?.editMetadata??null}         
                    metaData.reactTable =  EVOReactSelectHelper.getDatasetAndColumnDefinitionsFromStepActionsData(this.state?.stepActionData).reactTable;
                    metaData.reactTable.dataset = this.reactSelectDynamicGridComponentRef.current.getData();
                    metaData.reactTable.endPointMetadataDefinitions = {...metaData?.reactTable?.endPointMetadataDefinitions??null};    
                    let newMetaData = {...metaData, ...this.state?.editMetadata??null};                                  
                    this.state.stepActionData["MetaDataJson"] = JSON.stringify(newMetaData);
                },
                Editor : () => {                                                   
                    if (EVOReactSelectHelper.isSkip(this.state?.stepActionData)){
                        return <></>
                    }    
                                      
                    let urlName = "reactTable-endPointMetadataDefinitions-url";
                    let methodName = "reactTable-endPointMetadataDefinitions-method";
                    let methodOptions = [
                        {"value": "get", "label": "get"},
                        {"value": "put", "label": "put"},
                        {"value": "post", "label": "post"}                        
                    ];
                    let reactSelectMetaData = EVOReactSelectHelper.getDatasetAndColumnDefinitionsFromStepActionsData(this.state?.stepActionData);                                                                        
                    let methodSeletedOption = methodOptions
                        .filter(x=> x.value == reactSelectMetaData?.reactTable?.endPointMetadataDefinitions?.method??""
                        )[0]??null;                                         
                    let url = reactSelectMetaData?.reactTable?.endPointMetadataDefinitions?.url??"";   
                    
                    let isSearchableOption = EVOReactSelectHelper.getSelectedOptionFromValue(
                        reactSelectMetaData?.isSearchable??null,
                        EVOReactSelectHelper.getAdvancedSettingsSearchOptions()
                      );                
                                        
                    let isClearableOption = EVOReactSelectHelper.getSelectedOptionFromValue(
                        reactSelectMetaData?.isClearable??null,
                        EVOReactSelectHelper.getDefaultNoYesOptions()
                      );                     
                  
                    return (
                      <>
                        <Row>
                          <Col md="12">
                            <Button
                              className="float-right mb-2"
                              color="primary"
                              onClick={() =>
                                this.reactSelectDynamicGridComponentRef.current.onClickAddRow()
                              }
                              size="md"
                            >
                             + Προσθήκη Εγγραφής
                            </Button>
                            <Button
                              className="float-right mb-2 lt-2"
                              color="warning"
                              onClick={() =>
                                this.reactSelectDynamicGridComponentRef.current.onClickResetData()
                              }
                              size="md"
                            >
                              Αρχικοποίηση
                            </Button>                            
                            <EvoActionsDynamicGridComponent
                              dataset={reactSelectMetaData.reactTable.dataset}
                              columnMetadataDefinitions={reactSelectMetaData.reactTable.columnMetadataDefinitions}
                              ref={this.reactSelectDynamicGridComponentRef}
                            ></EvoActionsDynamicGridComponent>
                          </Col>                          
                        </Row>
                        <Row>
                          <Col md="12">
                            <br></br>
                            <h2>Additional Parameters</h2>
                          </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                            <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor={urlName}
                                    >
                                        Καθαρισμός Τιμής
                                    </label>
                                    <EVOReactSelectHelper.Select
                                        name="react-select-advancedSettingsClearOptions"
                                        options={EVOReactSelectHelper.getDefaultNoYesOptions()}
                                        onChange={this.RenderHelper.DynamicActions.DynamiReactSelect.onValueAdvancedChange.isClearable}
                                        defaultValue={isClearableOption}
                                        isClearable={true}
                                        isSearchable={false}
                                        isFirstOptionDefaultValue = {true}
                                        />                                                                                                     
                                </FormGroup>
                            </Col>
                            <Col md="6">
                            <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor={urlName}
                                    >
                                        Επιλογή Αναζήτησης
                                    </label>
                                    <EVOReactSelectHelper.Select
                                        name="react-select-advancedSettingsSearchOptions"
                                        options={EVOReactSelectHelper.getAdvancedSettingsSearchOptions()}
                                        onChange={this.RenderHelper.DynamicActions.DynamiReactSelect.onValueAdvancedChange.isSearchable}
                                        defaultValue={isSearchableOption}
                                        isSearchable={false}
                                        isFirstOptionDefaultValue = {true}
                                        />                                                               
                                </FormGroup>
                            </Col>
                          {/* <Col md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor={urlName}
                                    >
                                        End Point Url
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id={urlName}
                                        name={urlName}
                                        defaultValue={url}
                                        type="text"
                                        // onChange={evt => this.handleFieldChange(evt)}
                                        onChange={(evt) => {
                                            const url = evt.target.value;
                                            this.RenderHelper.DynamicActions.DynamiReactSelect.onValueChangeUrl(url);                                            
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor={methodName}
                                    >
                                        End Point Method
                                    </label>
                                    <Select          
                                        name={methodName}
                                        options={methodOptions}
                                        defaultValue={methodSeletedOption}
                                        onChange={(option) => {                                           
                                            this.RenderHelper.DynamicActions.DynamiReactSelect.onValueChangeMethod(option.value);  
                                        }}
                                        isClearable={false}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),                                    
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                          }}                          
                                    />
                                </FormGroup>
                            </Col> */}
                        </Row>
                      </>
                    );                      
                },
                Action : (stepActions, stepActionData, onChange) => {     
                    if (EVOReactSelectHelper.isSkip(stepActionData)){
                        return <></>
                    }  
                    let options = EVOReactSelectHelper.getReactSelectOptionsFromStepActionData(stepActionData);                        
                    return (
                        <Row>
                            <Col md="8">
                            <label
                                className="form-control-label form-control-label--primary"
                                htmlFor={stepActionData.Id}
                            >
                                {stepActionData.Name}
                            </label>
                            &nbsp;
                            <i className="fa fa-info-circle" data-tip data-for={'info-textarea' + stepActionData.Id} aria-hidden="true"></i>                             
                            <ReactTooltip id={stepActionData.Id} type='info'>
                                <span>{stepActionData.Description}</span>
                            </ReactTooltip>
                            <Select          
                                name={stepActionData.Id}
                                options={options}
                                defaultValue={stepActionData.Name}
                                // onChange={onChange}
                                isClearable={false}
                                styles={{
                                    menuPortal: base => ({ ...base, zIndex: 9999 }),                                    
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                  }}                               
                            />
                            </Col>
                            <Col md="4">
                                {this.RenderHelper.DynamicActions.Common.EditorButtons(stepActions, stepActionData)}
                            </Col> 
                            {this.RenderHelper.DynamicActions.Common.HrBlock(stepActions, stepActionData)}                            
                        </Row>
                   )
                }
            },
            DynamicButton : {
                onValueChange : (e) => {
                    this.setState({
                      dynamicButtonOption : e.target.value
                    })
                },
                buttonPressed : (actionId, instanceId, target) => {
                    fetch(process.env.REACT_APP_API_LINK + "/api/dynamic/pressed/", {
                        method: 'post',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + GlobalParametersHelper.Token.get(),
                            'Content-Type': 'application/json'
                        }),
                        body: JSON.stringify({
                            'ActionId': actionId,
                            'InstanceId': instanceId,
                            'Target' : target
                        })
                        .then(res => res.json())
                        .then((result) => {
                                    
                        })            
                    })
                },
                Editor : () => {
                    if (this.state?.stepActionData == null ||
                        this.state?.stepActionData.Type != DynamicStepActionTypes.button){
                        return <></>
                    }                        
                    return (
                        <Form>
                        <FormGroup>
                          <Label for="selectAction">Επιλογή Ενέργειας</Label>
                          <Input type="select" name="select" id="selectAction" onChange={e => { this.RenderHelper.DynamicActions.DynamicButton.onValueChange(e) }}>
                            <option value='-'>-- Επιλέξτε ενέργεια κουμπιού --</option>
                            <option value="jump">Αλλαγή Βήματος</option>
                            <option value="assign">Ανάθεση σε Χρήστη</option>
                            <option value="mail">Αποστολή Email</option>
                          </Input>
                        </FormGroup>
            
                        {this.state.dynamicButtonOption == "jump" ?
                          <FormGroup>
                            <Label for="selectAction">Επιλογή Βήματος</Label>
                            <Input type="select" name="select" id="selectAction">
                              <option value='-'>-- Επιλέξτε βήμα --</option>
                              <option value="1">Βήμα 1 : ...</option>
                              <option value="2">Βήμα 2 : ...</option>
                              <option value="3">Βήμα 3 : ...</option>
                            </Input>
                          </FormGroup> : <></>
                        }
            
                        {this.state.dynamicButtonOption == "assign" ?
                          <FormGroup>
                            <Label for="selectAction">Επιλογή Χρήστη</Label>
                            <Input type="select" name="select" id="selectAction">
                              <option value='-'>-- Επιλέξτε χρήστη --</option>
                              <option value="john">Χρήστης 1</option>
                              <option value="mike">Χρήστης 2 : ...</option>
                              <option value="ben">Χρήστης 3 : ...</option>
                            </Input>
                          </FormGroup> : <></>
                        }
                        
                        {this.state.dynamicButtonOption == "mail" ?
                          <>
                            <FormGroup>
                              <Label for="receiverEmail">Email Παραλήπτη</Label>
                              <Input type="email" name="email" id="receiverEmail" placeholder="email@email.com" />
                            </FormGroup>
                            <FormGroup>
                              <Label for="emailText">Κείμενο Email</Label>
                              <Input type="textarea" name="text" id="emailText" />
                            </FormGroup>
                          </> : <></>
                        }
            
                        
                        <InputGroup className="text-center">
                          <Button className="text-center" color="primary" type="submit" disabled={this.state.dynamicButtonOption == "-"} >
                            Δημιουργία
                          </Button>
                        </InputGroup> 
                      </Form>
                    )                 
                },
                Action : (stepActions, stepActionData, onChange) => {
                    if (stepActionData == null ||
                        stepActionData.Type != DynamicStepActionTypes.button){
                        return <></>
                    }    
                    return (
                        <Row>
                            <Col md="8">
                                <label
                                    className="form-control-label form-control-label--primary"
                                    htmlFor={stepActionData.Id}
                                >
                                    {stepActionData.Name}
                                </label>                                                                                    
                                <i className="fa fa-info-circle" data-tip data-for={'info-text' + stepActionData.Id} aria-hidden="true"></i>
                                <ReactTooltip id={'info-text' + stepActionData.Id} type='info'>
                                    <span>{stepActionData.Description}</span>
                                </ReactTooltip>
                                <Row>
                                    <Col md="12">
                                    <Button
                                    color="primary"
                                    size="sm"
                                    title="Δυναμικό Κουμπί"
                                    // onClick={() => { this.this.RenderHelper.DynamicActions.DynamicButton.buttonPressed(stepActionData.Id, this.state.instanceId, stepActionData.Target) }}
                                    style={{ marginTop: '40px' }}
                                        >
                                        {stepActionData.Name}
                                    </Button>
                                    </Col>
                                </Row>                                
                            </Col>
                            <Col md="4">
                                {this.RenderHelper.DynamicActions.Common.EditorButtons(stepActions, stepActionData)}
                            </Col>
                            {this.RenderHelper.DynamicActions.Common.HrBlock(stepActions, stepActionData)}
                        </Row>
                    )   
                }
            },
            Rating : {
                Action : (stepActions, stepActionData, onChange) => {
                    if (stepActionData == null ||
                        stepActionData.Type != DynamicStepActionTypes.rating){
                        return <></>
                    }  
                    return (
                        <>
                        <Row>
                        <Col md="8">
                        <label
                            className="form-control-label form-control-label--primary"
                            htmlFor={stepActionData.Id}>
                            {stepActionData.Name}
                        </label>
                        &nbsp;
                        <i className="fa fa-info-circle" data-tip data-for={'info-textarea' + stepActionData.Id} aria-hidden="true"></i>                             
                        <ReactTooltip id={stepActionData.Id} type='info'>
                            <span>{stepActionData.Description}</span>
                        </ReactTooltip>        
                        <Row>
                            <Col md="12">
                            <Rating></Rating>
                            </Col>
                        </Row>                
                        </Col>
                        <Col md="4">
                            {this.RenderHelper.DynamicActions.Common.EditorButtons(stepActions, stepActionData)}
                        </Col>
                        {this.RenderHelper.DynamicActions.Common.HrBlock(stepActions, stepActionData)}
                        </Row>                                                                  
                        </>
                    )   

                }
            },
            Map : {
                Action : (stepActions, stepActionData, onChange) => {                    
                    if (stepActionData == null ||
                        stepActionData.Type != DynamicStepActionTypes.mappin){
                        return <></>
                    }  
                    return (
                        <>
                        <Row>
                        <Col md="8">
                        <label
                            className="form-control-label form-control-label--primary"
                            htmlFor={stepActionData.Id}>
                            {stepActionData.Name}
                        </label>
                        &nbsp;
                        <i className="fa fa-info-circle" data-tip data-for={'info-textarea' + stepActionData.Id} aria-hidden="true"></i>                             
                        <ReactTooltip id={stepActionData.Id} type='info'>
                            <span>{stepActionData.Description}</span>
                        </ReactTooltip>                                                
                        <Row>
                            <Col md="12">
                            <Map></Map>
                            </Col>
                        </Row>
                        </Col>                                              
                        </Row>
                        <Row>
                        <Col md="8">&nbsp;</Col>
                        <Col md="4">
                            {this.RenderHelper.DynamicActions.Common.EditorButtons(stepActions, stepActionData)}
                        </Col> 
                            {this.RenderHelper.DynamicActions.Common.HrBlock(stepActions, stepActionData)}
                        </Row>                                           
                        
                        </>
                    )   

                }
            },
            DynamicInput : {
                _setMetadata : (key, value) => {                    
                    let editMetadata = this.state.editMetadata?? {};
                    editMetadata[key] = value;
                    this.setState({ editMetadata: editMetadata }, ()=>{console.log(this.state?.editMetadata);   });
                },          
                onValueAdvancedChange : {
                    minLength : (evt) => {                        
                        this.RenderHelper.DynamicActions.DynamicInput._setMetadata(
                            'minLength' , Number(evt.target.value)
                        )                        
                    },
                    maxLength : (evt) => {                        
                        this.RenderHelper.DynamicActions.DynamicInput._setMetadata(
                            'maxLength' , Number(evt.target.value)
                        )                                          
                    },
                    validationTypeOption : (option) => {                        
                        this.RenderHelper.DynamicActions.DynamicInput._setMetadata(
                            'validationTypeOption' , option.value
                        )                                          
                    }
                },                     
                IsNotStepAction : () => {
                    let stepActionData = this.state?.stepActionData;
                    return (
                        stepActionData === null ||
                        stepActionData.Type != DynamicStepActionTypes.number &&                        
                        stepActionData.Type != DynamicStepActionTypes.text &&
                        stepActionData.Type != DynamicStepActionTypes.textarea &&                        
                        stepActionData.Type != DynamicStepActionTypes.checkbox
                      );                   
                },
                UpdateStepMetaData : (stepActionId)=> {  
                    if (this.RenderHelper.DynamicActions.DynamicInput.IsNotStepAction()){
                        return;
                    }                                        
                    const metaData = JSON.parse(this.state?.stepActionData?.MetaDataJson??"{}");                             
                    let newMetaData = {...metaData, ...this.state?.editMetadata??null};                                  
                    this.state.stepActionData["MetaDataJson"] = JSON.stringify(newMetaData);
                },
                Editor : () =>{ 
                    if (this.RenderHelper.DynamicActions.DynamicInput.IsNotStepAction()){
                        return <></>
                    }

                    let validationTypeOptions = GetDynamicInputValidatonTypeOptionList();                    
                    let metaData = JSON.parse(this.state?.stepActionData?.MetaDataJson ?? "null");
                    let validationTypeOption = EVOReactSelectHelper.getSelectedOptionFromValue(
                        metaData?.validationTypeOption??null,
                        validationTypeOptions
                      );                    
                    
                    let minLength = metaData?.minLength??EvoInput.constants.defaultMinLengthValue;
                    let maxLength = metaData?.maxLength??EvoInput.constants.defaultMaxLengthValue;
                        
                    return (
                        <>
                        <Row>
                        <Col md="12">
                          <br></br>
                          <h2>Additional Parameters</h2>
                        </Col>
                      </Row>
                      <Row>
                          <Col md="3">
                          <FormGroup>
                                  <label
                                      className="form-control-label"
                                      htmlFor="input-maxLength"
                                  >
                                      Μέγιστοι Χαρακτήρες
                                  </label>
                                  <EvoInput.Input
                                      className="form-control-alternative"
                                      name="input-maxLength"
                                      validationTypeOption = {DynamicInputValidatonType.INTEGER_ONLY}
                                      defaultNullValue = {EvoInput.constants.defaultMaxLengthValue}
                                      defaultValue = {maxLength}
                                      type="number"                                      
                                      onChange = {this.RenderHelper.DynamicActions.DynamicInput.onValueAdvancedChange.maxLength}
                                      />                                                                                                 
                              </FormGroup>
                          </Col>
                          <Col md="3">
                          <FormGroup>
                                  <label
                                      className="form-control-label"
                                      htmlFor="input-minLength"
                                  >
                                      Ελάχιστοι Χαρακτήρες
                                  </label>
                                  <EvoInput.Input
                                      className="form-control-alternative"
                                      name="input-minLength"
                                      validationTypeOption = {DynamicInputValidatonType.INTEGER_ONLY}
                                      defaultNullValue = {EvoInput.constants.defaultMinLengthValue}
                                      defaultValue = {minLength}
                                      type="number"                                      
                                      onChange = {this.RenderHelper.DynamicActions.DynamicInput.onValueAdvancedChange.minLength}
                                      />                                                                                                                           
                              </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup>
                                  <label
                                      className="form-control-label"
                                      htmlFor="react-select-advanceValidationType"
                                  >
                                      Τύπος Ελέγχου
                                  </label>
                                  <EVOReactSelectHelper.Select
                                      name="react-select-advanceValidationType"
                                      options={validationTypeOptions}
                                      onChange={this.RenderHelper.DynamicActions.DynamicInput.onValueAdvancedChange.validationTypeOption}
                                      defaultValue={validationTypeOption}
                                      isSearchable={false}
                                      isFirstOptionDefaultValue = {true}
                                    />                                                               
                              </FormGroup>
                          </Col>
                      </Row>  
                      </>
                    )
                    
                },
                Action : (stepActions, stepActionData, onChange) => {                          
                    if (stepActionData == null ||
                        (
                        stepActionData.Type != DynamicStepActionTypes.number &&                        
                        stepActionData.Type != DynamicStepActionTypes.text &&
                        stepActionData.Type != DynamicStepActionTypes.textarea &&
                        stepActionData.Type != DynamicStepActionTypes.checkbox 
                        )){
                        return <></>
                    }                      
                    return (
                      <>       
                        <Row>
                            <Col md="8">
                            <label
                                className="form-control-label form-control-label--primary"
                                htmlFor={stepActionData.Id}
                            >
                                {stepActionData.Name}
                            </label>
                            &nbsp;
                            <i className="fa fa-info-circle" data-tip data-for={'info-textarea' + stepActionData.Id} aria-hidden="true"></i>                             
                            <ReactTooltip id={stepActionData.Id} type='info'>
                                <span>{stepActionData.Description}</span>
                            </ReactTooltip>                            
                            <Input
                              className="form-control-alternative"
                              defaultValue={stepActionData.Name}
                              id={stepActionData.Id}
                              // placeholder={dynamicActions.action.Description}
                              type={stepActionData.Type}
                              // className="w-25"
                              disabled={true}

                              // onChange={evt => this.updateInputValue(evt)}
                            />
                            </Col>
                            <Col md="4">
                                {this.RenderHelper.DynamicActions.Common.EditorButtons(stepActions, stepActionData)}
                            </Col> 
                            {this.RenderHelper.DynamicActions.Common.HrBlock(stepActions, stepActionData)}
                        </Row>                                               
                      </>
                    );   

                }
            },
            DynamicDateTimePicker : {
                Action : (stepActions, stepActionData, onChange) => {                          
                    if (stepActionData == null ||                        
                        stepActionData.Type != DynamicStepActionTypes.date
                        ){
                        return <></>
                    }                      
                    return (
                      <>       
                        <Row>
                            <Col md="8">
                            <label
                                className="form-control-label form-control-label--primary"
                                htmlFor={stepActionData.Id}
                            >
                                {stepActionData.Name}
                            </label>
                            &nbsp;
                            <i className="fa fa-info-circle" data-tip data-for={'info-textarea' + stepActionData.Id} aria-hidden="true"></i>                             
                            <ReactTooltip id={stepActionData.Id} type='info'>
                                <span>{stepActionData.Description}</span>
                            </ReactTooltip>                                                
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    
                                        <InputGroupText>
                                            <i className="ni ni-calendar-grid-58" />
                                        </InputGroupText>
                                    
                                    <ReactDatetime
                                        id={stepActionData.Id}
                                        inputProps={{
                                            placeholder: "Ημερομηνία"
                                        }}                  
                                        timeFormat={false}
                                        name={stepActionData.Id}
                                        value={new Date()}                                                      
                                        disabled={true}
                                    />              
                                </InputGroup>
                            </FormGroup>   
                            </Col>
                            <Col md="4">
                                {this.RenderHelper.DynamicActions.Common.EditorButtons(stepActions, stepActionData)}
                            </Col>
                            {this.RenderHelper.DynamicActions.Common.HrBlock(stepActions, stepActionData)}
                        </Row>                                
                      </>
                    );   

                }
            },
            File : {
                Action : (stepActions, stepActionData, onChange) => {
                    if (stepActionData == null ||                        
                        stepActionData.Type != DynamicStepActionTypes.file
                        ){
                        return <></>
                    }      

                    return (
                        <>
                        <Row>
                        <Col lg="8">
                            <label
                                className="form-control-label form-control-label--primary"
                                htmlFor={stepActionData.Id}
                            >
                                {stepActionData.Name}
                            </label>
                            <br />
                            <label className="btn btn-primary">
                                Επιλογή αρχείου
                            </label>

                            <i className="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + stepActionData.Id} aria-hidden="true"></i>
                            <ReactTooltip id={'info-form-control-alternative' + stepActionData.Id} type='info'>
                                <span>Μέγιστο μέγεθος αρχείου 3,5ΜΒ.</span>
                                <div id="imageattooltip"></div>
                            </ReactTooltip>
                            <Input
                                className="form-control-alternative"
                                style={{ visibility: "hidden" }}
                                type={stepActionData.Type}
                                disabled={true}
                            />
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Ονομα Αρχειου</th>
                                        <th scope="col">Ημερομηνια Αναρτησης</th>
                                        <th scope="col">Ενεργειες</th>
                                    </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </Table>
                        </Col>                        
                    </Row>
                    <Row>
                        <Col md="8">
                            &nbsp;
                        </Col>
                        <Col md="4">
                            {this.RenderHelper.DynamicActions.Common.EditorButtons(stepActions, stepActionData)}
                        </Col>
                        {this.RenderHelper.DynamicActions.Common.HrBlock(stepActions, stepActionData)}
                    </Row>
                    </>)
                }
            }
        }              
        
    }
    
    dynamicControlsHelper = {
        _isStepWithDynamicStepActionType : (dynamicStepActionType, stepActionId) => {
            let step = (this.state.steps.filter(x=>x.actions
                .filter(x=>x.Id == stepActionId && 
                        x.Type ==dynamicStepActionType).length>0))[0]??null;
            return step != null;                        
        }
    }

    render() {
        if (this.state.userDetails == undefined){
            return (<></>);            
        }

        if (!UserRoleHelper.isMaster(this.state.userDetails)){
            return (<></>);            
        }

        return (                                 
                        <Container fluid>
                            <Row className="mt-5">
                                <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow-none">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h3 className="mb-0">Διαμόρφωση Βημάτων</h3>
                                                </div>
                                                <div className="col text-right">
                                                    <Button
                                                        className="tool-button--primary"                                                
                                                        color="success"                                                        
                                                        onClick={() => this.setShow(true)}
                                                        size="sm"
                                                        //style={{backgroundImage: "url(" + require("assets/img/btn-background.png") + ")"}}
                                                    >
                                                        Προσθήκη Βήματος

                                                    </Button>
                                                </div>

                                            </Row>

                                            <Accordion defaultActiveKey={0}>
                                                {this.state.steps.map((dynamicData, key) =>                                                        
                                                    <Card key={key} className="bg-secondary no-shadow no-padding">
                                                        <CardHeader className="bg-white border-0">
                                                            <Accordion.Toggle as={CardHeader} eventKey={dynamicData.step.Status}>
                                                                <Row className="align-items-center">
                                                                    <Col xs="10">
                                                                        <h3 className="mb-0">{dynamicData.step.Status}. {dynamicData.step.Name}</h3>
                                                                    </Col>
                                                                    <Button
                                                                        color="link"
                                                                        onClick={() => { this.deleteStep(dynamicData.step.Id) }}
                                                                        size="sm"
                                                                        title="Διαγραφή"
                                                                    >
                                                                        <img src={AssetHelper.Icons.delete}></img>
                                                                        {/* <i className="fas fa-trash" /> */}
                                                                    </Button>
                                                                    <Col>

                                                                    </Col>
                                                                </Row>
                                                            </Accordion.Toggle>
                                                        </CardHeader>
                                                        <Accordion.Collapse eventKey={dynamicData.step.Status}>
                                                            <CardBody>
                                                                <Form id={dynamicData.step.Id}>
                                                                    <div className="">
                                                                        <Row >

                                                                            <Col lg="12">
                                                                                <Col lg="12">
                                                                                    <Card>
                                                                                        <CardBody>
                                                                                            <div className="col text-right">
                                                                                                <Button
                                                                                                    color="primary"
                                                                                                    onClick={() => this.setShow2(true, dynamicData.step.Id)}
                                                                                                    size="md"
                                                                                                    title="Προσθήκη Πεδίου"
                                                                                                >
                                                                                                    <i className="fas fa-plus" />
                                                                                                    &nbsp;Προσθήκη Πεδίου
                                                                                                </Button>
                                                                                                {/* <Button
                                                                                                    color="warning"
                                                                                                    onClick={() => console.log('click')}
                                                                                                    size="sm"
                                                                                                    title="Προσθήκη Mail Action"
                                                                                                >
                                                                                                    <i className="fas fa-plus" />
                                                                                                    &nbsp;Προσθήκη Mail Action
                                                                                                </Button> */}
                                                                                            </div>
                                                                                            {dynamicData.actions.map((dynamicActions, index) =>
                                                                                                <Fragment key={index}> {dynamicData.step.ViewPermission.includes(this.state.userDetails.PersonRole) ?
                                                                                                    <>
                                                                                                        {this.RenderHelper.DynamicActions.DynamiReactSelect.Action(dynamicData, dynamicActions, ()=>{})}
                                                                                                        {this.RenderHelper.DynamicActions.DynamicReactTable.Action(dynamicData, dynamicActions, ()=>{})}  
                                                                                                        {this.RenderHelper.DynamicActions.DynamicButton.Action(dynamicData, dynamicActions, ()=>{})}
                                                                                                        {this.RenderHelper.DynamicActions.Rating.Action(dynamicData, dynamicActions, ()=>{})}
                                                                                                        {this.RenderHelper.DynamicActions.Map.Action(dynamicData, dynamicActions, ()=>{})}
                                                                                                        {this.RenderHelper.DynamicActions.DynamicInput.Action(dynamicData, dynamicActions, ()=>{})}
                                                                                                        {this.RenderHelper.DynamicActions.DynamicDateTimePicker.Action(dynamicData, dynamicActions, ()=>{})}
                                                                                                        {this.RenderHelper.DynamicActions.File.Action(dynamicData, dynamicActions, ()=>{})}                                                                                                                                                                                                                                                                                                                                                                                          
                                                                                                    </>
                                                                                                    : <></>}
                                                                                                </Fragment>
                                                                                            )}
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Form>
                                                            </CardBody>
                                                        </Accordion.Collapse>
                                                    </Card>
                                                                                                             
                                                )}
                                            </Accordion>
                                        </CardHeader>
                                    </Card>
                                </Col>
                            </Row>

                            <div className='push'></div>
                            {this.RenderHelper.ModalAddNewStep()}
                            {this.RenderHelper.ModalNewStepAction()}
                            {this.RenderHelper.ModalEditStepAction()}
                                                           
                        </Container>
                               
        );
    }
}

export default Flow;