import React from "react";
import ApiCallerHelper from "../../api/apiCallerHelper";
import RedirectHelper from "../../common/redirectHelper";
import AssetHelper from "../../common/AssetHelper";
import GlobalParameters from "../../common/GlobalParametersHelper";

import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { Fragment } from "react";

const Literals = {
  password: "Κωδικός",
  invalidUsernameOrPassword:
    "To username ή ο κωδικός πρόσβασης είναι λάθος. Παρακαλούμε δοκιμάστε ξανά.",
  connection: "Σύνδεση",
  connectionWithUsernamePassword: "Σύνδεση με κωδικούς πρόσβασης",
  alertWrongPassword: "Λανθασμένος κωδικός.",
  sendPassword: "Αποστολή Κωδικού",
  connectionWithMobileNumber: "Σύνδεση Με Κινητό",
};
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
      dict: {},
      username: "",
      password: "",
      platform: "",
      errorusername: false,
      errorpass: false,
      auth: "",
      isUserCredentialsLoginVisible: true,
      isUserPhoneNumberLoginVisible: false,
      isPhoneTokenVisible: false,
      phoneNumber: "",
      passwordIsVisible: false,
    };
  }

  setErrorUsername(err) {
    this.state.errorusername = err;
    this.setState({
      errormail: err,
    });
  }

  setErrorPass(err) {
    this.state.errorpass = err;
    this.setState({
      errorpass: err,
    });
  }

  handleSubmit = async (e) => {
    let username = this.state.username;
    let password = this.state.password;

    e.preventDefault();
    if (username.trim() == "" || password.trim() == "") return;

    const auth = await ApiCallerHelper.Auth.getToken({
      username: username,
      password: password,
    });

    let responseMessages = {
      INCORECT_USERNAME_OR_PASSWORDS: "Incorrect username or password",
      EMAIL_NOT_EXISTS: "Username not exists",
      INCORECT_PASSWORD: "Password Incorrect",
    };

    if (auth == undefined) {
      this.setErrorUsername(true);
    } else {
      if (auth.data.isFirstLogin) {
        this.setLogin();
      }

      if (auth.data.access_token) {
        GlobalParameters.Token.set(String(auth.data.access_token));
        RedirectHelper.RedirectTo.home();
      }
    }

    /**
     * the code below is the previous code, it looks not to work
     * TODO: delete it at all in the future
     */
    // console.log(auth)
    // if (auth.detail) {
    //   if (auth.detail === responseMessages.INCORECT_USERNAME_OR_PASSWORDS) {
    //     this.setErrorUsername(true);
    //   }
    // }
    // The below does not work

    // if (auth.errors) {
    //   this.setErrorUsername(false);
    //   this.setErrorPass(false);
    //   if (auth.errors === responseMessages.EMAIL_NOT_EXISTS) {
    //     this.setErrorUsername(true);
    //     GlobalParameters.Token.remove();
    //   }
    //   if (auth.errors === responseMessages.INCORECT_PASSWORD) {
    //     alert("caught wrong pass");
    //     this.setErrorPass(true);
    //     GlobalParameters.Token.remove();
    //   }
    // }
  };

  setLogin(isFirstLogin) {
    localStorage.setItem("firstLogin", isFirstLogin);
  }

  setUsername(username) {
    this.state.username = username;
    this.setState({
      username: this.state.username,
    });
  }

  setPassword(password) {
    this.state.password = password;
    this.setState({
      password: this.state.password,
    });
  }

  setPhoneNumber(phoneNumber) {
    this.state.phoneNumber = phoneNumber;
    this.setState({
      phoneNumber: this.state.phoneNumber,
    });
  }

  setPhoneToken(phoneToken) {
    this.state.phoneToken = phoneToken;
    this.setState({
      phoneToken: this.state.phoneToken,
      isPhoneTokenVisible: true,
    });
  }

  handleSubmitPhoneNumber = async (e) => {
    e.preventDefault();
    let phoneNumber = this.state.phoneNumber;
    await ApiCallerHelper.Auth.phoneTokenGenerate(phoneNumber);
    this.setState({ isPhoneTokenVisible: true });
  };

  handleSubmitPhoneToken = async (e) => {
    e.preventDefault();
    let phoneToken = this.state.phoneToken;

    let tokenResult = await ApiCallerHelper.Auth.validatePhoneToken(phoneToken);
    GlobalParameters.Token.set(tokenResult.access_token);
    RedirectHelper.RedirectTo.home();
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit();
    }
  };

  PasswordField = ({ self }) => {
    let passwordType = self.state.passwordIsVisible ? "text" : "password";
    return (
      <>
        <Input
          placeholder={Literals.password}
          type={passwordType}
          onChange={(e) => self.setPassword(e.target.value)}
        />

        <InputGroupText>
          <i
            className="far fa-eye"
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              self.setState({
                passwordIsVisible: !self.state.passwordIsVisible,
              })
            }
          />
        </InputGroupText>
      </>
    );
  };

  render() {
    let RenderUserCredentialsLogin = (
      <>
        <FormGroup className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroupText>
              <i className="ni ni-username-83" />
            </InputGroupText>

            <Input
              placeholder="Username"
              type="text"
              onChange={(e) => this.setUsername(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <InputGroup className="input-group-alternative">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>

            <this.PasswordField self={this}></this.PasswordField>
            {/* <Input placeholder="{Literals.password}" type="password" onChange={e => this.setPassword(e.target.value)} /> */}
          </InputGroup>
        </FormGroup>

        {this.state.errorusername ? (
          <p style={{ color: "orange" }}>
            {Literals.invalidUsernameOrPassword}
          </p>
        ) : (
          <></>
        )}
        {this.state.errorpass ? (
          <Input
            placeholder="Error with password"
            value={Literals.alertWrongPassword}
            type="text"
            style={{ color: "red" }}
          />
        ) : (
          <Input
            placeholder="Error with password"
            value={Literals.alertWrongPassword}
            type="hidden"
            style={{ color: "red" }}
          />
        )}

        <div className="text-center">
          <Button
            className="my-4"
            color="primary"
            type="submit"
            onClick={this.handleSubmit}
          >
            {Literals.connection}
          </Button>

          {/* <Button href={"/signup"} className="my-4" color="info">
                        Γρήγορη Εγγραφή
                      </Button> */}
        </div>
      </>
    );

    let RenderPhoneNumberLogin = (
      <>
        {this.state.isPhoneTokenVisible === false && (
          <Fragment>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupText>
                  <i className="ni ni-mobile-83" />
                </InputGroupText>

                <Input
                  placeholder="Mobile"
                  type="text"
                  onChange={(e) => this.setPhoneNumber(e.target.value)}
                />
              </InputGroup>
            </FormGroup>

            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                onClick={this.handleSubmitPhoneNumber}
              >
                {Literals.sendPassword}
              </Button>
            </div>
          </Fragment>
        )}

        {this.state.isPhoneTokenVisible && (
          <Fragment>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupText>
                  <i className="ni ni-lock-circle-open" />
                </InputGroupText>

                <Input
                  placeholder={Literals.password}
                  type="text"
                  onChange={(e) => this.setPhoneToken(e.target.value)}
                />
              </InputGroup>
            </FormGroup>

            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                onClick={this.handleSubmitPhoneToken}
              >
                {Literals.connection}
              </Button>
            </div>
          </Fragment>
        )}
      </>
    );

    return (
      // <div className="main-content" style={{ backgroundImage: `url(${dedaBackground})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%", backgroundAttachment: "fixed", height: "100vh" }}>
      <div
        className="main-content"
        style={{
          background: "linear-gradient(270deg, #E53D99, #9525EF)",
          height: "100vh",
        }}
      >
        <div className="header py-7 py-lg-8"></div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card
                className="shadow border-0"
                style={{
                  backgroundColor: "rgba(255,255,255, 0.95)",
                  borderRadius: "10px",
                }}
              >
                <CardBody
                  className="px-lg-5 py-lg-5"
                  style={{
                    borderRadius: "10px",
                    boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.2)",
                  }}
                >
                  <Form role="form">
                    <img
                      src={AssetHelper.Logo.getMain()}
                      className="center-block"
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <br />
                    <h4 style={{ textAlign: "center" }}>
                      Πλατφόρμα διαχείρισης και υποβολής αιτημάτων βλαβών
                    </h4>
                    {GlobalParameters.getIsDefault() && (
                      <h2 style={{ textAlign: "center", fontWeight: "300" }}>
                        {GlobalParameters.Theme.SelectedTheme.ApplicationName}
                      </h2>
                    )}

                    {GlobalParameters.getIsDefault() == false && (
                      <div style={{ marginBottom: "30px" }}></div>
                    )}

                    {this.state.isUserCredentialsLoginVisible &&
                      RenderUserCredentialsLogin}
                    {this.state.isUserPhoneNumberLoginVisible &&
                      RenderPhoneNumberLogin}

                    <div className="text-center">
                      {/* <Button className="my-4" color="primary" type="submit">
                        Σύνδεση
                      </Button> */}

                      {this.state.isUserPhoneNumberLoginVisible && (
                        <Button
                          className="my-4"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              isUserCredentialsLoginVisible: true,
                              isUserPhoneNumberLoginVisible: false,
                              isPhoneTokenVisible: false,
                            });
                          }}
                        >
                          {Literals.connectionWithUsernamePassword}
                        </Button>
                      )}

                      {this.state.isUserCredentialsLoginVisible && (
                        <Button
                          // className="my-4"
                          color="warning"
                          onClick={() => {
                            this.setState({
                              isUserCredentialsLoginVisible: false,
                              isUserPhoneNumberLoginVisible: true,
                              isPhoneTokenVisible: false,
                            });
                          }}
                        >
                          {Literals.connectionWithMobileNumber}
                        </Button>
                      )}

                      {/* <Button href={"/signup"} className="my-4" color="info">
                        Γρήγορη Εγγραφή
                      </Button> */}
                    </div>
                  </Form>
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    {/* <small style={{ color: 'black', display: 'block', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>Ξεχάσατε τον κωδικό σας ;</small> */}
                  </a>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
