import {
  get, getAsync,
  post, postAsync,
  put, putAsync,
  del, deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum
  } from "./apiCallerHelper.core";  

  export async function changeFieldAsync(stepActionId, stepActionData) {
    var route = "/api/stepactions/changefield/" + stepActionId + '/';
    var data = {'data': [stepActionData]}
    return putAsync(route, data);
  }
  export async function getCurrentStepActionAsync(stepActionId) {
    var route = "/api/stepactions/current/" + stepActionId + '/';
    return getAsync(route);
  }

  export async function addNewStepAction({    
    name,
    description,
    type,
    target,
    required,
    isReadonly,
    isVisible,
    stepId,
  }) {
    var route = "/api/stepactions/new/";
    return postAsync(route, {
      name: name,
      description: description,
      // 'priority': this.state.stepActionPriority,
      type: type,
      target: target,
      required: required,
      isReadonly: isReadonly,
      isVisible : isVisible,
      stepId: stepId,
    });
  }  

  export async function deleteWorkflowStepActionAsync(stepId, stepActionId) {    
    var route = "/api/stepactions/delete/" + stepId + '/' + stepActionId + "/";
    return deleteAsync(route, ContentTypeEnum.ApplicationFormUrlEncoded);
  }

  export async function changeOrderAsync(stepId, fieldId, direction) {    
    var route = "/api/stepactions/changeorder/" + stepId + '/' + fieldId + '/' + (direction ? 1 : 0) + '/'
    return putAsync(route);
  }  