export const WorkflowStepIdTypes = {
  /*
    Προσθήκη Εργασιών
    */
  flow3_step_1_extra_work: "262853f7-4b42-4ab1-b00f-d9d32ca6c841", //Προσθήκη Εργασιών
  flow3_step_2_accept_or_decline_extra_work:
    "7c814322-0cf0-4fca-b36e-f2f87217a9c1", //Προσθήκη Εργασιών
  flow3_step3_modify_extra_work: "fc1cfd27-1ee0-4a33-a65a-238d2b13077c",
  flow2_step_1_work_voucher: "f0458a32-530a-427d-a43b-897e0487533a", //"Δελτίο Εργασίας"
  flow2_step_5_confirm_work: "64044b75-7c06-43e3-8a6d-7b4ebd8ec56d",  
};	
